import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ICatalogViewModel, IContentTypesCatalog} from '@core/models/interfaces/icatalog-view-model';
import {IContentCatalogViewModel} from '@core/models/interfaces/i-content-catalog-view-model';

@Component({
  selector: 'app-slider-filter',
  templateUrl: './slider-filter.component.html',
  styleUrls: ['./slider-filter.component.scss']
})
export class SliderFilterComponent implements OnInit {

  private _catalog: ICatalogViewModel;

  contentCatalogViewModel: IContentCatalogViewModel[];

  types: IContentTypesCatalog[] = [];

  current: IContentTypesCatalog;

  @Input()
  isOrganization: boolean;

  @Output()
  filter: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {


    if (this.isOrganization) {
      this.current = null;
    }
  }

  @Input()
  set catalog(value: ICatalogViewModel) {
    this._catalog = value;

    if (this._catalog && this._catalog.contentTypesCatalog) {
      this.types = this._catalog.contentTypesCatalog;
      var useDefault = this.types.find((f) => {
        return f.type === this._catalog.defaultContentType;
      });
      if (useDefault) {
        this.current = useDefault;
        this.types.splice(0, 0, this.types.splice(this.types.indexOf(useDefault), 1)[0]);
      } else {
        const Ptype = this.types.find((f) => {
          return f.type === this.types[0].type;
        });
        if (Ptype) {
          this.types.splice(0, 0, this.types.splice(this.types.indexOf(Ptype), 1)[0]);
        }
        this.current = this.types[0];
      }

    }

  }

  get catalog(): ICatalogViewModel {
    return this._catalog;
  }

  slideFilter(type) {
    if (type !== this.current) {
      this.current = type;
      this.filter.emit(this.current.type);
    }
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  getContentType(current: IContentTypesCatalog)
  {
    switch (current.type) {
      case 'ebook':
      case 'audiobook':
        return 'book'
      case 'podcast':
        return 'podcast'
      case 'podcastitem':
        return 'podcastitem'
      case 'article':
        return 'article'
      default:
        break;
    }
  }
}
