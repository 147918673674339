// Tipado de servicios
export type FromOutSide = Readonly<{
  result: ResultRequest;
  userInfo: UserChecked;
}>;

export type UserLoggedIn = Readonly<{
  jwtToken: string;
  refreshToken: string;
  expirationToken?: string;
  result: ResultRequest;
  userInfo: any;
}>;

export type ValidateToken = Readonly<{
  email: string;
  userName: string;
  result: ResultRequest;
}>;

export type StartMigration = Readonly<{
  result: ResultRequest;
}>;

export type StartForgotPassword = Readonly<{
  result: ResultRequest;
}>;

export type  responseResultEnum = Readonly<{
  result: ResultRequest;
}>;

export interface ResultRequest {
  result: string;
  resultEnum: number;
}

export interface UserChecked {
  email: string;
  firstName: string;
  id: string;
  isMigrated: boolean;
  lastName: string;
}

export interface NewAccount {
  email: string;
  password: string;
  passwordConfirmation: string;
  userName: string;
  gender: string;
  birthdayDate: Date;
  country: string;
  imageAvatar: Blob;
}
export interface TokenAuth {
  jwtToken: string;
  refreshToken: string;
}

// Tipado modulo Auth
export interface UserPayload {
  email: string;
  password: string;
}
export interface ResetPasswordPayload {
  userid: string;
  password: string;
  passwordconfirmation: string;
  MailingUserToken: string;
}

export interface SendFormData {
  type: string;
  data: any;
}

export interface BreadCrumbAuth {
  stepBlocked: boolean;
  statusCheked: boolean;
  canClick: boolean;
  name: string;
  type: string;
}

// Opciones disponibles

export enum BaseResult {
  OK = 1,
  EMAIL_REQUIRED = 2,
  PASSWORD_REQUIRED = 3,
  USER_NAME_REQUIRED = 4,
  GENDER_REQUIRED = 5,
  BIRTHDAY_DATE_REQUIRED = 6,
  COUNTRY_REQUIRED = 7,
  PASSWORD_INCORRECT = 8,
  USER_NOT_FOUND = 9,
  USER_NEEDS_MIGRATE = 10,
  REFRESH_TOKEN_NOT_FOUND = 11,
  REFRESH_TOKEN_IN_ACTIVE = 12,
  TOKEN_EXPIRED = 13,
  PASSWORD_CONFIRMATION_REQUIRED = 14,
  PASSWORD_AND_CONFIRMATION_NOT_EQUALS = 15,
  PASSWORD_MUSTBE_EIGHTCHARACTERS_ATLEAST = 16,
  USER_ALREADY_MIGRATED = 17,
  USER_ALREADY_EXISTS = 18,
  INVALID_EMAIL = 19,
  PASSWORD_CHANGED = 20,
  USER_HAS_BEEN_MIGRATED = 21,
}

export enum StepAuth {
  WELCOME_AUTH = 'WelcomeAuth',
  CHECK_PASSWORD = 'CheckPassword',
  RESET_PASSWORD = 'ResetPassword',
  USER_MIGRATION = 'UserMigration',
  NEW_ACCOUNT = 'NewAccount',
  FORGOT_PASSWORD = 'ForgotPassword',
  CHANGE_PASSWORD = 'ChangePassword'
}

export enum TextBreadCrumb {
  EMAIL = 'Correo Electrónico',
  PASSWORD = 'Contraseña',
  VERIFICATION = 'Verificación',
  NEW_ACCOUNT = 'Nueva Cuenta',
  FORGOT_PASSWORD = 'Recuperar Contraseña',
  CHANGE_PASSWORD = 'Crear Contraseña'
}

export enum ResponseModalAuth {
  OK = 'ok',
  ERROR_EMAIL = 'errorEmail',
  START_MIGRATION = 'startMigration',
  SEND_EMAIL_RESTORE_PASSWORD = 'restorePassword',
  FORGOT_PASSWORD = 'forgotPassword',
  NEW_ACCOUNT = 'NewAccount',
}

export enum ErrorMessages {
  PASSWORDINCORRECT = 'Tu contraseña actual es incorrecta, inténtalo nuevamente.',
  PASSWORDCONFIRMATIONREQUIRED = 'La confirmación de la contraseña es requerida.'
}

export const WELCOME_AUTH = {
  stepBlocked: false,
  statusCheked: false,
  canClick: false,
  name: TextBreadCrumb.EMAIL,
  type: StepAuth.WELCOME_AUTH,
};

export const ENTER_PASSWORD = [
  {
    ...WELCOME_AUTH,
    statusCheked: true,
    canClick: true,
  },
  {
    stepBlocked: false,
    statusCheked: false,
    canClick: false,
    name: TextBreadCrumb.PASSWORD,
    type: StepAuth.CHECK_PASSWORD,
  },
];


export const RESET_PASSWORD = [
  {
    ...WELCOME_AUTH,
    statusCheked: true,
    stepBlocked: true,
    canClick: false,
  },
  {
    stepBlocked: true,
    statusCheked: true,
    canClick: false,
    name: TextBreadCrumb.VERIFICATION,
    type: StepAuth.CHECK_PASSWORD,
  },
  {
    stepBlocked: false,
    statusCheked: false,
    canClick: false,
    name: TextBreadCrumb.PASSWORD,
    type: StepAuth.RESET_PASSWORD,
  },
];

export const NEW_ACCOUNT = [
  {
    ...WELCOME_AUTH,
    statusCheked: true,
    canClick: true,
  },
  {
    stepBlocked: false,
    statusCheked: false,
    canClick: false,
    name: TextBreadCrumb.NEW_ACCOUNT,
    type: StepAuth.NEW_ACCOUNT,
  }
];

export const NEW_ACCOUNT_MISIONERO = [
  {
    ...WELCOME_AUTH,
    statusCheked: true,
    canClick: false,
  },
  {
    stepBlocked: false,
    statusCheked: false,
    canClick: false,
    name: TextBreadCrumb.NEW_ACCOUNT,
    type: StepAuth.NEW_ACCOUNT,
  }
];

export const FORGOT_PASSWORD = [
  {
    ...WELCOME_AUTH,
    statusCheked: true,
    canClick: true,
  },
  {
    ...ENTER_PASSWORD[1],
    statusCheked: true,
    canClick: true,
  },
  {
    stepBlocked: false,
    statusCheked: false,
    canClick: false,
    name: TextBreadCrumb.FORGOT_PASSWORD,
    type: StepAuth.FORGOT_PASSWORD,
  }
];

export const CHANGE_PASSWORD = [
{
  ...WELCOME_AUTH,
  statusCheked: true,
  stepBlocked: true,
  canClick: false,
},
{
  ...ENTER_PASSWORD[1],
  statusCheked: true,
  stepBlocked: true,
  canClick: false,
},
{
  ...FORGOT_PASSWORD[2],
  statusCheked: true,
  stepBlocked: true,
  canClick: false,
},
{
  stepBlocked: false,
  statusCheked: false,
  canClick: false,
  name: TextBreadCrumb.CHANGE_PASSWORD,
  type: StepAuth.CHANGE_PASSWORD
}];
