import {Component, Input, OnInit} from '@angular/core';
import {IArticleGroup} from '@core/models/interfaces/i-articles-view-model';
import {Router} from '@angular/router';
@Component({
  selector: 'app-article-group',
  templateUrl: './article-group.component.html',
  styleUrls: ['./article-group.component.scss']
})
export class ArticleGroupComponent implements OnInit {

  @Input()
  clazz = 'horizontal-relative';

  @Input()
  title = 'Artículos Destacados';

  @Input()
  categoryId = null;

  @Input()
  isFirst: boolean; 

  @Input()
  showSeeAll: boolean = true;

  constructor(private router: Router) { }



  ngOnInit() {
  }
  goToCategoryPage(){
    const url: string[] = ['/', 'category'];
    url.push('article');
    url.push(this.categoryId);
    this.router.navigate(url);
    // swal.fire('Coming soon..',url.toString(), 'info');
  }
}
