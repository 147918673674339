import {AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { VideoV3Service } from '@services/video-v3.service';
import {IVVideo} from '@interfaces/i-v-video';
import {IIndexer} from '@interfaces/i-indexer';

@Component({
  selector: 'app-video-indexer',
  templateUrl: './video-indexer.component.html',
  styleUrls: ['./video-indexer.component.scss']
})
export class VideoIndexerComponent implements OnInit, AfterViewChecked {

  private static instance: VideoIndexerComponent;
  _video: IVVideo;
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() clickIndex: EventEmitter<number> = new EventEmitter<number>();
  @Input()
  fromSearch = false;
  @Input()
  search = '';
  @Input() index: string;
  @Input() isLast: boolean = false;
  @Input() isOdd: boolean = false;
  @Input() isEven: boolean = false;
  @Input() set currentTime(time: number) {
    if(!!time) this.timeUpdate(time);
  };

  isSearchOpen: boolean = false;
  indexer: IIndexer[];
  indexerSearch: IIndexer[] = [];
  showIndexesClose: boolean = false;
  currentIndexer: IIndexer[];
  counter: number = 0;
  removeDiacritics: any;
  default: any = 0;

  @ViewChild('transcripcions', { static: true }) public bodyContainer: ElementRef;

  constructor(
    private indexerServices: VideoV3Service
  ) {
  }

  ngOnInit() {
    this.removeDiacritics = require('diacritics').remove;
  }

  ngAfterViewChecked() {
    this.scrollIndexer();
  }

  @Input()
  set video(value: IVVideo) {
    this._video = value;
    this.showIndexes(this.search);
    if (VideoIndexerComponent.instance) {
      VideoIndexerComponent.instance.close.emit();
    }
    VideoIndexerComponent.instance = this;
  }

  get video(): IVVideo {
    return this._video;
  }

  clearIndexes() {
    this.indexer = this.indexerSearch;
    this.search = '';
    this.showIndexesClose = false;
  }

  filterIndexes() {
    this.indexer = this.indexerSearch.filter((search) => {
      return search.lines.find((line) => {
        const searchTerm = this.removeDiacritics(this.search);
        const lineTerm = this.removeDiacritics(line);
        return new RegExp('\\b' + searchTerm.trim().toLowerCase() + '\\b').test(lineTerm.toLowerCase());
      });
    });
    const titleSearch = this.indexerSearch.filter((item) => {
      const searchTerm = this.removeDiacritics(this.search);
      const titleTermn = this.removeDiacritics(item.title);
      return titleTermn.trim().toLowerCase().search(searchTerm.trim().toLowerCase()) !== -1;
    });
    if (titleSearch.length) {
      this.indexer = this.indexer.concat(titleSearch);
    }
    if (this.search === '') {
      this.showIndexesClose = false;
    } else {
      this.showIndexesClose = true;
    }
  }

  timeUpdate(time: number) {
    if(this.indexer != null) {
      const t = this.indexer.find(f => {
        const sec = f.startTime / 1000;
        return Math.trunc(time) === Math.trunc(sec);
      })
      if (t) {
        const myElement = document.getElementById('indexTime' + (Math.trunc(t.startTime / 1000)));
        if (!!myElement)  {
          if (this.default !== myElement.offsetTop) {
            this.default = myElement.offsetTop;
            this.bodyContainer.nativeElement.scrollTop = myElement.offsetTop;
          }
        }
      }
    }
  }

  private showIndexes(value) {
    if (this.video) {
      this.indexerServices.getIndexes(this.video.id).subscribe((indexer: IIndexer[]) => {
        this.indexer = this.parseIndexes(indexer);
        this.indexerSearch = this.indexer;
        if (this.search) {
          this.filterIndexes();
        }
      }, () => this.indexer = []);
    }
  }

  toggleIndexes() {
    this.close.emit(false);
  }

  searchIndexes(value) {
    this.showIndexes(value);
  }

  onClickIndexer(index: IIndexer) {
    this.clickIndex.emit(index.startTime);
  }

  scrollIndexer() {
    if (this.indexer) {
      this.currentIndexer = this.indexer.filter(
        index => Math.trunc(this.currentTime) === Math.trunc(index.startTime / 1000)
      );
    }
  }

  parseIndexes(indexer: IIndexer[]): IIndexer[] {
    const result: IIndexer[] = [];

    indexer.forEach(item => {
      let line = item.lines[0];
      let tabs: number = 0;
      let text = '';
      let title = '';
      if (line.indexOf('\\t') !== -1) {
        tabs = (line.match(/\\t/g) || []).length
        line = this.removeCharacter(line, '\\t');
      }
      if (line.indexOf('#') !== -1) {
        const titleSplit = line.split('#');
        if (titleSplit.length > 0) {
          title = titleSplit[0];
          text = titleSplit[1];
        } else {
          title = line;
        }
      } else {
        title = line;
      }
      result.push({
        startTime: item.startTime,
        endTime: item.endTime,
        lines: [text],
        title: title,
        tabs: tabs
      })
    });
    return result;
  }

  removeCharacter(text: string, character: string): string {
    text = text.replace('\\t', '');
    if (text.indexOf(character) !== -1) {
      return this.removeCharacter(text, character);
    } else {
      return text;
    }
  }
}
