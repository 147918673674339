import { Pipe, PipeTransform } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Pipe({
  name: 'ellipsis',
})
export class EllipsisPipe implements PipeTransform {
  constructor(private deviceService: DeviceDetectorService) {}

  transform(value: string): string {
    if (this.deviceService.isDesktop() && value.length > 240) {
      return value.substring(0, 240).concat('...');
    }
    if (this.deviceService.isMobile() && value.length > 150) {
      return value.substring(0, 125).concat('...');
    }
    return value;
  }
}
