<div class="main-container content-filter {{classType}}" [class.isMobile]="isMobile" *ngIf="active">
  <div class="content">
    <div class="categorySection">
      <div class="select-filter" [class.not-active]="!isOpenOption">
        <div class="select-default" (click)="toggleOpenOption()">
          <span class="title">{{typeSelected.label}}</span>
          <i class="iconlace" [ngClass]="{'iconlace-check': isOpenOption, 'iconlace-chevron-down': !isOpenOption}"></i>
        </div>
        <div class="select-option" *ngIf="isOpenOption">
          <ng-container *ngFor="let item of listFilters">
            <div class="select-item" *ngIf="(typeSelected.type !== item.type && item.visible) && isBibleSite(item)"
              (click)="changeFilter(item)">
              <i class="iconlace {{item.icon}}"></i>
              <span>{{item.label}}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="searchSection">
      <button type="button" class="filter-btn" *ngIf="showFilter" (click)="toggleFilter()">
        <i class="iconlace iconlace-filter-mixer"></i>
      </button>
      <div class="inputSection" [ngClass]="{'expand-input': !showFilter}">
        <input autocomplete="off" class="form-control search-input"
          [(ngModel)]="advanceSearchService.searchText" (keydown.enter)="search();" (keydown.tab)="toogleFocus(false)"
          type="text" maxlength="50" (click)="toogleFocus()" (keypress)="toogleFocus()" (blur)="toogleBlur()"
          #searchInput id="searchTextInput" placeholder="Busca programas, pastores o temas..."
          [class.search-text]="advanceSearchService.searchText" />
        <i class="search iconlace iconlace-chevron-next" *ngIf="!advanceSearchService.searchText"></i>
        <span class="close-search" (click)="advanceSearchService.searchText = ''; searchHistoryActive = false"
          *ngIf="advanceSearchService.searchText"><i class="iconlace iconlace-cross"
            style="cursor: pointer;"></i></span>
        <!--  -->
        <app-search-user-history *ngIf="isLogin || isMobileRoute()" [active]="searchHistoryActive"
          [inputActive]="toogleFocusValue" [newUserSearch]="searchTerm" (emitTriggerSearch)="research($event)">
        </app-search-user-history>
      </div>
    </div>
  </div>
</div>
