import { Injectable } from '@angular/core';
import {RestClient} from '../rest-client';
import {IRestMethod, RestAction, RestParams, RestRequestMethod} from 'rest-core';
import {environment} from '../../../environments/environment';
import {EnlaceRestHandler} from '../enlace-rest-handler';
import {AuthenticationService} from '../authentication.service';
import {IAudiobookViewModel} from '../models/v2/i-audiobook-view-model';
import { ILaunchbookResponseModel } from '../models/v2/i-launchbook-response-model';

@Injectable({
  providedIn: 'root'
})
@RestParams({
  // IRestParams
  pathPrefix: environment.webApi + 'v2/audiobook'
})
export class AudiobookService extends RestClient {

  @RestAction({
    path: '/view/{!audiobookId}',
    method: RestRequestMethod.Get
  })
  view: IRestMethod<{audiobookId: string}, IAudiobookViewModel>;

  @RestAction({
    path: '/launchbook',
    method: RestRequestMethod.Post
  })
  launchAudiobook: IRestMethod<{bookId: string, pageNumber: number}, ILaunchbookResponseModel>;

  constructor(restHandler: EnlaceRestHandler, authService: AuthenticationService) {super(restHandler, authService);}

}
