import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  transform(value: number): string {
    const hours: number = Math.floor(value / 3600);
    const minutes: number = Math.floor((value % 3600) / 60);
    const seconds: number = Math.floor(value % 60);
    let formattedTime = '';

    if (!value && value !== 0) return '00:00';
    
    if (hours > 0) {
      formattedTime += `${hours}:${this.pad(minutes)}:${this.pad(seconds)}`;
    } else {
      formattedTime += `${this.pad(minutes)}:${this.pad(seconds)}`;
    }
    
    return formattedTime;
  }

  private pad(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

}
