import { ChangeDetectionStrategy, ChangeDetectorRef, Component,
  Input, OnInit,
} from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ContentV3Service } from "@services/content-v3.service";
import { IUser } from "@interfaces/i-user.interface";
import { EnumAcquireByAccessCode } from "@app/core/models/interfaces/i-search-request-model";
import { PaymentSuccessComponent } from "@app/shared/components/modals/payment-success/payment-success.component";
import { PaymentErrorComponent } from "@app/shared/components/modals/payment-error/payment-error.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LocalStorage } from "ngx-webstorage";
import { HomeCatalogStateService } from '@app/shared/services/home-catalog-state.service';
import { ContentDetail } from "@app/core/models/interfaces/content-detail";
import { LoadingOverlayService } from "@app/shared/services/loading-overlay.service";

@Component({
  selector: "app-gift-code-modal",
  templateUrl: "./gift-code-modal.component.html",
  styleUrls: ["./gift-code-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GiftCodeModalComponent implements OnInit {
  @LocalStorage("user") private user: IUser;

  @Input() contentDetail: ContentDetail;
  @Input() contentType: string;

  form: UntypedFormGroup;
  isInvalidCode: boolean = false;

  gradientColors = {
    program: ["#6C00C0", "#F53D55"],
    video: ["#6C00C0", "#F53D55"],
    ebook: ["#6C00C0", "#F9B000"],
    podcast: ["#6C00C0", "#335CFF"],
  };

  constructor(
    private catalogState: HomeCatalogStateService,
    private contentV3Service: ContentV3Service,
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private loadingOverlayService: LoadingOverlayService
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  get codeField(): AbstractControl {
    return this.form.get("code");
  }

  get formDisabled(): boolean {
    return this.form.invalid;
  }

  close(): void {
    this.activeModal.close();
  }

  sendFormData(): void {
    this.loadingOverlayService.showLoadingOverlay();
    this.isInvalidCode = false;
    this.contentV3Service
      .acquireByAccessCode({
        contentId: this.contentDetail.id,
        accessCode: this.codeField.value,
        userId: this.user.id,
      })
      .subscribe(
        (response) => {
          this.resultAccessCode(response.result)
          this.loadingOverlayService.hideLoadingOverlay();
          this.cdr.markForCheck();
        },
        () => {
          this.openErrorModal('error');
        }
      );
  }

  resultAccessCode(result: EnumAcquireByAccessCode): void {
    if (result === EnumAcquireByAccessCode.INVALID_CODE) {
      this.openErrorModal('inválido');
    }
    if (result === EnumAcquireByAccessCode.EXPIRED_CODE) {
      this.openErrorModal('expirado');
    }
    if (result === EnumAcquireByAccessCode.ERROR) {
      this.openErrorModal('error');
    }
    if (result === EnumAcquireByAccessCode.SUCCESS) {
      this.openSuccessModal("purchase");
    }
  }

  openSuccessModal(successType: string){
    const modalRef = this.modalService.open(PaymentSuccessComponent, {
      windowClass: "dark-modal modal-gc-success",
      backdropClass: "light-black-backdrop",
    });

    modalRef.componentInstance.contentDetail = this.contentDetail;
    modalRef.componentInstance.action = successType;
    modalRef.componentInstance.fromGiftCode = true;
    modalRef.componentInstance.contentType = this.contentType;

    this.cdr.markForCheck();
    this.activeModal.close();
    this.catalogState.clearCatalog();
    this.loadingOverlayService.hideLoadingOverlay();
  }

  openErrorModal(errorType: string){
    const modalRef = this.modalService.open(PaymentErrorComponent, {
      windowClass: "dark-modal modal-gc-error over-lapping-modal",
      backdropClass: "light-black-backdrop",
    });
    modalRef.componentInstance.errorType = errorType;
    modalRef.componentInstance.fromGiftCode = true;
    this.cdr.markForCheck();
    this.activeModal.close();
    this.loadingOverlayService.hideLoadingOverlay();
  }

  isInValid(control: AbstractControl): boolean {
    return control.touched && !!control.errors;
  }

  keyFunc(event: any): void {
    this.isInvalidCode = false;
  }

  getGradientBackground(): string {
    const gradient = this.gradientColors[this.contentType ?? "ebook"];
    return `linear-gradient(to right, ${gradient[0]}, ${gradient[1]})`;
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      code: ["", [Validators.required, Validators.minLength(6)]],
    });
  }
}
