import { Pipe, PipeTransform } from '@angular/core';
import { ContentType } from '@enums/type-content.enum';

@Pipe({
  name: 'typeContent'
})
export class TypeContentPipe implements PipeTransform {

  transform(value: string): string {
    let title: string = '';
    switch (value) {
      case ContentType.video:
        title = 'EPISODIO'
        break;
      case ContentType.program:
        title = 'PROGRAMA'
        break;
      case ContentType.ebook:
        title = 'LIBRO DIGITAL'
        break;
      case ContentType.audiobook:
        title = 'AUDIO LIBRO'
        break;
      default:
        break;
    }
    return title;
  }

}
