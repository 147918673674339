import {AbstractControl, AsyncValidatorFn, ValidationErrors,} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MailingService } from '@services/mailing.service';

export function emailIsValid(
  mailingService: MailingService
): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return mailingService
    .verifyEmail(control.value).pipe(
      map(isValid =>
        typeof isValid === 'boolean' && isValid
          ? null
          : { isInvalidEmail: true }
      ),
      catchError(() => of(null))
    );
  };
}
