import {Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';
import {AdvanceSearchService} from '../services/advance-search.service';
import {Subscription} from 'rxjs';
import { ChangeFilter } from 'app/api-client/models/change-filter-model';
import { DropdownSelectList } from '@app/core/models/interfaces/i-dropdown-select.interface';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, OnDestroy {

  private _filters: any[];

  @Input() filtersSelected: any[] = [];
  @Input() isNewSection: boolean = false;
  @Input() isMobile: boolean = false;
  @Input() currentFilterIssueDate: DropdownSelectList;
  @Input() isLibrary = false;

  @Output()
  closeFilterEvent: EventEmitter<any[]> = new EventEmitter();

  @Output()
  changeFilter: EventEmitter<any> = new EventEmitter();
  @Output()
  hideBarFilters: EventEmitter<any> = new EventEmitter();
  @Output()
  closeFilter: EventEmitter<boolean> = new EventEmitter();
  @Output()
  listFilters: EventEmitter<any[]> = new EventEmitter();
  resetFilterSubject: Subscription;

  @ViewChild('bodyFilter') bodyFilter: ElementRef;

  constructor(private advanceSearchService: AdvanceSearchService, private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.resetFilterSubject = this.advanceSearchService.resetFilterSubject.subscribe(() => {
      this.filters = [];
      this.filtersSelected = [];
    });
  }

  ngOnDestroy() {
    if (this.resetFilterSubject) {
      this.resetFilterSubject.unsubscribe();
    }
  }

  @Input()
  set filters(values: any[]) {
    this._filters = values.sort((value1: any, value2: any) => value1.order - value2.order);
  }

  get filters() {
    return this._filters;
  }

  get isThereRecents(): boolean {
    return this.filtersSelected.filter(item => item.filter.type === 'recent').length > 0;
  }

  addFilter(filter: any) {
    if (this.isMobile) {
      this.hideBarFilters.emit();
    }

    this.resetScrollTop();
    filter.filter.visible = false;
    this.filtersSelected.push(filter);
    let changeFilter = new ChangeFilter();
    changeFilter.type = 'collection';
    changeFilter.value = this.filtersSelected;
    this.changeFilter.emit(changeFilter);
    this.listFilters.emit(this.filtersSelected);
  }

  changePrice(value: any, filter: any) {
    this.resetScrollTop();
    if (this.isMobile) {
      this.hideBarFilters.emit();
    }
    if(value > 0) {
      if(this.filtersSelected.filter(x => x.filter.type == "price").length > 0) {
        this.filtersSelected.filter(x => x.filter.type == "price")[0].filter.value = value;
      } else {
        filter.filter.visible = false;
        filter.value = value;
        this.filtersSelected.push({filter: filter});
      }
    } else {
      if (value != 0)
        this.removeFilter(filter);
    }
    let changeFilter = new ChangeFilter();
    changeFilter.type = 'price';
    changeFilter.value = value;
    if (value == 0) {
      this.filtersSelected.splice(this.filtersSelected.indexOf(filter),1);
      filter.filter.visible = true;
    }
    this.changeFilter.emit(changeFilter);
    this.listFilters.emit(this.filtersSelected);
  }

  changeRating(value: any, filter: any) {
    this.resetScrollTop();
    if (this.isMobile) {
      this.hideBarFilters.emit();
    }
    if (!this.validateFilter('rating', value)) {
      if(this.filtersSelected.filter(item => item.filter.type === 'rating').length > 0) {
        this.filtersSelected = this.filtersSelected.filter(item => item.filter.type !== 'rating');
      }
      filter.filter.visible = false;
      this.filtersSelected.push({ filter: filter, value: value, item: { name: 'Valoración (' + value + ')', visible: false }});
      let changeFilter = new ChangeFilter();
      changeFilter.type = 'rating';
      changeFilter.value = value;
      this.changeFilter.emit(changeFilter);
      this.listFilters.emit(this.filtersSelected);
    }
  }

  changeIssueDate(value: string): void {
    this.resetScrollTop();
    if (this.isMobile) {
      this.hideBarFilters.emit();
    }
    const filterIssueDate = this.filters.find(items => items.type === 'date-issue') || null;
    if (filterIssueDate) {
      if (!this.validateFilter('date-issue', value)) {
        if(this.filtersSelected.filter(item => item.filter.type === 'date-issue').length > 0) {
          this.filtersSelected = this.filtersSelected.filter(item => item.filter.type !== 'date-issue');
        }
        const filter = filterIssueDate.items.find(item => item.value === value) || null;
        if (filter) {
          this.filtersSelected.push({filter: {
            type: 'date-issue',
            ...filter
          }});
          const changeFilter = new ChangeFilter();
          changeFilter.type = 'date-issue';
          changeFilter.value = value;
          this.changeFilter.emit(changeFilter);
          this.listFilters.emit(this.filtersSelected);
        }
      }
    }
  }

  validateFilter(type: string, value: string | number): boolean {
    const findFilter =
      this.filtersSelected
      .filter(item => item.filter.type === type)
      .find(item => item.value === value);
    return !!findFilter;
  }

  get getFiltersToClear() {
    return this.filtersSelected.filter(x => x.filter.type != 'rating' && x.filter.type != 'recent');
  }

  selectRecents(filter: any) {
    this.resetScrollTop();
    if (this.isMobile) {
      this.hideBarFilters.emit();
    }
    filter.visible = false;

    const IDX_RECENT = this.filters.findIndex(rec => rec.type == 'recent');
    if (IDX_RECENT > -1) {
      this._filters[IDX_RECENT].visible = false;
      this.filters[IDX_RECENT].visible = false;
    }
    
    if (this.filtersSelected && !this.filtersSelected.some(rec => rec.filter.type === 'recent')) {
      this.filtersSelected.push({ filter: filter, item: { name: 'Recientes', visible: false }});
      let changeFilter = new ChangeFilter();
      changeFilter.type = 'recents';
      changeFilter.value = 1;
      this.changeFilter.emit(changeFilter);
      this.listFilters.emit(this.filtersSelected);
    }
  }

  removeFilter(filter: any) {
    if (filter.filter.type === 'date-issue') {
      this.currentFilterIssueDate = null;
    }
    this.filtersSelected.splice(this.filtersSelected.indexOf(filter),1);
    filter.filter.visible = true;
    let changeFilter = new ChangeFilter();
    changeFilter.type = 'collection';
    changeFilter.value = this.filtersSelected;
    this.changeFilter.emit(changeFilter);
    //this.listFilters.emit(this.filtersSelected);
  }

  getRecentsCount(recents: any) {
    if(recents != null) {
      if(recents.items.length > 0) {
        return recents.items[0].count;
      }
    }
    return 0;
  }

  getLabel(item:any): string {
    if (item.organization) {
      return item.organization;
    } else if (item.name) {
      return item.name;
    } else if (item.fullName) {
      return item.fullName;
    }
    return '';
  }

  clearFilters(emit: boolean = true) {
    // Para el caso de web se borran los filtros y se cierra la barra lateral. Para el caso de mobile solo se minimiza
    if (this.isMobile) {
      this.filtersSelected.length = 0;
      if (emit) {
        this.changeFilter.emit();
      }
      this.closeFilters();
      this.hideBarFilters.emit();
    } else {
      this.filtersSelected.forEach((value)=> {
        value.filter.visible = true;
      });
      this.filtersSelected.length = 0;
      if (emit) {
        this.changeFilter.emit();
        this.listFilters.emit(this.filtersSelected);
      }
      this.closeFilters();
    }
    this.currentFilterIssueDate = null;
  }

  public closeFilters(): void {
    this.closeFilterEvent.emit(this.filtersSelected);
  }

  closeFiltersMobile() {
    this.closeFilterEvent.emit(this.filtersSelected);
    // this.advanceSearchService.changeOpenFilter(false);
  }

  public resetScrollTop(): void {
    if (!!this.bodyFilter) this.bodyFilter.nativeElement.scrollTo(0,0);
  }
}
