import { Pipe, PipeTransform } from '@angular/core';
import {IContentImage} from '@core/models/interfaces/i-content-image';

@Pipe({
  name: 'imageByType'
})
export class ImageByTypePipe implements PipeTransform {

  transform(value: IContentImage[], type: string): any {
    const ci = value.find((f) => {
      return f.image.imageType.name === type
    });
    if (ci) {
      return ci.image.url;
    }
    return null;
  }

}
