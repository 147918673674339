import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment as ENV } from '@environment/environment';

import { IVideoUserView } from '@interfaces/i-video-user-view';
import { ITranscription } from '@interfaces/i-transcriptions';
import { IVVideo } from '@interfaces/i-v-video';
import { IIndexer } from '@interfaces/i-indexer';

import { UserStateService } from '@services/user-state.service';

@Injectable({
  providedIn: 'root',
})
export class VideoV3Service {

  private API = `${ENV.webApi}v2/video`;
  private APIV3 = `${ENV.webApi}v3/video`;

  constructor(
    private http: HttpClient,
    private userStateService: UserStateService
  ) { }

  public getNextView(videoId: string): Observable<IVVideo> {
    const url = `${this.API}/view/next/${videoId}`;
    return this.http.get<IVVideo>(url);
  }

  public getView(videoId: string): Observable<IVVideo> {
    const url = `${this.API}/view/${videoId}`;
    return this.http.get<IVVideo>(url);
  }

  public getVideoUserView(videoId: string): Observable<IVideoUserView> {
    const url = `${this.API}/get/userview/${videoId}`;
    return this.http.get<IVideoUserView>(url);
  }

  public getTranscriptions(videoId: string, language: string): Observable<ITranscription[]> {
    const url = `${this.API}/caption/${videoId}/${language}`;
    return this.http.get<ITranscription[]>(url);
  }

  public getIndexes(videoId: string): Observable<IIndexer[]> {
    const url = `${this.API}/index/${videoId}`;
    return this.http.get<IIndexer[]>(url);
  }

  private finalUpdateUserVideoView({ videoId, second }): Observable<IVideoUserView> {
    const url = `${this.APIV3}/userview/${videoId}/${second}`;
    return this.http.get<IVideoUserView>(url)
      .pipe(tap(video => this.userStateService.updateUserVideoView(video)));
  }

  public updateUserVideoView(videoUserViewData: any): void {
    this.finalUpdateUserVideoView(videoUserViewData).subscribe();
  }

}
