import { 
  Directive, 
  EventEmitter, 
  HostListener, 
  Input, 
  OnDestroy, 
  OnInit, 
  Output, 
  Renderer2 
} from '@angular/core';

@Directive({
  selector: '[appScrollListener]'
})
export class ScrollListenerDirective implements OnInit, OnDestroy{

  @Input('appScrollListener') canReadScroll!: boolean;
  @Input() fromAnotherElement: boolean = false;
  @Output() scrollListener: EventEmitter<void> = new EventEmitter();

  constructor(
    private renderer: Renderer2,
  ){}

  private unlistener: () => void;

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    if (this.canReadScroll && !this.fromAnotherElement) {
      this.scrollListener.emit();
    }
  }

  ngOnInit(): void {
    const modalElement = document.querySelector(".modal-detail-content");
    if (modalElement) {
      this.unlistener = this.renderer.listen(modalElement, 'scroll', () => {
        if (this.canReadScroll) {
          this.scrollListener.emit();
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.unlistener) {
      this.unlistener();
    }
  }
}
