import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-btn',
  templateUrl: './card-btn.component.html',
  styleUrls: ['./card-btn.component.scss']
})
export class CardBtnComponent implements OnInit {
  @Input()
  icon: string = '';

  @Input()
  text: string = '';

  @Input('image')
  imageUrl: string = '';

  constructor() { }

  ngOnInit() {
  }

}
