<div *ngIf="!fromGiftCode" class="payment-sucess">
  <div class="payment-header">
    <button type="button" class="payment-header-cross" aria-label="Close" (click)="actionNo()">
      <span class="payment-header-figure" aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="payment-content">
    <div class="payment-avatar">
      <i class="iconlace iconlace-checkmark-circle"></i>
    </div>
    <div class="payment-title-container">
      <h1 class="payment-title">
        ¡Has {{title}} este contenido satisfactoriamente!
      </h1>
      <p *ngIf="isRent" class="payment-subtitle">
      Tienes {{contentPPV?.rentalDays}} días para disfrutarlo a partir del momento en que lo inicies.
      </p>
      <p class="payment-subtitle">
      ¿Quieres {{text}} este contenido ya?
      </p>
    </div>
    <div class="payment-button">
      <button type="button" class="payment-button-play" [style.background]="buttonStyle" (click)="actionYes()">
        <i class="iconlace" [ngClass]="buttonIcon"></i>
        {{buttonText}}
      </button>
      <button type="button" class="payment-button-accept" (click)="actionNo()">No, después</button>
    </div>
    <div class="payment-info">
      <p class="payment-info-text">Siempre puedes ver tus compras en</p>
      <a class="payment-link"  (click)="goToMyContent()">
        Mis contenidos.
      </a>
    </div>
  </div>
</div>

<div *ngIf="fromGiftCode" class="payment-sucess" [ngClass]="{'payment-sucess-gf': fromGiftCode}">
  <div class="payment-header">
    <button type="button" [ngClass]="{'payment-header-cross-gf': fromGiftCode}" class="payment-header-cross" aria-label="Close" (click)="actionNo()">
      <span class="payment-header-figure" aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="payment-content-gf">
    <div class="payment-avatar">
      <i class="iconlace iconlace-checkmark-circle"></i>
    </div>
    <div class="payment-title-container">
      <h1 class="payment-title">
        ¡Éxito!</h1>
      <p class="payment-subtitle-gf">
        El código de acceso es válido y ahora tienes acceso
        a {{contentDetail.title}}.
      </p>
      <p class="payment-subtitle-gf-secundary">
        ¿Quieres {{text}} este contenido ya?
      </p>
    </div>
    <div class="payment-button">
      <button type="button" class="payment-button-play" [style.background]="buttonStyle" (click)="actionYes()">
        <i class="iconlace" [ngClass]="buttonIcon"></i>
        {{buttonText}}
      </button>
      <button type="button" class="payment-button-accept" (click)="actionNo()">No, después</button>
    </div>
    <div class="payment-info">
      <p class="payment-info-text">Siempre puedes ver tus compras en</p>
      <a class="payment-link" (click)="goToMyContent()">
        Mis contenidos.
      </a>
    </div>
  </div>
</div>
