import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';

import { ALLOW_PPV_TYPES } from '@constants/ppv-info.constants';
import { IUser } from '@interfaces/i-user.interface';
import { TypePropertyLocalStorage } from '@enums/type-property-localstorage.enum';

@Injectable({
  providedIn: 'root',
})
export class PPVContentService {

  @LocalStorage(TypePropertyLocalStorage.USER) private _user: IUser;

  allowPPVTypes: string[] = ALLOW_PPV_TYPES;

  constructor() {
  }
  
  get planId (): string{
    if(!this._user) { return undefined;}
    return this._user.planId;
  }

  isPPV(content: any): boolean {
    return this.hasContentPPV(content)
      ? this.planId
        ? this.userWithPlanId(content)
        : this.userWithoutPlanId(content)
      : false;
  }

  

  isRental(content: any): boolean {
    if (this.hasContentPPV(content)) {
      if (content.acquired && content.expireDate != null)
        return true;
      if (content.acquired && content.expireDate == null && content.validDays != null)
        return true;
    }
    return false;
  }

  getPrice(content: any): any {
    if (this.hasContentPPV(content)) {
      const ppv = this.getPPV(content);
      return this.findPrice(ppv);
    }
    return 0;
  }

  private hasContentPPV(content: any): boolean {
    return content && content.ppv && content.ppv.length > 0
    && this.allowPPVTypes.indexOf(content.type) !== -1;
  }

  private filterContentPPV(content: any): any {
    return content.ppv.filter(x => x.planId === this.planId.toUpperCase());
  }

  private userWithPlanId(content: any): boolean {
    return this.filterContentPPV(content).length > 0;
  }

  private userWithoutPlanId(content: any): boolean {
    return content.ppv.length > 0 && content.ppv[0] != null;
  }

  private getPPV(content: any): any {
    let ppv = null;
    if (this.planId)
      ppv = this.filterContentPPV(content)[0];
    else
      ppv = content.ppv.length > 0 ? content.ppv[0] : null;
    return ppv;
  }

  private findPrice(ppv: any): any {
    if (ppv !== null) {
      const price =  (ppv.purchasePrice || ppv.rentalPrice) || 0;
      return price.toFixed(2);
    }
    return 0;
  }
}
