import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-article-item',
  templateUrl: './article-item.component.html',
  styleUrls: ['./article-item.component.scss']
})
export class ArticleItemComponent implements OnInit {

  @Input()
  clazz = 'horizontal-item-v2';

  @Input()
  showAuthor = false;

  @Input()
  showImageAuthor = false;

  @Input()
  showImage = true;

  @Input()
  image = 'https://enlaceimagesprod.blob.core.windows.net/images/horizontal/af158406-4712-4355-88d4-b15f195cd505';

  @Input()
  imageAuthor = 'https://enlaceimagesprod.blob.core.windows.net/enlace-cr/images/hosts/Cash%20Luna.jpg';

  @Input()
  title = '';

  @Input()
  subTitle = '';

  @Input()
  type = '';

  @Input()
  date = '';

  @Input()
  dateCreation = '';

  @Input()
  description = '';

  @Input()
  author = '';

  charlen : number;

  @Input()
  routerLink2: string[];
  @Input()
  typeCaller: number;

  @Input() fromMobile: boolean = false;
  @Input() userId: string;

  constructor() { }

  ngOnInit() {
    this.charlen = this.author.length;
  }

  //TODO: Ajuste en redireccion de un articulo desde el slider de destacados. 
  //Esto para volver a cargar el componente Flickity y se despliegue bien el slider.
  redirectArticle(id: string[]) {
    if (!this.fromMobile)
      location.href = '/article/' + id[2];
    else 
      location.href = '/mobile/articleReader?articleId=' + id[2] + '&userId=' + this.userId + '&fromMobile=' + this.fromMobile;
  }

  getTypeCaller(caller: number): string {
    switch (caller) {
      case (1): {
        return "article";
      }
      case (2): {
        return 'articles';
      }
      case (3): {
        return 'category'
      }
      case (4): {
        return 'type'

      }
      case (5): {
        return 'home'

      }
      default: {
        "none"
      }
    }
  }

}
