import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GiftCodeComponent } from "./gift-code.component";
import { GiftCodeModalComponent } from "./gift-code-modal/gift-code-modal.component";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, NgbModalModule],
  declarations: [GiftCodeComponent, GiftCodeModalComponent],
  exports: [GiftCodeComponent],
})
export class GiftCodeModule {}
