import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { ValidatedEmailValidator } from '@utils/validators/email-kickbox.validator';

@Directive({
  selector: '[appValidatedEmailValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => ValidatedEmailValidatorDirective),
      multi: true
    }
  ]
})
export class ValidatedEmailValidatorDirective implements AsyncValidator {

  constructor(private validator: ValidatedEmailValidator) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.validator.validate(control);
  }

}
