import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IOfficesViewModel } from '@interfaces/i-offices-view-model';

@Component({
  selector: 'app-office-modal',
  templateUrl: './office-modal.component.html',
  styleUrls: ['./office-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfficeModalComponent  {

  @Input() set office(value: IOfficesViewModel) {
    this._office = value;
    this.setPhones(value);
  }

  get office(): IOfficesViewModel {
    return this._office;
  }

  @Output() officeClose: EventEmitter<void> = new EventEmitter<void>();

  private _office: IOfficesViewModel;
  public cellPhones: string[] = [];
  public styles = [];
  public zoomControlOption: {
    position: google.maps.ControlPosition.RIGHT_TOP,
    style: 'google.maps.ZoomControlStyle.SMALL'
  };

  constructor() {}

  public close(): void {
    this.officeClose.emit();
  }

  ///  Create Link to Google Map with Latitude and Longitude
  public OpenMapLink(): void {
    const Url: string = 'https://www.google.com/maps/place/' + this._office.latitude.toString()
      + ',' + this._office.longitude.toString();
    window.open(Url);
  }

  public setPhones(office: IOfficesViewModel): void {
    if (office && office.cellPhone) {
      const cellPhonesSplit = office.cellPhone.split(',');
      cellPhonesSplit.forEach(phone => {
        this.cellPhones.push(phone);
      });
    }
  }

  public getLat(latitude: string): number {
    return parseFloat(latitude);
  }

  public getLon(longitude: string): number {
    return parseFloat(longitude);
  }
}
