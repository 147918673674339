<!-- <app-loading></app-loading> -->
<app-loading-overlay></app-loading-overlay>
<router-outlet *ngIf="hasFeaturesLoaded">
</router-outlet>
<ng-container *ngIf="isPray">
    <app-btn-pray-request 
        *ngIf="isComunityEnabled && isOlder14">
    </app-btn-pray-request>
</ng-container>

