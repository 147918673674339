export enum TypeResultSearch {
   NARRATED_BIBLE = 'narrated-bible',
   PROGRAM = 'program',
   VIDEO = 'video',
   ARTICLE = 'article',
   PODCAST = 'podcast',
   AUDIOBOOK = 'audiobook',
   EBOOK = 'ebook',
   ALL = 'all',
   PERSON = 'person',
   ORGANIZATION = 'organization'
}
