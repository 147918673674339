<div class="modal-body" style="background: none">
  <div class="offices" *ngIf="!isOfficeOpen">
    <span class="offices_close"><a class="offices_link" href="javascript:void(0)" (click)="modal.dismiss()"><i class="iconlace iconlace-cross"></i></a></span>
    <h2 class="offices_title">Oficinas</h2>
    <div class="offices-region">
      <div class="col-region">
        <div class="office-region-title">{{namesContinent.SUDAMERICA}}</div>
        <div class="offices-region_link" *ngFor="let country of countriesSudamerica">
          <a class="offices-region_link" href="javascript:void(0)" (click)="toggleOffice(country)">{{country.name}}</a>
        </div>
      </div>
      <div class="col-region">
        <div class="col-subregion">
          <div class="office-region-title">{{namesContinent.NORTEAMERICA}}</div>
          <div class="offices-region_link" *ngFor="let country of countriesNorteAmerica">
            <a class="offices-region_link" href="javascript:void(0)" (click)="toggleOffice(country)">{{country.name}}</a>
          </div>        
        </div>
        <div class="col-subregion mt-22">
          <div class="office-region-title">{{namesContinent.CARIBE}}</div>
          <div class="offices-region_link" *ngFor="let country of countriesCaribe">
            <a class="offices-region_link" href="javascript:void(0)" (click)="toggleOffice(country)">{{country.name}}</a>
          </div>
        </div>
      </div>
      <div class="col-region">
        <div class="office-region-title">{{namesContinent.CENTRO_AMERICA}}</div>
        <div class="offices-region_link" *ngFor="let country of countriesCentroAmerica">
          <a class="offices-region_link" href="javascript:void(0)" (click)="toggleOffice(country)">{{country.name}}</a>
        </div>
      </div>
    </div>
  </div>

  <div class="office" *ngIf="isOfficeOpen">
    <span class="office_close"><a class="offices_link" href="javascript:void(0)" (click)="modal.dismiss()"><i class="iconlace iconlace-cross"></i></a></span>
    <h2 class="office_title">Oficinas</h2>
    <app-office-modal  [office]="office" (officeClose)="isOfficeOpen = false"></app-office-modal>
  </div>


</div>

