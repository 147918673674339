<div class="slider-component" *appFeatureIsOn="'WebEnablePPV'">
  <h2 class="title">Precio USD</h2>
  <span class="from">Gratis</span>
  <span class="to" [class.active]="isMaxPrice">${{ maxValue }}</span>
  <div class="output" #output *ngIf="isValue">${{ value }}</div>
  <input
    type="range"
    #inputPrice
    class="slide-range"
    min="0"
    step="5"
    max="{{ maxPrice }}"
    [disabled]="maxPrice == 0"
    [(ngModel)]="value"
    (change)="setFilter($event)"
  />
</div>
