<div class="col-12">
  <nav class="pagination-block" *ngIf="pages && pages.length > 0">
    <ul *ngIf="pages && pages.length" class="pagination">
      <li class="page-item" *ngIf="currentPage !== 1" [ngClass]="{disabled:currentPage === 1}">
        <a class="page-link arrow" (click)="setPage(1)">
          <<</a></li>
      <li class="page-item" *ngIf="currentPage !== 1" [ngClass]="{disabled:currentPage === 1}">
        <a class="page-link arrow" (click)="setPage(currentPage - 1)">
          <</a></li>
      <li *ngFor="let page of pages; let i = index" [ngClass]="{active:currentPage === page}">
        <a class="page-link" (click)="setPage(page)">{{page}}</a>
      </li>
      <li class="page-item" *ngIf="currentPage !== totalPages" [ngClass]="{disabled: (currentPage) === totalPages}">
        <a class="page-link arrow" (click)="setPage(currentPage + 1)">></a>
      </li>
      <li class="page-item" *ngIf="currentPage !== totalPages" [ngClass]="{disabled: (currentPage) === totalPages}">
        <a class="page-link arrow" (click)="setPage(totalPages)">>></a>
      </li>
    </ul>
    <span class="info-results" *ngIf="showInfoResults">Resultados <strong>{{(rowsPerPage * (currentPage - 1)) + 1}}</strong> -
      <strong>{{currentPage < totalPages ? rowsPerPage * currentPage : totalRows}}</strong>
      de <strong>{{totalRows}}</strong></span>
  </nav>
</div>
