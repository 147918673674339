<div class="modal-body">
  <span class="btn-close">
    <span (click)="clickCancel()" aria-hidden="true">&times;</span>
  </span>
  <div class="content">
    <ng-container *ngIf="isSuccess; else error">
      <ng-container *ngTemplateOutlet="success"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #success>
  <div class="info-icon">
    <i class="iconlace iconlace-circle circle"></i>
    <i class="iconlace iconlace-check check"></i>
  </div>
  <div class="info-title">¡Donación exitosa!</div>
  <div class="info-subtitle">Gracias por tu apoyo.</div>
  <div class="info-content">
    Tu siembra nos permite llevar un mensaje acertado, a la persona correcta, en el momento oportuno.
  </div>
  <div class="content-buttons">
    <button
      type="button"
      class="btn btn-primary btn-donations-popup"
      (click)="actionFirstButton()">
      {{ fromMobile ? "Aceptar" : isLoggedIn ? 'Mis Donaciones' : 'Volver al inicio' }}
    </button>
  </div>
</ng-template>

<ng-template #error>
  <div class="info-icon">
    <i class="iconlace iconlace-alert-radius alert"></i>
  </div>
  <div class="info-title">¡Error!</div>
  <div class="info-content">
    Parece que hubo un error al validar tu tarjeta.
    Por favor verifica los datos e inténtalo nuevamente.
  </div>
  <div class="content-buttons">
    <button
      type="button"
      class="btn btn-primary btn-donations-popup"
      (click)="clickCancel()">
      Aceptar
    </button>
  </div>
</ng-template>
