export const STYLE_DLOCAL = {
  base: {
    color: '#f5f5f5',
    background: '#142236',
    '::placeholder': {
      color: '#999999',
    },
    marginTop: '2px',
  },
};

export const PAN_OPTIONS = {
  style: STYLE_DLOCAL,
  hideIcon: true,
  placeholder: 'XXXX-XXXX-XXXX-XXXX',
};

export const EXPIRATION_OPTIONS = {
  style: STYLE_DLOCAL,
  placeholder: 'MM/YY',
};

export const CVV_OPTIONS = {
  style: STYLE_DLOCAL,
  placeholder: 'CVV',
};
