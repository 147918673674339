import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IVVideo } from '@interfaces/i-v-video';

import { VideoV3Service } from '@services/video-v3.service';

@Component({
  selector: 'app-auto-play',
  templateUrl: './auto-play.component.html',
  styleUrls: ['./auto-play.component.scss']
})
export class AutoPlayComponent implements OnInit, OnChanges, OnDestroy {

  _seconds: number;
  video: IVVideo;
  subscriptionRouter: Subscription;

  @Input()
  fullScreen: boolean = false;

  @Input()
  set seconds(value: number) {
    if (value) {
      this._seconds = value;
      if (value < 1) {
        this.next();
      }
    }
  }

  get seconds(): number {
    return this._seconds;
  }

  get hasProgram(): boolean {
    return !!this.video && !!this.video.program && !!this.video.program.program;
  }

  constructor(
    private router: Router,
    private videoService: VideoV3Service,
    private currentRoute: ActivatedRoute,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes && !!changes.seconds)
      if (changes.seconds.currentValue === 0) this.next();
  }

  ngOnInit(): void {
    this.subscriptionRouter = this.currentRoute.params.subscribe((params: any) => {
      if (params.videoId)
        this.videoService.getNextView(params.videoId.toString())
        .subscribe(video => this.video = video);
    });
  }

  ngOnDestroy(): void {
    this.subscriptionRouter.unsubscribe();
  }

  next(): void {
    this.router.navigate(['/', 'video', this.video.id]);
  }

}
