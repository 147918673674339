import { Injectable, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Subject } from 'rxjs';

import { SEARCH_FILTER, TypeFitlter } from '@api/models/entities/search-advanced.interface';
import { BibleApiService } from '@app/api-client/bible-api.service';
import { UtilsService } from '@app/shared/utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class AdvanceSearchService {

  subjectChangeOpenFilter: Subject<boolean> = new Subject<boolean>();

  subjectAdvancedSearchText: Subject<string> = new Subject<string>();

  resetFilterSubject: Subject<void> = new Subject<void>();

  private _typeSelected: TypeFitlter;

  isOpenFilter: boolean = false;

  isOpenSearch: boolean = false;

  searching: boolean = false;

  previousUrl: string;

  private _searchText: string;
  isFiltering: boolean = false;
  showInfoResults = false;
  textToShow = '';

  typesFilters: TypeFitlter[] = SEARCH_FILTER;
  currentRoute = '';

  constructor(private router: Router, private bibleApiService: BibleApiService, private utilsService: UtilsService) {
    /* TO DO
     if (this.featureFlagService.checkFeatureOn('WebEnableLibrary')) {
       this.typesFilters.push({value: 'ebook', label: 'Libros Digitales', type: 'ebook'});
       if (this.featureFlagService.checkFeatureOn('AudiobooksEnable')) {
         this.typesFilters.push({value: 'audiobook', label: 'Audiolibros', type: 'audiobook'});
       }
     }
     */
    this._typeSelected = this.typesFilters[0];
  }

  get openPopupSearch(): boolean {
    return this.isOpenSearch;
  }

  get searchText(): string {
    return this._searchText;
  }

  set searchText(value: string) {
    this._searchText = value;
    // this.isFiltering = !!(value && value.length > 0);
  }

  set typeSelected(value: TypeFitlter) {
    this._typeSelected = value;
  }

  get typeSelected(): TypeFitlter {
    return this._typeSelected;
  }


  set viewAllType(typeFilter: string) {
    const val = this.typesFilters.find((v) => {
      return v.value === typeFilter
    });
    if (val) {
      this.typeSelected = val;
    } else {
      this.typeSelected = this.typesFilters[0];
    }

  }

  get viewAllType() {
    return this.typeSelected.value;
  }

  goSearch() {
    this.search(this.searchText, this.typeSelected.value, this.utilsService.getValueParamFromUrl('userId'));
  }

  advancedSearchText(): void {
    if (this.typeSelected.value == 'bible') {
      this.router.url.replace(this.router.url, window.location.origin);
      this.router.navigate(['/bible', 'search', this.bibleApiService.defaultLanguageId, this.bibleApiService.defaultBibleId, this.searchText]);
    }
    else if (this.searchText && this.searchText.replace(/\s/g, '').length) {
      this.goSearch();
      this.showInfoResults = true;
    } else {
      this.showInfoResults = false;
      this.router.navigate([this.currentRoute]);
    }
  }

  changeCategory() {
    this.getUrlByCategory();
    if (this.typeSelected.value == 'bible' && this.searchText && this.searchText.replace(/\s/g, '').length) {
      this.router.url.replace(this.router.url, window.location.origin);
      this.router.navigate(['/bible', 'search', this.bibleApiService.defaultLanguageId, this.bibleApiService.defaultBibleId, this.searchText]);
    }
    else if (this.searchText && this.searchText.replace(/\s/g, '').length) {
      this.goSearch();
      this.showInfoResults = true;
    }
  }

  private search(q: string, t: string, userId: string = null) {

    if (this.currentRoute === '')
      this.getUrlByCategory();

    switch (this.typeSelected.value) {

      case 'organization':
      case 'program':
      case 'people': {
        const navExtras: NavigationExtras = <NavigationExtras>{
          queryParams: { q: q },
          replaceUrl: true
        };
        this.router.navigate([this.currentRoute], navExtras)
          .then(() => this.textToShow = this.searchText);
        break;
      }

      default: {
        if (userId) {
          const navExtras: NavigationExtras = <NavigationExtras>{
            queryParams: { q: q, t: t, userId: userId },
            replaceUrl: true
          };
          this.router.navigate(['/search/mobile'], navExtras)
            .then(() => this.textToShow = this.searchText);
        } else {
          const navExtras: NavigationExtras = <NavigationExtras>{
            queryParams: { q: q, t: t },
            replaceUrl: true
          };
          this.router.navigate(['/search'], navExtras)
            .then(() => this.textToShow = this.searchText);
        }

        break;
      }
    }
  }

  searchToggle() {
    this.isOpenSearch = !this.isOpenSearch;
    this.isOpenSearch ? this.setDefaultCategory() : '';
  }

  private setDefaultCategory() {
    const routerArray = this.router.url.split('/');
    // Asigna ruta teniendo en cuenta si está en una home page category o en una página hija.
    const elmQuery = routerArray.find(el => el.includes('?'))

    if (elmQuery && elmQuery.length > 0) {
      const elm = elmQuery.split('?');
      this.currentRoute = elm[0];
    } else {
      this.currentRoute = routerArray.length > 2 ? routerArray[1] : routerArray[routerArray.length - 1];
    }

    this.typeSelected = this.typesFilters.find(item => item.routeKey === this.currentRoute) || this.typesFilters[0];
  }

  private getUrlByCategory() {
    switch (this.typeSelected.value) {
      case 'program': {
        this.currentRoute = 'programs';
        break;
      }
      case 'article': {
        this.currentRoute = 'articles';
        break;
      }
      case 'library': {
        this.currentRoute = 'library';
        break;
      }
      case 'podcast': {
        this.currentRoute = 'podcast';
        break;
      }
      case 'organization': {
        this.currentRoute = 'organizations';
        break;
      }
      case 'people': {
        this.currentRoute = 'people';
        break;
      }
      case 'bible': {
        this.currentRoute = 'bible';
        break;
      }
      default: {
        this.currentRoute = '';
      }
    }
  }
  ngOnDestroy() {
    this.reset();
  }
  reset() {
    this.isOpenSearch = false;
    this.typeSelected = this.typesFilters[0];
    this.searchText = '';
  }

  changeOpenFilter(value: boolean) {
    this.subjectChangeOpenFilter.next(value);
    this.isOpenFilter = value;

  }

  changeTypeFilter(type: string) {
    const nextType = this.typesFilters.find((value) => {
      return value.value === type;
    });
    this.typeSelected = nextType;
  }

  getContentType(): string {
    const nextType = this.typesFilters.find((value) => {
      return value.value === this.typeSelected.value;
    });
    return nextType.value;
  }

  isMobileRoute() {
    return this.utilsService.isMobileRoute;
  }
}
