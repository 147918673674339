<div class="slider-book slider-book--strip" (click)="onClickCaratuta.emit()" (mouseenter)="hovered = !hovered" (mouseleave)="hovered = !hovered" 
[class.not-library]="!myLibrary && !isWebview" [class.not-library-webview]="!myLibrary && isWebview" [class.social]="mySocial">
  <div class="slider-book-body">
    <app-ppv-remaining-indicator [content]="book"></app-ppv-remaining-indicator>
    <img class="slider-book-img slider-book-img--strip"
         *ngIf="book"
         src="{{getImage()}}"
         src-fallback="{{srcFallback}}" />
    <div *ngIf="!book" class="slider-book-img--strip"></div>
    <app-ebook-progress-bar *ngIf="book && book.percent && book.percent > 0" [percent]="book.percent" [hovered]="hovered"></app-ebook-progress-bar>
    <div class="unfollow-container">
      <a *ngIf="popupText" 
        class="iconlace iconlace-cross unfollow-ebook"
        (click)="unfollowEbook.emit($event)"
        [ngbTooltip]="popupText"
        container="body"
        placement="top"></a>
    </div>
  </div>
  <div class="slider-book-footer"
    [ngClass]="{'slider-book-footer--acquired': book?.acquired, 'slider-book-footer--price': !book?.acquired}">
    <p class="slider-book-button" *ngIf="book?.acquired">leer</p>
    <p class="slider-book-button" *ngIf="!book?.acquired">{{ getPrice() }}</p>
  </div>
</div>