import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as ENV } from '@environment/environment';

import { IPodcastContent, IPodcastViewModel } from '@interfaces/i-podcast-view-model';
import { IPagingRequestModel } from '@interfaces/ipaging-request-model';

@Injectable({
  providedIn: 'root',
})
export class PodcastV3Service {

  private API = `${ENV.webApi}v2/podcast`;

  constructor(private http: HttpClient) {}

  public view(payload: IPagingRequestModel): Observable<IPodcastViewModel> {
    return this.http.post<IPodcastViewModel>(`${this.API}/view`, payload);
  }

  public viewRecommended(): Observable<IPodcastViewModel[]> {
    const url = `${this.API}/view/recommended`;
    return this.http.get<IPodcastViewModel[]>(url);
  }

  public getPodcastParent(podcastItemId: string): Observable<IPodcastContent> {
    const url = `${this.API}/view/podcastItem/${podcastItemId}`;
    return this.http.get<IPodcastContent>(url);
  }

}
