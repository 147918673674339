import { UtilsService } from '@app/shared/utils/utils.service';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';

import { SliderComponent } from './components/slider/slider.component';
import { LoginModalComponent } from './components/login-modal/login-modal.component';
import { VideoDetailInlineComponent } from './components/video-detail-inline/video-detail-inline.component';
import { ApiClientModule } from '../api-client/api-client.module';
import { PlayerLayoutComponent } from './player-layout/player-layout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AddToWatchlistComponent } from './components/add-to-watchlist/add-to-watchlist.component';
import { PipesModule } from './../pipes/pipes.module';
import { SliderImageComponent } from './components/slider-image/slider-image.component';
import { HttpClientModule } from '@angular/common/http';
import { VideoLikeComponent } from './components/video-like/video-like.component';
import { VideoProgressBarComponent } from './components/slider/video-progress-bar.component';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { NgbModule, NgbActiveModal, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchAdvancedComponent } from './components/search-advanced/search-advanced.component';
import { FollowComponent } from './components/follow/follow.component';
import { PopupFilterComponent } from './components/search-advanced/popup-filter/popup-filter.component';
import { FiltersComponent } from './components/search-advanced/filters/filters.component';
import { FilterComponent } from './components/search-advanced/filter/filter.component';
import { SearchContentComponent } from './components/search-advanced/search-content/search-content.component';
import { ImgFallbackModule } from 'ngx-img-fallback';
import { SeasonsComponent } from './components/video-detail-inline/seasons.component';
import { SliderFilterComponent } from './components/slider/slider-filter/slider-filter.component';

import {TagPpvModule} from '../shared/components/tags/tag-ppv/tag-ppv.module';
import {TagPlusModule} from '../shared/components/tags/tag-plus/tag-plus.module';
import { TranslateModule } from '@ngx-translate/core';
import { AgmCoreModule } from '@shared/components/google-maps';
import { TranscriptionsComponent } from './components/transcriptions/transcriptions.component';
import { ClickOutsideModule } from 'ng4-click-outside';
import { ArticleItemComponent } from './components/article-item/article-item.component';
import { ArticleGroupComponent } from './components/article-group/article-group.component';
import { ShareComponent } from './components/share/share.component';
import { BasePageLayoutComponent } from './base-page-layout/base-page-layout.component';
import { OrderComponent } from './components/order/order.component';
import { ContentItemComponent } from './components/content-item/content-item.component';
import { ContentItemListComponent } from './components/content-item-list/content-item-list.component';
import { ContentItemBigComponent } from './components/content-item-big/content-item-big.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { ProfileComponent } from './components/configuration/profile/profile.component';
import { NotificationsComponent } from './components/configuration/notifications/notifications.component';
import { SuscriptionsComponent } from './components/configuration/suscriptions/suscriptions.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxCurrencyModule } from 'ngx-currency';
import { ToastrModule } from 'ngx-toastr';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { PersonItemBigComponent } from './components/person-item-big/person-item-big.component';
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';
import { CarouselCenterComponent } from './components/carousel-center/carousel-center.component';
import { FlickityModule } from 'ngx-flickity';
import { AutoPlayComponent } from './components/media-player/auto-play/auto-play.component';
import { FontSizeManagerComponent } from './components/font-size-manager/font-size-manager.component';
import { ScoreComponent } from './components/score/score.component';
import { LikeComponent } from './components/like/like.component';
import { SocialShareComponent } from './components/social-share/social-share.component';
import { AudiobookListenerComponent } from '../pages/audiobook/audiobook-listener/audiobook-listener.component';
import { SliderBookItemComponent } from './components/slider/slider-book-item/slider-book-item.component';
import { CardBtnComponent } from './components/card-btn/card-btn.component';
import { FilterBtnComponent } from './components/filter-btn/filter-btn.component';
import { SliderAudiobookItemComponent } from './components/slider/slider-audiobook-item/slider-audiobook-item.component';
import { FilterByPriceComponent } from './components/filter-by-price/filter-by-price.component';
import { RatingFilterComponent } from './components/rating-filter/rating-filter.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { VideoIndexerComponent } from './components/video-indexer/video-indexer.component';
import { SubscribeModule } from 'app/pages/subscribe/subscribe.module';
import { PpvLabelIndicatorComponent } from './components/ppv-label-indicator/ppv-label-indicator.component';
import { PpvButtonsComponent } from './components/ppv-buttons/ppv-buttons.component';
import { PpvRemainingIndicatorComponent } from './components/ppv-remaining-indicator/ppv-remaining-indicator.component';
import { AnswerModalComponent } from './components/modals/answer-modal/answer-modal.component';
import { ConfirmationModalComponent } from './components/modals/confirmation-modal/confirmation-modal.component';
import { EbookProgressBarComponent } from './components/slider/ebook-progress-bar/ebook-progress-bar.component';

import { SuscribePlusModalComponent } from './components/suscribe-plus-modal/suscribe-plus-modal.component';
import { SuscribePlusConfirmationModalComponent } from './components/suscribe-plus-confirmation-modal/suscribe-plus-confirmation-modal.component';
import { HeaderSearchAdvancedComponent } from './components/header-search-advanced/header-search-advanced.component';
import { FeaturedSearchComponent } from './components/search-advanced/featured-search/featured-search.component';
import { ContentSearchComponent } from './components/search-advanced/content-search/content-search.component';

import { CheckoutModalComponent } from '@app/layout/components/modals/checkout-modal/checkout-modal.component';
import { AlertModalComponent } from '@app/layout/components/modals/alert-modal/alert-modal.component';
import { BtnDonationComponent } from './components/btn-donation/btn-donation.component';
import { FilterTagsComponent } from './components/filter-tags/filter-tags.component';
import { AuthResponseModalComponent } from './components/auth-response-modal/auth-response-modal.component';

import { LoadingPagingComponent } from './components/loading-paging/loading-paging.component';

import { DisableButtonOnSubmitDirective } from '@shared/directives/PreventDoubleClickDirective';
import { IvyCarouselModule } from '@components/angular-responsive-carousel/carousel.module';
import { DirectivesModule } from '@directives/directives.module';
import { PipesV2Module } from '@pipes/pipes-v2.module';
import { BreadcrumbV2Module } from '@components/breadcrumb-v2/breadcrumb-v2.module';
import { ModalTemplateModule } from '@components/modals/modal-template/modal-template.module';
import { CheckBoxModule } from '@components/checkbox/checkbox.module';
import { HeaderModule } from '@components/header/header.module';
import { FooterModule } from '@components/footer/footer.module';
import { SearchV2Module } from '@components/search-v2/search-v2.module';
import { RecaptchaModule } from 'ng-recaptcha';
import { IssueDateFilterModule } from '@components/issue-date-filter/issue-date-filter.module';
import { PromotionAlertModalComponent } from './components/modals/promotion-alert-modal/promotion-alert-modal.component';
import { GiftCodeModule } from '@components/gift-code/gift-code.module';
import { PreviewTaxeModalModule } from '@app/shared/components/modals/preview-taxe-modal/preview-taxe-modal.module';
import { ChannelLoaderModule } from '@app/shared/components/channel-loader/channel-loader.module';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

@NgModule({
    imports: [
        HttpClientModule,
        CommonModule,
        BreadcrumbV2Module,
        ModalTemplateModule,
        CheckBoxModule,
        DirectivesModule,
        RecaptchaModule,
        HeaderModule,
        FooterModule,
        PipesV2Module,
        SearchV2Module,
        IssueDateFilterModule,
        ApiClientModule.forRoot(),
        NgSelectModule,
        FormsModule,
        RouterModule,
        PipesModule,
        NgbModule,
        ImgFallbackModule,
        NgbDropdownModule,
        TranslateModule,
        AgmCoreModule,
        SubscribeModule,
        ClickOutsideModule,
        InfiniteScrollModule,
        NgxCurrencyModule,
        TagPpvModule,
        TagPlusModule,
        FlickityModule,
        ReactiveFormsModule,
        PreviewTaxeModalModule,
        ToastrModule.forRoot({
            maxOpened: 1,
            autoDismiss: true,
            preventDuplicates: true,
            positionClass: 'toast-bottom-right',
            timeOut: 5000
        }),
        CreditCardDirectivesModule,
        IvyCarouselModule,
        ClickOutsideModule,
        GiftCodeModule,
        ChannelLoaderModule,
        ShareButtonsModule,
        LottieModule.forRoot({ player: playerFactory })
        // AngularOpenlayersModule
    ],
    declarations: [
        SliderComponent,
        LoginModalComponent,
        VideoDetailInlineComponent,
        PlayerLayoutComponent,
        AddToWatchlistComponent,
        VideoLikeComponent,
        VideoProgressBarComponent,
        SliderImageComponent,
        SliderBookItemComponent,
        MessageModalComponent,
        AnswerModalComponent,
        ConfirmationModalComponent,
        SearchAdvancedComponent,
        FollowComponent,
        PopupFilterComponent,
        FiltersComponent,
        FilterComponent,
        SearchContentComponent,
        SeasonsComponent,
        SliderFilterComponent,
        TranscriptionsComponent,
        CarouselCenterComponent,
        ArticleItemComponent,
        ArticleGroupComponent,
        ShareComponent,
        BasePageLayoutComponent,
        OrderComponent,
        ContentItemComponent,
        ContentItemListComponent,
        ContentItemBigComponent,
        ConfigurationComponent,
        ProfileComponent,
        NotificationsComponent,
        SuscriptionsComponent,
        PersonItemBigComponent,
        AudioPlayerComponent,
        AutoPlayComponent,
        FontSizeManagerComponent,
        ConfigurationComponent,
        ProfileComponent,
        NotificationsComponent,
        SuscriptionsComponent,
        PersonItemBigComponent,
        AudioPlayerComponent,
        AutoPlayComponent,
        FontSizeManagerComponent,
        CardBtnComponent,
        FilterBtnComponent,
        ScoreComponent,
        AudiobookListenerComponent,
        LikeComponent,
        SocialShareComponent,
        SliderAudiobookItemComponent,
        FilterByPriceComponent,
        RatingFilterComponent,
        PaginatorComponent,
        VideoIndexerComponent,
        PpvLabelIndicatorComponent,
        PpvButtonsComponent,
        PpvRemainingIndicatorComponent,
        EbookProgressBarComponent,
        SuscribePlusModalComponent,
        AuthResponseModalComponent,
        SuscribePlusModalComponent,
        SuscribePlusConfirmationModalComponent,
        HeaderSearchAdvancedComponent,
        FeaturedSearchComponent,
        ContentSearchComponent,
        CheckoutModalComponent,
        AlertModalComponent,
        FilterTagsComponent,
        BtnDonationComponent,
        LoadingPagingComponent,
        DisableButtonOnSubmitDirective,
        PromotionAlertModalComponent
    ],
    exports: [
        SliderComponent,
        VideoDetailInlineComponent,
        PlayerLayoutComponent,
        VideoLikeComponent,
        SliderImageComponent,
        SliderBookItemComponent,
        SliderAudiobookItemComponent,
        SearchAdvancedComponent,
        AddToWatchlistComponent,
        VideoProgressBarComponent,
        FollowComponent,
        TranscriptionsComponent,
        CarouselCenterComponent,
        ArticleItemComponent,
        ArticleGroupComponent,
        ShareComponent,
        BasePageLayoutComponent,
        OrderComponent,
        ContentItemComponent,
        FiltersComponent,
        ContentItemListComponent,
        ContentItemBigComponent,
        PopupFilterComponent,
        ConfigurationComponent,
        SuscriptionsComponent,
        PersonItemBigComponent,
        SeasonsComponent,
        AudioPlayerComponent,
        CardBtnComponent,
        FilterBtnComponent,
        ScoreComponent,
        AudiobookListenerComponent,
        SocialShareComponent,
        FilterByPriceComponent,
        PaginatorComponent,
        SearchContentComponent,
        VideoIndexerComponent,
        FontSizeManagerComponent,
        EbookProgressBarComponent,
        HeaderSearchAdvancedComponent,
        FeaturedSearchComponent,
        ContentSearchComponent,
        CheckoutModalComponent,
        FilterTagsComponent,
        BtnDonationComponent,
        LoadingPagingComponent,
        DisableButtonOnSubmitDirective
    ]
})
export class LayoutModule {
  deviceInfo = null;
  constructor(private deviceService: DeviceDetectorService) {
  }
  ngOnInit() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }
  static forRoot(): ModuleWithProviders<LayoutModule> {
    return {
      ngModule: LayoutModule,
      providers: [
        NgbActiveModal,
        DatePipe,
        UtilsService,
        DisableButtonOnSubmitDirective
      ]
    };
  }
}
