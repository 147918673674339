import { Pipe, PipeTransform } from '@angular/core';
import { BaseResult } from '@api/models/entities/auth.interface';
@Pipe({
  name: 'resultLogin',
})
export class ResultLoginPipe implements PipeTransform {

  transform(errorEnum: number, args: string): string {
    let result = '';
    if (!!errorEnum) {
      switch (errorEnum) {
        case BaseResult.EMAIL_REQUIRED:
          result = 'Correo Electronico Requerido';
          break;
        case BaseResult.PASSWORD_REQUIRED:
          result = 'Contraseña Requerido';
          break;
        case BaseResult.USER_NEEDS_MIGRATE:
          result = 'Usuario necesita ser migrado';
          break;
        case BaseResult.USER_NOT_FOUND:
          result = 'Tu correo electrónico o contraseña es incorrecto, inténtalo nuevamente.';
          break;
        case BaseResult.PASSWORD_INCORRECT:
          result = 'Tu correo electrónico o contraseña es incorrecto, inténtalo nuevamente.';
          break;
        default:
          break;
      }
    }
    return result;
  }
}
