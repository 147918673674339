import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ImageHost'
})
export class ImageHostPipe implements PipeTransform {

  transform(hosts: any[], type: string): string {
    let srcImage = 'assets/img/icons/user.svg';
    if(!!hosts && !!hosts[0] && hosts[0].indexOf('|') !== -1) {
      if(hosts[0].split('|')[2] !== '') {
        srcImage = hosts[0].split('|')[2];
      }
    }
    return srcImage;
  }

}
