import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscribeComponent } from './subscribe.component';
import { SubscribeRoutingModule } from 'app/pages/subscribe/subscribe-routing.module';
import { ApiClientModule } from 'app/api-client/api-client.module';

@NgModule({
  imports: [
    CommonModule,
    SubscribeRoutingModule,
    ApiClientModule.forRoot(),
  ],
  declarations: [SubscribeComponent]
})
export class SubscribeModule { }
