const dex = 10;

export function toNumber(value?: string | number): number {
  if (typeof value === 'undefined') {
    return NaN;
  }

  if (typeof value === 'number') {
    return value;
  }

  return parseInt(value, dex);
}
