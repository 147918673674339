import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {VideoSearch} from './models/video-search';
import {VideoSearchResults} from './models/video-search-results';
import {Catalog} from './models/catalog';
import {RestClient} from './rest-client';
import {IRestMethod, RestAction, RestParams, RestRequestMethod} from 'rest-core';
import {EnlaceRestHandler} from './enlace-rest-handler';
import {AuthenticationService} from './authentication.service';
import {Router} from '@angular/router';
import { LoadingOverlayService } from '@app/shared/services/loading-overlay.service';


@Injectable()
@RestParams({
  pathPrefix: environment.webApi + 'search'
})
export class SearchService extends RestClient {

  searchResult: VideoSearchResults;

  constructor(restHandler: EnlaceRestHandler, authService: AuthenticationService, private router: Router,
              private loadingOverlayService: LoadingOverlayService) {
    super(restHandler, authService);
  }

  @RestAction({
    path: '/',
    isArray: true,
  })
  list: IRestMethod<any, VideoSearchResults>;

  @RestAction({
    path: '/',
    method: RestRequestMethod.Post
  })
  getPaged: IRestMethod<{ SearchText: string, Top: number, SearchByPublished: boolean, Page: number }, VideoSearchResults>;

  @RestAction({
    path: '/{!searchText}',
  })
  get: IRestMethod<{ searchText: any }, VideoSearch[]>;

  @RestAction({
    path: '/getsuggestions/{!suggestions}',
  })
  getsuggestions: IRestMethod<{ suggestions: any }, string[]>;

  @RestAction({
    path: '/advancesearch/{!searchText}',
  })
  advancesearch: IRestMethod<{ searchText: any }, any>;

  @RestAction({
    pathPrefix: environment.webApi + 'v2/search',
    path: '/advancedsearch/',
    method: RestRequestMethod.Post
  })
  advancedSearch: IRestMethod<{ catalogId: number, ministeryId: number[], countryId: number, languageId: number, transcriptions: boolean, searchText: string, searchByPublished: boolean, top: number, page: number }, any>;

  @RestAction({
    path: '/getrelatedvideos/{!id}',
  })
  getrelatedvideos: IRestMethod<{ id: any }, Catalog>;


  sendRequestSearch(pageInfo: any): Promise<any> {
    this.loadingOverlayService.showLoadingOverlay();
    return new Promise((resolve, reject) => {
      this.advancedSearch({
        catalogId: pageInfo.catalogId,
        ministeryId: pageInfo.ministryIds,
        countryId: pageInfo.countryId,
        languageId: pageInfo.languageId,
        transcriptions: true,
        searchText: pageInfo.searchText,
        searchByPublished: environment.SearchByPublished,
        top: pageInfo.top,
        page: pageInfo.page
      }).then((result) => {
        resolve(result);
        this.loadingOverlayService.hideLoadingOverlay();
      }).catch((e) => {
        console.warn('called .catch on %o arguments %o', this, e);
        this.loadingOverlayService.hideLoadingOverlay();
      });
    });
  }

  listCache(): Observable<VideoSearchResults> {
    return Observable.create(observer => {
      if (this.searchResult) {
        observer.next(this.searchResult);
      } else {
        this.list().then((result: VideoSearchResults) => {
          this.searchResult = result;
          observer.next(this.searchResult);
        }).catch((e) => {
          console.warn('called .catch on %o arguments %o', this, e);
          // this.router.navigate(['/500']);
        });
      }
    });
  }

}
