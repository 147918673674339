import {
  Component, OnInit, ViewChild, ElementRef, Input,
  HostListener, OnDestroy, AfterViewInit, Output, EventEmitter
} from '@angular/core';
import {Router} from '@angular/router';
import { state, style, trigger } from '@angular/animations';
import {LocalStorage} from 'ngx-webstorage';
import {Subject, Subscription} from 'rxjs';
import {DeviceDetectorService} from 'ngx-device-detector';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {ICatalogViewModel, IContentTracking} from '@core/models/interfaces/icatalog-view-model';
import {IContentCatalogViewModel} from '@core/models/interfaces/i-content-catalog-view-model';
import {ICatalogViewModelPagingRequest} from '@core/models/interfaces/i-catalog-view-model-paging-request';
import {IContentSocialInfoViewModel} from '@core/models/interfaces/i-content-social-info-view-model';
import { TypeContentCover } from '@core/models/enums/type-features-content.enum';
import {IUser} from '@core/models/interfaces/i-user.interface';

import {CatalogV2Service} from '@api/v2/catalog-v2.service';
import {ContentV2Service} from '@api/v2/content-v2.service';
import {MyMonitoringService} from '../../../shared/services/lib/monitoring.service';
import {ProgramService} from '@api/v2/program.service';
import { PPVContentService } from '@app/shared/utils/ppvcontent.service';
import { PodcastService } from '@api/v2/podcast.service';
import { PersonService } from '@api/v2/person.service';
import { OrganizationService } from '@api/v2/organization.service';

import {VideoDetailInlineComponent} from '../video-detail-inline/video-detail-inline.component';
import {SliderFilterComponent} from './slider-filter/slider-filter.component';
import {LoginModalComponent} from '../login-modal/login-modal.component';
import { RoutesApp } from '@core/models/enums/type-routes-app.enum';
import { UtilsService } from '@app/shared/utils/utils.service';
import { ModalManagementService } from '@app/shared/utils/modal-management.service';
import { LoadingOverlayService } from '@app/shared/services/loading-overlay.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  animations: [
    trigger('slide', [
      state('left', style({ transform: 'translateX({{x}}px)' }), {
        params: { x: 0 },
      }),
      state('right', style({ transform: 'translateX({{x}}px)' }), {
        params: { x: -87 },
      }),
    ]),
  ],
})
export class SliderComponent implements OnInit, OnDestroy, AfterViewInit {
  static hideSubject: Subject<ICatalogViewModel> = new Subject<ICatalogViewModel>();

  @LocalStorage('user')
  user: IUser;
  @Input() typeSection: string = '';

  @ViewChild('slider', { static: true })
  slider: ElementRef;

  @ViewChild('items', { static: true })
  items: ElementRef;

  @ViewChild('videoDetail')
  videoDetail: VideoDetailInlineComponent;

  @ViewChild('videoDetail', { read: ElementRef })
  videoDetailElementRef: ElementRef;

  @ViewChild('slideFilter', { static: true })
  slideFilter: SliderFilterComponent;

  @ViewChild('slideFilter', { read: ElementRef, static: true }) slideFilterRef: ElementRef;

  _catalog?: ICatalogViewModel;
  catalogItems: Array<IContentCatalogViewModel>;

  @Input()
  layout: number;

  @Input()
  showHeader = true;

  @Input()
  paginated = true;

  @Input()
  hiddeCloseDetail = false;

  @Input()
  seasons: boolean = false;

  @Input()
  showHeart: boolean = true;

  @Input() showMenuIcon = false;

  private _contentCatalogSelected: IContentCatalogViewModel;

  isShowDetail: boolean = false;

  sliderObject;

  @Input()
  landingSeason: string;

  @Input()
  recommended: boolean = false;

  @Input()
  continueWatching: boolean = false;
  @Input()
  contentTracking: IContentTracking;

  @Input()
  preloading: boolean = false;

  @Input()
  showFilter: true;

  @Input()
  showArrows = true;

  @Input()
  color: string;

  @Input()
  showStars: boolean = false;

  @Input()
  parentShowingPPV: boolean = false;

  @Input()
  noPadding = false;

  @Input()
  parentShowingRental: boolean = false;

  @Input() isLanding: boolean = false;
  @Input() isPeople: boolean = false;
  @Input() isOrganization: boolean = false;
  @Input() isArticle: boolean = false;
  @Input() isProgram: boolean = false;
  @Input() className: string = '';
  @Output() onAcquireContent: EventEmitter<boolean> = new EventEmitter();
  @Output() pagingContentProgram: EventEmitter<any> = new EventEmitter();
  @Output() closeAllItems = new EventEmitter();

  private _catalogNameSelected: string;

  @Input()
  set catalogNameSelected(catalogName: string) {
    if (this.catalog && this.catalog.name !== catalogName) {
      if (this.contentCatalogSelected) {
        this.contentCatalogSelected.isShow = false;
      }
    }
    this._catalogNameSelected = catalogName;
  }

  @Input()
  set fillInItems(value) {
    if (!!value.length && !!this.catalogItems) {
      this.catalogItems = value;
    };
  }

  @Input()
  set totalItemsPerPage(value) {
    if (!!value) {
      this.paging.sliderItems = value;
    }
  }

  scrolling = false;

  activePane: string = 'left';

  isMobile = false;
  enabledList = true;
  trackingListActive = true;
  enabledLike = true;
  trackingLikeActive = true;
  trackingDetailActive = true;
  xCalculated = 0;
  currentIndex = 0;
  lastCurrentIndex = 0;
  visibleItemsCount = 0;
  totalItems = 0;
  loadingModal: boolean = false;

  x = 0;
  screenHeight = 0;
  screenWidth = 0;
  paging: ICatalogViewModelPagingRequest = <ICatalogViewModelPagingRequest>{
    sliderPage: 0,
    sliderItems: 10,
  };
  @Input()
  set pagingSlider(value: ICatalogViewModelPagingRequest) {
    if (value) {
      this.paging = {
        ...this.paging,
        orderByDesc: value.orderByDesc,
        orderByField: value.orderByField
      };
    }
  }
  totalPages: number = 0;
  pagesLoaded: number[] = [0];

  suscriberChangeMyList: Subscription;

  programSeason: ICatalogViewModel[];
  lastViewEpisodeId: string;
  _contentCatalog: IContentCatalogViewModel;

  socialInfo: IContentSocialInfoViewModel;
  overSocialInfo: IContentSocialInfoViewModel;
  socialInfoArticles: any = {};
  itemsHovered: any = {};
  socialInfos: any = {};
  href: string;
  oldCatalog: ICatalogViewModel = {} as ICatalogViewModel;
  public showRight: boolean = false;

  @LocalStorage('user')
  _user: IUser;

  @Input()
  set contentCatalog(value: IContentCatalogViewModel) {
    this.programSeason = null;
    this.lastViewEpisodeId = null;
    this._contentCatalog = value;

    //this._contentCatalog.topTitle = 'El lugar de su Presencia';
    if (this._contentCatalog) {
      this.href = window.location.href + this._contentCatalog.type + '/';
      this.getSocialInfo(value);
    }
  }

  getSocialInfo(content: IContentCatalogViewModel) {
    this.socialInfo = <IContentSocialInfoViewModel>{ isFollow: false };
    if (this._user) {
      this.contentService
        .getSocialInfo(content.contentId)
        .then((socialInfo: IContentSocialInfoViewModel) => {
          this.socialInfo = socialInfo;
          this.preloading = false;
        });
    }
  }

  constructor(
    private router: Router,
    private contentService: ContentV2Service,
    private catalogService: CatalogV2Service,
    private devideDetector: DeviceDetectorService,
    private modalService: NgbModal,
    private myMonitoringService: MyMonitoringService,
    private programService: ProgramService,
    private personService: PersonService,
    private organizationService: OrganizationService,
    private ppvContentService: PPVContentService,
    private podcastService: PodcastService,
    private utilsServ: UtilsService,
    private modalManagementService: ModalManagementService,
    private loadingOverlayService: LoadingOverlayService
  ) {
    this.onResize();
    this.isMobile =
      this.devideDetector.isMobile() || this.devideDetector.isTablet();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngOnDestroy() {
  }

  ngOnInit() {
    SliderComponent.hideSubject.subscribe((catalog: ICatalogViewModel) => {
      if (this.catalog) {
        if (this.catalog !== catalog || catalog.isMyList || catalog.isMySee) {
          this.catalog.isShowDetail = false;
        } else {
          this.catalog.isShowDetail = true;
        }
      }
    });
  }

  unfollowItem(event: Event, content: any) {
    event.stopPropagation();
    switch(this.contentTracking.section) {
      case 'my-favourites':
        this.contentService.toggleLike({ contentId: content.contentId }).then(() => {
          this.removeUnfollowItem(content.contentId);
          this.contentService.updateSocialCacheIsLike(content.contentId, false);
        });
        break;
      case 'my-list':
        this.contentService.toggleMyLIst({ contentId: content.contentId }).then(() => {
          this.catalogService.removeMyList(content).then();
          this.removeUnfollowItem(content.contentId);
        });
        break;
      case 'my-follows':
        this.contentService.followUnFollow({ contentId: content.contentId }).then(() => {
          this.removeUnfollowItem(content.contentId);
          this.contentService.updateSocialInfoCacheIsFollow(content.contentId, false);
        })
        break;
    }
  }

  removeUnfollowItem(contentId: string) {
    if (this.pagesLoaded.length < this.totalPages) {
      this.paging.sliderPage += 1;
      this.runPaging().then();
    }
    const content = this.catalogItems.find(ci => ci.contentId === contentId);
    if (content) {
      this.catalogItems.splice(this.catalogItems.indexOf(content), 1);
      this._catalog.totalItems -= 1;
    }
  }

  ngAfterViewInit(): void {
   setTimeout(() => {
     this.showRight = this.items.nativeElement.scrollLeft
      + this.items.nativeElement.clientWidth < this.items.nativeElement.scrollWidth;
   });
  }

  get sectionTypeContent(): boolean {
    return this.typeSection === 'my-content';
  }

  get sectionTypeLibrary(): boolean {
    return this.typeSection === 'library';
  }

  getItemDomRect(item: any){
    const { left, right, x } = item.getBoundingClientRect();
    return {
      isVisible: left >= 0 && right <= window.innerWidth,
      x
    };
  }

  itemsVisible(items): number {
    if (this.visibleItemsCount === 0) {
      for (let i = 0; i < items.children.length; i++) {
        const { isVisible } = this.getItemDomRect(items.children[i])
        if (isVisible) {
          this.visibleItemsCount += 1;
        }
      }
    }
    return this.visibleItemsCount;
  }

  nextItemsIsVisible(items, index): number {
    let count = 0;
    for (let i = index; i < items.children.length; i++) {
      const { isVisible } = this.getItemDomRect(items.children[i]);
      if (!isVisible) {
        count += 1;
      }
    }

    return count;
  }

  right(items) {
    this.validatePaging().then(() => {
      this.itemsVisible(items);
      let firstX = 0;
      if (items.children.length > 0) {
        firstX = this.getItemDomRect(items.children[0]).x;
      }
      let move = false;
      for (let i = this.currentIndex; i < items.children.length; i++) {
        let el = items.children[i];
        const { isVisible, x } = this.getItemDomRect(el);
        this.xCalculated = x - firstX;
        if (!isVisible) {
          move = true;
          this.currentIndex = i;
          const nii = this.nextItemsIsVisible(items, this.currentIndex - 1);
          if (nii < this.visibleItemsCount) {
            el = items.children[i - (this.visibleItemsCount - nii)];
            const { x } = this.getItemDomRect(el);
            this.xCalculated = x - firstX;
          }
          break;
        }
      }
      if (this.lastCurrentIndex !== this.currentIndex || this.pagesLoaded.length < this.totalPages) {
        this.x = !this.sectionTypeLibrary ? this.xCalculated * -1 :  this.xCalculated * -1 - 50;
        this.activePane = 'right';
      } else {
        this.currentIndex = 0;
        this.x = 0;
        this.activePane = 'left';
      }
      this.lastCurrentIndex = this.currentIndex;
    });
  }

  left(items) {
    let firstX = 0;
    if (items.children.length > 0) {
      firstX = this.getItemDomRect(items.children[0]).x;
    }

    let i = this.currentIndex;
    const to = this.currentIndex - this.visibleItemsCount;

    if (this.currentIndex !== 0) {
      for (i; i >= to; i--) {
        if (items.children[i]) {
          const { x } = this.getItemDomRect(items.children[i]);
          this.xCalculated = x - firstX;
          this.currentIndex = i;
        }
      }
    }
    this.paging.sliderPage -= 1;
    this.x = this.xCalculated * -1;
    this.activePane = 'left';
    this.lastCurrentIndex = this.currentIndex;
  }

  validatePaging(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.paginated && this.pagesLoaded.length < this.totalPages) {
        this.paging.sliderPage = this.paging.sliderPage + 1;
        this.pagesLoaded.push(this.paging.sliderPage);
        if (this.isPeople) {
          this.runPagingPeople().then(() => {
            setTimeout(() => {
              resolve();
            }, 50);
          })
        }
        else if (this.isOrganization) {
          this.runPagingOrganization().then(() => {
            setTimeout(() => {
              resolve();
            }, 50);
          })
        }
        else {
          this.runPaging().then(() => {
            setTimeout(() => {
              this.paging.sliderItems = 10;
              resolve();
            }, 50);
          })
        }
      } else {
         if (this.paginated && this.isProgram) {
          this.runPagingProgram().then(() => {
            setTimeout(() => {
              resolve();
            }, 500);
          })
        } else {
          resolve();
        }
      }
    })
  }

  runPagingProgram(): Promise<void> {
    return new Promise((resolve) => {
      if (this.catalog.canPage) {
        this.pagingContentProgram.emit({ programSeason: this.catalog, type: 'horizontal'});
      }
      resolve();
    });
  }

  runPagingPeople(): Promise<void> {
    return new Promise((resolve) => {
      const pagingObj = {
        page: this.paging.sliderPage + 1,
        rowsPage: this.paging.sliderItems,
        userId: this.personService.personId,
        category: this.slideFilter && this.slideFilter.current.type
      };

      this.preloading = true;

      this.personService.getPersonCatalogPaging(pagingObj)
          .then((items: IContentCatalogViewModel[]) => {
            this.preloading = false;
            if (items && items.length > 0) {
              this.createSliderItems(items);
            }
            resolve();
          });
    });
  }

  runPagingOrganization(): Promise<void> {
    return new Promise((resolve) => {
      const paginObj = {
        page: this.paging.sliderPage,
        rowsPage: this.paging.sliderItems,
        organizationId: this.catalog.id,
        category: this.slideFilter && this.slideFilter.current.type,
        customFilters: [
          {
            name: 'contentType',
            value: this.catalog.name
          }
        ]
      }

      this.organizationService.GetOrganizationPaging(paginObj)
        .then((items: IContentCatalogViewModel[]) => {
          this.preloading = false;
            if (items && items.length > 0) {
              this.createSliderItems(items);
            }
            resolve();
        })
    })
  }

  runPaging(reverse: boolean = false): Promise<void> {
    this.paging.catalogId = this._catalog.id;
    this.paging.isMyList = this._catalog.isMyList;
    this.paging.isMySee = this._catalog.isMySee;
    this.paging.isRecommended = this._catalog.isRecommended;
    this.preloading = true;
    return new Promise((resolve) => {
      this.catalogService
        .catalogPaging(this.paging)
        .then((items: IContentCatalogViewModel[]) => {
          this.preloading = false;
          let index = 0;
          if (index < items.length) {
            this.createSliderItems(items);
          }
          resolve();
        });
    })
  }

  createSliderItems(items: IContentCatalogViewModel[]) {
    if(items && items.length > 0) {
      this.catalogItems = [...this.catalogItems, ...items.map((item) => {
        item.loaded = true;
        item.isShow = false;
        this.calculateProgress(item);
        if (item.type === 'ebook' || item.type === 'audiobook') {
          this.setPercentageByItem(item);
        }
        return item
      })];
      if (this._catalog.isMyList) {
        this._catalog.contentCatalogViewModels = this.catalogItems;
        this._catalog.defaultContentType = this.catalogItems[0].type;
        this._catalog.totalItems = this.catalogItems.length;
      }
    }
  }

  calculateProgress(content: IContentCatalogViewModel) {
    if (this.user && !this.preloading && this.user.videoUserViews) {
      const videoUserView = this.user.videoUserViews.find((f) => {
        return f.videoId === content.contentId;
      });
      content.percent = content.percent ? content.percent : 0;
      if (
        videoUserView &&
        videoUserView.totalSeconds - videoUserView.secondEnd > 60
      ) {
        content.percent = videoUserView.secondEnd / videoUserView.totalSeconds;
      }
    }
  }

  // Método que setea el porcentaje por contenido de cada item contenido en los datos de User.
  setPercentageByItem(catalogItem: IContentCatalogViewModel) {
    if (this.user && !this.preloading && this.user.contentUserPlayed) {
      return new Promise((resolve, reject) => {
        try {
          if (this.user.contentUserPlayed && Array.isArray(this.user.contentUserPlayed)) {
            const idxItem = this.user.contentUserPlayed.findIndex(catalog => catalog.contentId === catalogItem.contentId);
            if (idxItem > -1) catalogItem.percent = this.user.contentUserPlayed[idxItem].percentage;
          }
          resolve(true);
        } catch (error) {
          reject(error);
        }
      });
    }
  }

  @Input()
  set contentCatalogSelected(contentCatalog: IContentCatalogViewModel) {
    this._contentCatalogSelected = contentCatalog;
    if (
      contentCatalog &&
      contentCatalog.type === 'program' &&
      this.contentCatalogSelected.isShow
    ) {
      this.loadingOverlayService.showLoadingOverlay();
      this.programService
        .getSeasons({ programId: this.contentCatalogSelected.contentId })
        .then((programSeason: ICatalogViewModel[]) => {
          this.programSeason = programSeason;
          this.loadingOverlayService.hideLoadingOverlay();
        });
    }
  }

  get contentCatalogSelected(): IContentCatalogViewModel {
    return this._contentCatalogSelected;
  }

  navigate(content: IContentCatalogViewModel) {
    if(this.trackingLikeActive || this.trackingListActive || this.trackingDetailActive)
    {
      this.trackingLikeActive = false;
      this.trackingListActive = false;
      this.trackingDetailActive = false;
      return;
    }
    if (content.type === 'video' || content.type === 'program') {
      this.openModalDetail(content.contentId, content.type);
    }
    else {
      content.isShow = true;
      this.catalog.isShowDetail = true;
      this.contentCatalogSelected = content;
    }
  }

  closeDetail() {
    if (this.contentCatalogSelected) {
      this.contentCatalogSelected.isShow = false;
    }
    this.catalog.contentCatalogViewModels.forEach((value) => {
      value.isShow = false;
    });
    this.contentCatalogSelected = null;
  }

  refreshSlider() {
    this.sliderObject.refresh();
  }


  @Input()
  set catalog(value: ICatalogViewModel) {
    this._catalog = value;
    this.x = 0;
    this.lastCurrentIndex = 0;
    this.xCalculated = 0;
    this.currentIndex = 0;
    if (this.isProgram) this.restoreScrollSeason(this._catalog);
    if (!this.preloading) {
      if (!!this._catalog && this._catalog.isMyList) {
        this.suscriberChangeMyList = this.catalogService.changeMyListItemSubscriber.subscribe(
          (content) => {
            content === 'change'
              ? this.refreshFilters()
              : this.catalogItems =  this._catalog.contentCatalogViewModels
          }
        );
      }
      if (this.paginated) {
        this.slideFilter.catalog = this._catalog;
        if (this._catalog.isMySee) {
          this.paging.contentType = 'video';
          this.totalItems = value.totalItems;
        } else {
          this.paging.contentType = this.slideFilter.current.type;
        }

        if (this.slideFilter.current) {
          this.totalPages = Math.ceil(
            this.slideFilter.current.totalItemsPerType / this.paging.sliderItems
          );
          this.totalItems = this.slideFilter.current.totalItemsPerType;
        } else {
          this.totalItems = value.totalItems;
          this.totalPages = Math.ceil(
            value.totalItems / this.paging.sliderItems
          );
        }
      } else {
        this.totalItems = this._catalog.contentCatalogViewModels.length;
        this.pagesLoaded.push(1);
      }
    } else {
      this.totalItems = this._catalog.contentCatalogViewModels.length;
    }

    this.catalogItems = [];
    this.createSliderItems(this._catalog.contentCatalogViewModels);
  }

  get catalog(): ICatalogViewModel {
    return this._catalog;
  }

  refreshFilters(): void {
    this.changeFilter(this._catalog.defaultContentType);
    this.slideFilter.catalog = this._catalog;
  }

  show(contentCatalog: IContentCatalogViewModel) {
    this.trackingDetailActive = true;
    this.closeAllItems.emit(true);
    setTimeout(() => {
      this.programSeason = null;
      if (this.devideDetector.isDesktop()) {
        if (contentCatalog.type === 'audiobook') {
          this.showAudioBookDetail(contentCatalog);
          return;
        }
        if (contentCatalog.type === 'ebook') {
          this.showEBookDetail(contentCatalog);
          return;
        }
        if (this.contentCatalogSelected) {
          this.contentCatalogSelected.isShow = false;
        }
        if (
          this.isShowDetail === true &&
          this.contentCatalogSelected !== contentCatalog
        ) {
          this.contentCatalogSelected = contentCatalog;
          this.contentCatalogSelected.isShow = true;
        } else if (this.isShowDetail === false) {
          this.contentCatalogSelected = contentCatalog;
          this.isShowDetail = true;
          this.contentCatalogSelected.isShow = true;
        } else if (
          this.isShowDetail === true &&
          this.contentCatalogSelected === contentCatalog
        ) {
          this.isShowDetail = false;
          this.contentCatalogSelected.isShow = false;
        }

        this.contentCatalogSelected = contentCatalog;
        SliderComponent.hideSubject.next(this.catalog);

        this.videoDetail.change(contentCatalog.isShow, contentCatalog);
        this.slideFilterRef.nativeElement.scrollIntoView({
          behavior: 'auto',
          block: 'start',
        });
      } else {
        if (contentCatalog.type === 'ebook') {
          this.showEBookDetail(contentCatalog);
          return;
        } else {
          this.router.navigate([
            '/',
            contentCatalog.type,
            contentCatalog.contentId,
          ]);
        }
      }
    }, 1000);

  }

  changeFilter(type: string) {
    this.x = 0;
    this.paging.sliderPage = 0;
    this.paging.contentType = type;
    this.catalogItems = [];
    this.pagesLoaded = [];
    this.currentIndex = 0;
    if (this._catalog.id === '00000000-0000-0000-0000-000000000500' && this._catalog.isContinueWatching) {
      this._catalog.name = `CONTINUAR ${type === 'ebook' ? 'LEYENDO' : 'ESCUCHANDO'}`;
    }
    if (this.slideFilter.current) {
      this.totalPages = Math.ceil(
        this.slideFilter.current.totalItemsPerType / this.paging.sliderItems
      );
      this.totalItems = this.slideFilter.current.totalItemsPerType;
    } else {
      this.totalItems = this._catalog.totalItems;
      this.totalPages = Math.ceil(
        this._catalog.totalItems / this.paging.sliderItems
      );
    }
    this.createSliderItems([]);
    this.runPaging();
  }

  mouseOverItem(content: IContentCatalogViewModel) {
    // Sentencia if solo para type "article" ya que tiene un tratamiento diferente en el "else" se continúa la sentencia normal.
    if (content.type === 'article' && this._user && content.contentId) {
      this.itemsHovered[content.contentId] = true;
      if (!this.socialInfoArticles[content.contentId]) {
        this.contentService
            .getSocialInfo(content.contentId)
            .then((socialInfo: IContentSocialInfoViewModel) => this.socialInfoArticles[content.contentId] = socialInfo);
      }
    } else {
      if (this._user && content.contentId) {
        this.overSocialInfo = null;
        if (!this.socialInfos[content.contentId]) {
          this.socialInfos[content.contentId] = true;
          this.contentService
          .getSocialInfo(content.contentId)
          .then((socialInfo: IContentSocialInfoViewModel) => {
            this.overSocialInfo = socialInfo;
          });
        } else if (this.socialInfos[content.contentId]) {
          const socialInfo = this.contentService.contentSocialInfo.find((c) => {
            return c.contentId === content.contentId;
          });
          if (socialInfo) {
            this.overSocialInfo = socialInfo;
          }
        }
      }
    }
  }

  // Método para controlar cuando el mouse sale de un item tipo article para controlar si se muestra en el slider en Like o no.
  mouseOutItem(content: IContentCatalogViewModel) {
    if (this._user && content.contentId && content.type === 'article') {
      this.itemsHovered[content.contentId] = false;
    }
  }

  addRemoveToWhatchList(content: IContentCatalogViewModel) {
    this.trackingListActive = true;
    if (this._user) {
      if (content.type === 'article') {
        this.overSocialInfo = this.socialInfoArticles[content.contentId];
      }
      if (this.overSocialInfo) {
        this.enabledList = false;
        this.overSocialInfo.isMyContent = !this.overSocialInfo.isMyContent;
        if (this.overSocialInfo.isMyContent) {
          this.catalogService.addMyList(content).then(
            () => {
              this.enabledList = true;
            },
            () => {
              this.enabledList = true;
            }
          );
        } else {
          this.catalogService.removeMyList(content).then(
            () => {
              this.enabledList = true;
            },
            () => {
              this.enabledList = true;
            }
          );
          if (this.catalog.isMyList) {
            this.closeDetail();
          }
        }

        this.contentService
          .toggleMyLIst({ contentId: this.overSocialInfo.contentId })
          .then(() => {
            if (!this.overSocialInfo.isMyContent) {
              this.myMonitoringService.logEvent({name: 'RemoveVideoFromWatchlist', properties: {
                ContentId: this.overSocialInfo.contentId,
              }});
            } else {
              this.myMonitoringService.logEvent({name: 'AddVideoToWatchlist', properties: {
                ContentId: this.overSocialInfo.contentId,
              }});
            }
          });
      }
    } else {
      const modalRef = this.modalService.open(LoginModalComponent, {
        windowClass: 'dark-modal modal-outlogin',
        backdropClass: 'light-black-backdrop'
      });
      modalRef.componentInstance.customMessageTag = 'need-to-login';
    }
  }

  trackLike(content: IContentCatalogViewModel) {
    this.enabledLike = false;
    this.trackingLikeActive = true;
    if (!this.user) {
      const modalRef = this.modalService.open(LoginModalComponent, {
        windowClass: 'dark-modal modal-outlogin',
        backdropClass: 'light-black-backdrop'
      });
      modalRef.componentInstance.customMessageTag = 'need-to-login';
      return;
    }

    if (content.type === 'article') {
      this.overSocialInfo = this.socialInfoArticles[content.contentId];
    }

    this.myMonitoringService.logEvent({name:'Like', properties: {
      VideoId: this.overSocialInfo.contentId,
      SocialEventTypeId: 1,
    }});
    this.overSocialInfo.isLike = !this.overSocialInfo.isLike;
    this.contentService
      .toggleLike({ contentId: this.overSocialInfo.contentId })
      .then(
        () => {
          this.enabledLike = true;
        },
        () => {
          this.enabledLike = true;
        }
      );
  }

  goToContent(content: IContentCatalogViewModel) {
    if (content.type === TypeContentCover.PROGRAM){
      this.navigate(content);
    } else {
      if (content.type === TypeContentCover.PODCAST_ITEM){
        this.loadingOverlayService.showLoadingOverlay();
        this.podcastService.getPodcastParent({podcastItemId: content.contentId})
        .then((podcast) => {
          this.loadingOverlayService.hideLoadingOverlay();
          this.router.navigate([RoutesApp.PODCAST, podcast.contentId]);
      })
      .catch(() => this.loadingOverlayService.hideLoadingOverlay());
      }
      if (content.type === TypeContentCover.ORGANIZATION){
        this.router.navigate([RoutesApp.DETAIL_ORGANIZATION, content.contentId]);
      }
      if (content.type === TypeContentCover.PERSON){
        this.router.navigate([RoutesApp.DETAIL_PERSON, content.contentId]);
      }
      if (content.type === TypeContentCover.ARTICLE){
        this.router.navigate(['/', content.type, content.contentId]);
      }
      if (content.type === TypeContentCover.PODCAST) {
        this.openModalDetail(content.contentId, content.type)
      }
    }
  }

  goToCatalogPage() {
    if (this.catalog.isMyList)
    {
      this.goToMyList();
    } else {
      const url: string[] = ['/', 'catalog'];
      if (this.slideFilter.current) {
        url.push(this.slideFilter.current.type);
      }
      url.push(this.catalog.id);
      if (!this.sectionTypeContent) {
        url.push('content');
        this.router.navigate(url, {
          queryParams: { name: this.catalog.name },
        });
      } else {
        this.router.navigate(url);
      }
    }
}

  goToMyList() {
    this.router.navigate(['/user/mylist']);
  }

  private showAudioBookDetail(contentCatalog: IContentCatalogViewModel) {
    this.contentService
      .getAcquireContent({ contentId: contentCatalog.contentId })
      .then((res: boolean) => {
        this.loadingModal = false;
        if (res == true) {
          this.read(contentCatalog.type, contentCatalog.contentId);
        }
      });
  }

  private showEBookDetail(contentCatalog: IContentCatalogViewModel) {
    this.contentService
      .getAcquireContent({ contentId: contentCatalog.contentId })
      .then((res: boolean) => {
        this.loadingModal = false;
        if (res) {
          this.openModalDetail(contentCatalog.contentId, contentCatalog.type)
        };
      });
  }

  read(contentType, contentId) {
    this.router.navigate(['/', contentType, contentId]);
  }

  isPPV(content: any) {
    if (this.ppvContentService.isPPV(content) && !content.acquired) {
      return true;
    }
    return false;
  }

  public navigateFromSeason(content: IContentCatalogViewModel): void {
    if (this.seasons) this.navigate(content);
  }

  restoreScrollSeason(catalog: ICatalogViewModel): void {
    if (!!this.oldCatalog) {
      if (catalog.name !== this.oldCatalog.name) {
        // restore scroll
        this.x = 0;
        this.activePane = 'left';
        this.currentIndex = 0
        this.lastCurrentIndex = 0;
        this.oldCatalog = catalog
      }
    } else {
      this.oldCatalog = catalog;
    }
  }

  getCatalogType(){
    if(this.catalogItems && this.catalogItems.length > 0){
      return this.catalogItems[0].type;
    }
  }

  openModalDetail(contentId: string, contentType: string): void{
    this.modalManagementService.openModalMoreInfo(contentId, contentType);
  }
}
