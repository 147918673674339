import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent {

  @Input() label: string;
  @Input() classType: string;
  @Input() model: boolean;
  @Input() disabled = false;
  @Output() modelChange: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  public onChanges(newValue: boolean) {
    if (!this.disabled) {
      this.model = newValue;
      this.modelChange.emit(newValue);
    }
  }
}
