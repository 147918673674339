import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingOverlayComponent } from './loading-overlay.component';
import { LottieModule } from 'ngx-lottie';


@NgModule({
  declarations: [
    LoadingOverlayComponent
  ],
  exports:[
    LoadingOverlayComponent
  ],
  imports: [
    CommonModule,
    LottieModule
  ]
})
export class LoadingOverlayModule { }
