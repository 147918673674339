import { CountryTimeZone, IUserCountry } from '@interfaces/i-country';

export const COUNTRIES_LIST: CountryTimeZone[] = [
  {
    value: 'world',
    name: 'Resto del Mundo',
    imgFlag: 'assets/img/flags/glo.webp',
    id: null,
    code: null,
    languageId: null,
    isoCurrency: null,
    isoCode: null,
  },
  {
    value: 'CR',
    name: 'Costa Rica',
    imgFlag: 'assets/img/flags/crc.webp',
    id: null,
    code: null,
    languageId: null,
    isoCurrency: null,
    isoCode: null,
  },
  {
    value: 'AR',
    name: 'Argentina',
    imgFlag: 'assets/img/flags/arg.webp',
    id: null,
    code: null,
    languageId: null,
    isoCurrency: null,
    isoCode: null,
  },
  {
    value: 'BO',
    name: 'Bolivia',
    imgFlag: 'assets/img/flags/bol.webp',
    id: null,
    code: null,
    languageId: null,
    isoCurrency: null,
    isoCode: null,
  },
  {
    value: 'BR',
    name: 'Brasil',
    imgFlag: 'assets/img/flags/bra.webp',
    id: null,
    code: null,
    languageId: null,
    isoCurrency: null,
    isoCode: null,
  },
  {
    value: 'CL',
    name: 'Chile',
    imgFlag: 'assets/img/flags/chi.webp',
    id: null,
    code: null,
    languageId: null,
    isoCurrency: null,
    isoCode: null,
  },
  {
    value: 'CO',
    name: 'Colombia',
    imgFlag: 'assets/img/flags/col.webp',
    id: null,
    code: null,
    languageId: null,
    isoCurrency: null,
    isoCode: null,
  },
  {
    value: 'CU',
    name: 'Cuba',
    imgFlag: 'assets/img/flags/cub.webp',
    id: null,
    code: null,
    languageId: null,
    isoCurrency: null,
    isoCode: null,
  },
  {
    value: 'DO',
    name: 'Rep. Dominicana',
    imgFlag: 'assets/img/flags/rep.webp',
    id: null,
    code: null,
    languageId: null,
    isoCurrency: null,
    isoCode: null,
  },
  {
    value: 'EC',
    name: 'Ecuador',
    imgFlag: 'assets/img/flags/ecu.webp',
    id: null,
    code: null,
    languageId: null,
    isoCurrency: null,
    isoCode: null,
  },
  {
    value: 'GT',
    name: 'Guatemala',
    imgFlag: 'assets/img/flags/gua.webp',
    id: null,
    code: null,
    languageId: null,
    isoCurrency: null,
    isoCode: null,
  },
  {
    value: 'GY',
    name: 'Guyana',
    imgFlag: 'assets/img/flags/gya.webp',
    id: null,
    code: null,
    languageId: null,
    isoCurrency: null,
    isoCode: null,
  },
  {
    value: 'HN',
    name: 'Honduras',
    imgFlag: 'assets/img/flags/hon.webp',
    id: null,
    code: null,
    languageId: null,
    isoCurrency: null,
    isoCode: null,
  },
  {
    value: 'MX',
    name: 'México',
    imgFlag: 'assets/img/flags/mex.webp',
    id: null,
    code: null,
    languageId: null,
    isoCurrency: null,
    isoCode: null,
  },
  {
    value: 'NI',
    name: 'Nicaragua',
    imgFlag: 'assets/img/flags/nic.webp',
    id: null,
    code: null,
    languageId: null,
    isoCurrency: null,
    isoCode: null,
  },
  {
    value: 'PA',
    name: 'Panamá',
    imgFlag: 'assets/img/flags/pan.webp',
    id: null,
    code: null,
    languageId: null,
    isoCurrency: null,
    isoCode: null,
  },
  {
    value: 'PE',
    name: 'Perú',
    imgFlag: 'assets/img/flags/per.webp',
    id: null,
    code: null,
    languageId: null,
    isoCurrency: null,
    isoCode: null,
  },
  {
    value: 'PR',
    name: 'Puerto Rico',
    imgFlag: 'assets/img/flags/pto.webp',
    id: null,
    code: null,
    languageId: null,
    isoCurrency: null,
    isoCode: null,
  },
  {
    value: 'SV',
    name: 'El Salvador',
    imgFlag: 'assets/img/flags/els.webp',
    id: null,
    code: null,
    languageId: null,
    isoCurrency: null,
    isoCode: null,
  },
  {
    value: 'US',
    name: 'Estados Unidos',
    imgFlag: 'assets/img/flags/usa.webp',
    id: null,
    code: null,
    languageId: null,
    isoCurrency: null,
    isoCode: null,
  },
  {
    value: 'UY',
    name: 'Uruguay',
    imgFlag: 'assets/img/flags/uru.webp',
    id: null,
    code: null,
    languageId: null,
    isoCurrency: null,
    isoCode: null,
  },
  {
    value: 'VE',
    name: 'Venezuela',
    imgFlag: 'assets/img/flags/ven.webp',
    id: null,
    code: null,
    languageId: null,
    isoCurrency: null,
    isoCode: null,
  },
];

export const COUNTRIES_CURRENCIES: IUserCountry[] = [
  { CountryName: 'Afganistán', IsoCurrencyCode: 'AFN', IsoCountryCode: 'AF' },
  {
    CountryName: 'África del Sur',
    IsoCurrencyCode: 'ZAR',
    IsoCountryCode: 'ZA',
  },
  { CountryName: 'Åland', IsoCurrencyCode: 'AX', IsoCountryCode: 'AX' },
  { CountryName: 'Albania', IsoCurrencyCode: 'ALL', IsoCountryCode: 'AL' },
  { CountryName: 'Alemania', IsoCurrencyCode: 'EUR', IsoCountryCode: 'DE' },
  { CountryName: 'Andorra', IsoCurrencyCode: 'EUR', IsoCountryCode: 'AD' },
  { CountryName: 'Angola', IsoCurrencyCode: 'AOA', IsoCountryCode: 'AO' },
  { CountryName: 'Anguila', IsoCurrencyCode: 'XCD', IsoCountryCode: 'AI' },
  {
    CountryName: 'Antigua y Barbuda',
    IsoCurrencyCode: 'XCD',
    IsoCountryCode: 'AG',
  },
  {
    CountryName: 'Antillas Neerlandesas',
    IsoCurrencyCode: 'ANG',
    IsoCountryCode: 'AN',
  },
  { CountryName: 'Arabia Saudí', IsoCurrencyCode: 'SAR', IsoCountryCode: 'SA' },
  { CountryName: 'Argelia', IsoCurrencyCode: 'DZD', IsoCountryCode: 'DZ' },
  { CountryName: 'Argentina', IsoCurrencyCode: 'ARS', IsoCountryCode: 'AR' },
  { CountryName: 'Armenia', IsoCurrencyCode: 'AMD', IsoCountryCode: 'AM' },
  { CountryName: 'Aruba', IsoCurrencyCode: 'AWG', IsoCountryCode: 'AW' },
  { CountryName: 'Australia', IsoCurrencyCode: 'AUD', IsoCountryCode: 'AU' },
  { CountryName: 'Austria', IsoCurrencyCode: 'EUR', IsoCountryCode: 'AT' },
  { CountryName: 'Azerbaiyán', IsoCurrencyCode: 'AZN', IsoCountryCode: 'AZ' },
  { CountryName: 'Bahamas', IsoCurrencyCode: 'BSD', IsoCountryCode: 'BS' },
  { CountryName: 'Bahrein', IsoCurrencyCode: 'BHD', IsoCountryCode: 'BH' },
  { CountryName: 'Bangladesh', IsoCurrencyCode: 'BDT', IsoCountryCode: 'BD' },
  { CountryName: 'Barbados', IsoCurrencyCode: 'BBD', IsoCountryCode: 'BB' },
  { CountryName: 'Bélgica', IsoCurrencyCode: 'EUR', IsoCountryCode: 'BE' },
  { CountryName: 'Belice', IsoCurrencyCode: 'BZD', IsoCountryCode: 'BZ' },
  { CountryName: 'Benin', IsoCurrencyCode: 'XOF', IsoCountryCode: 'BJ' },
  { CountryName: 'Bermuda', IsoCurrencyCode: 'BMD', IsoCountryCode: 'BM' },
  { CountryName: 'Bhoutan', IsoCurrencyCode: 'BTN', IsoCountryCode: 'BT' },
  { CountryName: 'Bielorrusia', IsoCurrencyCode: 'BYN', IsoCountryCode: 'BY' },
  {
    CountryName: 'Birmania (Myanmar)',
    IsoCurrencyCode: 'MMK',
    IsoCountryCode: 'MM',
  },
  { CountryName: 'Bolivia', IsoCurrencyCode: 'BOB', IsoCountryCode: 'BO' },
  {
    CountryName: 'Bosnia y Herzegovina',
    IsoCurrencyCode: 'BAM',
    IsoCountryCode: 'BA',
  },
  { CountryName: 'Botsuana', IsoCurrencyCode: 'BWP', IsoCountryCode: 'BW' },
  { CountryName: 'Brasil', IsoCurrencyCode: 'BRL', IsoCountryCode: 'BR' },
  { CountryName: 'Brunéi', IsoCurrencyCode: 'BND', IsoCountryCode: 'BN' },
  { CountryName: 'Bulgaria', IsoCurrencyCode: 'BGN', IsoCountryCode: 'BG' },
  { CountryName: 'Burkina Faso', IsoCurrencyCode: 'XOF', IsoCountryCode: 'BF' },
  { CountryName: 'Burundi', IsoCurrencyCode: 'BIF', IsoCountryCode: 'BI' },
  { CountryName: 'Cabo Verde', IsoCurrencyCode: 'CVE', IsoCountryCode: 'CV' },
  { CountryName: 'Camboya', IsoCurrencyCode: 'KHR', IsoCountryCode: 'KH' },
  { CountryName: 'Camerún', IsoCurrencyCode: 'XAF', IsoCountryCode: 'CM' },
  { CountryName: 'Canadá', IsoCurrencyCode: 'CAD', IsoCountryCode: 'CA' },
  { CountryName: 'Chad', IsoCurrencyCode: 'XAF', IsoCountryCode: 'TD' },
  { CountryName: 'Chile', IsoCurrencyCode: 'CLP', IsoCountryCode: 'CL' },
  { CountryName: 'China', IsoCurrencyCode: 'CNY', IsoCountryCode: 'CN' },
  { CountryName: 'Chipre', IsoCurrencyCode: 'EUR', IsoCountryCode: 'CY' },
  {
    CountryName: 'Ciudad del Vaticano',
    IsoCurrencyCode: 'EUR',
    IsoCountryCode: 'VA',
  },
  { CountryName: 'Colombia', IsoCurrencyCode: 'COP', IsoCountryCode: 'CO' },
  { CountryName: 'Comoras', IsoCurrencyCode: 'KMF', IsoCountryCode: 'KM' },
  {
    CountryName: 'Congo (Brazzaville)',
    IsoCurrencyCode: 'XAF',
    IsoCountryCode: 'CG',
  },
  {
    CountryName: 'Congo/Kinshasa (RDC)',
    IsoCurrencyCode: 'CDF',
    IsoCountryCode: 'CD',
  },
  {
    CountryName: 'Corea del Norte',
    IsoCurrencyCode: 'KPW',
    IsoCountryCode: 'KP',
  },
  {
    CountryName: 'Corea del Sur',
    IsoCurrencyCode: 'KRW',
    IsoCountryCode: 'KR',
  },
  {
    CountryName: 'Costa de Marfil',
    IsoCurrencyCode: 'XOF',
    IsoCountryCode: 'CI',
  },
  { CountryName: 'Costa Rica', IsoCurrencyCode: 'CRC', IsoCountryCode: 'CR' },
  { CountryName: 'Croacia', IsoCurrencyCode: 'HRK', IsoCountryCode: 'HR' },
  { CountryName: 'Cuba', IsoCurrencyCode: 'CUC', IsoCountryCode: 'CU' },
  { CountryName: 'Dinamarca', IsoCurrencyCode: 'DKK', IsoCountryCode: 'DK' },
  { CountryName: 'Dominique', IsoCurrencyCode: 'XCD', IsoCountryCode: 'DM' },
  { CountryName: 'Ecuador', IsoCurrencyCode: 'USD', IsoCountryCode: 'EC' },
  { CountryName: 'Egipto', IsoCurrencyCode: 'EGP', IsoCountryCode: 'EG' },
  { CountryName: 'El Salvador', IsoCurrencyCode: 'SVC', IsoCountryCode: 'SV' },
  {
    CountryName: 'Emiratos Arabes Unidos',
    IsoCurrencyCode: 'AED',
    IsoCountryCode: 'AE',
  },
  { CountryName: 'Eritrea', IsoCurrencyCode: 'ERN', IsoCountryCode: 'ER' },
  { CountryName: 'Eslovaquia', IsoCurrencyCode: 'EUR', IsoCountryCode: 'SK' },
  { CountryName: 'Eslovenia', IsoCurrencyCode: 'EUR', IsoCountryCode: 'SI' },
  { CountryName: 'España', IsoCurrencyCode: 'EUR', IsoCountryCode: 'ES' },
  {
    CountryName: 'Estados Unidos',
    IsoCurrencyCode: 'USD',
    IsoCountryCode: 'US',
  },
  { CountryName: 'Estonia', IsoCurrencyCode: 'EUR', IsoCountryCode: 'EE' },
  { CountryName: 'Etiopía', IsoCurrencyCode: 'ETB', IsoCountryCode: 'ET' },
  { CountryName: 'Fiji', IsoCurrencyCode: 'FJD', IsoCountryCode: 'FJ' },
  { CountryName: 'Filipinas', IsoCurrencyCode: 'PHP', IsoCountryCode: 'PH' },
  { CountryName: 'Finlandia', IsoCurrencyCode: 'EUR', IsoCountryCode: 'FI' },
  { CountryName: 'Francia', IsoCurrencyCode: 'EUR', IsoCountryCode: 'FR' },
  {
    CountryName: 'Francia metropolitana',
    IsoCurrencyCode: 'EUR',
    IsoCountryCode: 'FR',
  },
  { CountryName: 'FSU', IsoCurrencyCode: 'RUB', IsoCountryCode: 'RU' },
  { CountryName: 'Gabón', IsoCurrencyCode: 'XAF', IsoCountryCode: 'GA' },
  { CountryName: 'Gambia', IsoCurrencyCode: 'GMD', IsoCountryCode: 'GM' },
  { CountryName: 'Georgia', IsoCurrencyCode: 'GEL', IsoCountryCode: 'GE' },
  { CountryName: 'Ghana', IsoCurrencyCode: 'GHS', IsoCountryCode: 'GH' },
  { CountryName: 'Gibraltar', IsoCurrencyCode: 'GIP', IsoCountryCode: 'GI' },
  { CountryName: 'Gran Bretaña', IsoCurrencyCode: 'GBP', IsoCountryCode: 'UK' },
  { CountryName: 'Grecia', IsoCurrencyCode: 'EUR', IsoCountryCode: 'GR' },
  { CountryName: 'Grenade', IsoCurrencyCode: 'XCD', IsoCountryCode: 'GD' },
  {
    CountryName: 'Groenlandia (Dinamarca)',
    IsoCurrencyCode: 'DKK',
    IsoCountryCode: 'GL',
  },
  { CountryName: 'Guadalupe', IsoCurrencyCode: 'EUR', IsoCountryCode: 'GP' },
  {
    CountryName: 'Guam (EE.UU.)',
    IsoCurrencyCode: 'USD',
    IsoCountryCode: 'GU',
  },
  { CountryName: 'Guatemala', IsoCurrencyCode: 'GTQ', IsoCountryCode: 'GT' },
  { CountryName: 'Guayana', IsoCurrencyCode: 'GYD', IsoCountryCode: 'GY' },
  { CountryName: 'Guernesey', IsoCurrencyCode: 'GGP', IsoCountryCode: 'GG' },
  {
    CountryName: 'Guiana francés',
    IsoCurrencyCode: 'EUR',
    IsoCountryCode: 'GF',
  },
  { CountryName: 'Guinea', IsoCurrencyCode: 'GNF', IsoCountryCode: 'GN' },
  {
    CountryName: 'Guinea Bissau',
    IsoCurrencyCode: 'XAF',
    IsoCountryCode: 'GW',
  },
  { CountryName: 'Haití', IsoCurrencyCode: 'HTG', IsoCountryCode: 'HT' },
  { CountryName: 'Holanda', IsoCurrencyCode: 'EUR', IsoCountryCode: 'NL' },
  { CountryName: 'Honduras', IsoCurrencyCode: 'HNL', IsoCountryCode: 'HN' },
  { CountryName: 'Hong-Kong', IsoCurrencyCode: 'HKD', IsoCountryCode: 'HK' },
  { CountryName: 'Hungría', IsoCurrencyCode: 'HUF', IsoCountryCode: 'HU' },
  { CountryName: 'India', IsoCurrencyCode: 'INR', IsoCountryCode: 'IN' },
  { CountryName: 'Indonesia', IsoCurrencyCode: 'IDR', IsoCountryCode: 'ID' },
  { CountryName: 'Irak', IsoCurrencyCode: 'IQD', IsoCountryCode: 'IQ' },
  { CountryName: 'Irán', IsoCurrencyCode: 'IRR', IsoCountryCode: 'IR' },
  { CountryName: 'Irlanda', IsoCurrencyCode: 'EUR', IsoCountryCode: 'IE' },
  { CountryName: 'Isla de Man', IsoCurrencyCode: 'IMP', IsoCountryCode: 'IM' },
  {
    CountryName: 'Isla de Navidad',
    IsoCurrencyCode: 'AUD',
    IsoCountryCode: 'CX',
  },
  {
    CountryName: 'Isla Mauricio',
    IsoCurrencyCode: 'MUR',
    IsoCountryCode: 'MU',
  },
  { CountryName: 'Islandia', IsoCurrencyCode: 'ISK', IsoCountryCode: 'IS' },
  { CountryName: 'Islas Caimán', IsoCurrencyCode: 'KYD', IsoCountryCode: 'KY' },
  { CountryName: 'Islas Cocos', IsoCurrencyCode: 'AUD', IsoCountryCode: 'CC' },
  { CountryName: 'Islas Cook', IsoCurrencyCode: 'NZD', IsoCountryCode: 'CK' },
  {
    CountryName: 'Islas del Norte Marianne',
    IsoCurrencyCode: 'USD',
    IsoCountryCode: 'MP',
  },
  { CountryName: 'Islas Feroe', IsoCurrencyCode: 'DKK', IsoCountryCode: 'FO' },
  {
    CountryName: 'Islas Heard y Macdonald',
    IsoCurrencyCode: 'AUD',
    IsoCountryCode: 'HM',
  },
  {
    CountryName: 'Islas Marshall',
    IsoCurrencyCode: 'USD',
    IsoCountryCode: 'MH',
  },
  {
    CountryName: 'Islas Salomón',
    IsoCurrencyCode: 'SBD',
    IsoCountryCode: 'SB',
  },
  {
    CountryName: 'Islas Vírgenes Británicas',
    IsoCurrencyCode: 'USD',
    IsoCountryCode: 'VG',
  },
  {
    CountryName: 'Islas Vírgenes de los Estados Unidos',
    IsoCurrencyCode: 'USD',
    IsoCountryCode: 'VI',
  },
  { CountryName: 'Israel', IsoCurrencyCode: 'ILS', IsoCountryCode: 'IL' },
  { CountryName: 'Italia', IsoCurrencyCode: 'EUR', IsoCountryCode: 'IT' },
  { CountryName: 'Jamaica', IsoCurrencyCode: 'JMD', IsoCountryCode: 'JM' },
  { CountryName: 'Japón', IsoCurrencyCode: 'JPY', IsoCountryCode: 'JP' },
  { CountryName: 'Jersey', IsoCurrencyCode: 'JEP', IsoCountryCode: 'JE' },
  { CountryName: 'Jordania', IsoCurrencyCode: 'JOD', IsoCountryCode: 'JO' },
  { CountryName: 'Kazajstán', IsoCurrencyCode: 'KZT', IsoCountryCode: 'KZ' },
  { CountryName: 'Kenia', IsoCurrencyCode: 'KES', IsoCountryCode: 'KE' },
  { CountryName: 'Kirguistán', IsoCurrencyCode: 'KGS', IsoCountryCode: 'KG' },
  { CountryName: 'Kiribati', IsoCurrencyCode: 'KID', IsoCountryCode: 'KI' },
  { CountryName: 'Kuwait', IsoCurrencyCode: 'KWD', IsoCountryCode: 'KW' },
  { CountryName: 'La Reunión', IsoCurrencyCode: 'EUR', IsoCountryCode: 'RE' },
  { CountryName: 'Laos', IsoCurrencyCode: 'LAK', IsoCountryCode: 'LA' },
  { CountryName: 'Lesotho', IsoCurrencyCode: 'LSL', IsoCountryCode: 'LS' },
  { CountryName: 'Letonia', IsoCurrencyCode: 'EUR', IsoCountryCode: 'LV' },
  { CountryName: 'Líbano', IsoCurrencyCode: 'LBP', IsoCountryCode: 'LB' },
  { CountryName: 'Liberia', IsoCurrencyCode: 'LRD', IsoCountryCode: 'LR' },
  { CountryName: 'Libia', IsoCurrencyCode: 'LYD', IsoCountryCode: 'LY' },
  {
    CountryName: 'Liechtenstein',
    IsoCurrencyCode: 'CHF',
    IsoCountryCode: 'LI',
  },
  { CountryName: 'Lituania', IsoCurrencyCode: 'LTL', IsoCountryCode: 'LT' },
  { CountryName: 'Luxemburgo', IsoCurrencyCode: 'EUR', IsoCountryCode: 'LU' },
  { CountryName: 'Macao', IsoCurrencyCode: 'MOP', IsoCountryCode: 'MO' },
  { CountryName: 'Macedonia', IsoCurrencyCode: 'MKD', IsoCountryCode: 'MK' },
  { CountryName: 'Madagascar', IsoCurrencyCode: 'MGA', IsoCountryCode: 'MG' },
  { CountryName: 'Malasia', IsoCurrencyCode: 'MYR', IsoCountryCode: 'MY' },
  { CountryName: 'Malawi', IsoCurrencyCode: 'MWK', IsoCountryCode: 'MW' },
  { CountryName: 'Maldivas', IsoCurrencyCode: 'MVR', IsoCountryCode: 'MV' },
  { CountryName: 'Malí', IsoCurrencyCode: 'XOF', IsoCountryCode: 'ML' },
  { CountryName: 'Malta', IsoCurrencyCode: 'EUR', IsoCountryCode: 'MT' },
  { CountryName: 'Marruecos', IsoCurrencyCode: 'MAD', IsoCountryCode: 'MA' },
  { CountryName: 'Martinica', IsoCurrencyCode: 'EUR', IsoCountryCode: 'MQ' },
  { CountryName: 'Mauritania', IsoCurrencyCode: 'MRU', IsoCountryCode: 'MR' },
  { CountryName: 'Mayotte', IsoCurrencyCode: 'EUR', IsoCountryCode: 'YT' },
  { CountryName: 'México', IsoCurrencyCode: 'MXN', IsoCountryCode: 'MX' },
  { CountryName: 'Micronesia', IsoCurrencyCode: 'USD', IsoCountryCode: 'FM' },
  { CountryName: 'Moldavia', IsoCurrencyCode: 'MDL', IsoCountryCode: 'MD' },
  { CountryName: 'Mónaco', IsoCurrencyCode: 'EUR', IsoCountryCode: 'MC' },
  { CountryName: 'Mongolia', IsoCurrencyCode: 'MNT', IsoCountryCode: 'MN' },
  { CountryName: 'Montenegro', IsoCurrencyCode: 'EUR', IsoCountryCode: 'ME' },
  { CountryName: 'Montserrat', IsoCurrencyCode: 'XCD', IsoCountryCode: 'MS' },
  { CountryName: 'Mozambique', IsoCurrencyCode: 'MZN', IsoCountryCode: 'MZ' },
  { CountryName: 'Namibia', IsoCurrencyCode: 'NAD', IsoCountryCode: 'NA' },
  { CountryName: 'Nauru', IsoCurrencyCode: 'AUD', IsoCountryCode: 'NR' },
  { CountryName: 'Nepal', IsoCurrencyCode: 'NPR', IsoCountryCode: 'NP' },
  { CountryName: 'Nicaragua', IsoCurrencyCode: 'NIO', IsoCountryCode: 'NI' },
  { CountryName: 'Níger', IsoCurrencyCode: 'XOF', IsoCountryCode: 'NE' },
  { CountryName: 'Nigeria', IsoCurrencyCode: 'NGN', IsoCountryCode: 'NG' },
  { CountryName: 'Niue', IsoCurrencyCode: 'NZD', IsoCountryCode: 'NU' },
  { CountryName: 'Norfolk', IsoCurrencyCode: 'AUD', IsoCountryCode: 'NF' },
  { CountryName: 'Noruega', IsoCurrencyCode: 'NOK', IsoCountryCode: 'NO' },
  {
    CountryName: 'Nueva Caledonia',
    IsoCurrencyCode: 'XPF',
    IsoCountryCode: 'NC',
  },
  {
    CountryName: 'Nueva Zelanda',
    IsoCurrencyCode: 'NZD',
    IsoCountryCode: 'NZ',
  },
  { CountryName: 'Omán', IsoCurrencyCode: 'OMR', IsoCountryCode: 'OM' },
  { CountryName: 'Pakistán', IsoCurrencyCode: 'PKR', IsoCountryCode: 'PK' },
  { CountryName: 'Palau', IsoCurrencyCode: 'USD', IsoCountryCode: 'PW' },
  { CountryName: 'Palestina', IsoCurrencyCode: 'ILS', IsoCountryCode: 'PS' },
  { CountryName: 'Panamá', IsoCurrencyCode: 'PAB', IsoCountryCode: 'PA' },
  {
    CountryName: 'Papuasia-Nueva-Guinea',
    IsoCurrencyCode: 'PGK',
    IsoCountryCode: 'PG',
  },
  { CountryName: 'Paraguay', IsoCurrencyCode: 'PYG', IsoCountryCode: 'PY' },
  { CountryName: 'Perú', IsoCurrencyCode: 'PEN', IsoCountryCode: 'PE' },
  { CountryName: 'Pitcairn', IsoCurrencyCode: 'NZD', IsoCountryCode: 'PN' },
  {
    CountryName: 'Polinesia francese',
    IsoCurrencyCode: 'XPF',
    IsoCountryCode: 'PF',
  },
  { CountryName: 'Polonia', IsoCurrencyCode: 'PLN', IsoCountryCode: 'PL' },
  { CountryName: 'Portugal', IsoCurrencyCode: 'EUR', IsoCountryCode: 'PT' },
  { CountryName: 'Puerto Rico', IsoCurrencyCode: 'USD', IsoCountryCode: 'PR' },
  { CountryName: 'Qatar', IsoCurrencyCode: 'QAR', IsoCountryCode: 'QA' },
  {
    CountryName: 'República Centroafricana',
    IsoCurrencyCode: 'XAF',
    IsoCountryCode: 'CF',
  },
  {
    CountryName: 'República Checa',
    IsoCurrencyCode: 'CZK',
    IsoCountryCode: 'CS',
  },
  {
    CountryName: 'República Dominicana',
    IsoCurrencyCode: 'DOP',
    IsoCountryCode: 'DO',
  },
  { CountryName: 'Ruanda', IsoCurrencyCode: 'RWF', IsoCountryCode: 'RW' },
  { CountryName: 'Rumania', IsoCurrencyCode: 'RON', IsoCountryCode: 'RO' },
  { CountryName: 'Rusia', IsoCurrencyCode: 'RUB', IsoCountryCode: 'RU' },
  {
    CountryName: 'Sáhara Occidental',
    IsoCurrencyCode: 'MAD',
    IsoCountryCode: 'EH',
  },
  {
    CountryName: 'Samoa Americana',
    IsoCurrencyCode: 'USD',
    IsoCountryCode: 'AS',
  },
  {
    CountryName: 'Samoa Occidentales',
    IsoCurrencyCode: 'WST',
    IsoCountryCode: 'WS',
  },
  {
    CountryName: 'San Cristóbal y Nieves',
    IsoCurrencyCode: 'XCD',
    IsoCountryCode: 'KN',
  },
  { CountryName: 'San Marino', IsoCurrencyCode: 'EUR', IsoCountryCode: 'SM' },
  {
    CountryName: 'San Pedro y Miquelón',
    IsoCurrencyCode: 'EUR',
    IsoCountryCode: 'PM',
  },
  {
    CountryName: 'San Vicente y las Granadinas',
    IsoCurrencyCode: 'XCD',
    IsoCountryCode: 'VC',
  },
  { CountryName: 'Santa Elena', IsoCurrencyCode: 'SHP', IsoCountryCode: 'SH' },
  { CountryName: 'Santa Lucía', IsoCurrencyCode: 'XCD', IsoCountryCode: 'LC' },
  {
    CountryName: 'Santo Tomé y Príncipe',
    IsoCurrencyCode: 'STN',
    IsoCountryCode: 'ST',
  },
  { CountryName: 'Senegal', IsoCurrencyCode: 'XOF', IsoCountryCode: 'SN' },
  { CountryName: 'Serbia', IsoCurrencyCode: 'RSD', IsoCountryCode: 'RS' },
  { CountryName: 'Seychelles', IsoCurrencyCode: 'SCR', IsoCountryCode: 'SC' },
  { CountryName: 'Sierra Leona', IsoCurrencyCode: 'SLL', IsoCountryCode: 'SL' },
  { CountryName: 'Singapur', IsoCurrencyCode: 'SGD', IsoCountryCode: 'SG' },
  { CountryName: 'Sint Maarten', IsoCurrencyCode: 'ANG', IsoCountryCode: 'SX' },
  { CountryName: 'Siria', IsoCurrencyCode: 'SYP', IsoCountryCode: 'SY' },
  { CountryName: 'Somalia', IsoCurrencyCode: 'SOS', IsoCountryCode: 'SO' },
  { CountryName: 'Sri Lanka', IsoCurrencyCode: 'LKR', IsoCountryCode: 'LK' },
  { CountryName: 'Sudán', IsoCurrencyCode: 'SDG', IsoCountryCode: 'SD' },
  {
    CountryName: 'Sudán del Sur',
    IsoCurrencyCode: 'SSP',
    IsoCountryCode: 'SS',
  },
  { CountryName: 'Suecia', IsoCurrencyCode: 'SEK', IsoCountryCode: 'SE' },
  { CountryName: 'Suiza', IsoCurrencyCode: 'CHF', IsoCountryCode: 'CH' },
  { CountryName: 'Surinam', IsoCurrencyCode: 'SRD', IsoCountryCode: 'SR' },
  {
    CountryName: 'Svalbard y Jan Mayen',
    IsoCurrencyCode: 'NOK',
    IsoCountryCode: 'SJ',
  },
  { CountryName: 'Swazilandia', IsoCurrencyCode: 'SZL', IsoCountryCode: 'SZ' },
  { CountryName: 'Tailandia', IsoCurrencyCode: 'THB', IsoCountryCode: 'TH' },
  { CountryName: 'Taiwán', IsoCurrencyCode: 'TWD', IsoCountryCode: 'TW' },
  { CountryName: 'Tanzania', IsoCurrencyCode: 'TZS', IsoCountryCode: 'TZ' },
  { CountryName: 'Tayikistán', IsoCurrencyCode: 'TJS', IsoCountryCode: 'TJ' },
  {
    CountryName: 'Territorios británicos en el Océano Índico',
    IsoCurrencyCode: 'GBP',
    IsoCountryCode: 'IO',
  },
  {
    CountryName: 'Timor Oriental',
    IsoCurrencyCode: 'USD',
    IsoCountryCode: 'TL',
  },
  { CountryName: 'Togo', IsoCurrencyCode: 'XOF', IsoCountryCode: 'TG' },
  { CountryName: 'Tokelau', IsoCurrencyCode: 'NZD', IsoCountryCode: 'TK' },
  { CountryName: 'Tonga', IsoCurrencyCode: 'TOP', IsoCountryCode: 'TO' },
  {
    CountryName: 'Trinidad y Tobago',
    IsoCurrencyCode: 'TTD',
    IsoCountryCode: 'TT',
  },
  { CountryName: 'Túnez', IsoCurrencyCode: 'TND', IsoCountryCode: 'TN' },
  { CountryName: 'Turkmenistán', IsoCurrencyCode: 'TMT', IsoCountryCode: 'TM' },
  { CountryName: 'Turquía', IsoCurrencyCode: 'TRY', IsoCountryCode: 'TR' },
  {
    CountryName: 'Turquía y Caicos',
    IsoCurrencyCode: 'USD',
    IsoCountryCode: 'TC',
  },
  { CountryName: 'Tuvalu', IsoCurrencyCode: 'AUD', IsoCountryCode: 'TV' },
  { CountryName: 'Ucrania', IsoCurrencyCode: 'UAH', IsoCountryCode: 'UA' },
  { CountryName: 'Uganda', IsoCurrencyCode: 'UGX', IsoCountryCode: 'UG' },
  {
    CountryName: 'Unión Europea',
    IsoCurrencyCode: 'EUR',
    IsoCountryCode: 'EU',
  },
  { CountryName: 'Uruguay', IsoCurrencyCode: 'UYU', IsoCountryCode: 'UY' },
  { CountryName: 'Uzbekistán', IsoCurrencyCode: 'UZS', IsoCountryCode: 'UZ' },
  { CountryName: 'Vanuatu', IsoCurrencyCode: 'VUV', IsoCountryCode: 'VU' },
  {
    CountryName: 'varias islas estadounidenses',
    IsoCurrencyCode: 'USD',
    IsoCountryCode: 'UM',
  },
  { CountryName: 'Venezuela', IsoCurrencyCode: 'VES', IsoCountryCode: 'VE' },
  { CountryName: 'Vietnam', IsoCurrencyCode: 'VND', IsoCountryCode: 'VN' },
  {
    CountryName: 'Wallis y Futuna',
    IsoCurrencyCode: 'XPF',
    IsoCountryCode: 'WF',
  },
  { CountryName: 'Yemen', IsoCurrencyCode: 'YER', IsoCountryCode: 'YE' },
  { CountryName: 'Yibuti', IsoCurrencyCode: 'DJF', IsoCountryCode: 'DJ' },
  { CountryName: 'Yugoslavia', IsoCurrencyCode: 'YUN', IsoCountryCode: 'YU' },
  { CountryName: 'Zambia', IsoCurrencyCode: 'ZMW', IsoCountryCode: 'ZM' },
  { CountryName: 'Zimbabue', IsoCurrencyCode: 'ZWL', IsoCountryCode: 'ZW' },
  { CountryName: 'Guyana', IsoCurrencyCode: 'GYD', IsoCountryCode: 'GY' },
  {
    CountryName: 'Rep. Dominicana',
    IsoCurrencyCode: 'DOP',
    IsoCountryCode: 'DO',
  },
  { CountryName: 'United State', IsoCurrencyCode: 'USD', IsoCountryCode: 'US' },
];
