import { Injectable } from '@angular/core';
import { FeatureFlagV3Service } from '../services/feature-flags-v3.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {

  constructor(private featureFlagService: FeatureFlagV3Service,) { }

  get NewWatchEnabled(): boolean {
    return this.featureFlagService.checkFeatureOn('EnableWatch');
  }

  public getFeatureStatus(): boolean {
    return this.NewWatchEnabled;
  }
}
