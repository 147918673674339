import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IResponseCardViewModel } from '@interfaces/i-response-card-view-model';

@Component({
  selector: 'app-user-card-rows',
  templateUrl: './user-card-rows.component.html',
  styleUrls: ['./user-card-rows.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCardRowsComponent {

  @Input() userCards: IResponseCardViewModel[];
  @Input() cardSeleted: IResponseCardViewModel;
  @Input() classType: string = '';

  @Output() onCardSeleted: EventEmitter<IResponseCardViewModel> = new EventEmitter();
  tooltipText = 'Actualiza tus medios de pago desde Configuración.';
  constructor() { }

  public selectCard(card: IResponseCardViewModel): void {
    if(card.expired) { return; }
    this.onCardSeleted.emit(card);
  }

}
