import {Injectable, Inject} from '@angular/core';
import {RestParams, RestAction, IRestMethod, RestHandler, RestRequestBodyType, RestRequestMethod} from 'rest-core';
import {LocalStorage} from 'ngx-webstorage';
import {Router} from '@angular/router';
import {ILanguage} from '@interfaces/i-language';
import {environment} from '../../../environments/environment';
import {EnlaceRestHandler} from '../enlace-rest-handler';
import {AuthenticationService} from '../authentication.service';
import {CacheService} from './cache.service';
import {RestClient} from '../rest-client';

@RestParams({
  url: environment.webApi + 'v2/language'
})
@Injectable({
  providedIn: 'root'
})
export class LanguageService extends RestClient {


  @LocalStorage('language', 'es')
  _language: string;
  _lang: ILanguage;
  _locale: string = 'es-ES';
  languages: ILanguage[];


  constructor(restHandler: EnlaceRestHandler,
              authService: AuthenticationService,
              private router: Router,
              private readonly cache: CacheService) {
    super(restHandler, authService);


  }

  @RestAction({
    path: '/list',

  })
  private _list: IRestMethod<void, ILanguage[]>;

  @RestAction({
    path: '/{!languageCode}',
    method: RestRequestMethod.Get
  })
  getByCode: IRestMethod<{ languageCode: string }, ILanguage>;

  getLocale(): string {
    return this._locale;
  }

  list(): Promise<ILanguage[]> {
    return new Promise((resolve, reject) => {
      if(this.languages) {
        resolve(this.languages);
      } else {
        this._list().then((result: ILanguage[]) => {
          this.languages = result;
          resolve(this.languages);
        });
      }
    });

  }

  set language(languageCode: string) {
    this._language = languageCode;
    if (!this.cache.has('languageResources_' + languageCode)) {
      this.findLanguage();
    }
  }

  findLanguage(): Promise<ILanguage> {
    return new Promise<ILanguage>((resolve, reject) => {
      if (this.cache.has('languageResources_' + this._language)) {
        this._lang = <ILanguage>this.cache.get('languageResources_' + this._language);
        resolve(this._lang);
      } else {

        this.getByCode({languageCode: this._language}).then((language: ILanguage) => {
          this._lang = language;
          this.cache.add('languageResources_' + this._language, this._lang);
          if (this._language === 'es') {
            this._locale = 'es-ES';
          } else {
            this._locale = 'en-US';
          }
          resolve(this._lang);
        }).catch((e) => {
          console.warn('called .catch on %o arguments %o', this, e);
          reject();
        });
      }

    });

  }

  get currentLang() {
    return this._language;
  }

  public translate(key: string): string {
    //console.log(this._languages);
    // private perform translation
    if (this._lang) {
      const translation = this._lang.contentResourceValues.filter(l => l.contentResource.tag === key)[0];
      if (!translation) {
        return '{{' + key + ' NOT FOUND}}'
      }
      return translation.value;
    }
    return '';
  }

}
