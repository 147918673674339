import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';


import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class JwtInterceptorService {

  private _ignoreUrls = [
    '.json','//data.fixer.io',
    'api/v2/user/refresh-token',
    'api/v2/EnableFeatures/GetAllFeaturesStatus',
    'api/v2/user/fromoutside',
    'api/v2/user/authenticate',
    'api/v2/user/startUserMigration',
    'api/v2/user/startForgotPassword',
    'api/v2/user/userTokenValidation',
    'api/v2/user/migrateAndAuthenticate',
    'api/v2/user/resetPasswordAndAuthenticate',
    'api/v2/user/registerAndAuthenticate'];
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authService: AuthService,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (this.shouldIgnore(request.url)) {
      return next.handle(request);
    }

    const jwtToken = this.authService.getToken()?.jwtToken;

    if (jwtToken) {
      request = this.addTokenToRequest(request, jwtToken);
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (error.status !== 401 && error.status !== 403) {
          return throwError(error);
        }

        if (this.refreshTokenInProgress) {
          return this.refreshTokenSubject.pipe(
            filter(result => result !== null),
            take(1),
            switchMap(() => next.handle(this.addTokenToRequest(request, this.authService.getToken().jwtToken)))
          );
        } else {
          this.refreshTokenInProgress = true;

          this.refreshTokenSubject.next(null);

          return this.authService.refreshToken(this.authService.getToken().refreshToken).pipe(
            switchMap((userLoggedIn) => {
              this.refreshTokenInProgress = false;
              this.refreshTokenSubject.next(userLoggedIn);
              return next.handle(this.addTokenToRequest(request, this.authService.getToken().jwtToken));
            }),
            catchError(refreshError => {
              this.refreshTokenInProgress = false;
              return throwError(refreshError);
            })
          );
        }
      })
    );

  }

  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private shouldIgnore(url: string): boolean {
    return this._ignoreUrls.some(ignoredUrl => url.includes(ignoredUrl));
  }


}
