export interface IDonationViewModel {
  paymentId: string;
  amount: number;
  currency: string;
  frecuencyType: string;
  frequencyInterval?: number;
  method: string;
  transactionDate: Date;
  status: string;
  statusDescription: string;
  donationTransactionsId: string;
  cycles: number;
  totalCycles: number;
  organizationId?: string;
  organizationName: string;
  gateway: string;
  imageAvatar: string;
  allowCancelRecurrence: boolean;
}

export enum StatusDonation {
  Active = 'Active',
  Reclaimed = 'Reclaimed',
  Rejected = 'Rejected',
  UniqueReturned = 'UniqueReturned',
  RejectedReturned = 'RejectedReturned',
  RecurrentCanceled = 'RecurrentCanceled',
  RecurrentReturned = 'RecurrentReturned',
  Success = 'Success',
  RequestCancellation = 'RequestCancellation',
  Inactive = 'Inactive',
  Approved = 'Approved'
};


export interface DonationHeaderForm {
  amount: number;
  currency: number;
  frequency: string;
  cycle: string;
  cycleQuantity: number;
}

export interface DonationPlanFrequency {
  frequencyType: string;
  frequencyQuantity: number;
}

export interface DonationLandingText {
  title: string;
  chapter: string;
  subtitle: string;
  paragraph: string;
}
