import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IOfficesViewModel, Region } from '@interfaces/i-offices-view-model';

@Component({
  selector: 'app-offices-modal',
  templateUrl: './offices-modal.component.html',
  styleUrls: ['./offices-modal.component.scss']
})
export class OfficesModalComponent  {

  @Input() isOfficesOpen: boolean = true;
  @Input() offices: IOfficesViewModel[] = [];
  @Output() officesClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isOfficeOpen: boolean = false;
  public office: IOfficesViewModel;
  public namesContinent = Region;

  constructor(public modal: NgbActiveModal) {}

  get countriesSudamerica(): IOfficesViewModel[] {
    return this.offices.filter(country => country.region === Region.SUDAMERICA);
  }

  get countriesNorteAmerica(): IOfficesViewModel[] {
    return this.offices.filter(country => country.region === Region.NORTEAMERICA);
  }

  get countriesCentroAmerica(): IOfficesViewModel[] {
    return this.offices.filter(country => country.region === Region.CENTRO_AMERICA);
  }

  get countriesCaribe(): IOfficesViewModel[] {
    return this.offices.filter(country => country.region === Region.CARIBE);
  }

  public toggleOffice(office: IOfficesViewModel): void {
    this.office = office;
    this.isOfficeOpen = true;
  }
}
