import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment as ENV } from '@environment/environment';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';

import { IUserSubscriptionPlanViewModel } from '@interfaces/i-user-suscription-plan-view-model';
import { IVideoUserView } from '@interfaces/i-video-user-view';
import { IResetPassword } from '@interfaces/i-reset-password';
import { IUser } from '@interfaces/i-user.interface';
import { TypePropertyLocalStorage } from '@enums/type-property-localstorage.enum';
import { RoutesApp } from '@enums/type-routes-app.enum';

import { responseResultEnum, TokenAuth } from '@api/models/entities/auth.interface';
import { AuthService } from '@api/v3/auth.service';
import { CatalogV2Service } from '@app/api-client/v2/catalog-v2.service';

@Injectable({
  providedIn: 'root',
})
export class UserStateService  {

  @LocalStorage(TypePropertyLocalStorage.USER) private user: IUser;

  private isEditingConfSource$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private API = `${ENV.webApi}v2/user`;

  public mobileRoutes: string[] = ['donate', 'suscribirme', 'payment', 'mobile', 'auth'];

  constructor(
    private http: HttpClient,
    private router: Router,
    private newLoginService: AuthService,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private catalogService: CatalogV2Service) {}

  get isEditingConf$(): Observable<boolean> {
    return this.isEditingConfSource$.asObservable();
  }

  get isUserLoggedIn(): boolean {
    if (this.isAccessedFromMobile()) {
      return this.hasValidUserId();
    }
    return this.hasValidJwtToken();
  }

  get currentRoute(): string {
    return this.router.url.split('?')[0];
  }

  get isMobileRoute(): boolean {
    return this.currentRoute.split('/').some((el) => this.mobileRoutes.includes(el));
  }

  private hasValidUserId(): boolean {
    const userId = this.activatedRoute.snapshot.queryParamMap.get('userId');
    return Boolean(userId);
  }
  
  private hasValidJwtToken(): boolean {
    return Boolean(this.authService.getToken()?.jwtToken);
  }

  private isAccessedFromMobile(): boolean {
    const fromMobile = this.activatedRoute.snapshot.queryParamMap.get('fromMobile');
    return fromMobile?.toLowerCase() === 'true' || this.isMobileRoute;
  }

  public setIsEditing(value: boolean): void {
    this.isEditingConfSource$.next(value);
  }

  public updateUser(payload: IUser): Observable<void> {
    return this.http.post<void>(`${this.API}/updateuser`, payload);
  }

  public resetPassword(payload: IResetPassword): Observable<responseResultEnum> {
    return this.http.post<responseResultEnum>(`${this.API}/resetPassword`, payload);
  }

  public getUserPlan(userId?: string): Observable<IUserSubscriptionPlanViewModel> {
    return this.http.post<IUserSubscriptionPlanViewModel>(`${this.API}/getuserplan/${userId}`, userId)
  }

  public getVideoUserViews(): Observable<IVideoUserView[]> {
    return this.http.get<IVideoUserView[]>(`${this.API}/getVideoUserViews`);
  }

  public suscriptionEnableBeta(userId?: string): Observable<void> {
    return this.http.post<void>(`${this.API}/suscription/enablebeta/${userId}`, userId);
  }

  public updateSubscriptionFounder(result: IUserSubscriptionPlanViewModel): void {
    if (!!this.user) {
      this.user.isSuscriptionPlanEnabled = result.isSubscriptionPlanEnabled;
      this.user.suscriptionPlanId = result.subscriptionPlanId;
      this.user.planId = result.userPlan.id;
      this.user.planName = result.userPlan.name;
      this.saveUserStorage(this.user);
    }
  }

  public updateUserVideoView(videoUserView: IVideoUserView): void {
    if(this.user && this.user.videoUserViews === null)
      this.user.videoUserViews = [];

    const watchedVideo = this.findWatchedVideo(videoUserView.videoId);
    const index = this.user.videoUserViews.indexOf(watchedVideo);

    if (index !== -1)
      this.user.videoUserViews[index] = videoUserView;
    else
      this.user.videoUserViews.push(videoUserView);

    if (this.catalogService.catalogs)
      this.catalogService.checkCompleteMySeenVideo(videoUserView);

    this.saveUserStorage(this.user);
  }

  public saveUserStorage(user: IUser): void {
    this.localStorageService.store(TypePropertyLocalStorage.USER, user);
  }

  public userIsOlderThan13(): boolean {
    return this.user ? this.validateAge() : false;
  }

  public logout(): void {
    this.user = null;
    this.authService.logout();
    this.router.navigate([RoutesApp.HOME]);
  }

  private findWatchedVideo(videoId: string): IVideoUserView {
    return this.user.videoUserViews.find((video) => video.videoId === videoId);
  }

  private validateAge(): boolean {
    const yearOfBirth = this.user.yearOfBirth;
    const birthdayDate = new Date(this.user.birthdayDate).getFullYear();
    return new Date().getFullYear() - (yearOfBirth ? yearOfBirth : birthdayDate) > 13;
  }

}
