import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as ENV } from '@environment/environment';

import { IRequestCreateCardViewModel, SuccessSavedCard } from '@interfaces/i-request-create-card-model';
import { IRequestDefaultCardViewModel } from '@interfaces/i-request-default-card-model';
import { IRequestRemoveCardViewModel } from '@interfaces/i-request-remove-card-model';
import { IResponseCardViewModel } from '@interfaces/i-response-card-view-model';
import { PaymentMethodProvider } from '@interfaces/i-payment-methods.interface';

@Injectable({
  providedIn: 'root',
})
export class WalletV3Service {

  private API = `${ENV.webApi}v2/wallet`;

  constructor(private http: HttpClient) {}

  public getUserCreditCards(userId?: string): Observable<IResponseCardViewModel[]> {
    const params = userId ? { userId } : {};
    return this.http.get<IResponseCardViewModel[]>(`${this.API}/GetUserCCs`, { params });
  }

  public updateDefaultCard(payload: IRequestDefaultCardViewModel): Observable<boolean> {
    return this.http.post<boolean>(`${this.API}/UpdateDefaultCard`, payload);
  }

  public removeCard(payload: IRequestRemoveCardViewModel): Observable<boolean> {
    return this.http.post<boolean>(`${this.API}/RemoveCC`, payload);
  }

  public getPaymentMethods(userId: string, provider: string): Observable<PaymentMethodProvider> {
    const params = { userId, provider };
    return this.http.get<PaymentMethodProvider>(`${this.API}/GetPaymentMethods`, { params });
  }

  public addCard(payload: IRequestCreateCardViewModel): Observable<SuccessSavedCard> {
    return this.http.post<SuccessSavedCard>(`${this.API}/SaveCC`, payload);
  }

}
