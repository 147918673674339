import { Injectable } from '@angular/core';
import { environment as ENV } from '@environment/environment';
import { Observable } from 'rxjs';

import {
  CVV_OPTIONS, EXPIRATION_OPTIONS, PAN_OPTIONS
} from '@constants/style-dlocal.constant';
import { DlocalClass, DLocalInterface } from '@classes/DLocal.class';
import {
  DLocalFields, HandleDLocalErrors,
} from '@interfaces/i-dlocal-fields.interface';
import { TypeFieldsDLocal } from '@enums/type-fields-dlocal.enum';

@Injectable({
  providedIn: 'root',
})
export class DLocalFormService {

  private dloc: any;
  private builder: any;
  private pan: DLocalInterface = new DlocalClass(TypeFieldsDLocal.PAN);
  private expiration: DLocalInterface = new DlocalClass(TypeFieldsDLocal.EXPIRATION);
  private cvv: DLocalInterface = new DlocalClass(TypeFieldsDLocal.CVV);

  constructor() {}

  get isPanError$(): Observable<HandleDLocalErrors> {
    return this.pan.error$();
  }

  get isExpirationError$(): Observable<HandleDLocalErrors> {
    return this.expiration.error$();
  }

  get isCvvError$(): Observable<HandleDLocalErrors> {
    return this.cvv.error$();
  }

  get areAllFieldsValid(): boolean {
    return this.pan.getFieldValid() && this.expiration.getFieldValid() && this.cvv.getFieldValid();
  }

  public init(inputs: DLocalFields): void {
    this.dloc = dlocal(ENV.dlocal);
    this.builder = this.dloc.fields({ locale: 'es', country: 'AR' });
    this.initFields(inputs);
  }

  private initFields(inputs: DLocalFields): void {
    this.pan.initField(PAN_OPTIONS, inputs.formPanField, this.builder);
    this.expiration.initField(EXPIRATION_OPTIONS, inputs.formExpirationField, this.builder);
    this.cvv.initField(CVV_OPTIONS, inputs.formCvvField, this.builder);
  }

  public setErrorsDLocalField(result: any): void {
    switch(result.error.param) {
      case TypeFieldsDLocal.NUMBER:
        this.pan.setError({isEmpty: false, error: result.error});
        break;
      case TypeFieldsDLocal.EXPIRATION:
        this.expiration.setError({isEmpty: false, error: result.error});
        break;
      case TypeFieldsDLocal.CVV:
        this.cvv.setError({isEmpty: false, error: result.error});
        break;
    }
  }

  public generateDlocalToken(cardHolderName: string): Promise<any> {
    return this.dloc.createToken(this.pan.getField(), { name: cardHolderName });
  }

  public onSubmit(): void {
    this.pan.onSubmit();
    this.expiration.onSubmit();
    this.cvv.onSubmit();
  }

  public clear(): void {
    this.pan.clearField();
    this.expiration.clearField();
    this.cvv.clearField();
  }

}
