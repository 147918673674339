import { Component, Input, Output, EventEmitter } from '@angular/core';
import {  ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthenticationService } from '@api/authentication.service';
import { RoutesApp } from '@app/core/models/enums/type-routes-app.enum';
import { LocalStorage } from 'ngx-webstorage';
import { LoadingOverlayService } from '@app/shared/services/loading-overlay.service';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent {

  @Input() customMessageTag: string = 'enjoy-content';
  @Input() textSize: string = 'big';
  @Output() cancelAction: EventEmitter<any> = new EventEmitter();
  @LocalStorage('previewURL')
  previewURL: string = '';
  @Input() redirectTo: string = '';
  @Input() hideClose = false;

  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingOverlayService: LoadingOverlayService) {
    }

  login(): void {
    if (this.redirectTo !== '') { this.previewURL = this.redirectTo; }
    else { this.previewURL = this.router.url; }
    if (this.authService.isNewLoginEnabled) {
      const queryParams = this.route.snapshot.queryParams;
      this.router.navigate([RoutesApp.AUTH], { queryParams });
      this.activeModal.dismiss('login');
    } else {
      this.loadingOverlayService.showLoadingOverlay();
      this.authService.login();
    }
  }

  registerNow(): void {
    if (this.redirectTo !== '') { this.previewURL = this.redirectTo; }
    else { this.previewURL = this.router.url; }
    const queryParams = this.route.snapshot.queryParams;
    this.router.navigate([RoutesApp.AUTH, 'register'], { queryParams });
    this.activeModal.dismiss('register');
  }

  clickCancel(): void {
    this.activeModal.dismiss();
    this.cancelAction.emit();
  }
}
