import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observer, Observable} from 'rxjs';
import {Channel} from './models/channel';
import {RestClient} from './rest-client';
import {IRestMethod, RestAction, RestParams, RestRequestMethod} from 'rest-core';
import {EnlaceRestHandler} from './enlace-rest-handler';
import {AuthenticationService} from './authentication.service';
import {BaseService} from './v2/base-service';

@Injectable()
@RestParams({
  pathPrefix: environment.webApi + 'v2/channel'
})
export class ChannelService extends BaseService<Channel> {

  constructor(restHandler: EnlaceRestHandler, authService: AuthenticationService) {
    super(restHandler, authService);
  }

}
