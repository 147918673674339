import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  removeDiacritics: any;

  constructor(
  ) {
    this.removeDiacritics = require('diacritics').remove;
  }

  transform(value: string, args: string): any {
    if (args && value) {

      const valueTerm = this.removeDiacritics(value);
      const argsTerm = this.removeDiacritics(args);

      if(valueTerm.trim().toLowerCase().indexOf(argsTerm.trim().toLowerCase()) != -1){

        var index = valueTerm.trim().toLowerCase().indexOf(argsTerm.trim().toLowerCase());
        var length = argsTerm.length;

        value = value.replace(value.substr(index, length), "<strong>" + value.substr(index, length) + "</strong>")
      }
    }
    return value;
  }
}
