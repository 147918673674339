import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ISearchUserHistory } from '@app/api-client/models/v2/i-search-user-history';
import { UtilsService } from '@app/shared/utils/utils.service';
import { SearchUserHistoryService } from '@services/search-user-history.service';

@Component({
  selector: 'app-search-user-history',
  templateUrl: './search-user-history.component.html',
  styleUrls: ['./search-user-history.component.scss']
})
export class SearchUserHistoryComponent implements OnInit {
  @Input() inputActive: boolean;
  @Input() active: boolean;
  @Input()
  set newUserSearch(userSearch: ISearchUserHistory) {
    if (userSearch) {
      let index = this.userSearches.findIndex(io => io.id == userSearch.id);

      if (index > -1) {
        this.userSearches[index].searchDate = userSearch.searchDate;
        this.userSearches[index].searchTerm = userSearch.searchTerm;
      }

      if (index < 0 && this.userSearches.length < 10) {
        this.userSearches.push(userSearch);
      }

      if (index < 0 && this.userSearches.length >= 10) {
        this.userSearches.splice(this.userSearches.length - 1, 1);
        this.userSearches.push(userSearch);
      }

      this.userSearches.sort((a, b) => {
        // @ts-ignore
        return new Date(b.searchDate) - new Date(a.searchDate);
      });
    }
  }

  @Output() emitTriggerSearch: EventEmitter<string> = new EventEmitter<string>();
  canDelteFlag: boolean = true;
  userSearches: ISearchUserHistory[] = [];

  constructor(
    public searchUserService: SearchUserHistoryService,
    private changeDetector: ChangeDetectorRef,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.getUserSearhes();
  }

  getUserSearhes() {
    this.searchUserService.getUserSearches(this.utilsService.getValueParamFromUrl('userId'))
      .subscribe((data) => {
        this.userSearches = data;
        this.userSearches = this.userSearches.filter(item => item.searchTerm != 'undefined');
        this.changeDetector.detectChanges();
      });
  }

  deleteItem(event: Event, userSearch: ISearchUserHistory) {
    event.stopPropagation();
    if (this.canDelteFlag) {
      this.canDelteFlag = !this.canDelteFlag;
      this.searchUserService.deleteSearch(userSearch.id)
        .subscribe(() => {
          let index = this.userSearches.indexOf(userSearch);
          if (index > -1) {
            this.userSearches.splice(index, 1);
            this.changeDetector.detectChanges();
          }
          this.canDelteFlag = !this.canDelteFlag;
        });
    }
  }

  deleteHistory() {
    this.searchUserService.deleteHistory(this.utilsService.getValueParamFromUrl('userId'))
      .subscribe(() => {
        this.userSearches = [];
        this.changeDetector.detectChanges();
        this.getUserSearhes();
      });
  }

  goSearch(term: string) {
    this.emitTriggerSearch.emit(term);
  }
}
