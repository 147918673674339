import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'SubtitleHost',
})
export class SubtitleHostPipe implements PipeTransform {

  transform(item: any, type: string): string {
    //Evitamos poner los Hosts cuando es episodio y le pasamos el Subtitulo
    //Cuando es StandAlone el año viene en el Subtitulo; se muestra el primer anfitrión si este tiene,
    // de lo contrario muestra vacío.
    if(item.type == 'video'){
      if(item.contentParentId)
        return item.subtitle;
      else
        if (!!item.hosts && item.hosts.length > 0)
          return item.hosts[0].split('|')[1];
        else 
        return '';
    }
    if (!!item.hosts && item.hosts.length > 0)
      return item.hosts[0].split('|')[1];
    return item.subtitle;
  }

}
