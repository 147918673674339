import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-featured-search',
  templateUrl: './featured-search.component.html',
  styleUrls: ['./featured-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturedSearchComponent implements OnInit {
  screenHeight: number;
  screenWidth: number;
  organizationCutAmount: number = 40;
  @Input() searchBibleResults: any = null;
  @Input() searchBibleResultsFirstVerse: any = null;
  // TODO
  @Input() searchPersonResults: searchObject[] = [];
  @Input() searchOrganizationResults: searchObject[] = [];
  @Input() seeMorePerson: boolean[] = [];
  @Input() isMobileWebView: boolean;
  @Input() seeMoreOrganization: boolean[] = [];
  @Output() readMoreBible: EventEmitter<boolean> = new EventEmitter();
  @Output() morePersonResults: EventEmitter<boolean> = new EventEmitter();
  @Output() moreOrganizationResults: EventEmitter<boolean> = new EventEmitter();
  @Output() viewLoaded = new EventEmitter();

  constructor() { }
  ngOnInit(): void {
    // this.setupMock();
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.searchOrganizationResults && this.searchOrganizationResults.length > 0) {
      this.organizationNameCut();
    }
  }

  ngAfterViewInit() {
    this.viewLoaded.next(true);
  }

  availablePersonResults() {
    return this.searchPersonResults && this.searchPersonResults.length > 0;
  }

  availableOrganizationResults() {
    return this.searchOrganizationResults && this.searchOrganizationResults.length > 0;
  }

  availableBibleResults() {
    return this.searchBibleResults && this.searchBibleResults?.passages || this.searchBibleResults?.verses && this.searchBibleResults?.passages.length > 0 || this.searchBibleResults?.verses.length > 0;
  }

  setupMock() {
    this.searchPersonResults.push(
      {
        id: '06299f2a-1885-4574-86fa-f4e6c6464107',
        name: 'Wendy Bello',
        img: 'https://enlace-plus-images-prod.imgix.net/organizations/31917752-3183-48f7-bf8e-b5db65f3ed2b?auto=compress&w=370',
        url: '/people/06299f2a-1885-4574-86fa-f4e6c6464107',
        totalCount: 1
      }
    );
    this.searchOrganizationResults.push(
      {
        id: '90138358-4842-4443-81dc-fe950f776574',
        name: 'Asociación Evangelistica Alberto Motessi',
        img: 'https://enlace-plus-images-prod.imgix.net/horizontal/7b986232-b8b3-4b7d-83bd-d1778429fa1b?auto=compress&w=400',
        url: '/organization/90138358-4842-4443-81dc-fe950f776574',
        totalCount: 1
      }
    );

  }
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (this.searchOrganizationResults && this.searchOrganizationResults.length > 0) {
      this.screenHeight = window.innerHeight;
      this.screenWidth = window.innerWidth;
      this.organizationNameCut();
    }
  }

  organizationNameCut() {
    this.organizationCutAmount = 100;
    if (this.screenWidth <= 1440 && this.screenWidth >= 1025) {
      this.organizationCutAmount = 60;
      return;
    }
    if (this.screenWidth <= 1024 && this.screenWidth >= 769) {
      this.organizationCutAmount = 20;
      return;
    }
    if (this.screenWidth <= 768) {
      this.organizationCutAmount = 15;
      return;
    }
  }

  getClassOf() {
    if (this.searchBibleResultsFirstVerse?.length >= 400
      || this.searchBibleResults.verses[0]?.text.length >= 400) {
      return 'four-hundred';
    } else if (this.searchBibleResultsFirstVerse?.length >= 300
      || this.searchBibleResults.verses[0]?.text.length >= 300) {
      return 'three-hundred';
    } else if (this.searchBibleResultsFirstVerse?.length >= 200
      || this.searchBibleResults.verses[0]?.text.length >= 200) {
      return 'two-hundred';
    } else if (this.searchBibleResultsFirstVerse?.length >= 150
      || this.searchBibleResults.verses[0]?.text.length >= 150) {
      return 'one-hundred-fifty';
    } else {
      return 'one-hundred'
    }
  }
}

export class searchObject {
  id: string;
  name: string;
  img: string;
  url: string;
  totalCount: number
}
