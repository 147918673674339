import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-person-item-big',
  templateUrl: './person-item-big.component.html',
  styleUrls: ['./person-item-big.component.scss']
})
export class PersonItemBigComponent implements OnInit {

  @Input()
  type: string;

  @Input()
  imageFaceUrl: string;

  @Input()
  imageCoverUrl: string;

  @Input()
  titlelbl: string;

  @Input()
  subtitle: string;

  @Input()
  personId: string;

  constructor() { }

  ngOnInit() {
  }

}
