import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {Observer, Observable} from "rxjs";
import {RestClient} from "./rest-client";
import {RestParams, IRestMethod, RestAction, RestHandler, RestRequestMethod} from "rest-core";
import {EnlaceRestHandler} from "./enlace-rest-handler";
import {AuthenticationService} from "./authentication.service";
import { Router } from '@angular/router';
import {Ministry} from "./models/ministry";
import {CacheService} from './v2/cache.service';


@RestParams({
  pathPrefix: environment.webApi + 'ministry'
})
@Injectable()
export class MinistryService extends  RestClient {

  result: Ministry[];

  constructor(restHandler: EnlaceRestHandler,
              authService: AuthenticationService,
              private router: Router,
              private cache: CacheService) {
    super(restHandler, authService);
  }

  @RestAction({
    path: '',
    isArray: true,
  })
  list: IRestMethod<any, Ministry[]>;

  listCache(): Observable<Ministry[]> {
    return Observable.create(observer => {
      if (this.cache.has('ministries')) {
        observer.next(this.cache.get<Ministry[]>('ministries'));
      } else {
        this.list().then((result: Ministry[]) => {
          this.result = result;
          this.cache.add('ministries', this.result);
          observer.next(this.result);
        }).catch((e) => {
          console.warn('called .catch on %o arguments %o', this, e);
        });
      }
    });
  }

}
