import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorage } from 'ngx-webstorage';

import { BreadCrumb } from '@interfaces/i-breadcrumb.interface';
import { TypePropertyLocalStorage } from '@enums/type-property-localstorage.enum';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {

  @LocalStorage(TypePropertyLocalStorage.LAST_BREADCRUMB) private breadcrumb: BreadCrumb;
  private breadcrumb$: BehaviorSubject<BreadCrumb> = new BehaviorSubject(
    {} as BreadCrumb
  );

  constructor() {}

  get breadcrumbData$(): Observable<BreadCrumb> {
    return this.breadcrumb$.asObservable();
  }

  get lastBreadcrumb(): BreadCrumb {
    return this.breadcrumb;
  }

  public change(event: BreadCrumb): void {
    this.breadcrumb = {...event};
    this.breadcrumb$.next({ ...event });
  }

  public clear(): void {
    this.breadcrumb$.next({} as BreadCrumb);
  }
}
