import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IUser } from '@app/core/models/interfaces/i-user.interface';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorage } from 'ngx-webstorage';
import { LoginModalComponent } from '../../login-modal/login-modal.component';
import { environment as ENV } from '@environment/environment';
import { GtmService } from '@app/shared/services/lib/gtm.service';

@Component({
  selector: 'app-promotion-alert-modal',
  templateUrl: './promotion-alert-modal.component.html',
  styleUrls: ['./promotion-alert-modal.component.scss']
})
export class PromotionAlertModalComponent implements OnInit {
  @LocalStorage('user')
  private user: IUser;
  @Input() title: string = 'Hazte hoy un';
  @Input() titleUpper: string = 'MISIONERO DIGITAL';
  @Input() bodyTextpPartOneV1: string = 'Apóyanos';
  @Input() amountText: string = 'desde $4.99';
  @Input() bodyTextpPartOneV2: string = 'al mes y disfruta de contenido exclusivo, videos sin anuncios y más.';
  @Input() bodyTextpPartTwo: string = 'Tu suscripción permite que otros puedan disfrutar Enlace+ de manera gratuita.';
  @Input() buttonName: string = 'SUSCRIBIRME';
  @Input() icon: string = 'iconlace iconlace-list';
  modalVersionList = ['CENTER', 'BOTTOMRIGHTV1', 'TOPRIGHTV1', 'TOPRIGHTV2'];
  @Input() modalVersion: string = 'CENTER';
  versionToGtmVersionMap = {
    BOTTOMRIGHTV1: 'suscribeme_modal_inferior_derecho',
    CENTER: 'suscribeme_modal_central',
    TOPRIGHTV1: 'suscribeme_modal_superior_derecho_v1',
    TOPRIGHTV2: 'suscribeme_modal_superior_derecho_v2'
  };
  constructor(public activeModal: NgbActiveModal, private router: Router, private modalService: NgbModal, private gtmService: GtmService) { }

  ngOnInit() {
    let x = Math.floor(Math.random() * 4);
    this.modalVersion = this.modalVersionList[x];
  }

  navigateTo(version: string) {
    this.activeModal.dismiss();
    const paramsDataLayer = {
      event: 'userEvent',
      pageUrl : this.router.routerState.snapshot.url,
      userId : this.user?.id ?? null,
      eventType: 'click',
      eventName : this.versionToGtmVersionMap[this.modalVersion],
      env: ENV.name
    }
    this.gtmService.logPromotionAlert(paramsDataLayer);
    if (!this.user) {
      this.showLoginMessage('need-to-login-for-subscription');
    } else {
      this.router.navigate(['/get-subscription'], { queryParams: { version: version } });
    }
  }

  showLoginMessage(message: string) {
    const modalRef = this.modalService.open(LoginModalComponent, { windowClass: 'dark-modal modal-outlogin', backdropClass: 'light-black-backdrop' });
    modalRef.componentInstance.customMessageTag = message;
    modalRef.componentInstance.redirectTo = '/get-subscription';
  }
}
