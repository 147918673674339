<div class="search-user" *ngIf="userSearches?.length > 0 && active">
  <div class="container" [ngClass]="{'container-active': inputActive}">
    <div class="header">
      <div class="header-line"></div>
      <div class="header-content">
        <div class="header-main-title">Búsquedas recientes</div>
        <div class="header-subtitle" (click)="deleteHistory()">Borrar historial</div>
      </div>
    </div>
    <ul class="body">
      <li class="item" (click)="goSearch(userSearch.searchTerm)" *ngFor="let userSearch of userSearches">
        <div class="item-group">
          <span class="item-icon" ><i class="iconlace iconlace-search"></i></span>
          <div class="item-text">{{userSearch.searchTerm}}</div>
        </div>
        <button class="item-icon item-icon--delete" (click)="deleteItem($event, userSearch)">
          <i class="iconlace iconlace-cross"></i>
        </button>
      </li>
    </ul>
  </div>
</div>