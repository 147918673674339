import {Pipe, PipeTransform, ElementRef} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {LanguageService} from '../api-client/v2/language.service';

@Pipe({
  name: 'language',
  pure: false
})
export class LanguagePipe implements PipeTransform {

  value: string;
  args: any[];
  test: boolean = false;
  constructor(private _translate: LanguageService, sessionStorage: LocalStorageService, private elementRef: ElementRef) {
    sessionStorage.observe('language').subscribe(() => {
      this.test = true;
      this.transform(this.value,this.args);
    });
  }

  transform(value: string, args: any[]): any {
    this.value = value;
    this.args = args;
    if (!value) return;
    let result =  this._translate.translate(value);
    if(result.length === 0) {
      result = value;
    }

    return result;
  }

}
