import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cuttext',
  pure: false
})
export class CutTextPipe implements PipeTransform {

  transform(value: string, length: number, showAll?: boolean): any {
    if(Number(value)){
      return value;
    }
    if (!value) {
      return '';
    }
    if (showAll || value.length <= length) {
      return value;
    }

    return value.substring(0, length) + '...';
  }

}
