import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';

import { IUser } from '@interfaces/i-user.interface';
import { TypePropertyLocalStorage } from '@enums/type-property-localstorage.enum';
import { RoutesApp } from '@enums/type-routes-app.enum';

@Injectable({
  providedIn: 'root'
})
export class UserHasSubscribeGuard implements CanActivate {

  @LocalStorage(TypePropertyLocalStorage.USER) private user: IUser;
  @LocalStorage(TypePropertyLocalStorage.SUBSCRIPTION_FROM_LANDING) private subscriptionFromLanding: boolean;

  constructor(private router: Router,) { }

  //cuando utilizan el link directo a suscribirme mostramos la Landing de Misionero
  get mustRedirectLanding(): boolean {
    return this.router.url.split('?')[0].split('/').some(x => x === 'suscribirme');
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (this.isWebviewAcquire(state.url)) return true;
      if (this.user && !this.user.isSuscriptionPlanEnabled) {
        if (this.subscriptionFromLanding) {
          // TO DO: Check this module
          this.router.navigate([RoutesApp.GET_SUBSCRIPTION]);
          return false;
        } else {
          if(this.mustRedirectLanding)
            this.router.navigate([RoutesApp.SUBSCRIBE]);
          else
            this.router.navigate([RoutesApp.SUBSCRIPTION]);
          return false;
        }
      }
      return true;
  }

  private isWebviewAcquire(url: string): boolean {
    const route = url.split('/');
    return route ? route.some((el) => ['mobile', 'donate', 'donation','paypal'].includes(el)) : false;
  }
}
