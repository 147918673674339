import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
(window as any).ENV = environment;

declare var module: any;

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log=function(){};
  }
} else {
  if (module.hot) {
    module.hot.accept();
  }
}
platformBrowserDynamic().bootstrapModule(AppModule);
