<div class="modal-close">
  <span class="close-button" (click)="closeModalWithOption(true)">
    <span>&times;</span>
  </span>
</div>
<div class="content">
  <ng-container *ngIf="isEmailNotExist">
    <ng-container *ngTemplateOutlet="emailNotExist"></ng-container>
  </ng-container>
  <ng-container *ngIf="isUserNeedsMigrate">
    <ng-container *ngTemplateOutlet="userNeedsMigrate"></ng-container>
  </ng-container>
  <ng-container *ngIf="isResultOk">
    <ng-container *ngTemplateOutlet="sendEmailForgotPassword"></ng-container>
  </ng-container>
  <ng-container *ngIf="isTokenExpired">
    <ng-container *ngTemplateOutlet="tokenExpired"></ng-container>
  </ng-container>
  <ng-container *ngIf="isInvalidEmail">
    <ng-container *ngTemplateOutlet="invalidEmail"></ng-container>
  </ng-container>
  <ng-container *ngIf="userWantToCreateAccount">
    <ng-container *ngTemplateOutlet="userAlreadyMigrated"></ng-container>
  </ng-container>
  <ng-container *ngIf="hasPasswordChangedSuccess">
    <ng-container *ngTemplateOutlet="passwordChangedSuccess"></ng-container>
  </ng-container>
  <ng-container *ngIf="hasUserMigratedSuccess">
    <ng-container *ngTemplateOutlet="userMigratedSuccess"></ng-container>
  </ng-container>
</div>

<ng-template #emailNotExist>
  <div class="info-icon">
    <i class="iconlace iconlace-alert-radius alert"></i>
  </div>
  <div class="info-title">¡Error!</div>
  <div class="info-content">
    <p>No existe una cuenta asociada a este correo, <br> verifícalo o crea una nueva cuenta.</p>
  </div>
  <div class="content-buttons">
    <div class="btn-link-signup" (click)="goToCreateAccount()">Crear cuenta nueva aquí.</div>
    <div class="content-helper-links">
      <a class="btn-link-support" target="_blank" routerLink="/faq">Ayuda</a>
      <a class="btn-link-support" href="mailto:soporte@enlace.plus">Soporte</a>
    </div>
  </div>
</ng-template>

<ng-template #userNeedsMigrate>
  <div class="info-icon">
    <i class="iconlace iconlace-circle circle"></i>
    <i class="iconlace iconlace-check check"></i>
  </div>
  <div class="info-title">¡Listo!</div>
  <div class="info-content">
    <p>
      Hemos <strong>enviado un link</strong> a tu correo electrónico para que
      {{isUserNeedsMigrate ? 'restaures' : 'puedas cambiar' }} tu contraseña y puedas ingresar a Enlace+.
    </p>
  </div>
  <div class="content-buttons">
    <button type="button" class="btn btn-primary" (click)="closeModalWithOption()">Aceptar</button>
    <div class="content-helper-links">
      <a class="btn-link-support" target="_blank" routerLink="/faq">Ayuda</a>
      <a class="btn-link-support" href="mailto:soporte@enlace.plus">Soporte</a>
    </div>
  </div>
</ng-template>

<ng-template #sendEmailForgotPassword>
  <div class="info-icon">
    <i class="iconlace iconlace-circle circle"></i>
    <i class="iconlace iconlace-check check"></i>
  </div>
  <div class="info-title">¡Listo!</div>
  <div class="info-content">
    <p>
      Hemos enviado un correo electrónico para que puedas cambiar tu contraseña.
    </p>
  </div>
  <div class="content-buttons">
    <button type="button" class="btn btn-primary" (click)="closeModalWithOption()">Aceptar</button>
    <div class="content-helper-links">
      <a class="btn-link-support" target="_blank" routerLink="/faq">Ayuda</a>
      <a class="btn-link-support" href="mailto:soporte@enlace.plus">Soporte</a>
    </div>
  </div>
</ng-template>

<ng-template #tokenExpired>
  <div class="info-icon">
    <i class="iconlace iconlace-alert-radius alert"></i>
  </div>
  <div class="info-title">¡Error!</div>
  <div class="info-content">
    <p>Parece que este enlace ya expiró. Solicita uno nuevo ingresando tu correo electrónico en la siguiente
    pantalla.</p>
  </div>
  <div class="content-buttons">
    <button type="button" class="btn btn-primary" (click)="closeModalWithOption()">Aceptar</button>
    <div class="content-helper-links">
      <a class="btn-link-support" target="_blank" routerLink="/faq">Ayuda</a>
      <a class="btn-link-support" href="mailto:soporte@enlace.plus">Soporte</a>
    </div>
  </div>
</ng-template>

<ng-template #invalidEmail>
  <div class="info-icon">
    <i class="iconlace iconlace-alert alert"></i>
  </div>
  <div class="info-title">¡Correo inválido!</div>
  <div class="info-content">
    <p>Verifica la información que ingresaste.</p>
  </div>
  <div class="content-buttons">
    <button type="button" class="btn btn-primary" (click)="closeModalWithOption()">ACEPTAR</button>
  </div>
</ng-template>

<ng-template #userAlreadyMigrated>
  <div class="info-icon">
    <i class="iconlace iconlace-alert-radius alert"></i>
  </div>
  <div class="info-title f-20 title-v2">
    <p>Parece que ya existe una cuenta asociada
      a este correo electrónico.</p>
    <p>{{ data.email }}</p>
  </div>
  <br>
  <div class="info-content">
    <p>¿Deseas recuperar tu cuenta?</p>
    <p>Te enviaremos un correo electrónico para que <br/>
      restaures tu contraseña.</p>
  </div>
  <div class="content-buttons">
    <button type="button" class="btn btn-primary" (click)="closeModalWithOption()">ENVIAR</button>
  </div>
</ng-template>


<ng-template #passwordChangedSuccess>
  <div class="info-icon">
    <i class="iconlace iconlace-circle circle"></i>
    <i class="iconlace iconlace-check check"></i>
  </div>
  <div class="info-title">¡Listo!</div>
  <div class="info-content">
    <p>
      Tu contraseña se ha cambiado exitosamente.
    </p>
  </div>
  <div class="content-buttons">
    <button type="button" class="btn btn-primary" (click)="closeModalWithOption()">Aceptar</button>
  </div>
</ng-template>

<ng-template #userMigratedSuccess>
  <div class="info-icon">
    <i class="iconlace iconlace-circle circle"></i>
    <i class="iconlace iconlace-check check"></i>
  </div>
  <div class="info-title">¡Listo!</div>
  <div class="info-content">
    <p>
      Tu contraseña se ha restaurado exitosamente.
    </p>
  </div>
  <div class="content-buttons">
    <button type="button" class="btn btn-primary" (click)="closeModalWithOption()">Aceptar</button>
  </div>
</ng-template>

