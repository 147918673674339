
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment as ENV } from '@environment/environment';
import { IConvertCurrencyResponse } from '@interfaces/i-convert-currency-response';

@Injectable({
  providedIn: 'root',
})
export class ConvertCurrencyService {

  public server = ENV.convertCurrencyApi;
  private accessKey = ENV.convertCurrencyAccessKey;

  constructor(private http: HttpClient) {}

  public convertCurrency<T>(from: string, to: string, amount: string): Observable<IConvertCurrencyResponse> {
    const actionUrl = this.server + 'convert';

    return this.http
      .get<T>(actionUrl, {
        params: {
          access_key: this.accessKey,
          from: from,
          to: to,
          amount: amount,
        },
      }).pipe(
      map((res: any) => {
        const result: IConvertCurrencyResponse = {
          date: res.date,
          result: res.result,
          info: {
            rate: res.info.rate,
            timestamp: res.info.timestamp,
          },
        };
        return result;
      }));
  }
}
