export enum RoutesApp {
   HOME = '/',
   AUTH = '/enlace/auth',
   PROGRAMS = '/programs',
   PROGRAM = '/program',
   ORGANIZATION = '/organizations',
   PEOPLE = '/people',
   ARTICLE = '/articles',
   PODCAST = '/podcast',
   LIBRARY = '/library',
   MY_LIBRARY = '/mylibrary',
   BIBLE = '/bible',
   PRAY = '/prays/home',
   LIVE = '/live',
   FAVORITE = '/user/myfavorites',
   MY_LIST= '/user/mylist',
   FOLLOW = '/user/myfollows',
   TRANSACTION = '/user/mytransactions',
   MY_CONTENT = '/user/mycontent',
   SETTING = '/user/myconfiguration',
   MY_HIGHLIGHT = '/bible/my-highlights',
   EBOOKS = '/ebooks',
   EBOOK = '/ebook',
   SEARCH  = '/search',
   SEARCH_V2  = '/search-v2',
   VIDEO = '/video',
   DETAIL_ARTICLE = '/article',
   DETAIL_PERSON = '/people',
   DETAIL_ORGANIZATION = '/organization',
   SUBSCRIPTION = '/subscription',
   SUBSCRIBE = '/suscribirme',
   GET_SUBSCRIPTION = '/get-subscription',
   DONATION = '/ofrendar',
   DONATION_PAYPAL_SUCCESS = '/donation/donation/paypal/approved/confirmation',
   COMUNITY = '/community',
   EBOOK_READER = '/library'
};

