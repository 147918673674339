import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserCardRowsComponent } from './user-card-rows.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [UserCardRowsComponent],
  imports: [CommonModule,NgbTooltipModule],
  exports: [UserCardRowsComponent]
})
export class UserCardRowsModule { }
