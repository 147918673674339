import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DonationPopupSuccessComponent } from './donation-popup-success.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [DonationPopupSuccessComponent],
    exports: [DonationPopupSuccessComponent]
})
export class DonationPopupSuccessModule { }
