<div class="pray-modal-container" [formGroup]="form" appClickOutsideModal (clickOutside)="closeModal()">
    <div class="modal-header content-header">
        <button type="button" class="close close-modal" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="main-content">
        <ng-container *ngIf="result === PrayResult.Default">
            <ng-container *ngTemplateOutlet="default"></ng-container>
        </ng-container>
        <ng-container *ngIf="result === PrayResult.Success">
            <ng-container *ngTemplateOutlet="success"></ng-container>
        </ng-container>
        <ng-container *ngIf="result === PrayResult.Error">
            <ng-container *ngTemplateOutlet="error"></ng-container>
        </ng-container>
    </div>

    <ng-template #default>

        <div class="pray-request-container">
            <div class="img-container"></div>
            <div class="pray-container">
                <div class="body-container">
                    <div class="pray-title">Petición de oración</div>
                    <div class="pray-description">
                        Estamos interesados en ti y en tus necesidades, y creemos que Dios responderá toda
                        petición que hagamos de común acuerdo.
                    </div>
                    <div class="textarea-container" [class.is-invalid]="isInValid(descriptionField)">
                      <div class="pray-avatar"
                        [ngClass]="{'no-border-radius': profileImage !== 'assets/img/icons/user.svg'}">
                        <img *ngIf="profileImage !== ''" class="option-img" [src]="profileImage" alt="user Image">
                        <i *ngIf="profileImage == ''" class="iconlace iconlace-user icon-dimensions"></i>
                      </div>
                        <textarea class="txt-pray" placeholder="Escribe tu petición de oración aquí" maxlength="300"
                            formControlName="description">
                        </textarea>
                    </div>
                    <div class="petition-error-text" [ngClass]="{'is-invalid-text': invalidText}">
                        <span>Tu petición contiene palabras inapropiadas, por favor revisa y corrige el
                            contenido.</span>
                    </div>
                    <div class="right">
                        <small>{{ (descriptionField?.value?.length || '0') + '/' + 300}}</small>
                    </div>
                </div>
                <div class="content-button">
                    <button class="btn btn-pray" [disabled]="form.invalid" (click)="sendRequest()"
                        [ngClass]="{'sendOn': !form.invalid}">
                        Enviar
                    </button>
                    <button class="btn btn-cancel" (click)="closeModal()">
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #success>
        <div class="success-pray-request-container">
            <div class="success-img-container">
                <div class="lottie-container">
                    <ng-lottie [options]="options">
                    </ng-lottie>
                </div>
            </div>
            <div class="success-body-container">
                <p class="title">¡Tu petición fue enviada exitosamente!</p>
                <p class="subtitle">Un equipo de oración de Enlace+ <br>
                    estará orando por ti.</p>
                <p class="verse">"Ésta es la confianza que tenemos al acercarnos a Dios:
                    que si pedimos conforme a su voluntad, él nos oye."
                    <br>
                    <br>
                    1 Juan 5:14
                </p>
            </div>
            <div class="content-button-accept">
                <button class="btn btn-accept" (click)="closeModal()">ACEPTAR</button>
            </div>
        </div>
    </ng-template>

    <ng-template #error>
        <div class="error-pray-request-container">
            <div class="error-img-container">
                <i class="iconlace iconlace-alert-radius iconlace-alert-error"></i>
            </div>
            <div class="error-body-container">
                <p class="error-title">¡Parece que hubo un error!</p>
                <p class="error-subtitle">Inténtalo de nuevo más tarde o <span class="second-linebreak">ingresa
                        <span class="first-linebreak">tu petición aquí: <a
                                href="https://www.enlace.org/peticiones/">Petición</a></span></span></p>
            </div>
            <div class="content-button-accept">
                <button class="btn btn-accept" (click)="closeModal()">ACEPTAR</button>
            </div>
        </div>
    </ng-template>

</div>
