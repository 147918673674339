import { Injectable } from '@angular/core';
import {RestClient} from '../rest-client';
import {IRestMethod, RestAction, RestParams, RestRequestMethod} from 'rest-core';
import {environment} from '../../../environments/environment';
import {EnlaceRestHandler} from '../enlace-rest-handler';
import {AuthenticationService} from '../authentication.service';
import {IEbookViewModel} from '../models/v2/i-ebook-view-model';
import { ILaunchbookResponseModel } from '../models/v2/i-launchbook-response-model';

@Injectable({
  providedIn: 'root'
})
@RestParams({
  // IRestParams
  pathPrefix: environment.webApi + 'v2/ebook'
})
export class EbookService extends RestClient {

  @RestAction({
    path: '/view/{!ebookId}',
    method: RestRequestMethod.Get
  })
  view: IRestMethod<{ebookId: string, puserId?: string}, IEbookViewModel>;

  @RestAction({
    path: '/launchbook',
    method: RestRequestMethod.Post
  })
  launchEbook: IRestMethod<{bookId: string, userId?: string, pageNumber: number}, ILaunchbookResponseModel>;

  constructor(restHandler: EnlaceRestHandler, authService: AuthenticationService) {super(restHandler, authService);}

}
