import {Directive, ElementRef, HostListener, Input} from '@angular/core';

    @Directive({
        selector: 'button[n-submit]'
    })
    export class DisableButtonOnSubmitDirective {
      @Input()
      DISABLE_TIME = 300;
        constructor(private elementRef: ElementRef) { }
        @HostListener('click', ['$event'])
        clickEvent() {
            this.elementRef.nativeElement.setAttribute('disabled', 'true');
            setTimeout(() => this.elementRef.nativeElement.removeAttribute('disabled'), this.DISABLE_TIME);
        }
    }