import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { PipesV2Module } from '@pipes/pipes-v2.module';
import { DlocalFormInlineComponent } from '@components/dlocal-form-inline/dlocal-form-inline.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, PipesV2Module],
  exports: [DlocalFormInlineComponent],
  declarations: [DlocalFormInlineComponent]
})
export class DlocalFormInlineModule { }
