import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WalletService, PurchaseType } from "@app/api-client/v2/wallet.service";
import { ICreateTaxTransactionResponse } from "@api/models/v2/i-create-tax-transaction-response";
import { ICreateTaxTransactionRequest } from "@api/models/v2/i-create-tax-transaction-request";
import { IRequestCardViewModel } from "@app/api-client/models/v2/i-request-card-view-model";
import { DeviceDetectorService } from "ngx-device-detector";

import { ProgramService } from "@app/api-client/v2/program.service";
import { AudiobookService } from "@app/api-client/v2/audiobook.service";
import { EbookService } from "@app/api-client/v2/ebook.service";
import { CatalogV2Service } from "@app/api-client/v2/catalog-v2.service";
import { IEbookViewModel } from "@app/api-client/models/v2/i-ebook-view-model";
import { IProgram } from "@interfaces/i-program";
import { IVVideo } from "@interfaces/i-v-video";
import { IAudiobookViewModel } from "@app/api-client/models/v2/i-audiobook-view-model";
import { IssueRequest } from "@app/api-client/models/v2/i-trace-errors";
import { IssueReportService } from "@app/api-client/v2/issue-report-v2.service";
import { IUser } from "@interfaces/i-user.interface";

import { VideoV3Service } from "@services/video-v3.service";
import { IResponseCardViewModel } from "@interfaces/i-response-card-view-model";
import { PaymentMethod } from "@enums/type-payment-method.enum";
import { validateExpiredCard } from "@app/shared/helpers/validate-expired-card.helper";
import { HomeCatalogStateService } from "@app/shared/services/home-catalog-state.service";
import { PaymentSuccessComponent } from "@app/shared/components/modals/payment-success/payment-success.component";
import { PaymentErrorComponent } from "@app/shared/components/modals/payment-error/payment-error.component";
import { LocalStorage } from "ngx-webstorage";
import { MarketingParamsService } from "@app/shared/utils/marketing-params.service";
import { ContentDetail } from "@app/core/models/interfaces/content-detail";
import { LoadingOverlayService } from "@app/shared/services/loading-overlay.service";

type CardListType = {
  textCard: string;
  textCardPlane: string;
  date: string;
  holderName: string;
  cardId: string;
  isLocal: boolean;
  provider: string;
  expired: boolean;
};

@Component({
  selector: "checkout-modal-app",
  templateUrl: "./checkout-modal.component.html",
  styleUrls: ["./checkout-modal.component.scss"],
})
export class CheckoutModalComponent implements OnInit {

  @LocalStorage('user')
  private _user: IUser;
  contentDetail: ContentDetail;
  paymentContent: any;
  fromMobile: boolean;
  userCards: IResponseCardViewModel[] = [];
  selectedSavedCard: CardListType;
  cards: CardListType[] = [];
  public parentModal: NgbActiveModal;
  public previewTax: ICreateTaxTransactionResponse;
  @ViewChild("docIdInput") docIdInput: ElementRef;
  public errorMsg: string = "";
  public selectedCardExpired: boolean = false;
  countriesWithStates = ["US", "CAD"];
  docId = "";
  public titleContent: string = "";
  public traceIdentifier: string;
  isMobile = false;
  urlRedirect = "";
  paymentResult: any = null;
  thereIsError = false;
  tooltipText = "Actualiza tus medios de pago desde Configuración.";
  showCreditCard = true;
  showHolderName = true;
  showDate = true;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private deviceDetector: DeviceDetectorService,
    private videoService: VideoV3Service,
    private programService: ProgramService,
    private audiobookService: AudiobookService,
    private issueReportService: IssueReportService,
    private ebookService: EbookService,
    private catalogService: CatalogV2Service,
    private modalService: NgbModal,
    private walletService: WalletService,
    private catalogState: HomeCatalogStateService,
    private marketingParamsService: MarketingParamsService,
    private loadingOverlayService: LoadingOverlayService
  ) {}

  ngOnInit() {
    this.marketingParamsService.sendMarketingTrack({
      event_location: 'Checkout',
      event_action: this.paymentContent.isRent ? "rent" : "purchase",
    });
    this.isMobile =
      this.deviceDetector.isMobile() || this.deviceDetector.isTablet();
    this.parentModal.dismiss();
    this.getUserCreditCards();
    this.handleResize();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event): void {
    this.handleResize();
  }

  handleResize(): void {
    const width = window.innerWidth;
    this.showCreditCard = width > 572;
    this.showHolderName = width > 437;
    this.showDate = width > 365;
  }

  public getUserCreditCards(): void {
    this.loadingOverlayService.showLoadingOverlay();
    const request =
      this.paymentContent != null && this.paymentContent.userId
        ? { userId: this.paymentContent.userId }
        : {};

    this.walletService
      .getUserCreditCards(request)
      .then((data: IResponseCardViewModel[]) => {
        this.loadingOverlayService.hideLoadingOverlay();

        if (data && data.length > 0) {
          this.userCards = data.map(validateExpiredCard);
          let allCardExpireds =
            this.userCards.filter((x) => x.expired == false).length == 0;
          if (allCardExpireds) {
            if (!this.fromMobile) {
              this.router.navigate(
                [
                  "/acquire",
                  this.paymentContent.contentType,
                  this.paymentContent.contentId,
                ],
                {
                  queryParams: {
                    action: this.paymentContent.isRent ? "rent" : "purchase",
                  },
                }
              );
            }
            this.parentModal.dismiss();
            this.activeModal.dismiss();
          }
          this.userCards.sort((dflt) => (dflt.isDefault ? 1 : 0));
          this.userCards.forEach((card) => {
            if (!allCardExpireds)
              this.cards.push({
                textCard: `${card.cardBrandName} que termina en ${card.last4}`,
                textCardPlane: `${card.cardBrandName} -  ${card.last4}`,
                holderName: card.cardHolderName,
                date: `${card.expMonth}/${card.expYear}`,
                cardId: card.cardId,
                isLocal: PaymentMethod[card.provider] == PaymentMethod.DLocal,
                provider: card.provider,
                expired: card.expired,
              });
          });

          const defaultCard = this.userCards.find((dfl) => dfl.isDefault)
            ? this.userCards.find((dfl) => dfl.isDefault)
            : this.userCards[0];
          if (defaultCard) {
            this.selectedSavedCard = {
              cardId: defaultCard.cardId,
              textCard: `${defaultCard.cardBrandName} que termina en ${defaultCard.last4}`,
              textCardPlane: `${defaultCard.cardBrandName} - ${defaultCard.last4}`,
              holderName: defaultCard.cardHolderName,
              date: `${defaultCard.expMonth}/${defaultCard.expYear}`,
              isLocal:
                PaymentMethod[defaultCard.provider] == PaymentMethod.DLocal,
              provider: defaultCard.provider,
              expired: defaultCard.expired,
            };
          }
          this.previewTaxCalculation();
        } else {
          if (!this.fromMobile) {
            this.router.navigate(
              [
                "/acquire",
                this.paymentContent.contentType,
                this.paymentContent.contentId,
              ],
              {
                queryParams: {
                  action: this.paymentContent.isRent ? "rent" : "purchase",
                },
              }
            );
          }
          this.parentModal.dismiss();
          this.activeModal.dismiss();
        }
      });
  }

  public previewTaxCalculation(): void {
    this.loadingOverlayService.showLoadingOverlay();
    const selectedCard = this.userCards.find(
      (card) => card.cardId == this.selectedSavedCard.cardId
    );
    const body: ICreateTaxTransactionRequest = {
      adressLine1: selectedCard.cardHolderAddress,
      amount: this.paymentContent.amount,
      contentType: this.paymentContent.contentType,
      countryCode: selectedCard.cardHolderCountryCode,
      city: selectedCard.cardHolderCity,
      stateCode: this.countriesWithStates.find(
        (cty) => cty == selectedCard.cardHolderCountryCode
      )
        ? selectedCard.cardHolderStateCode
        : "",
      postalCode: selectedCard.cardHolderPostalCode,
      purchaseType: this.paymentContent.isRent
        ? PurchaseType.Rent
        : PurchaseType.Purchase,
    };
    this.walletService
      .previewTaxesCalculation(body)
      .then((data: ICreateTaxTransactionResponse) => {
        if (data.success) {
          this.previewTax = data;
          this.errorMsg = "";
          this.thereIsError = false;
        } else {
          this.thereIsError = true;
          this.errorMsg =
            "Parece que hubo un error al procesar tus datos. Por favor verifica que la información que ingresaste esté correcta y vuelve a intentarlo.";
        }
        this.loadingOverlayService.hideLoadingOverlay();
      });
  }

  public selectCard(card: CardListType): void {
    if (card.expired) {
      this.thereIsError = true;
      return;
    }
    if (card.cardId != this.selectedSavedCard.cardId) {
      this.thereIsError = false;
      this.selectedCardExpired = false;
      this.selectedSavedCard = card;
      this.previewTaxCalculation();
    }
  }

  public pay(): void {
    if (this.thereIsError) return;
    if (
      this.selectedSavedCard &&
      this.selectedSavedCard.isLocal &&
      !this.docId
    ) {
      this.errorMsg = "El número de identificación es requerido";
      (<HTMLInputElement>document.getElementById("docId")).style[
        "border-color"
      ] = "#FF6F05";
      this.docIdInput.nativeElement.focus();
      return;
    }

    this.paymentResult = null;
    this.loadingOverlayService.showLoadingOverlay();
    const selectedCard = this.userCards.find(
      (card) => card.cardId == this.selectedSavedCard.cardId
    );
    if (selectedCard.expired) {
      this.thereIsError = true;
      this.selectedCardExpired = selectedCard.expired;
      this.errorMsg =
        "La tarjeta terminada en " + selectedCard.last4 + " está ";
      this.loadingOverlayService.hideLoadingOverlay();
      return;
    }
    const request: IRequestCardViewModel = {
      provider: PaymentMethod[this.selectedSavedCard.provider],
      purchaseType: this.paymentContent.isRent
        ? PurchaseType.Rent
        : PurchaseType.Purchase,
      contentId: this.paymentContent.contentId,
      contentType: this.paymentContent.contentType,
      countryCode: selectedCard.cardHolderCountryCode,
      planId: this.paymentContent.planId,
      isMobile: this.isMobile,
      subTotal: this.paymentContent.amount,
      taxes: this.previewTax.totalTaxCalculated,
      cardToken: null,
      currency: "USD",
      description:
        PaymentMethod[selectedCard.provider] == PaymentMethod.DLocal
          ? this.buildDlocalPayDescription(
              this.paymentContent.isRent
                ? PurchaseType.Rent
                : PurchaseType.Purchase
            )
          : this.buildPayDescription(
              this.paymentContent.isRent
                ? PurchaseType.Rent
                : PurchaseType.Purchase
            ),
      state: selectedCard.cardHolderState,
      stateCode: selectedCard.cardHolderStateCode,
      city: selectedCard.cardHolderCity,
      district: selectedCard.cardHolderDistrict,
      cardCCV: selectedCard.cvv,
      payerName: selectedCard.cardHolderName,
      payerLastName: selectedCard.cardHolderLastName,
      cardNumber: selectedCard.last4,
      address: selectedCard.cardHolderAddress,
      cardExpiryYear: selectedCard.expYear.toString(),
      cardExpiryMonth: selectedCard.expMonth.toString(),
      cardId: selectedCard.cardId,
      payerDocument: this.docId
        ? this.docId
        : selectedCard.cardHolderIdentifier,
      payerEmail: selectedCard.cardHolderEmail,
      markAsDefaultCard: selectedCard.isDefault,
      isCardDefault: selectedCard.isDefault,
      saveCard: false,
      postalCode: selectedCard.cardHolderPostalCode,
    };

    if (this.paymentContent.userId) {
      request.userId = this.paymentContent.userId;
    }

    this.walletService
      .PayWithCC(request)
      .then((response: any) => {
        if (response.success) {
          this.errorMsg = "";
          this.updatePPVContentInfoCatalogCache(
            this.paymentContent.contentId,
            this.paymentContent.contentType
          );

          this.parentModal.dismiss();

          // Redirección componente para muestreo de resultados y paso de data a través de componente.
          const other = {
            contentId: this.paymentContent.contentId,
            action: this.paymentContent.isRent
              ? PurchaseType.Rent
              : PurchaseType.Purchase,
            contentType: this.paymentContent.contentType,
            isSuccess: response.success,
            isPurchase: !this.paymentContent.isRent,
            traceIdentifier: response.traceIdentifier,
            fromMobile: this.fromMobile,
            rentalDays: this.paymentContent.rentalDays,
            urlRedirect: this.urlRedirect,
          };

          if (this.fromMobile) {
            this.paymentResult = {
              success: response.success,
              message: response.message
            };
            this.activeModal.close(this.paymentResult);
          } else {
            this.walletService.acquiredContent.push(other);
            this.openModalSuccess();
            this.activeModal.dismiss();
            this.trackSendMarketing('PPV_success');
          }
        } else {
          this.openModalError();
          this.trackSendMarketing('PPV_error');
        }
        if (response.traceIdentifier) this.sendReport(response.traceIdentifier);
        this.loadingOverlayService.hideLoadingOverlay();
      })
      .catch((error) => {
        console.error(error);
        this.loadingOverlayService.hideLoadingOverlay();
      });
  }

  public updatePPVContentInfoCatalogCache(
    contentId: string,
    contentType: string,
    option: boolean = true
  ): void {
    if (contentType == "video") {
      this.videoService.getView(contentId).subscribe((video: IVVideo) => {
        //Update catalog cache
        if (option) {
          this.catalogService.updatePPVContentInfo(video);
          this.catalogState.clearCatalog();
        } else {
          this.titleContent = video.title;
          this.paymentContent.imageContent =
            video.backgroundImageUrl || video.thumbnailUrl;
        }
      });
    }
    if (contentType == "program") {
      this.programService
        .getView({ programId: contentId })
        .then((program: IProgram) => {
          //Update catalog cache
          if (option) {
            this.catalogService.updatePPVContentInfo(program);
            this.catalogState.clearCatalog();
          } else {
            this.titleContent = program.title;
            this.paymentContent.imageContent =
              program.urlBackgroundImage || program.urlImage;
          }
        });
    }
    if (contentType == "ebook") {
      this.ebookService
        .view({ ebookId: contentId, puserId: null })
        .then((ebook: IEbookViewModel) => {
          //Update catalog cache
          if (option) {
            this.catalogService.updatePPVContentInfo(ebook);
            this.catalogState.clearCatalog();
          } else {
            this.titleContent = ebook.title;
            this.paymentContent.imageContent = ebook.urlBackgroundImage;
          }
        });
    }
    if (contentType == "audiobook") {
      this.audiobookService
        .view({ audiobookId: contentId })
        .then((audiobook: IAudiobookViewModel) => {
          //Update catalog cache
          if (option) {
            this.catalogService.updatePPVContentInfo(audiobook);
            this.catalogState.clearCatalog();
          } else {
            this.titleContent = audiobook.title;
            this.paymentContent.imageContent = audiobook.urlBackgroundImage;
          }
        });
    }
  }

  public goToOtherPayment() {
    const queryParams = this.marketingParamsService.getQueryParamsMarketing();
    const navigationParams = {
      queryParams: {
        action: this.paymentContent.isRent ? PurchaseType.Rent : PurchaseType.Purchase,
        ...queryParams,
      },
    };
    this.marketingParamsService.sendMarketingTrack({
      event_location: 'other_cc',
      event_action: this.paymentContent.isRent ? "rent" : "purchase",
    });
    if (!this.fromMobile) {
      this.router.navigate(
        [
          "/acquire",
          this.paymentContent.contentType,
          this.paymentContent.contentId,
        ],
        navigationParams
      );
    }
    this.parentModal.dismiss();
    this.activeModal.dismiss();
  }

  buildDlocalPayDescription(action: PurchaseType) {
    let description = action == PurchaseType.Purchase ? "Compra " : "Alquiler ";
    switch (this.paymentContent.contentType) {
      case "video":
        description += "contenido: video/" + this.paymentContent.contentId;
        break;
      case "program":
        description += "contenido: program/" + this.paymentContent.contentId;
        break;
      case "ebook":
        description += "contenido: ebook/" + this.paymentContent.contentId;
        break;
      case "audiobook":
        description += "contenido: audiobook/" + this.paymentContent.contentId;
        break;
    }
    return description;
  }

  buildPayDescription(action: PurchaseType) {
    let description =
      (action == PurchaseType.Purchase ? "Compra" : "Alquiler") +
      " " +
      "Contenido PPV | Enlace+";
    description += "\n";
    switch (this.paymentContent.contentType) {
      case "video":
        description +=
          "Contenido adquirido: Video | " + " " + this.paymentContent.title;
        break;
      case "program":
        description +=
          "Contenido adquirido: Programa |" + " " + this.paymentContent.title;
        break;
      case "ebook":
        description +=
          "Contenido adquirido: Libro Digital" +
          " " +
          this.paymentContent.title;
        break;
      case "audiobook":
        description +=
          "Contenido adquirido: Audiolibro" + " " + this.paymentContent.title;
        break;
    }
    return description;
  }

  openModalSuccess(): void {
    const modalRef = this.modalService.open(PaymentSuccessComponent, {
      windowClass: 'dark-modal modal-payment',
      backdropClass: 'light-black-backdrop'
    });

    modalRef.componentInstance.contentDetail = this.contentDetail;
    modalRef.componentInstance.action = this.paymentContent.isRent ? "rent" : "purchase";
    modalRef.componentInstance.contentType = this.paymentContent.contentType;
    modalRef.componentInstance.fromMobile = this.fromMobile;
  }

  openModalError(): void {
    const modalRef = this.modalService.open(PaymentErrorComponent, {
      windowClass: 'dark-modal modal-payment',
      backdropClass: 'light-black-backdrop'
    });
    modalRef.componentInstance.action = this.paymentContent.isRent ? "rent" : "purchase";;
  }

  public sendReport(trace: string): void {
    let request: IssueRequest = {
      traceidentifier: trace,
      source: "Web",
    };
    if (this._user) request = { ...request, userId: this._user.id };
    this.issueReportService
      .addIssueReport(request)
      .subscribe(() => (this.traceIdentifier = trace));
  }

  public clearError(): void {
    this.errorMsg = "";
  }

  trackSendMarketing(event_status: string) {
    this.marketingParamsService.sendMarketingTrack({
      event_location: 'Checkout',
      event_status: event_status
    });
  }
}
