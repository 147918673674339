import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root',
})
export class CanRefreshTokenService {

  private _key = 'canRefresh';

  constructor(private storageService: LocalStorageService) {}

  get retrieveKey(): boolean | null {
    return this.storageService.retrieve(this._key);
  }

  public clearKey(): void {
    this.storageService.clear(this._key);
  }

  public stopRefreshToken(): void {
    this.storageService.store(this._key, false);
  }

  public allowRefreshToken(): void {
    this.storageService.store(this._key, true);
  }
}
