
<div class="modal-body csb">
  <button type="button" class="close closemodal-login" (click)="clickAcept(); activeModal.dismiss()" aria-label="Close"><span
    aria-hidden="true">&times;</span></button>
  <div class="row title">
    <h4>{{title}}</h4>
  </div>
  <div class="row subTitle">
    <p>{{subTitle}}</p>
  </div>
  <div class="row button">
    <input class="btn" type="text" value="ACEPTAR" (click)="clickAcept(); activeModal.dismiss();">
  </div>
</div>