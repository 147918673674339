import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {Observer, Observable} from "rxjs";
import {RestClient} from "./rest-client";
import {RestParams, IRestMethod, RestAction, RestHandler, RestRequestMethod} from "rest-core";
import {EnlaceRestHandler} from "./enlace-rest-handler";
import {AuthenticationService} from "./authentication.service";
import { Router } from '@angular/router';
import {Country} from "../core/models/interfaces/i-country";
import {CacheService} from './v2/cache.service';


@RestParams({
  pathPrefix: environment.webApi + 'countries'
})
@Injectable()
export class CountryService extends  RestClient {

  result: Country[];

  constructor(restHandler: EnlaceRestHandler,
              authService: AuthenticationService,
              private router: Router,
              private cache: CacheService) {
    super(restHandler, authService);
  }

  @RestAction({
    path: '/list',
    isArray: true,
  })
  list: IRestMethod<any, Country[]>;

  listCache(): Observable<Country[]> {
    return Observable.create(observer => {
      if (this.cache.has('countries')) {
        observer.next(this.cache.get<Country[]>('countries'));
      } else {
        this.list().then((result: Country[]) => {
          this.result = result;
          this.cache.add('countries', this.result);
          observer.next(this.result);
        }).catch((e) => {
          console.warn('called .catch on %o arguments %o', this, e);
        });
      }
    });
  }

}
