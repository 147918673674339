import { Injectable } from '@angular/core';
import {RestClient} from "./rest-client";
import {AuthenticationService} from "./authentication.service";
import {EnlaceRestHandler} from "./enlace-rest-handler";
import {environment} from "../../environments/environment";
import {RestParams, RestAction, IRestMethod} from "rest-core";
import {FollowInformation} from "./models/follow-information";
import {Observable} from "rxjs";

@Injectable()
@RestParams({
  pathPrefix: environment.webApi + 'follow'
})
export class FollowService extends RestClient{

  constructor(restHandler: EnlaceRestHandler, authService: AuthenticationService) {
    super(restHandler, authService);
  }

  @RestAction({
    path: '/getfollows/{!videoId}',
    isArray: true,
  })
  list: IRestMethod<{videoId: any}, FollowInformation[]>;

  @RestAction({
    path: '/{!entityTypeId}/{!entityId}',
  })
  follow: IRestMethod<{entityTypeId: any,entityId: any}, any>;

  @RestAction({
    path: '/unfollow/{!entityTypeId}/{!entityId}',
  })
  unfollow: IRestMethod<{entityTypeId: any,entityId: any}, any>;

  listFollowInfo(videoId: number): Observable<FollowInformation[]>{
    return Observable.create((observer) => {
      const list:FollowInformation[] = [];
      list.push(<FollowInformation>{entityFollowTypeId: 1, entityFollowTypeName:'Ministrie', entityId: 1, entityName: 'Test'});
      list.push(<FollowInformation>{entityFollowTypeId: 1, entityFollowTypeName:'Ministrie', entityId: 2, entityName: 'Test 1'});
      list.push(<FollowInformation>{entityFollowTypeId: 2, entityFollowTypeName:'Person', entityId: 3, entityName: 'Test 2'});
      list.push(<FollowInformation>{entityFollowTypeId: 2, entityFollowTypeName:'Person', entityId: 3, entityName: 'Test 3'});
      observer.next(list);
    });
  }

}
