
<div class="content-result" *ngIf="advanceSearchService.showInfoResults && !(isLoading$ | async)">
  <div class="row" *ngIf="totalItems == 0">
    <div class="col-xs-12 col-md-12">
      <h3 class="text-left">No hay resultados para <strong>{{ advanceSearchService.textToShow }}</strong></h3>
      <h4 class="text-left">
          Te sugerimos revisar que esté bien escrito lo que buscas; intentar con otra palabra, título de
          un programa o prédica, o con el nombre de un predicador o ministerio.
      </h4>
    </div>
  </div>

  <div class="row" *ngIf="totalItems > 0">
    <div class="col-xs-12 col-sm-12 col-md-12">
      <h3 class="text-left filter-text-results">
        {{totalItems}} Resultado/s para <strong>{{ advanceSearchService.textToShow }}</strong>
      </h3>
    </div>
  </div>
</div>