import {
  ChangeDetectionStrategy, Component,
  EventEmitter, Input, Output,
} from '@angular/core';
import { UtilsService } from '@app/shared/utils/utils.service';
import { NavigationService } from '@utils/navigation.service';
@Component({
  selector: 'app-header-search-advanced',
  templateUrl: './header-search-advanced.component.html',
  styleUrls: ['./header-search-advanced.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderSearchAdvancedComponent {

  @Input() isMobile: boolean;
  @Input() isMobileWebView: boolean;
  @Output() emitToggleFilter: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor(private navigation: NavigationService,
              private utilsService: UtilsService) {}

  public toggleFilter(event: boolean): void {
    this.emitToggleFilter.emit(event);
  }

  public goBack(): void {
    this.navigation.goBack();
  }

  isHiddenHeader() {
    return this.utilsService.isMobileRoute;
  }
}
