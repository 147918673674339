<div class="wrapper-modal {{ wrapperClass }}" [class.active]="showModal"></div>

<div
  class="content {{ contentClass }}"
  [class.fadeOut]="fadeOut"
  [ngStyle]="{ 'max-width': widthPx, 'width': widthPercentage, 'margin-top':marginTop}"
  [class.active]="showModal">
  <span class="close-button" (click)="toggleModal()">
    <span>&times;</span>
  </span>
  <ng-content *ngIf="isActive" select="[content]"></ng-content>
  <div class="content-buttons" *ngIf="showButtons">
    <button type="button" class="btn btn-primary" [class.isPaypal]="isbtnPaypal" (click)="actionFirstButton()">
      {{firstButton}}
      <img *ngIf="isbtnPaypal" width="70" src="/assets/img/payment/payment-paypal.webp">
    </button>
    <button *ngIf="showSecondButton" type="button" class="btn btn-secondary" (click)="actionSecondButton()">{{secondButton}}</button>
  </div>
</div>
