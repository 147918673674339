import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as ENV } from '../../environments/environment';
import { Observable } from 'rxjs';
import { LastReadBible } from './models/last-read-bible';

@Injectable({
  providedIn: 'root'
})
export class BibleApiService {

  defaultLanguageId: string = 'spa';
  defaultLanguageName: string = 'Español';
  defaultBibleId: string = '01c25b8715dbb632-01';
  defaultBibleName: string = 'Nueva Versión Internacional';

  private httpOptions = {
    headers: new HttpHeaders({
      'api-key': 'dfabd59ff5f493934aefba937ff786e2'
    })
  };

  bookReferences: any[] = [];

  constructor(private http: HttpClient) {
    this.getBookReferences(this.defaultLanguageId).subscribe(data => {
      this.bookReferences = data;
    });
  }

  getLanguages() {
    return this.http.get('https://api.scripture.api.bible/v1/languages', this.httpOptions);
  }

  getBibleVersions(languageId: string = this.defaultLanguageId) {
    let filter = `?language=${languageId}`

    return this.http.get(`https://api.scripture.api.bible/v1/bibles${filter}`, this.httpOptions);
  }

  getBooks(bibleVersionId) {
    return this.http.get(`https://api.scripture.api.bible/v1/bibles/${bibleVersionId}/books`, this.httpOptions);
  }

  getChapters(bibleVersionId, bibleBookId) {
    return this.http.get(`https://api.scripture.api.bible/v1/bibles/${bibleVersionId}/books/${bibleBookId}/chapters?include-verse-spans=true`, this.httpOptions);
  }

  getChaptersJSON(bibleVersionId, bibleBookId) {
    return this.http.get(`https://api.scripture.api.bible/v1/bibles/${bibleVersionId}/books/${bibleBookId}/chapters?include-verse-spans=true&content-type=json`, this.httpOptions);
  }

  getSections(bibleVersionId, bibleBookId) {
    return this.http.get(`https://api.scripture.api.bible/v1/bibles/${bibleVersionId}/books/${bibleBookId}/sections`, this.httpOptions);
  }

  getVerses(bibleVersionId, bibleChapterId) {
    const url = `https://api.scripture.api.bible/v1/bibles/${bibleVersionId}/chapters/${bibleChapterId}/verses`;
    return this.http.get(url, this.httpOptions);
  }

  getChapterText(bibleVersionId, bibleChapterId) {
    return this.http.get(`https://api.scripture.api.bible/v1/bibles/${bibleVersionId}/chapters/${bibleChapterId}?include-verse-spans=true`, this.httpOptions);
  }

  getChapterTextJSON(bibleVersionId, bibleChapterId) {
    return this.http.get(`https://api.scripture.api.bible/v1/bibles/${bibleVersionId}/chapters/${bibleChapterId}?include-verse-spans=true&content-type=text`, this.httpOptions);
  }

  getChapterTextHTML(bibleVersionId, bibleChapterId) {
    return this.http.get(`https://api.scripture.api.bible/v1/bibles/${bibleVersionId}/chapters/${bibleChapterId}?include-verse-spans=true&content-type=html`, this.httpOptions);
  }

  getSelectedVerse(bibleVersionId, bibleVerseId) {
    return this.http.get(`https://api.scripture.api.bible/v1/bibles/${bibleVersionId}/verses/${bibleVerseId}?include-chapter-numbers=false&include-verse-numbers=false`, this.httpOptions);
  }

  getSelectedSection(bibleVersionId, bibleSectionId) {
    return this.http.get(`https://api.scripture.api.bible/v1/bibles/${bibleVersionId}/sections/${bibleSectionId}?include-chapter-numbers=true&include-verse-spans=true`, this.httpOptions);
  }

  getSelectedSectionJSON(bibleVersionId, bibleSectionId) {
    return this.http.get(`https://api.scripture.api.bible/v1/bibles/${bibleVersionId}/sections/${bibleSectionId}?include-chapter-numbers=true&include-verse-spans=true&content-type=json`, this.httpOptions);
  }

  getResults(bibleVersionId, searchText, offset = 0, limit = 50, booksRange = null) {
    if (booksRange == null) {
      return this.http.get(`https://api.scripture.api.bible/v1/bibles/${bibleVersionId}/search?query=${searchText}&offset=${offset}&limit=${limit}&sort=relevance`, this.httpOptions);
    } else {
      return this.http.get(`https://api.scripture.api.bible/v1/bibles/${bibleVersionId}/search?query=${searchText}&offset=${offset}&limit=${limit}&sort=relevance&range=${booksRange}`, this.httpOptions);
    }
  }

  getBookReferences(languageId): Observable<any> {
    return this.http.get('/assets/mock/bible-book-references-' + languageId + '.json');
  }

  getEnlaceParser(text: any): Observable<any> {
    return this.http.post('https://enlace-bible-toolbox.azurewebsites.net/Api/htmltotext3', {textoHtml: text});
  }

  getSearchCompleteChapter(text) {
    let reference = null;
    let bookRef = null;
    let chapterId = null;

    const arrayStr = text.split(' ').filter(x => x != "");
    if(arrayStr.length > 1 && arrayStr.length < 5) {
      arrayStr.forEach(element => {
        element.trim();
      });

      if(arrayStr.length > 2) {
        if(arrayStr.length == 3)
          bookRef = arrayStr[0] + ' ' + arrayStr[1];
        if(arrayStr.length == 4)
          bookRef = arrayStr[0] + ' ' + arrayStr[1] + ' ' + arrayStr[2];
      } else{
        bookRef = arrayStr[0];
      }

      chapterId = arrayStr[arrayStr.length - 1];
      if (chapterId.indexOf(':') != -1) {
        chapterId = chapterId.split(':')[0];
      }

      for (var i = 0; i < this.bookReferences.length; i++) {
        if (this.bookReferences[i].alias.filter(x => x.toLowerCase() == bookRef.toLowerCase()).length > 0) {
          reference = this.bookReferences[i].id + '.' + chapterId;
          break;
        }
      }
    }

    return reference;
  }
}
