import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as ENV } from '@environment/environment';

import { LanguageV3Service } from '@services/language-v3.service';

import { Country, CountryWithCode, Department, District, State } from '@interfaces/i-country';
import { ResponseSoaApi } from '@app/core/models/interfaces/i-cancel-subscription';
@Injectable({
  providedIn: 'root',
})
export class CountryV3Service  {

  private API = `${ENV.webApi}v2/country`;
  private soaApi = `${ENV.soaApiBaseUrl}`;

  constructor(private http: HttpClient, private languageService: LanguageV3Service) {}

  public getAllCountries(): Observable<Country[]> {
    const loc = this.languageService.locale;
    if (loc === 'es-ES') {
      return this.http.get<Country[]>('/assets/mock/countries-es.json');
    } else {
      return this.http.get<Country[]>('/assets/mock/countries-en.json');
    }
  }

  getCountriesLite(): Observable<Country[]> {
    return this.http.get<Country[]>(`${this.API}/list/lite`);
  }

  public getCountriesWithCode(): Observable<CountryWithCode[]> {
    return this.http.get<CountryWithCode[]>(`${this.API}/countryWithCodes`);
  }

  public getStates(countryCode: string = 'us'): Observable<State[]> {
    return this.http.get<State[]>(`${this.API}/${countryCode}/states?orderBy=nameenlgish`);
  }

  public getDepartments(stateCode: string = 'us'): Observable<Department[]> {
    return this.http.get<Department[]>(`${this.API}/${stateCode}/departments?orderBy=nameenlgish`);
  }

  public getDistricts(departmentCode: string): Observable<District[]> {
    return this.http.get<District[]>(`${this.API}/${departmentCode}/districts?orderBy=nameenlgish`);
  }

  public getCountryStateCommunity(countryId: string): Observable<ResponseSoaApi> {
    const url = `${this.soaApi}Country/getStatesByCountry/${countryId}`;
    return this.http.get<ResponseSoaApi>(url);
  }

  getDialCodeByCountryId(countryId: string): Observable<ResponseSoaApi> {
    const url = `${this.soaApi}Country/getDialCode/${countryId}`;
    return this.http.get<ResponseSoaApi>(url);
  }

  getDialCode(): Observable<ResponseSoaApi> {
    const url = `${this.soaApi}Country/dialcodes/`;
    return this.http.get<ResponseSoaApi>(url);
  }

}
