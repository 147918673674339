import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  transform(value: number): any {
    let ms = value % 1000;
    value = (value - ms) / 1000;
    let secs = value % 60;
    value = (value - secs) / 60;
    let mins = value % 60;
    value = (value - mins) / 60;
    let hours = value % 60;
    let result = '';
    if (mins > 0) {
      result += (mins <= 9 ? '0' + mins + ':' : mins + ':');
    }
    if (hours > 0) {
      result += (hours <= 9 ? '0' + hours + ':' : hours + ':');
    }
    if (hours < 1) {
      return (mins <= 9 ? '0' + mins + ':' : mins + ':') + (secs <= 9 ? '0' + secs : secs);
    } else {
      return (hours <= 9 ? '0' + hours + ':' : hours + ':') + (mins <= 9 ? '0' + mins + ':' : mins + ':') + (secs <= 9 ? '0' + secs : secs);

    }
   }

}

@Pipe({
  name: 'timeFormatFromSecond'
})
export class TimeFormatFromSecond implements PipeTransform {

  transform(value: number): any {
    let secs = Math.trunc(value % 60);
    value = (value - secs) / 60;
    let mins = Math.trunc(value % 60);
    return (mins <= 9 ? '0' + mins + ':' : mins + ':') + (secs <= 9 ? '0' + secs : secs);
  }
}

@Pipe({
  name: 'allTimeFormatFromSecond'
})
export class AllTimeFormatFromSecond implements PipeTransform {

  transform(value: number): any {
    let secs = Math.trunc(value % 60);
    value = (value - secs) / 60;
    let mins = Math.trunc(value % 60);
    value = (value - mins) / 60;
    let hour = Math.trunc(value % 60);
    return (hour <= 9 ? '0' + hour + ':' : hour + ':') + (mins <= 9 ? '0' + mins + ':' : mins + ':') + (secs <= 9 ? '0' + secs : secs);
  }
}



@Pipe({
  name: 'timeFormatSec'
})
export class TimeFormatSecPipe implements PipeTransform {

  transform(value: number): any {
    const ms = value / 1000;
    return Math.trunc(ms);
  }

}

@Pipe({
  name: 'timeHumanFormat'
})
export class TimeHumanFormatPipe implements PipeTransform {

  transform(value: string): any {
    let result = '';
    if (value) {
      const hora = value.slice(0,2);
      if (hora !== '00') {
        result += Math.trunc(Number(hora)) + ' h ';
      }

      const minuto = value.slice(3,5);
      if (minuto !== '00') {
        result += minuto + ' min';
      }
    }

    return result;
  }

  }

