import { ChangeDetectionStrategy, Component, EventEmitter,
Input, Output
} from '@angular/core';

import { DonationHeaderForm } from '@interfaces/i-donation-view-model';
import { SaveCard } from '@interfaces/i-type-card.interface';
import { Country } from '@interfaces/i-country';
import { GatewayTypes } from '@enums/type-gateways-card.enum';

@Component({
  selector: 'app-donation-form',
  templateUrl: './donation-form.component.html',
  styleUrls: ['./donation-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DonationFormComponent {

  @Input() style: string;
  @Input() countries: Country[];
  @Input() enablePaypal: boolean;
  @Input() showEmail: boolean;
  @Input() organizationName: string;
  @Input() userCurrency: string;
  @Input() fromMobile: boolean = false;
  @Input() fromModal: boolean = false;
  @Input() showForms: boolean = true;
  @Input() gateWay: GatewayTypes;

  @Output() onSubmitCard: EventEmitter<SaveCard> = new EventEmitter();
  @Output() onGateWay: EventEmitter<GatewayTypes> = new EventEmitter();
  @Output() onCancelDonation: EventEmitter<boolean> = new EventEmitter();
  @Output() donationHeaderForm: EventEmitter<DonationHeaderForm> = new EventEmitter();

  constructor() { }

  public cancelDonation(event: boolean): void {
    this.onCancelDonation.emit(event);
  }

  public submitCard(event: SaveCard): void {
    this.onSubmitCard.emit(event);
  }

  public onDonationHeaderForm(event: DonationHeaderForm): void {
    this.donationHeaderForm.emit(event);
  }

  public changeGateWay(event: GatewayTypes): void {
    this.onGateWay.emit(event);
  }

}
