export const AMERICAN_CODES = [
  'AR', //Argentina
  'BZ', //Belice
  'BO', //Bolivia
  'CL', //Chile
  'CO', //Colombia
  'CR', //Costa Rica
  'EC', //Ecuador
  'SV', //El Salvador
  'ES', //España
  'US', //Estados Unidos
  'GT', //Guatemala
  'HN', //Honduras
  'MX', //Mexico
  'NI', //Nicaragua
  'PA', //Panama
  'PY', //Paraguay
  'PE', //Peru
  'PR', //Puerto Rico
  'UY', //Uruguay
  'VE' //Venezuela
];

// Deshabilitado: 'BO','EC','PY' (Bolivia, Ecuador, Paraguay)
export const LOCAL_CODES = [
  'AR', //Argentina
  'BR', //Brasil
  'CL', //Chile
  'CO', //Colombia
  'MX', //México
  'PE', //Perú
  'UY', //Uruguay
];
