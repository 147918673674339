<div class="office-breadcrumb">
  <a class="office-breadcrumb_level" href='javascript:void(0)' (click)="close()">Todas</a>
  <span class="office-breadcrumb_separator"><i class="iconlace iconlace-chevron-next"></i></span>
  <a class="office-breadcrumb_level office_link" href='javascript:void(0)'>{{office.name}}</a>
</div>
<div class="office-info">
  <div class="office-data">
    <div class="office-data_icon">
      <i class="iconlace iconlace-phone"></i>
    </div>
    <div class="office-text-group">
      <p *ngFor="let phone of cellPhones" class="office-text-group_text" style="cursor: pointer" (click)="linkTel.click()">
        <a #linkTel class="office_link" href="tel:{{phone}}">{{phone}}</a>
        <br><a class="office-text-group_action"><a #linkTel class="office_link" href="tel:{{phone}}">Llamar</a></a></p>
    </div>
  </div>
  <div class="office-data">
    <div class="office-data_icon">
      <i class="iconlace iconlace-mail"></i>
    </div>
    <div class="office-text-group">
      <p class="office-text-group_text" style="cursor: pointer" (click)="linkEmail.click()">{{office.email}}</p>
      <p class="office-text-group_action"><a #linkEmail class="office_link" href="mailto:{{office.email}}@enlace.org">Enviar
        Mensaje</a></p>
    </div>
  </div>

  <div class="office-data">
    <div class="office-data_icon">
      <i class="iconlace iconlace-map-marker"></i>
    </div>
    <div class="office-text-group">
      <p class="office-text-group_text">{{office.address}}</p>
      <p class="office-text-group_action"><a class="office_link" href="javacript:void(0)"></a></p>
    </div>
  </div>
</div>
<div class="office-map">
  <agm-map [latitude]="getLat(office.latitude)" [longitude]="getLon(office.longitude)" [zoom]="16" [zoomControl]="false"
           [streetViewControl]="true" [zoomControlOptions]="zoomControlOption" [disableDefaultUI]="true">
    <agm-marker [latitude]="getLat(office.latitude)" [longitude]="getLon(office.longitude)"
                (markerClick)="OpenMapLink()"></agm-marker>
  </agm-map>
</div>
