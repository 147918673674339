<form class="{{style}}" [formGroup]="form" (ngSubmit)="submitCard()" novalidate>
  <div class="row">
    <div class="col-lg-3 col-sm-3 col-xs-12">
      <label class="lbl_input" for="form-card-holder">Nombre:</label>
      <input formControlName="cardName" [class.is-invalid]="isInValid('cardName')"
        appScrollIntoViewElement [canScroll]="isMobile$ | async"
        [typeBlock]="'center'"
        type="text" class="form-control" id="form-card-holder"
        autocomplete="off" placeholder="Nombre" required
        (blur)="onFieldBlur('cardName')"
        />
      <div *ngIf="isInValid('cardName')" class="help-block">
        <span>El nombre es requerido.</span>
      </div>
    </div>
    <div class="col-lg-3 col-sm-3 col-xs-12">
      <label class="lbl_input" for="international_Card_LastName">Apellido (s):</label>
      <input formControlName="payerlastName" [class.is-invalid]="isInValid('payerlastName')"
        appScrollIntoViewElement [canScroll]="isMobile$ | async" [typeBlock]="'center'"
        type="text" class="form-control" id="international_Card_LastName"
        autocomplete="off" placeholder="Apellido (s)" required
        (blur)="onFieldBlur('payerlastName')"
        />
      <div *ngIf="isInValid('payerlastName')" class="help-block">
        <span>El apellido (s) es requerido.</span>
      </div>
    </div>
    <div class="col-xs-12" [ngClass]="{'col-lg-3 col-sm-3': isCountryCR, 'col-lg-6 col-sm-6': !isCountryCR}">
      <label class="lbl_input apply-ellipsis" for="internacionalCardAddress">Dirección de facturación:</label>
      <input formControlName="address" [class.is-invalid]="isInValid('address')"
        appScrollIntoViewElement [canScroll]="isMobile$ | async" [typeBlock]="'center'"
        type="text" class="form-control" id="internacionalCardAddress"
        autocomplete="off" placeholder="Ingrese dirección exacta" required
        (blur)="onFieldBlur('address')"
        />
      <div *ngIf="isInValid('address')" class="help-block">
        <span *ngIf="hasError('required', 'address')">La dirección de facturación es requerida.</span>
        <span *ngIf="hasError('maxlength', 'address')">
          La dirección de facturación no debería tener más de {{getFormControl('address')?.errors.maxlength.requiredLength}} caracteres.
        </span>
      </div>
    </div>
    <div *ngIf="isCountryCR" class="col-lg-3 col-sm-3 col-xs-12"  appScrollIntoViewElement="true" [canScroll]="isMobile$ | async">
      <label class="lbl_input" for="newLocalCardCountry">País de facturación:</label>
      <select formControlName="country"
        [ngClass]="{'is-invalid': isInValid('country'), 'not-picked': !getFormControl('country')?.value}"
        class="select-input {{style}}" id="newLocalCardCountry" required
        (blur)="onFieldBlur('country')"
        >
        <option [ngValue]="null" disabled>Elige el país</option>
        <option [ngValue]="country" *ngFor="let country of internationalCountries">{{country.name}}</option>
      </select>
      <i></i>
      <div *ngIf="isInValid('country')" class="help-block">
        <span>El país de facturación es requerido.</span>
      </div>
    </div>
  </div>
  <div class="row mt-15">
    <div *ngIf="!isCountryCR" class="col-lg-3 col-sm-3 col-xs-12"  appScrollIntoViewElement="true" [canScroll]="isMobile$ | async">
      <label class="lbl_input" for="newLocalCardCountry">País de facturación:</label>
      <select formControlName="country"
        [ngClass]="{'is-invalid': isInValid('country'), 'not-picked': !getFormControl('country')?.value}"
        class="select-input {{style}}" id="newLocalCardCountry" required
        (blur)="onFieldBlur('country')"
        >
        <option [ngValue]="null" disabled>Elige el país</option>
        <option [ngValue]="country" *ngFor="let country of internationalCountries">{{country.name}}</option>
      </select>
      <i></i>
      <div *ngIf="isInValid('country')" class="help-block">
        <span>El país de facturación es requerido.</span>
      </div>
    </div>
     <!-- State -->
    <div class="col-lg-3 col-sm-3 col-xs-12" *ngIf="isSpecialCountry"
      appScrollIntoViewElement="true" [canScroll]="isMobile$ | async" [typeBlock]="'center'">
      <label class="lbl_input text-ellipsis" for="newLocalCardState">{{isCountryUS ? 'Estado:' : 'Provincia o territorio:'}}</label>
      <select formControlName="state" (change)="setDepartment()" [ngClass]="{'is-invalid': isInValid('state')}"
        class="select-input {{style}}" id="newLocalCardState" required
        (blur)="onFieldBlur('state')"
        >
        <option [ngValue]="null" disabled>{{isCountryUS ? 'Elige el estado' : 'Elige la provincia o territorio'}}</option>
        <option [ngValue]="state" *ngFor="let state of states">{{state.nameEnglish}}</option>
      </select>
      <i></i>
      <div *ngIf="isInValid('state')" class="help-block">
        <span>{{isCountryUS ? 'El estado  es requerido.' : 'La provincia o territorio es requerido.'}}</span>
      </div>
    </div>
    <div class="col-lg-3 col-sm-3 col-xs-12" *ngIf="!isSpecialCountry">
      <label class="lbl_input text-ellipsis" for="newLocalCardState">Estado (Provincia o Departamento):</label>
      <input formControlName="state" [class.is-invalid]="isInValid('state')"
        appScrollIntoViewElement [canScroll]="isMobile$ | async" [typeBlock]="'center'"
        type="text" class="form-control" id="newLocalCardState"
        autocomplete="off" placeholder="Ingresa el estado" required
        (blur)="onFieldBlur('state')"
        />
      <div *ngIf="isInValid('state')" class="help-block">
        <span>El estado es requerido.</span>
      </div>
    </div>
     <!-- END State -->
     <div class="col-lg-3 col-sm-3 col-xs-12" *ngIf="isCountryCR"
      appScrollIntoViewElement="true" [canScroll]="isMobile$ | async">
      <label class="lbl_input text-ellipsis" for="internationalCardCity">Cantón:</label>
      <select formControlName="city" (change)="setDistrict()" [ngClass]="{'is-invalid': isInValid('city')}"
        class="select-input {{style}}" id="internationalCardCity" required
        (blur)="onFieldBlur('city')"
        >
        <option [ngValue]="null" disabled>Elige el cantón</option>
        <option [ngValue]="department" *ngFor="let department of departments">{{department.nameEnglish}}</option>
      </select>
      <i></i>
      <div *ngIf="isInValid('city')" class="help-block">
        <span>El cantón es requerido.</span>
      </div>
    </div>
    <div class="col-lg-3 col-sm-3 col-xs-12" *ngIf="isCountryCR"
      appScrollIntoViewElement="true" [canScroll]="isMobile$ | async">
      <label class="lbl_input text-ellipsis" for="internationalCardDistrict">Distrito:</label>
      <select formControlName="district" [ngClass]="{'is-invalid': isInValid('district')}"
        class="select-input {{style}}" id="internationalCardDistrict"
        (blur)="onFieldBlur('district')"
        >
        <option [ngValue]="null" disabled>Elige el distrito</option>
        <option [ngValue]="district" *ngFor="let district of districts">{{district.nameEnglish}}</option>
      </select>
      <i></i>
      <div *ngIf="isInValid('district')" class="help-block">
        <span>El distrito es requerido.</span>
      </div>
    </div>
    <div class="col-lg-3 col-sm-3 col-xs-12" *ngIf="!isCountryCR">
      <label class="lbl_input" for="internationalCardCity">Ciudad:</label>
      <input formControlName="city" [class.is-invalid]="isInValid('city')"
        appScrollIntoViewElement [canScroll]="isMobile$ | async" [typeBlock]="'center'"
        type="text" class="form-control" id="internationalCardCity"
        autocomplete="off" placeholder="Ingresa la ciudad" required
        (blur)="onFieldBlur('city')"
        />
      <div *ngIf="isInValid('city')" class="help-block">
        <span>La ciudad es requerida.</span>
      </div>
    </div>
     <!-- Zip Code -->
     <div class="col-lg-3 col-sm-3 col-xs-12" *ngIf="isSpecialCountry && !isCountryCR">
      <label class="lbl_input text-ellipsis" for="internationalCardZipCode">Código postal (Zip Code):</label>
        <input 
        appScrollIntoViewElement
        [canScroll]="isMobile$ | async"
        [typeBlock]="'center'"
        [ngClass]="{'highlight-input': !isInValid('postalCode'), 'has-error': isInValid('postalCode')}"
        appOnlyNumber
        [isOnlyNumber]="!isCountryCA"
        class="form-control"
        type="text"
        autocomplete="off"
        placeholder="Código postal"
        formControlName="postalCode"
        [maxlength]="maxLengthPostalCode"
        (blur)="onFieldBlur('postalCode')"
      />
      <div *ngIf="isInValid('postalCode')" class="help-block">
        <span *ngIf="hasError('required', 'postalCode')">El código postal es requerido.</span>
        <span *ngIf="hasError('maxlength', 'postalCode')">
          El código postal no debería tener más de {{getFormControl('postalCode')?.errors.maxlength.requiredLength}} caracteres.
        </span>
        <span *ngIf="hasError('minlength', 'postalCode')">
          El código postal es invalido.
        </span>
      </div>
    </div>
    <!-- END Zip Code -->
    <!-- Email -->
    <ng-container *ngIf="isCountryCR && !showEmail">
      <ng-container *ngTemplateOutlet="inputEmail; context: { formGroup: form }"></ng-container>
    </ng-container>

    <ng-container *ngIf="!isCountryUS && !isCountryCA && showEmail">
      <ng-container *ngTemplateOutlet="inputEmail; context: { formGroup: form }"></ng-container>
    </ng-container>
    <!-- END Email -->
  </div>
  <div class="row mt-15">
    <div class="col-lg-3 col-sm-3 col-xs-12">
      <label class="lbl_input" for="internationalCardNumber">Número de tarjeta:</label>
      <input formControlName="cardNumber" [class.is-invalid]="isInValid('cardNumber')"
        (keypress)="utils.limitCharactersOnAppleBrowsers($event, 19)"
        appScrollIntoViewElement [canScroll]="isMobile$ | async" appOnlyNumber
        [typeBlock]="'center'" type="text"  class="form-control"  id="internationalCardNumber"
        inputmode="numeric" [value]="getFormControl('cardNumber').value | creditCardSeparator"
        autocomplete="off" maxlength="19" placeholder="xxxx-xxxx-xxxx-xxxx" required
        (blur)="onFieldBlur('cardNumber')"
        />
      <div *ngIf="isInValid('cardNumber')" class="help-block">
        <span>El número de tarjeta es requerido.</span>
      </div>
    </div>
    <div class="col-lg-3 col-sm-3 col-xs-12">
      <label class="lbl_input text-ellipsis" for="internationalCard-cc-exp-date">Fecha de vencimiento:</label>
      <div class="input-group">
        <input formControlName="expiredDate" [class.is-invalid]="isInValid('expiredDate')"
        appScrollIntoViewElement [canScroll]="isMobile$ | async" [typeBlock]="'center'"
        type="text" class="form-control withoutRightBorder"
        id="internationalCard-cc-exp-date"
        maxlength="7"
        inputmode="numeric"
        [value]="getFormControl('expiredDate').value | dateExpiredSeparator"
        autocomplete="off" placeholder="MM/AA" required
        (blur)="onFieldBlur('expiredDate')"
        />
        <div class="input-group-addon" [class.is-invalid]="isInValid('expiredDate')">
          <span>
            <i class="iconlace iconlace-calendar"></i>
          </span>
        </div>
      </div>
      <div *ngIf="isInValid('expiredDate')" class="help-block">
        <span *ngIf="hasError('required', 'expiredDate')">La fecha de vencimiento es requerida.</span>
        <span *ngIf="hasError('expiredCard', 'expiredDate') || hasError('pattern', 'expiredDate')">La fecha de expiración es inválida.</span>
      </div>
    </div>
    <div class="col-lg-3 col-sm-3 col-xs-12">
      <label class="lbl_input" for="internationalCardCvvCode">Código de seguridad:</label>
      <div class="input-group">
        <input formControlName="maskedCVV" [class.is-invalid]="isInValid('maskedCVV')"
        appScrollIntoViewElement [canScroll]="isMobile$ | async" [typeBlock]="'center'"
        type="password"  class="form-control"  id="internationalCardCvvCode"
        placeholder="Ingresa el CVV (dígitos al dorso)"
        minlength="3" maxlength="4"
        inputmode="numeric"
        [value]="getFormControl('maskedCVV').value | creditCardSeparator"
        autocomplete="off"  required
        (blur)="onFieldBlur('maskedCVV')"
        />
        <div class="input-group-addon" [class.is-invalid]="isInValid('maskedCVV')">
          <span>
            <i class="iconlace iconlace-card"></i>
          </span>
        </div>
      </div>
      <div *ngIf="isInValid('maskedCVV')" class="help-block">
        <span *ngIf="hasError('required', 'maskedCVV')">El CVV es requerido.</span>
        <span *ngIf="hasError('maxlength', 'maskedCVV')">
          El CVV no debería tener más de {{getFormControl('maskedCVV')?.errors.maxlength.requiredLength}} caracteres.
        </span>
        <span *ngIf="hasError('minlength', 'maskedCVV')">
          CVV inválido.
        </span>
      </div>
    </div>
    <div class="col-lg-3 col-sm-3 col-xs-12" *ngIf="isCountryCR">
      <label class="lbl_input" for="internationalPayerDocument">Número de cédula:</label>
      <input formControlName="payerDocument" appOnlyNumber type="text"
        appScrollIntoViewElement [canScroll]="isMobile$ | async" [typeBlock]="'center'"
        class="form-control" id="internationalPayerDocument"
        [ngClass]="{'is-invalid': isInValid('payerDocument')}"
        maxlength="10" autocomplete="off" placeholder="Ingresa tu número de cédula física o jurídica" required
        (blur)="onFieldBlur('payerDocument')"
        />
      <div *ngIf="isInValid('payerDocument')" class="help-block">
        <span *ngIf="hasError('required', 'payerDocument')">La cédula física o jurídica es requerida.</span>
        <span *ngIf="hasError('maxlength', 'payerDocument')">
          La cédula física o jurídica es invalida.
        </span>
        <span *ngIf="hasError('minlength', 'payerDocument')">
          La cédula física o jurídica es invalida.
        </span>
      </div>
    </div>
    <ng-container *ngIf="(isCountryUS || isCountryCA) && showEmail">
      <ng-container *ngTemplateOutlet="inputEmail; context: { formGroup: form }"></ng-container>
    </ng-container>
  </div>
</form>


<ng-template #inputEmail let-formGroup="formGroup">
  <div class="col-lg-3 col-sm-3 col-xs-12" [formGroup]="formGroup">
    <label class="lbl_input" for="internationalEmail">Correo electrónico:</label>
    <input formControlName="payerEmail" type="email" class="form-control"
      id="internationalEmail" appScrollIntoViewElement [canScroll]="isMobile$ | async"
      [typeBlock]="'center'"
      [ngClass]="{'highlight-input': !isInValid('payerEmail'), 'is-invalid': isInValid('payerEmail')}"
      autocomplete="off" placeholder="Ingresa un correo electrónico" required
      (blur)="onFieldBlur('payerEmail')"
      />
      <div *ngIf="isInValid('payerEmail')" class="help-block">
        <span *ngIf="hasError('required', 'payerEmail')">El correo electrónico es requerido.</span>
        <span *ngIf="hasError('pattern', 'payerEmail') || hasError('isInvalidEmail', 'payerEmail')">
           Por favor ingresa un correo electrónico válido.
        </span>
      </div>
  </div>
</ng-template>
