import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(publishedDate: Date): string {
    if (publishedDate) {
      const day = this.datePipe.transform(publishedDate, 'dd');
      const month = this.datePipe.transform(publishedDate, 'MMMM');
      const year = this.datePipe.transform(publishedDate, 'yyyy');
      return `${day} de ${month} del ${year}`;
    } else {
      return '';
    }
  }

}
