import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IContentCatalogViewModel } from '@core/models/interfaces/i-content-catalog-view-model';
import { PPVContentService } from '@app/shared/utils/ppvcontent.service';

@Component({
  selector: 'app-slider-audiobook-item',
  templateUrl: './slider-audiobook-item.component.html',
  styleUrls: ['./slider-audiobook-item.component.scss']
})
export class SliderAudiobookItemComponent implements OnInit {
  srcImage: string;
  srcFallback = 'assets/img/no-image.webp';

  // LINE-CLAMPING https://css-tricks.com/line-clampin/#article-header-id-0
  lineClamp = {
    'display': '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  }

  @Input() audiobook: IContentCatalogViewModel;
  @Input() myLibrary: boolean = true;
  @Input() mySocial: boolean = false;
  @Input() popupText: string;
  @Input() isWebview: boolean = false;
  @Output() onClickCaratuta: EventEmitter<Event> = new EventEmitter();
  @Output() unfollowAudioBook: EventEmitter<Event> = new EventEmitter();

  constructor(private ppvContentService: PPVContentService) {
  }

  ngOnInit() {
  }

  getImage() {
    return this.audiobook && (this.audiobook.urlBackgroundImage || this.audiobook.urlImage || this.audiobook.thumbnailUrl);
  }

  getPrice(): string {
    if(!this.ppvContentService.isPPV(this.audiobook)) {
      return 'gratis';
    }
    return '$ ' + this.ppvContentService.getPrice(this.audiobook);
  }
}
