import { Pipe, PipeTransform } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { DatePipe } from '@angular/common';
import { LanguageService } from '../../api-client/v2/language.service';
import * as moment from 'moment';

@Pipe({
  name: 'datelocalformat',
})
export class DateLocalFormatPipe extends  DatePipe implements PipeTransform {

  value: string;
  format: string;
  timezone: string;
  constructor(private _translate: LanguageService, sessionStorage: LocalStorageService) {
    super(null);
    sessionStorage.observe('language').subscribe(() => {
      this.transform(this.value,this.format,this.timezone,this._translate.getLocale());
    });
  }

  transform(value: any, format?: string, timezone?: string, _locale?: string): string | any {
    const serverTime = moment.utc(value).valueOf();
    const loc = this._translate.getLocale();
    return super.transform(serverTime,format,timezone,loc);
  }

}
