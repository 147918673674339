import { NgModule} from '@angular/core';
import { CommonModule} from '@angular/common';
import { LanguagePipe} from './language.pipe';
import { ApiClientModule} from '../api-client/api-client.module';
import { DateFormatPipe} from './dateformat.pipe';
import { CutTextPipe} from './cut-text.pipe';
import { TimeFormatFromSecond, TimeFormatPipe, TimeFormatSecPipe, TimeHumanFormatPipe, AllTimeFormatFromSecond} from './time-format.pipe';
import { HighlightPipe } from './highlight.pipe';
import { JoinPipe } from './join.pipe';
import { IsEmptyGuidPipe } from './is-empty-guid.pipe';
import { ImageByTypePipe } from './image-by-type.pipe';
import { ContainWordPipe } from './contain-word.pipe';
import { HtmlToTextPipe } from './html-to-text.pipe';

import { SafeHtmlPipe } from './safe-html.pipe';
import { TypeContentPipe } from './type-content.pipe';
import { ImageFilterPipe } from './image-filter.pipe';
import { ImageHostPipe } from './image-host.pipe';
import { SubtitleHostPipe } from './subtitle-host.pipe';
import { LabelFilterPipe } from './label-filter.pipe';
import { DotSeparatorPipe } from '@app/pipes/dot-separator.pipe';
import { NameClassMenuIconPipe } from './nameClassMenuIcon.pipe';
import { EllipsisPipe } from './ellipsis.pipe';


@NgModule({
  imports: [CommonModule],
  declarations: [
    LanguagePipe,
    DateFormatPipe,
    CutTextPipe,
    TimeFormatPipe,
    HighlightPipe,
    JoinPipe,
    IsEmptyGuidPipe,
    ImageByTypePipe,
    ContainWordPipe,
    TimeFormatSecPipe,
    TimeHumanFormatPipe,
    TimeFormatFromSecond,
    HtmlToTextPipe,
    SafeHtmlPipe,
    TypeContentPipe,
    AllTimeFormatFromSecond,
    ImageFilterPipe,
    ImageHostPipe,
    SubtitleHostPipe,
    LabelFilterPipe,
    DotSeparatorPipe,
    NameClassMenuIconPipe,
    EllipsisPipe
  ],
  exports: [
    LanguagePipe,
    DateFormatPipe,
    CutTextPipe,
    TimeFormatPipe,
    HighlightPipe,
    JoinPipe,
    IsEmptyGuidPipe,
    ImageByTypePipe,
    ContainWordPipe,
    TimeFormatSecPipe,
    TimeHumanFormatPipe,
    AllTimeFormatFromSecond,
    TimeFormatFromSecond,
    HtmlToTextPipe,
    SafeHtmlPipe,
    TypeContentPipe,
    ImageFilterPipe,
    ImageHostPipe,
    SubtitleHostPipe,
    LabelFilterPipe,
    DotSeparatorPipe,
    NameClassMenuIconPipe,
    EllipsisPipe
  ],
})
export class PipesModule {}
