import { ChangeDetectionStrategy, ChangeDetectorRef, Component,
  EventEmitter, Input, OnDestroy, OnInit, Output
} from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { CountryV3Service } from '@services/country-v3.service';
import { MailingService } from '@services/mailing.service';
import { SubscriptionSubmitService } from '@utils/subscription-submit.service';

import { expiredCardDateValidator } from '@utils/validators/expired-card-date.validator';
import { emailIsValid } from '@utils/validators/is-valid-email.validator';
import { MobileService } from '@utils/mobile.service';
import { getInternationalCountries } from '@helpers/sort-countries.helper';

import { EMAIL_REGEX, EXPIRED_CARD_REGEX } from '@constants/validators-pattern.constants';
import { InternationalCardModel, SaveCard } from '@interfaces/i-type-card.interface';
import { Country, Department, District, State } from '@interfaces/i-country';
import { TypeSaveCard } from '@enums/type-save-card.enum';
import { UtilsService } from '@app/shared/utils/utils.service';
import { environment as ENV } from '@environment/environment';
import { IUser } from '@app/core/models/interfaces/i-user.interface';
import { GtmService } from '@app/shared/services/lib/gtm.service';
import { LocalStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-international-card-form-inline',
  templateUrl: './international-card-form-inline.component.html',
  styleUrls: ['./international-card-form-inline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternationalCardFormInlineComponent implements OnInit, OnDestroy {
  @LocalStorage('user')
  private user: IUser;

  @Input() countries: Country[] = [];
  @Input() style: string;
  @Input() showEmail: boolean;

  @Output() onSubmitCard: EventEmitter<SaveCard> = new EventEmitter();

  public internationalCardModel: InternationalCardModel;
  public form: UntypedFormGroup;
  public states: State[] = [];
  public departments: Department[] = [];
  public districts: District[] = [];
  public maxLengthPostalCode: number;

  private subscriptionForm: Subscription;
  private subscriptionSubmitted: Subscription;
  private formSubmitAttempt: boolean;
  private specialInputs = ['payerEmail', 'payerDocument', 'state', 'city', 'postalCode', 'district'];

  constructor(
    private subscriptionSubmitService: SubscriptionSubmitService,
    private countryService: CountryV3Service,
    private mailingService: MailingService,
    private mobileService: MobileService,
    private formBuilder: UntypedFormBuilder,
    public utils: UtilsService, // This is being used in template
    private cdr: ChangeDetectorRef,
    private gtmService: GtmService
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.listenFromCountryField();
    this.listenFromSubmitService();
  }

  ngOnDestroy(): void {
    this.subscriptionForm.unsubscribe();
    this.subscriptionSubmitted.unsubscribe();
  }

  get isSubmitted$(): Observable<boolean> {
    return this.subscriptionSubmitService.isSubmitSubscription$;
  }

  get internationalCountries(): Country[] {
    return getInternationalCountries(this.countries);
  }

  get countryField(): AbstractControl {
    return this.form.get('country');
  }

  get isCountryUS(): boolean {
    return !!this.countryField && !!this.countryField.value
    && this.countryField.value.code === 'US';
  }

  get isCountryCA(): boolean {
    return !!this.countryField && !!this.countryField.value
    && this.countryField.value.code === 'CA';
  }

  get isCountryCR(): boolean {
    return !!this.countryField && !!this.countryField.value
    && this.countryField.value.code === 'CR';
  }

  get isSpecialCountry(): boolean {
    return this.isCountryUS || this.isCountryCA || this.isCountryCR;
  }

  get isMobile$(): Observable<boolean> {
    return this.mobileService.isMobileDevice$;
  }

  public onFieldBlur(fieldName: string): void {
    const paramsDataLayer = {
      event: 'suscription',
      origin: 'subscription',
      userId : this.user?.id ?? null,
      eventType: 'click',
      eventName : 'subscription_process',
      fieldName: fieldName,
      env: ENV.name
    }
    this.gtmService.logFieldTrack(paramsDataLayer);
  }

  public submitCard(): void {
    this.formSubmitAttempt = true;
    this.cdr.detectChanges();
    if (this.form.valid)
      this.emitInternationalCardModel();
  }

  public emitInternationalCardModel(): void {
    this.internationalCardModel = {
      ...this.form.value,
      country: this.countryField.value.code,
      isDefaultCard: false,
      stateCode: this.isSpecialCountry ? this.getFormControl('state').value.code : '',
      state: this.isSpecialCountry ? this.getFormControl('state').value.nameEnglish : this.getFormControl('state').value,
      city: this.isCountryCR ? this.getFormControl('city').value.nameEnglish : this.getFormControl('city').value,
      district: this.isCountryCR ? this.getFormControl('district').value.nameEnglish : this.getFormControl('district').value,
      payerDocument: this.isCountryCR ? this.getFormControl('payerDocument').value : '',
      saveCard: true
    }
    this.onSubmitCard.emit({card: this.internationalCardModel, type: TypeSaveCard.INTERNATIONAL});
  }

  public listenFromSubmitService(): void {
    this.subscriptionSubmitted = this.isSubmitted$.subscribe(val => {
      if (val) this.submitCard();
    });
  }

  public setState(code: string): void {
    this.countryService.getStates(code).subscribe((data) => this.states = data);
  }

  public setDepartment(): void {
    if (this.isCountryCR) {
      const value = this.getFormControl('state').value;
      const code = !!value && value.code;
      if (code)
        this.countryService.getDepartments(code).subscribe((data) => {
          this.getFormControl('city').setValue(null);
          this.departments = data;
        });
    }
  }

  public setDistrict(): void {
    if (this.isCountryCR) {
      const value = this.getFormControl('city').value;
      const code = !!value && value.code;
      if (code)
        this.countryService.getDistricts(code).subscribe((data) => {
          this.getFormControl('district').setValue(null);
          this.districts = data;
        });
    }
  }

  public getFormControl(control: string): AbstractControl {
    return this.form.get(control);
  }

  public listenFromCountryField(): void {
    this.subscriptionForm = this.countryField.valueChanges.subscribe(value => this.changeFields(value));
  }

  public changeFields(country: Country): void {
    this.clearValidators(this.specialInputs);
    if (this.isCountryCR)
      this.setValidatorToCR();
    if (this.isSpecialCountry)
      this.setValidatorToSpecialCountries(country);
    this.setValidators('state', [Validators.required]);
    this.setValidators('city', [Validators.required]);
  }

  public setValidators(control: string, validators: ValidatorFn[]): void {
     this.getFormControl(control).setValidators(validators);
     this.getFormControl(control).updateValueAndValidity();
  }

  public setAsyncValidator(control: string, validators: AsyncValidatorFn | AsyncValidatorFn[]): void {
    this.getFormControl(control).setAsyncValidators(validators);
  }

  public clearValidators(controls: string[]): void {
    controls.forEach(control => {
      this.getFormControl(control).clearValidators();
      this.getFormControl(control).clearAsyncValidators();
      this.getFormControl(control).markAsUntouched();
      this.getFormControl(control).setValue(null);
    });
  }

  public setValidatorToCR(): void {
    this.setValidators('payerDocument', [Validators.required, Validators.minLength(9), Validators.maxLength(10)]);
    this.setValidators('payerEmail', [Validators.required, Validators.pattern(EMAIL_REGEX)]);
    this.setValidators('district', [Validators.required]);
    this.setAsyncValidator('payerEmail',emailIsValid(this.mailingService))
  }

  public setValidatorToSpecialCountries(country: Country): void {
    this.setState(country.code);
    this.maxLengthPostalCode = this.isCountryUS ? 5 : this.isCountryCA ? 6 : 3;
    if (!this.isCountryCR)
      this.setValidators('postalCode', [
        Validators.required,
        Validators.minLength(this.maxLengthPostalCode),
        Validators.maxLength(this.maxLengthPostalCode)
      ]);
  }

  public isInValid(control: string): boolean {
    return (this.getFormControl(control).touched && !!this.getFormControl(control).errors)
    || (this.getFormControl(control).untouched && this.formSubmitAttempt);
  }

  public hasError(type: string, control: string): boolean {
    return !!this.getFormControl(control).errors && this.getFormControl(control).errors[type];
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      cardName: ['', [Validators.required]],
      payerlastName: ['', [Validators.required]],
      address: ['', [Validators.required, Validators.maxLength(100)]],
      country: [null, [Validators.required]],
      state: [null, [Validators.required]],
      city: [null, [Validators.required]],
      district: [null],
      payerEmail: ['', { updateOn: 'blur' }],
      postalCode: [''],
      cardNumber: ['', [Validators.required, Validators.maxLength(19)]], // 19 for the 3 spaces
      expiredDate: ['', [Validators.required, Validators.pattern(EXPIRED_CARD_REGEX), expiredCardDateValidator()]],
      maskedCVV: ['', [Validators.required, Validators.maxLength(4)]],
      isDefaultCard: [false],
      payerDocument: [''],
    });
  }
}
