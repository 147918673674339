import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { LocalStorage } from 'ngx-webstorage';

import { AdvanceSearchService } from './services/advance-search.service';
import { MyMonitoringService } from '@services/lib/monitoring.service';
import { ContentV3Service } from '@services/content-v3.service';
import { BibleApiService } from '@api/bible-api.service';
import { MobileService } from '@utils/mobile.service';


import { TypeResultSearch } from '@api/models/entities/search.interface';
import { ISearchRequestModel } from '@interfaces/i-search-request-model';
import { IVideoUserView } from '@interfaces/i-video-user-view';
import { FiltersComponent } from './filters/filters.component';
import { ChangeFilter } from '@api/models/change-filter-model';
import { IOrganization } from '@interfaces/i-organization';
import { IPerson } from '@interfaces/i-person.interface';
import { IUser } from '@interfaces/i-user.interface';
import { ILanguage } from '@interfaces/i-language';
import { ICatalog } from '@interfaces/i-catalog';
import { UtilsService } from '@app/shared/utils/utils.service';
import { DropdownSelectList } from '@app/core/models/interfaces/i-dropdown-select.interface';
import { IFeaturedContent } from '@app/core/models/interfaces/i-search-response-view-model';
import { DomManipulateService } from '@app/shared/utils/dom-manipulate.service';
import { LoadingOverlayService } from '@app/shared/services/loading-overlay.service';

@Component({
  selector: 'app-search-advanced',
  templateUrl: './search-advanced.component.html',
  providers: [BibleApiService],
  styleUrls: ['./search-advanced.component.scss'],
})
export class SearchAdvancedComponent implements OnInit, OnDestroy{

  @LocalStorage('user')
  private user: IUser;
  videoUserView: IVideoUserView;

  public searchResult: any[] = [];
  public searchText = '';
  public currentContentFilter: string = TypeResultSearch.ALL;
  public filtersSelected: any[] = [];
  public loadingPaging: boolean = false;
  public isActive: boolean = false;
  public listFilters: any[] = [];

  catalogId: string = null;
  personId: string = null;
  countryId: string = null;
  languageId: string = null;
  personList: IPerson[] = [];
  catalogList: ICatalog[] = [];
  ministryList: IOrganization[] = [];
  // TO DO: Definir country here
  countryOrig: any[] = [];
  languageOrig: ILanguage[] = [];
  selectedPrice: number = null;
  maxValuePrice: number = 0;
  ministryIds: string = null;
  contentTypeId: string = null;
  isRecentSelected: boolean = false;
  topicId: string = null;
  bookCategoryId: string = null;
  ratingList: any[] = [];
  selectedRating: number = null;
  topicList: any[] = [];
  recentList: any[] = [];
  bookCategoryList: any[] = [];
  contentTypeList: any[] = [];
  dateOfIssues = [
    {
      name: 'Última semana',
      value: 'week'
    },
    {
      name: 'Último mes',
      value: 'month'
    },
    {
      name: 'Mes anterior',
      value: 'lastMonth'
    },
    {
      name: 'Últimos 90 días',
      value: 'quarter'
    },
    {
      name: 'Este año',
      value: 'year'
    },
    // {
    //   name: 'Personalizado',
    //   value: 'custom'
    // },
  ]
  currentIssueDate: DropdownSelectList;
  notFound: boolean;
  isShowFilter = true;
  totalItems: number;
  lastSearchResult: any;
  searchBibleResults: any = null;
  searchBibleResultsFirstVerse: any = null;
  searchBibleUrl: string = null;
  searchFeaturedContentResult: IFeaturedContent;
  airDateFilter = null;
  airDateBegin: string = '';
  airDateEnd: string = '';

  filters: any[] = [];
  subjectAdvancedSearchTextSubscription: Subscription;
  removeDiacritics: any;
  featuredSearchEnable: boolean;
  @ViewChild('appFilter', { read: FiltersComponent }) public filterComponent: FiltersComponent;
  filterComponentRef: FiltersComponent;
  displaySearchResults = false;

  constructor(
    private router: Router,
    private myMonitoringService: MyMonitoringService,
    private searchService: ContentV3Service,
    private mobileService: MobileService,
    private bibleApiService: BibleApiService,
    public advanceSearchService: AdvanceSearchService,
    private domManipulateService: DomManipulateService,
    private utilsService: UtilsService,
    private changeDetector: ChangeDetectorRef,
    private loadingOverlayService: LoadingOverlayService
  ) {
  }

  ngOnInit() {
    this.notFound = null;
    this.totalItems = 0;

    this.subjectAdvancedSearchTextSubscription = this.advanceSearchService.subjectAdvancedSearchText.subscribe((value: string) => {
      if (value !== '' && value !== undefined) {
        this.searchText = value;
        this.searchBibleResults = null;
        this.searchBible();
        this.search();
      }
    });
    this.removeDiacritics = require('diacritics').remove;
    if (this.isMobileUrlWebview)
      this.domManipulateService.setBodyClass(true, 'mobile');
  }

  ngAfterViewChecked(): void {
    if (this.filterComponent) {
      this.filterComponentRef = this.filterComponent;
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this.domManipulateService.setBodyClass(false, 'mobile');
    this.subjectAdvancedSearchTextSubscription.unsubscribe();
  }

  get isMobile$(): Observable<boolean> {
    return this.mobileService.isMobileDevice$;
  }

  get isMobileUrlWebview(): boolean {
    return this.utilsService.isMobileRoute;
  }

  addFilterMinisteries(visible: boolean = true) {
    this.filters.push({
      title: 'Ministerios', items: this.ministryList, order: 5, visible: visible, type: 'ministry', getAvatar(item): string {
        return item.image || '/assets/img/avatar.webp';
      },
      getAvatarDefault: (): string => {
        return '/assets/img/avatar.webp';
      },
      filter: ((items: any[], filters: any[]): any[] => {
        if (items.length === 0) {
          return filters;
        }
        return filters.filter((f) => {
          return items.filter((i) => {
            return i.organizationId === f.id;
          }).length > 0
        });
      })
    });
  }

  addFilterCountry(visible: boolean = true) {
    this.filters.push({
      title: 'Paises', items: this.countryOrig, order: 10, visible: visible, type: 'country', getAvatar(item): string {
        return '/assets/img/flags/' + item.code.toLowerCase() + '.png';
      },
      getAvatarDefault: (): string => {
        return '/assets/img/glo.webp';
      },
      filter: ((items: any[], filters: any[]): any[] => {
        if (!items) {
          return [];
        }
        if (items.length === 0) {
          return filters;
        }
        return filters.filter((f) => {
          return items.filter((i) => {
            return i.countryId === f.id;
          }).length > 0
        });
      })
    });

  }

  addFilterPricing(visible: boolean = true) {
    this.filters.push({
      value: this.selectedPrice == null ? 0 : this.selectedPrice,
      maxValue: this.maxValuePrice,
      title: 'Precio USD', items: [], order: 4, visible: visible, type: 'price', getAvatar(item): string {
        return item.image;
      },
      getAvatarDefault: (): string => {
        return '/assets/img/glo.webp';
      },
      filter: ((items: any[], filters: any[]): any[] => {
        if (!items) {
          return [];
        }
        if (items.length === 0) {
          return filters;
        }
        return filters.filter((f) => {
          return items.filter((i) => {
            return i.bookCategoryId === f.id;
          }).length > 0
        });
      })
    });
  }

  addFilterLanguage(visible: boolean = true) {
    this.filters.push({
      title: 'Idiomas', items: this.languageOrig, order: 12, visible: visible, type: 'language', getAvatar(item): string {
        let lan = 'usa';
        if (item.code.split('-')[0] === 'es') {
          lan = 'esp';
        }
        return '/assets/img/flags/' + lan + '.png';
      },
      getAvatarDefault: (): string => {
        return '/assets/img/glo.webp';
      },
      filter: ((items: any[], filters: any[]): any[] => {
        if (!items) {
          return [];
        }
        if (items.length === 0) {
          return filters;
        }
        return filters.filter((f) => {
          return items.filter((i) => {
            return i.languageId === f.id;
          }).length > 0
        });
      })
    });
  }

  addFilterCatalogs(visible: boolean = true) {
    this.filters.push({
      title: 'Catálogos', items: this.catalogList, order: 6, visible: visible, type: 'catalog', getAvatar(item): string {
        return item.image || '/assets/img/avatar.webp';
      },
      getAvatarDefault: (): string => {
        return '/assets/img/avatar.webp';
      },
      filter: ((items: any[], filters: any[]): any[] => {
        if (!items) {
          return [];
        }
        if (items.length === 0) {
          return filters;
        }
        return filters.filter((f) => {
          return items.filter((i) => {
            return i.catalogsIds.indexOf(f.id.toUpperCase()) !== -1;
          }).length > 0
        });
      })
    });
  }


  addFilterPersons(visible: boolean = true) {
    this.filters.push({
      title: 'Personas', items: this.personList, order: 7, visible: visible, type: 'host', getAvatar(item): string {
        return item.image || '/assets/img/avatar.webp';
      },
      getAvatarDefault: (): string => {
        return '/assets/img/avatar.webp';
      },
      filter: ((items: any[], filters: any[]): any[] => {
        if (!items) {
          return [];
        }
        if (items.length === 0) {
          return filters;
        }
        return filters.filter((f) => {
          return items.filter((i) => {
            return true;
          }).length > 0
        });
      })
    });
  }

  addFilterContentType(visible: boolean = true): void {
    this.filters.push({
      title: 'Formatos', items: this.contentTypeList, order: 1, visible: visible, type: 'contentType', getAvatar(item): string {
        return item.image || '/assets/img/img-btn-librosdigitales.webp';
      },
      getAvatarDefault: (): string => {
        return '/assets/img/avatar.webp';
      },
      filter: ((items: any[], filters: any[]): any[] => {
        if (items.length === 0) {
          return filters;
        }
        return filters.filter((f) => {
          return items.filter((i) => {
            return i.contentTypeId === f.id;
          }).length > 0
        });
      })
    });
  }

  addFilterRecents(visible: boolean = true) {
    this.filters.push({
      title: 'Recientes', items: this.recentList, order: 2, visible: visible, type: 'recent', getAvatar(item): string {
        return item.image;
      },
      getAvatarDefault: (): string => {
        return '/assets/img/glo.png';
      },
      filter: ((items: any[], filters: any[]): any[] => {
        if (!items) {
          return [];
        }
        if (items.length === 0) {
          return filters;
        }
        return filters.filter((f) => {
          return items.filter((i) => {
            return i.bookCategoryId === f.id;
          }).length > 0
        });
      })
    });
  }

  addFilterDateIssue(visible: boolean = true) {
    this.filters.push({
      title: 'Fecha de Emisión', items: this.dateOfIssues, order: 3, visible: visible, type: 'date-issue', getAvatar(item): string {
        return item.image;
      },
      getAvatarDefault: (): string => {
        return '/assets/img/glo.png';
      },
      filter: ((items: any[], filters: any[]): any[] => {
        return [];
      })
    });
  }

  addFilterRating(visible: boolean = true) {
    this.filters.push({
      title: 'Valoracion', items: this.ratingList, order: 8, visible: visible, type: 'rating', getAvatar(item): string {
        return item.image;
      },
      getAvatarDefault: (): string => {
        return '/assets/img/glo.png';
      },
      filter: ((items: any[], filters: any[]): any[] => {
        if (!items) {
          return [];
        }
        if (items.length === 0) {
          return filters;
        }
        return filters.filter((f) => {
          return items.filter((i) => {
            return i.ratingId === f.id;
          }).length > 0
        });
      })
    });
  }

  addFilterTopic(visible: boolean = true) {
    this.filters.push({
      title: 'Temas', items: this.topicList, order: 6, visible: visible, type: 'topic', getAvatar(item): string {
        return item.avatarUrl;
      },
      getAvatarDefault: (): string => {
        return 'assets/img/avatar.webp';
      },
      filter: ((items: any[], filters: any[]): any[] => {
        if (!items) {
          return [];
        }
        if (items.length === 0) {
          return filters;
        }
        return filters.filter((f) => {
          return items.filter((i) => {
            return i.topicId === f.id;
          }).length > 0
        });
      })
    });
  }

  addFilterBookCategory(visible: boolean = true) {
    this.filters.push({
      title: 'Categorías de libros', items: this.bookCategoryList, order: 8, visible: visible, type: 'bookCategory', getAvatar(item): string {
        return item.avatarUrl;
      },
      getAvatarDefault: (): string => {
        return 'assets/img/avatar.webp';
      },
      filter: ((items: any[], filters: any[]): any[] => {
        if (!items) {
          return [];
        }
        if (items.length === 0) {
          return filters;
        }
        return filters.filter((f) => {
          return items.filter((i) => {
            return i.bookCategoryId === f.id;
          }).length > 0
        });
      })
    });
  }

  changeFilter(changeFilter: ChangeFilter) {
    if (changeFilter == null) {
      this.selectedPrice = null;
      this.ministryIds = null;
      this.languageId = null;
      this.countryId = null;
      this.personId = null;
      this.catalogId = null;
      this.contentTypeId = null;
      this.isRecentSelected = false;
      this.topicId = null;
      this.bookCategoryId = null;
      this.selectedRating = null;
      this.airDateBegin = '';
      this.airDateEnd = '';
      this.airDateFilter = '';
    } else {
      this.isActive = false;
      if (changeFilter.type == 'price') {
        this.selectedPrice = null;
        this.selectedPrice = changeFilter.value;
      } else if (changeFilter.type == 'collection') {
        this.ministryIds = null;
        this.languageId = null;
        this.countryId = null;
        this.personId = null;
        this.selectedPrice = null;
        this.catalogId = null;
        this.contentTypeId = null;
        this.isRecentSelected = false;
        this.topicId = null;
        this.bookCategoryId = null;
        this.airDateFilter = '';
        changeFilter.value.forEach((value: any) => {
          const filter: any = value.filter;

          switch (filter.type) {
            case 'ministry':
              this.ministryIds = value.item.id;
              break;
            case 'catalog':
              this.catalogId = value.item.id;
              break;
            case 'language':
              this.languageId = value.item.id;
              break;
            case 'country':
              this.countryId = value.item.id;
              break;
            case 'host':
              this.personId = value.item.id;
              break;
            case 'contentType':
              this.contentTypeId = value.item.id;
              break;
            case 'topic':
              this.topicId = value.item.id;
              break;
            case 'bookCategory':
              this.bookCategoryId = value.item.id;
              break;
            case 'recent':
              this.isRecentSelected = true;
              break;
          }
        });
      } else if (changeFilter.type == 'recents') {
        this.isRecentSelected = true;
      } else if (changeFilter.type == 'date-issue') {
        this.airDateFilter = changeFilter.value;
      } else if (changeFilter.type == 'rating') {
        if (!this.validateRating(changeFilter.value)) {
          this.selectedRating = null;
          this.selectedRating = changeFilter.value;
        } else {
          return;
        }
      }
    }

    this.search();
  }

  validateRating(value: number): boolean {
    const findFilter = this.listFilters.filter(item => item.filter.type === 'rating').find(item => item.value === value);
    return !!findFilter;
  }


  public searchByContent(type: string): void {
    this.currentContentFilter = type;
    this.search();
  }

  clear() {
    this.searchResult = [];
    this.filters = [];
    this.ministryList = [];
    this.languageOrig = [];
    this.countryOrig = [];
    this.contentTypeList = [];

    this.filters = [];
    this.addFilterMinisteries(true);
    this.addFilterCountry(true);
    this.addFilterLanguage(true);

    this.searchText = '';

    this.isShowFilter = false;
    this.isActive = false;
  }

  getFirstVerse(passages) {
    const firstVerse = passages[0].id.split('-')[0];
    const firstVerseNumber = firstVerse.split('.')[2];
    this.bibleApiService.getSelectedVerse(this.bibleApiService.defaultBibleId, firstVerse)
      .subscribe((data) => {
        let content = data['data'].content;
        this.searchBibleResultsFirstVerse = content;
      });
  }

  searchBible(): void {
    let text = this.removeDiacritics(this.searchText);
    const reference = this.bibleApiService.getSearchCompleteChapter(text);
    if (reference) {
      const bookId = reference.split('.')[0];
      const chapterId = reference.split('.')[1];
      if (text.indexOf(':') != -1) {
        const verses = text.split(':');
        text = bookId + ' ' + chapterId + ':' + verses[1];
      } else {
        text = bookId + ' ' + chapterId + ':' + '1';
      }
    }
    this.bibleApiService.getResults(this.bibleApiService.defaultBibleId, text, 0, 20)
      .subscribe((data) => {

        if (data['data'].total || (data['data'].passages != null && data['data'].passages.length)) {
          this.searchBibleUrl = `/bible/search/${this.bibleApiService.defaultLanguageId}/${this.bibleApiService.defaultBibleId}/${this.searchText}`;

          this.searchBibleResults = {}
          this.searchBibleResults.verses = new Array();
          this.searchBibleResults.passages = new Array();

          if (data['data'].verses) {
            this.searchBibleResults.verses = data['data'].verses;
            this.searchBibleResults.total = data['data'].total;
          } else if (data['data'].passages instanceof Array) {
            this.getFirstVerse(data['data'].passages);
            this.searchBibleResults.passages = data['data'].passages;
            this.searchBibleResults.total = data['data'].passages.length;
          }
        } else {
          this.searchBibleResults = null;
          this.searchBibleUrl = null;
        }
      });
  }

  search(pageInfo: ISearchRequestModel = <ISearchRequestModel>{
    rowsPage: 20,
    page: 0,
    newSearch: true,
    clearSearch: false,
    showAll: false
  }) {
    this.myMonitoringService.logEvent({ name: 'SearchAdvanced', properties: { 'SearchTerms': this.searchText } });
    this.searchResult = [];

    this.featuredSearchEnable = this.advanceSearchService.typeSelected.type === 'all';

    this.notFound = false;
    if (this.searchText && this.searchText !== '' && this.searchText.length > 1) {
      this.loadingOverlayService.showLoadingOverlay();
      pageInfo.catalogId = this.catalogId;
      pageInfo.organizationId = this.ministryIds;
      pageInfo.countryId = this.countryId;
      pageInfo.languageId = this.languageId;
      pageInfo.personHostId = this.personId;
      pageInfo.price = this.selectedPrice;
      pageInfo.search = this.searchText.concat('*');
      pageInfo.isRecent = this.isRecentSelected;
      pageInfo.rating = this.selectedRating;
      pageInfo.topicId = this.topicId;
      pageInfo.airDateFilter = this.airDateFilter;
      pageInfo.airDateBegin = null;
      pageInfo.airDateEnd = null;
      pageInfo.bookCategoryId = this.bookCategoryId;
      pageInfo.sectionName = this.advanceSearchService.typeSelected.value === 'library' ? this.advanceSearchService.typeSelected.value : '';
      pageInfo.type = this.contentTypeId != null ? this.contentTypeId : this.advanceSearchService.typeSelected.type;
      this.advanceSearchService.searching = true;


      this.searchService.advancedSearch(pageInfo).subscribe((result) => {
        this.loadingOverlayService.hideLoadingOverlay();
        this.lastSearchResult = result;

        this.setupFilters(this.lastSearchResult);

        this.searchFeaturedContentResult = result.featuredContent;
        const narratedBibleList = result.result.list.filter((f) => {
          return f.type === 'narrated-bible';
        });
        if (narratedBibleList.length > 0) {
          this.searchResult.push({
            title: 'LOS EVANGELIOS',
            search: this.searchText,
            typeFilter: TypeResultSearch.NARRATED_BIBLE,
            result: result,
            list: narratedBibleList,
            catalogs: this.catalogList,
            totalItems: result.typeContents.length > 0 ? result.typeContents.find((f) => { return f.name === 'narrated-bible' }).count : result.result.totalRows,
            isShowAllElements: pageInfo.showAll,
            pageInfo: pageInfo
          });
        }


        const programsList = result.result.list.filter((f) => {
          return f.type === 'program';
        });
        if (programsList.length > 0) {
          this.searchResult.push({
            title: 'PROGRAMAS',
            search: this.searchText,
            typeFilter: TypeResultSearch.PROGRAM,
            result: result,
            list: programsList,
            catalogs: this.catalogList,
            totalItems: result.typeContents.length > 0 ? result.typeContents.find((f) => { return f.id === 'program' }).count : result.result.totalRows,
            isShowAllElements: pageInfo.showAll,
            pageInfo: pageInfo
          });
        }


        const videoList = result.result.list.filter((f) => {
          return f.type === 'video';
        });
        if (videoList.length > 0) {
          this.searchResult.push({
            search: this.searchText,
            title: 'VIDEOS',
            typeFilter: TypeResultSearch.VIDEO,
            result: result,
            list: videoList,
            catalogs: this.catalogList,
            totalItems: result.typeContents.length > 0 ? result.typeContents.find((f) => { return f.id === 'video' }).count : result.result.totalRows,
            isShowAllElements: pageInfo.showAll,
            pageInfo: pageInfo
          });
        }


        const articleList = result.result.list.filter((f) => {
          return f.type === 'article';
        });
        if (articleList.length > 0) {
          this.searchResult.push({
            search: this.searchText,
            title: 'ARTÍCULOS',
            typeFilter: TypeResultSearch.ARTICLE,
            result: result,
            list: articleList,
            catalogs: this.catalogList,
            totalItems: result.typeContents.length > 0 ? result.typeContents.find((f) => { return f.id === 'article' }).count : result.result.totalRows,
            isShowAllElements: pageInfo.showAll,
            pageInfo: pageInfo
          });
        }

        const podcastList = result.result.list.filter((f) => {
          return f.type === 'podcast';
        });
        if (podcastList.length > 0) {
          this.searchResult.push({
            search: this.searchText,
            title: 'PODCASTS',
            typeFilter: TypeResultSearch.PODCAST,
            result: result,
            list: podcastList,
            catalogs: this.catalogList,
            totalItems: result.typeContents.length > 0 ? result.typeContents.find((f) => { return f.id === 'podcast' }).count : result.result.totalRows,
            isShowAllElements: pageInfo.showAll,
            pageInfo: pageInfo
          });
        }

        const audioBookList = result.result.list.filter((f) => {
          return f.type === 'audiobook';
        });
        if (audioBookList.length > 0) {
          this.searchResult.push({
            search: this.searchText,
            title: 'AUDIOLIBROS',
            typeFilter: TypeResultSearch.AUDIOBOOK,
            result: result,
            list: audioBookList,
            catalogs: this.catalogList,
            totalItems: result.typeContents.length > 0 ? result.typeContents.find((f) => { return f.id === 'audiobook' }).count : result.result.totalRows,
            isShowAllElements: pageInfo.showAll,
            pageInfo: pageInfo
          });
        }

        const eBookList = result.result.list.filter((f) => {
          return f.type === 'ebook';
        });
        if (eBookList.length > 0) {
          this.searchResult.push({
            search: this.searchText,
            title: ' LIBROS DIGITALES',
            typeFilter: TypeResultSearch.EBOOK,
            result: result,
            list: eBookList,
            catalogs: this.catalogList,
            totalItems: result.typeContents.length > 0 ? result.typeContents.find((f) => { return f.id === 'ebook' }).count : result.result.totalRows,
            isShowAllElements: pageInfo.showAll,
            pageInfo: pageInfo
          });
        }

        this.advanceSearchService.searching = false;
        if (!this.featuredSearchEnable)
          this.displaySearchResults = true;

      }, () => this.loadingOverlayService.hideLoadingOverlay());

    } else {
      this.notFound = true;
    }
  }

  setupFilters(result: any) {
    this.totalItems = result.result.totalRows;
    this.notFound = result.result.list.length === 0;
    // this.full = true;

    if (this.notFound) {
      this.isShowFilter = false;
      this.advanceSearchService.changeOpenFilter(this.isShowFilter);
    }

    this.filters = [];

    this.addFilterDateIssue(true);

    if (result.hosts.length > 0) {
      this.personList = result.hosts;
      this.addFilterPersons(this.personList.length > 0 && this.personId === null);
    }

    // Retirar catalogos
    // if (result.catalogs.length > 0) {
    //   this.catalogList = result.catalogs;
    //   this.addFilterCatalogs(this.catalogList.length > 0 && this.catalogId === null);
    // }

    if (result.organizations.length > 0) {
      this.ministryList = result.organizations;
      this.addFilterMinisteries(this.ministryIds === null && this.ministryList.length > 0);
    }

    if (result.languages.length > 0) {
      this.languageOrig = result.languages;
      this.addFilterLanguage(this.languageId === null && this.languageOrig.length > 0);
    }

    if (result.countries.length > 0) {
      this.countryOrig = result.countries;
      this.addFilterCountry(this.countryId === null && this.countryOrig.length > 0);
    }

    if (result.maxPurchasePrice != null) {
      const maxValue = result.maxPurchasePrice;
      if (this.maxValuePrice == 0 || maxValue > this.maxValuePrice) {
        this.maxValuePrice = maxValue;
      }
      this.addFilterPricing(true);
    }

    if (result.typeContents.length > 0) {
      this.contentTypeList = result.typeContents.filter(item => item.name !== 'narrated-bible')
        .map(item => {
          if (item.name === 'ebook') {
            item.id = item.name;
            item.name = '';
            item.fullName = 'Libros Digitales'
            item.icon = 'iconlace-ebook';
          }
          if (item.name === 'audiobook') {
            item.id = item.name;
            item.name = '';
            item.fullName = 'Audiolibros'
            item.icon = 'iconlace-audiobook';
          }
          if (item.name === 'program') {
            item.id = item.name;
            item.name = '';
            item.fullName = 'Programas'
            item.icon = 'iconlace-program';
          }
          if (item.name === 'video') {
            item.id = item.name;
            item.name = '';
            item.fullName = 'Video'
            item.icon = 'iconlace-video';
          }
          if (item.name === 'podcast') {
            item.id = item.name;
            item.name = '';
            item.fullName = 'Podcasts'
            item.icon = 'iconlace-podcast';
          }
          if (item.name === 'article') {
            item.id = item.name;
            item.name = '';
            item.fullName = 'Articulos'
            item.icon = 'iconlace-article';
          }
          return item;
        });
      this.addFilterContentType(this.contentTypeList.length > 0 && this.contentTypeId === null);
    }

    if (result.recents.length > 0) {
      this.isRecentSelected = null;
      this.recentList = result.recents.filter(r => r.name == 'isRecent');
      this.addFilterRecents(this.recentList.length > 0);
    }

    if (result.topics.length > 0) {
      this.topicList = result.topics;
      this.topicList.forEach(element => {
        element.icon = 'iconlace-circle-status';
      });
      this.addFilterTopic(this.topicId === null && this.topicList.length > 0);
    }

    if (result.bookCategories.length > 0) {
      this.bookCategoryList = result.bookCategories;
      this.bookCategoryList.forEach(element => {
        element.icon = 'iconlace-circle-status';
      });
      this.addFilterBookCategory(this.bookCategoryId === null && this.bookCategoryList.length > 0);
    }

    if (result.rating.length > 0) {
      this.selectedRating = null;
      this.ratingList = this.getRatings(result.rating);
      this.addFilterRating(this.ratingList.length > 0);
    }
  }

  getRatings(ratingList: any) {
    const ratings = [
      { stars: 0, bookCount: 0 },
      { stars: 1, bookCount: 0 },
      { stars: 2, bookCount: 0 },
      { stars: 3, bookCount: 0 },
      { stars: 4, bookCount: 0 },
      { stars: 5, bookCount: 0 }
    ];
    if (ratingList != null && ratingList != null && ratingList.length > 0) {
      ratingList.forEach(rating => {
        const existingRating = ratings.filter(x => x.stars == rating.id)[0];
        if (existingRating != null)
          existingRating.bookCount += rating.count;
      });
    }
    return ratings.sort((a, b) => b.stars - a.stars);
  }

  activeLoadingPaging(event: boolean): void {
    this.loadingPaging = event;
  }

  toggleFilter(event: boolean): void {
    this.isActive = !this.isActive;
  }

  viewMoreBible() {
    this.router.navigate([this.searchBibleUrl]);
  }

  moreOrganizationResults(event: any) {
    const searchOrganizationUrl = `/organizations`;
    this.router.navigate([searchOrganizationUrl], { queryParams: { q: this.utilsService.getValueParamFromUrl('q') } });
  }

  morePersonResults(event: any) {
    const searchPersonUrl = `/people`;
    this.router.navigate([searchPersonUrl], { queryParams: { q: this.utilsService.getValueParamFromUrl('q') } });
  }

  pushFilterTags(event: any[]): void {
    (event.length === 0) ? this.listFilters = [] : this.listFilters = [...event];
    const filter = this.listFilters.find(item => item.filter.type === 'date-issue') || null;
    if (filter) {
      this.currentIssueDate = {
        name: filter.filter.name,
        value: filter.filter.value,
      }
    }
  }

  removeFilter(event: any): void {
    if (event.filter.type === 'date-issue') {
      this.currentIssueDate = null;
      if (this.notFound && this.listFilters.length == 1) {
        this.filtersSelected = [];
        this.changeFilter(null);
        this.listFilters = [];
      } else {
        this.listFilters = this.listFilters.filter(x => x != event);
        this.filterComponentRef.removeFilter(event);
        let changeFilter = new ChangeFilter();
        changeFilter.type = 'collection';
        changeFilter.value = this.filtersSelected;
        this.changeFilter(changeFilter);
      }
    } else {
      this.listFilters = this.listFilters.filter(x => x != event);
      this.filterComponentRef.removeFilter(event);
    }
  }

  clearFilters(): void {
    this.listFilters = [];
    this.filterComponentRef.clearFilters();
    this.currentIssueDate = null;
  }

}
