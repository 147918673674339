import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BreadcrumbV2Component } from '@components/breadcrumb-v2/breadcrumb-v2.component';

@NgModule({
  imports: [CommonModule],
  exports: [BreadcrumbV2Component],
  declarations: [BreadcrumbV2Component],
})
export class BreadcrumbV2Module {}
