<div class="modal-header">
  <button type="button" class="close close-modal" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="main-content">
  <app-donation-form
    [style]="styleFormSubscription"
    [showForms]="false"
    [fromModal]="true"
    [organizationName]="donationParametes?.organizationName"
    [userCurrency]="userCurrency"
    [showEmail]="!(isUserLoggedIn)"
    (donationHeaderForm)="setDonationHeader($event)">
 </app-donation-form>
 <div class="modal-cards">
    <app-user-card-rows
      [userCards]="userCards"
      [cardSeleted]="cardSeleted"
      [classType]="'donation'"
      (onCardSeleted)="selectCard($event)">
    </app-user-card-rows>
  </div>
  <div class="donation-button">
    <button class="btn btn-primary btn-form" [disabled]="(isLoading$ | async) || this.allCardExpireds || !cardSeleted" (click)="submitForms()">
      DONAR
      <i class="fa fa-spinner fa-spin" *ngIf="isLoading$ | async"></i>
    </button>
  </div>
  <div class="help-text" (click)="goToLandingDonation()">
    Usar otro medio de pago
  </div>
</div>

<div class="overlay"></div>
