import {finalize} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

import { HttpLoadingService } from '@utils/http-loading.service';

@Injectable({
  providedIn: 'root',
})
export class HttpLoadingInterceptorService {

  private countRequest = 0;
  private ignoreUrl = [
    '.json',
    '/v2/UserSearch/GetUserSearches',
    'v2/country',
    '/listActivePlans',
    'data.fixer.io'
  ];

  constructor(private httpLoadingService: HttpLoadingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.canIgnoreUrl(req)) return next.handle(req);

    if (!this.countRequest) this.httpLoadingService.show();
    this.countRequest++;

    return next.handle(req).pipe(finalize(() => {
      this.countRequest--;
      if (!this.countRequest) this.httpLoadingService.hide();
    }));

  }

  private canIgnoreUrl(request: HttpRequest<any>): boolean {
    return this.ignoreUrl.some(element => request.url.includes(element));
  }
}
