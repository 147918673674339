import {Component, Input, OnInit} from '@angular/core';
import {MyMonitoringService} from '../../../shared/services/lib/monitoring.service';
import * as copy from 'copy-to-clipboard';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {
  @Input() contentId: string;
  @Input() description: string;
  @Input() title: string;
  @Input() color: string = null;
  @Input() showElement: boolean = true;
  @Input() urlPath: string;
  url: string;
  showShare: boolean;
  private lastInserted: number[] = [];

  constructor(private myMonitoringService: MyMonitoringService, private toastr: ToastrService) {
  }

  ngOnInit() {
    this.url = this.urlPath ? window.location.origin + this.urlPath : window.location.href;
  }

  showItem() {
    if (!this.showElement) {
      const style = {
        'display': 'none'
      };
      return style;
    }
  }

  trackShare(SocialAppId: string) {
    this.myMonitoringService.logEvent({name: 'Share',
    properties: {'VideoId': this.contentId, 'SocialEventTypeId': 2, 'SocialAppId': SocialAppId}});
  }

  copyToClipboard() {
    this.url = this.urlPath ? window.location.origin + this.urlPath : window.location.href;
    copy(this.url);
    this.toastr.clear();
    const inserted = this.toastr.show('', '¡Link copiado!');
    if (inserted) {
      this.lastInserted.push(inserted.toastId);
    }
  }
}
