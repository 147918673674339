import { ElementRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DLOCAL_ERROR_MESSAGES } from '@constants/message-dlocal-errors.constant';
import {
  ErrorInputDLocal,
  HandleDLocalErrors,
  StatusDLocalFields,
} from '@interfaces/i-dlocal-fields.interface';

export interface DLocalInterface {
  initField(options: Object, input: ElementRef, builder: any): void;
  setError(error: StatusDLocalFields): void;
  getField(): any;
  getError(): HandleDLocalErrors;
  getFieldValid(): boolean;
  onSubmit(): void;
  clearField(): void;
  error$(): Observable<HandleDLocalErrors>;
}

export class DlocalClass implements DLocalInterface {

  private isEmpty: boolean;
  private error: ErrorInputDLocal;
  private type: string;
  private field: any;
  private builder: any;
  private errorSubject$: BehaviorSubject<HandleDLocalErrors> =
    new BehaviorSubject({} as HandleDLocalErrors);

  constructor(type: string) {
    this.isEmpty = true;
    this.error = {} as ErrorInputDLocal;
    this.type = type;
  }

  public error$(): Observable<HandleDLocalErrors> {
    return this.errorSubject$.asObservable();
  }

  public setError(status: StatusDLocalFields): void {
    this.isEmpty = status.isEmpty;
    this.error = status.error;
    this.onSubmit();
  }

  public getError(): HandleDLocalErrors {
    return {
      isError: this.isEmpty || !!this.error,
      message:
        !!this.error && !!this.error.message
          ? this.error.message
          : DLOCAL_ERROR_MESSAGES[this.type],
    };
  }

  public getFieldValid(): boolean {
    return !this.isEmpty && !this.error;
  }

  public getField(): any {
    return this.field;
  }

  public initField(options: Object, input: ElementRef, builder: any): void {
    this.builder = builder;
    this.field = this.createField(this.type, options);
    this.field.mount(input.nativeElement);
    this.activeFieldsEvent();
  }

  public onSubmit(): void {
     this.nextErrors(this.getError());
  }

  public clearField(): void {
    this.nextErrors({} as HandleDLocalErrors);
  }

  private nextErrors({isError, message}: HandleDLocalErrors): void {
    this.errorSubject$.next({ isError, message });
  }

  private createField(type: string, options: Object): any {
    return this.builder.create(type, { ...options });
  }

  private activeFieldsEvent(): void {
    this.onBlur();
    this.onChange();
  }

  private onBlur(): void {
    this.field.on('blur', ({ empty, error }) => {
      this.setError({ isEmpty: empty, error });
      this.onSubmit();
    });
  }

  private onChange(): void {
    this.field.on('change', ({ empty, error }) => {
      this.setError({ isEmpty: empty, error });
      this.onSubmit();
    });
  }

}
