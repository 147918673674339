<li class="col-xs-6 col-sm-4 col-md-4 col-lg-3 person-item">
  <div class="person-item-image">
    <img src="{{imageCoverUrl || 'assets/img/no-image.webp'}}" src-fallback="/assets/img/no-image.webp" alt="">
  </div>
  <div class="person-item-overlay">
    <a class="person-item-preview" [routerLink]="['/', type, personId]">
      <div class="person-item-preview-container">
        <div class="person-item-preview-container-content">
          <p><strong>{{titlelbl}}</strong></p>
        </div>
      </div>
    </a>
  </div>
</li>
