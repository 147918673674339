import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export enum OrderType {
  ALPHA = 0,
  DATE = 1
}

export enum OrderDirection {
  DESC = 0,
  ASC = 1
}

export class Order {
  type: OrderType;
  direction: OrderDirection;
}
@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  @Input() typeOrder: number = OrderType.ALPHA;

  order: Order;

  currentOrderDirection = {
    [OrderType.ALPHA]: OrderDirection.ASC,
    [OrderType.DATE]: OrderDirection.DESC
  };

  @Output()
  orderChanged: EventEmitter<Order> = new EventEmitter<Order>();

  isFirstTime = true;

  constructor() { }

  ngOnInit() {
    this.order = <Order> {
      direction: OrderDirection.DESC,
      type: this.typeOrder
    }
  }

  changeOrder(type: OrderType) {

    // Etnra aquí cuando el tipo es el mismo, es decir, cuando se va a cambiar la dirección pero no el tipo.
    if (type === this.order.type) {
      // Actualiza la dirección (ASC/DESC) solamente del tipo actual
      if (this.currentOrderDirection[type] === OrderDirection.DESC) {
        this.order.direction = OrderDirection.ASC;
      } else {
        this.order.direction = OrderDirection.DESC;
      }

      // Guarda en memoria la dirección de cada tipo.
      if (type === OrderType.ALPHA) {
        this.currentOrderDirection[OrderType.ALPHA] = this.order.direction;
      } else {
        this.currentOrderDirection[OrderType.DATE] = this.order.direction;
      }
    } else {
      // Cuando se alterna de tipo y es tipo alfabético; por defecto que sea Ascendente
      // Se valida también con la propiedad isFirstTime para que lo tenga en cuenta solo la primera vez;
      // después de esa vez sigue guardando el orden que se guardó en currentOrderDirection.
      if (type === OrderType.ALPHA && this.isFirstTime) {
        this.order.direction = OrderDirection.ASC;
        this.isFirstTime = false;
      }
    }

    // Actualiza tipo y dirección según el tipo actual.
    this.order.type = type;
    this.order.direction = this.currentOrderDirection[type];

    this.orderChanged.emit(this.order);
  }

}


