<div *ngIf="loadingOverlay$ | async" id="loading" style="z-index: 9999999">
  <div class="loader">
    <ng-lottie
      width="120px"
      height="120px"
      [options]="configLottie"
    >
    </ng-lottie>
  </div>
</div>
