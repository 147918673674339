import {Component,OnInit,Input} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LocalStorage} from 'ngx-webstorage';
import {ToastrService} from 'ngx-toastr';

import { GenericContentFollow, GenericContentFollowItem, GenericContentFollowTemplate } from '@interfaces/i-follow';
import {IContentSocialInfoViewModel} from '@interfaces/i-content-social-info-view-model';
import {LoginModalComponent} from '@layout/components/login-modal/login-modal.component';
import {IFollowUnfollowViewModel} from '@interfaces/i-follow-unfollow-view-model';
import {IFollowViewModel} from '@interfaces/i-follow-view-model';
import {IUser} from '@interfaces/i-user.interface';


import { ContentV3Service } from '@shared/services/content-v3.service';
import { FollowV3Service } from '@shared/services/follow-v3.service';
declare const Android: any;

@Component({
  selector: 'app-follow',
  templateUrl: './follow.component.html',
  styleUrls: ['./follow.component.scss']
})
export class FollowComponent implements OnInit {

  @Input() show: boolean = true;
  @Input() fromMobile: boolean = false;
  @Input() userId: string;
  @Input() onlyIcon: boolean;
  @Input() contentType: string;
  @Input() socialInfo: IContentSocialInfoViewModel;
  @Input() isReader: boolean = false;
  @Input() preloading: boolean;
  @Input() type: string;
  @Input() color: string;
  @Input() toolTip: string;
  @Input() contentFollowTypeToolTip: string;
  @Input()
  set contentFollowList(value: GenericContentFollow) {
    if (value) {
      this._contentFollowList = value;
      this.checkFollows();
    }
  }

  @LocalStorage('user')
  private _user: IUser;
  private _contentFollowList: GenericContentFollow;

  public showFollows: boolean = false;
  public isGuest: boolean = true;
  public inModal = false;

  constructor(
    private contentService: ContentV3Service,
    private modalService: NgbModal,
    private followService: FollowV3Service,
    private toastr: ToastrService,
    ) {
  }

  ngOnInit() {
    if (this.type == null) {
      this.type = ''
    }
    if (this._user || this.fromMobile) {
      this.isGuest = false;
    }
  }

  get contentFollowList(): GenericContentFollow {
    return this._contentFollowList;
  }

  get hasContentFollowList(): boolean {
    return (!!this.contentFollowList &&
    !!this.contentFollowList.template &&
    this.contentFollowList.template.length > 0);
  }

  // Los dos siguientes métodos son necesarios para la interacción de mostrar/ocultar modal automáticamente. BUG: #17753
  onLeave() {
    setTimeout(() => {
      if (!this.inModal) {
        this.showFollows = false;
      }
    }, 250);
  }

  onLeaveModal() {
    this.showFollows = false;
    this.inModal = false;
  }

  public followUnFollow(): void {
      if (this._user || this.fromMobile) {
        this.socialInfo.isFollow = !this.socialInfo.isFollow;
        let contentFollowId = this.socialInfo.contentFollowId;
        if (!contentFollowId) {
          contentFollowId = this.socialInfo.contentId;
        }
        this.contentService.followUnFollow(contentFollowId).subscribe();
  
      } else {
        const modalRef = this.modalService.open(LoginModalComponent, {windowClass: 'dark-modal modal-outlogin', backdropClass: 'light-black-backdrop'});
        modalRef.componentInstance.customMessageTag = 'need-to-login';
      }
    }

  followUnFollowContent(element: GenericContentFollowItem, template: GenericContentFollowTemplate) {
    if (this.fromMobile && !this.userId && (typeof Android !== 'undefined')) {
      Android.CallPreloginClip();
      return;
    }
    element.isFollowed = !element.isFollowed;
    const followUnFollow: IFollowUnfollowViewModel = new class implements IFollowUnfollowViewModel {
      id: string;
      isFollow: boolean;
      type: string;
    };
    followUnFollow.id = element.id;
    followUnFollow.type = template.type;
    followUnFollow.isFollow = element.isFollowed;

    this.followService.followUnFollow(followUnFollow, this.userId).subscribe((result: boolean) => {
      if (!result) {
        element.isFollowed = false;
      } else {
        this.toastr.clear();
        if (element.isFollowed) this.toastr.show('', '¡Siguiendo!');
      }
    });
  }

  checkFollows() {
    const IFvm: IFollowViewModel = new class implements IFollowViewModel {
      resourceIds: string[];
    };
    IFvm.resourceIds = this.getFollowIds();
    if (IFvm.resourceIds.length) {
      this.followService.checkManyFollows(IFvm, this.userId).subscribe((result: IFollowViewModel) => {
        result.resourceIds.forEach((id: string) => {
          this.checkIfIsFollowed(id);
        });
      });
    }
  }

  getFollowIds(): string[] {
    const ids: string[] = [];
    if(this.contentFollowList && this.contentFollowList.template) {
      this.contentFollowList.template.forEach(template => {
        template.list.forEach(item => {
          ids.push(item.id);
        })
      });
    }
    return ids;
  }

  checkIfIsFollowed(id: string) {
    if(this.contentFollowList && this.contentFollowList.template) {
      this.contentFollowList.template.forEach(template => {
        template.list.forEach(item => {
          if (item.id.toLowerCase() === id.toLowerCase()) {
            item.isFollowed = true;
          }
        });
      })
    }
  }

  isFollowing(): boolean {
    if(this.contentFollowList && this.contentFollowList.template) {
      return this.contentFollowList.template.some(template => {
        return template.list.some(item => {
          return item.isFollowed === true;
        });
      })
    }
    return false;
  }

  toggleShowFollows(): void {
    if (this.fromMobile && (typeof Android !== 'undefined')) {
      Android.CallPreloginClip('toggle-follow');
    }
    else {
      this.showFollows = !this.showFollows;
    }
  }
}
