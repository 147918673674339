import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { MobileService } from '@utils/mobile.service';
import { SaveCard } from '@interfaces/i-type-card.interface';
import { Country } from '@interfaces/i-country';

import { GatewayTypes } from '@enums/type-gateways-card.enum';

@Component({
  selector: 'app-subscription-body',
  templateUrl: './subscription-body.component.html',
  styleUrls: ['./subscription-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionBodyComponent {

  @Input() countries: Country[];
  @Input() style: string;
  @Input() showEmail: boolean;
  @Input() enableDLocal: boolean = false;
  @Input() enablePaypal: boolean = false;
  @Input() isHidden = false;
  @Input() set gateWay(value: GatewayTypes) {
    if (!!value) this.handleGateway(value);
  }

  @Output() onSubmitCard: EventEmitter<SaveCard> = new EventEmitter();
  @Output() onGateway: EventEmitter<string> = new EventEmitter();

  public gatewayTypes = GatewayTypes;
  public selectedGateway: GatewayTypes = GatewayTypes.NewInternationalCreditCard;

  constructor(private mobileService: MobileService,) { }

  get isInternationalOption(): boolean {
    return this.selectedGateway === GatewayTypes.NewInternationalCreditCard;
  }

  get isDlocalOption(): boolean {
    return this.selectedGateway === GatewayTypes.NewLocalCreditCard;
  }

  get isPaypalOption(): boolean {
    return this.selectedGateway === GatewayTypes.PayPal;
  }

  get isMobile$(): Observable<boolean> {
    return this.mobileService.isMobileDevice$;
  }

  public handleGateway(gateway: GatewayTypes): void {
    this.selectedGateway = gateway;
    if (this.gatewayTypes.NewInternationalCreditCard === gateway)
      this.onGateway.emit(this.gatewayTypes.CreditCard);
    if (this.gatewayTypes.PayPal === gateway)
      this.handlePaypal();
  }

  public submitCard(event: SaveCard): void {
    this.onSubmitCard.emit(event);
  }

  public handlePaypal(): void {
    this.onGateway.emit(this.gatewayTypes.PayPal);
    this.isMobile$
    .subscribe(isMobile => isMobile && this.goTop())
    .unsubscribe();
  }

  goTop(): void {
    if(typeof window !== 'undefined')
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

}
