import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ContentDetail, ContentPpv } from '@app/core/models/interfaces/content-detail';

@Component({
  selector: 'app-header-video',
  templateUrl: './header-video.component.html',
  styleUrls: ['./header-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderVideoComponent {

  @Input() contentDetail: ContentDetail;
  @Input() contentType: string = 'video';
  @Input() onlyInfo: boolean;

  @Output() rent: EventEmitter<void> = new EventEmitter();
  @Output() purcharse: EventEmitter<void> = new EventEmitter();
  @Output() redirectToContent: EventEmitter<void> = new EventEmitter();

  get contentPPV(): ContentPpv {
    return this.contentDetail?.contentPpv;
  }

}
