import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentDetail, ContentPpv } from '@app/core/models/interfaces/content-detail';
import { FeatureFlagV3Service } from '@app/shared/services/feature-flags-v3.service';
import { environment } from '@environment/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {

  @Input() contentDetail: ContentDetail;
  @Input() contentType: string;
  @Input() action: string;
  @Input() fromGiftCode: boolean = false;
  @Input() fromMobile: boolean = false;

  buttonStyle: string;
  buttonIcon: string;
  buttonText: string;
  text: string;

  propertyHero = {
    video: {
      buttonBackground: 'linear-gradient(90deg, #6C00C0 0%, #F53D55 100%)',
      iconClass: 'iconlace-watch-lineal',
      buttonName: 'Si, reproducir',
      text: 'ver'
    },
    program: {
      buttonBackground: 'linear-gradient(90deg, #6C00C0 0%, #F53D55 100%)',
      iconClass: 'iconlace-watch-lineal',
      buttonName: 'Si, reproducir',
      text: 'ver'
    },
    ebook: {
      buttonBackground: 'linear-gradient(90deg, #6C00C0 0%, #F9B000 100%)',
      iconClass: 'iconlace-read-lineal',
      buttonName: 'Si, iniciar lectura',
      text: 'leer'
    }
  }

  constructor(
    private featureFlagsService: FeatureFlagV3Service,
    private activeModal: NgbActiveModal,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.setButtonProperties();
  }

  get contentPPV(): ContentPpv {
    return this.contentDetail?.contentPpv;
  }

  get title(): string {
    return this.action.toLocaleLowerCase() === 'purchase' ? 'comprado' : 'alquilado'
  }

  get isRent(): boolean {
    return this.action.toLocaleLowerCase() === 'rent';
  }

  get isEnableNewReader(): boolean{
    return this.featureFlagsService.checkFeatureOn('EnableNewReader');
  }

  setButtonProperties(): void {
    if (this.contentType && this.propertyHero[this.contentType]) {
      const { buttonBackground, iconClass, buttonName, text } = this.propertyHero[this.contentType];
      this.buttonStyle = buttonBackground;
      this.buttonIcon = iconClass;
      this.buttonText = buttonName;
      this.text = text;
    }
  }

  actionYes() {
    if (this.fromMobile) {
      this.backToApp();
      return;
    }

    switch (this.contentType) {
      case 'program':
        this.redirectToProgram();
        break;
      case 'ebook':
        this.navigateToReader();
        break;
      default:
        this.navigate(this.contentType, this.contentDetail.id);
    }

    this.closeModal();
  }

  redirectToProgram(): void {
    const lastSeenEpisode = this.contentDetail.lastSeenEpisode;
    const episodes = this.contentDetail.seasonEpisodes.items;
    if (lastSeenEpisode) {
      this.navigate('video', lastSeenEpisode.videoId);
    } else if (episodes.length > 0) {
      this.navigate('video', episodes[0].videoId);
    }
  }

  navigateToReader(): void {
    this.navigate(this.isEnableNewReader ? 'read' : this.contentType, this.contentDetail.id);
  }

  navigate(contentType: string, contentId: string): void {
    this.router.navigate(["/", contentType, contentId]);
  }

  actionNo() {
    this.fromMobile ? this.returnContentPPV() : this.goHome();
    this.closeModal();
  }

  returnContentPPV(): void {
    window.location.href = `${environment.mobileBaseUrl}/payment/goback`;
  }

  goHome() {
    this.router.navigate(['/']);
  }

  backToApp() {
    window.location.href = environment.mobileBaseUrl + '/payment/approved';
  }

  goToMyContent() {
    this.fromMobile
      ? this.returnMyContentPPV()
      :  this.router.navigate(['/user/mycontent']);
    this.closeModal();
  }

  returnMyContentPPV(): void {
    window.location.href = `${environment.mobileBaseUrl}/user/mycontent`;
  }

  closeModal(): void {
    this.activeModal.close();
  }
}
