import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  public logoutUser$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }
  
  get isLogoutUser$(): Observable<boolean> {
    return this.logoutUser$.asObservable();
  }

  public change(event: boolean): void {
    this.logoutUser$.next(event);
  }
}
