<div [class.container-mobile]="isMobileUrlWebview">
  <app-header-search-advanced [isMobile]="isMobile$ | async" [isMobileWebView]="isMobileUrlWebview" (emitToggleFilter)="toggleFilter($event)">
  </app-header-search-advanced>
  <div class="container-filter-tags">
    <app-filter-tags [filterTags]="listFilters" (emitRemoveFilter)="removeFilter($event)"
      (emitClearFilters)="clearFilters()"></app-filter-tags>
  </div>
  <div class="container-fluid" [class.pl-0]="!notFound">
    <div class="row" *ngIf="!notFound && searchResult.length">

      <div class="col-lg-12 col-md-12 body-search">

        <div class="filters-content" *ngIf="!(isMobile$ | async)">
          <app-filters #appFilter [hidden]="false" [filters]="filters" [isNewSection]="true"
            [filtersSelected]="filtersSelected" [currentFilterIssueDate]="currentIssueDate"
            (changeFilter)="changeFilter($event)" (listFilters)="pushFilterTags($event)">
          </app-filters>
        </div>

        <div class="filters-content isMobile" [class.active]="isActive" *ngIf="(isMobile$ | async)">
          <app-filters #appFilter [hidden]="false" [filters]="filters" [isNewSection]="true"
            [isMobile]="isMobile$ | async" [filtersSelected]="filtersSelected"
            [currentFilterIssueDate]="currentIssueDate" (changeFilter)="changeFilter($event)"
            (closeFilter)="isActive = false" (listFilters)="pushFilterTags($event)">
          </app-filters>
        </div>

        <div class="results-content" [class.pt-65]="listFilters.length > 0"
          [ngStyle]="{'height': searchResult.length < 2 && searchResult[0].list.length < 5 ? '100vh' : 'auto'}">
          <!-- Nueva sección de destacados -->
          <app-featured-search (viewLoaded)="displaySearchResults=true" *ngIf="featuredSearchEnable" [searchBibleResults]="searchBibleResults"
            [searchBibleResultsFirstVerse]="searchBibleResultsFirstVerse"
            [searchOrganizationResults]="searchFeaturedContentResult?.organizations"
            [searchPersonResults]="searchFeaturedContentResult?.persons"
            [seeMorePerson]="searchFeaturedContentResult?.totalPersons > 1"
            [seeMoreOrganization]="searchFeaturedContentResult?.totalOrganizations > 1"
            [isMobileWebView]="isMobileUrlWebview"
            (readMoreBible)="viewMoreBible()" (moreOrganizationResults)="moreOrganizationResults($event)"
            (morePersonResults)="morePersonResults($event)">
          </app-featured-search>

          <div class="results-title">
            {{ totalItems }} {{ "search-results" | translate }} para
            <strong>{{ searchText }}</strong>
          </div>

          <div class="results-item" [class.pt-52]="listFilters.length > 0" *ngIf="displaySearchResults">
            <app-content-search *ngFor="let content of searchResult" [searchResult]="content"
              [isMobile]="isMobile$ | async" [currentContentFilter]="currentContentFilter"
              (emitChangeTypeContent)="searchByContent($event)" (emitLoadingPaging)="activeLoadingPaging($event)">
            </app-content-search>
            <app-loading-paging [loadingPaging]="loadingPaging"></app-loading-paging>
          </div>

        </div>

      </div>
    </div>
    <div class="row" *ngIf="notFound">
      <div class="col-lg-12 col-xs-12">
        <div class="not-found">
          No hay resultados para <strong>{{ searchText }}</strong>
        </div>
        <div class="small-text">
          Te sugerimos revisar que esté bien escrito lo que buscas; intentar con
          otra palabra, título de un programa o prédica, o con el nombre de un
          predicador o ministerio.
        </div>
        <!-- AQUI VA SLIDER DE RECOMENDADOS -->
        <!-- <div class="hnav slider-recommended" *ngFor="let catalog of catalogs">
          <app-slider *ngIf="catalog.contentCatalogViewModels.length > 0" [paginated]="false" [catalog]="catalog" [recommended]="catalog.isRecommended" [layout]="1"></app-slider>
        </div> -->
        <!-- FIN -->
      </div>
    </div>
  </div>

</div>
