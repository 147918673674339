import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observer, Observable, Subject } from "rxjs";
import { PrayRequest } from "./models/pray-request";
import { PrayAllowResponse } from "./models/pray-allow-response";
import { PrayResponse } from "./models/pray-response";
import { PrayerCategory } from "./models/prayer-category";
import { PrayerType } from "./models/prayer-type";
import { RestClient } from "./rest-client";
import { RestParams, RestAction, IRestMethod, RestHandler, RestRequestMethod } from "rest-core";
import { EnlaceRestHandler } from "./enlace-rest-handler";
import { AuthenticationService } from "./authentication.service";
import { Router } from '@angular/router';

@Injectable()
@RestParams({
  // IRestParams
  pathPrefix: environment.webApi + 'prayers'
})
export class PrayerService extends RestClient {

  publicRequestsList: PrayRequest[];
  publicRequestsSubject = new Subject<any>();

  constructor(restHandler: EnlaceRestHandler, authService: AuthenticationService, private router: Router) {
    super(restHandler, authService);
  }

  @RestAction({
    path: '/',
    isArray: true,
  })
  list: IRestMethod<any, PrayRequest[]>;

  @RestAction({
    path: '/{!id}'
  })
  getById: IRestMethod<{ id: any }, PrayRequest>;

  @RestAction({
    path: '/IsAllowPrayerRequest/{prayerTypeId}'
  })
  canMakeRequest: IRestMethod<{ prayerTypeId: number}, PrayAllowResponse >;

  @RestAction({
    method: RestRequestMethod.Post
  })
  add: IRestMethod<PrayRequest, PrayRequest>;

  @RestAction({
    path: '/getByTypeId/{!id}'
  })
  getByTypeId: IRestMethod<{ id: number }, PrayRequest[]>;

  @RestAction({
    path: '/GetPrayerCategory'
  })
  getCategories: IRestMethod<any, PrayerCategory[]>;

  @RestAction({
    path: '/GetPrayerTypes'
  })
  getTypes: IRestMethod<any, PrayerType[]>;

  listPublicCache(typeId: number): Observable<PrayRequest[]> {
    return Observable.create(observer => {
      if (this.publicRequestsList) {
        observer.next(this.publicRequestsList);
      } else {
        this.getByTypeId({ id: typeId }).then((result: PrayRequest[]) => {
          this.publicRequestsList = result;
          observer.next(this.publicRequestsList);
        }).catch((e) => {
          console.warn('called .catch on %o arguments %o', this, e);
        });
      }
    });
  }

  listPublic(typeId: number) {
    //TODO: Check why the commented code is not working correctly
    //if (this.publicRequestsList) {
    //  this.publicRequestsSubject.next(this.publicRequestsList);
    //  console.log(this.publicRequestsList.length);
    //} else {
      this.getByTypeId({ id: typeId }).then((result: PrayRequest[]) => {
        this.publicRequestsList = result;
        this.publicRequestsSubject.next(this.publicRequestsList);
      }).catch((e) => {
        console.warn('called .catch on %o arguments %o', this, e);
        // this.router.navigate(['/500']);
      });
    //}
  }

}

