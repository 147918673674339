import { ChangeDetectionStrategy, Component, EventEmitter,
  Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { DropdownSelectList } from '@interfaces/i-dropdown-select.interface';
@Component({
  selector: 'app-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownSelectComponent implements OnInit, OnChanges {

  @Input() form: UntypedFormGroup;
  @Input() isError: boolean = false;
  @Input() title: string;
  @Input() optionList: DropdownSelectList[] = [];
  @Input() additionalClass = '';
  @Input() additionalClassTitle = '';
  @Input() additionalClassIcon = '';
  @Input() cutTextLength: number = 1000;
  @Input() enableClearOption = false;
  @Output() emitOption: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitError: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isOpen: boolean = false;
  public isChosenOption: boolean = false;
  isTouched = false;
  private isAlreadyAssigned = false;
  private _currentValue: DropdownSelectList;
  private _title: string = '';

  get currentValue() {
    return this._currentValue
  }

  @Input()
  set currentValue(value: DropdownSelectList) {
    this._currentValue = value;
    if (this._currentValue) {
      this.isChosenOption = true;
      this.title = this.currentValue.name;
    }
  }

  @Input() set hasClearDropdown(value: boolean) {
    if (value) {
      this.isChosenOption = false;
      this.title = this._title || this.title;
    }
  }
  constructor() { }

  ngOnInit(): void {
   this._title = this.title;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.currentValue && this.currentValue.name && !this.isAlreadyAssigned) {
      this.isAlreadyAssigned = true;
      this.setInitialValue();
    }
  }

  private setInitialValue() {
    if (this.currentValue) {
      this.isChosenOption = true;
      this.title = this.currentValue.name;
    }
  }

  public toggleShowOptions(): void {
    this.isOpen = !this.isOpen
    if (this.isOpen) {
      this.isTouched = true;
    }
  }

  public sendOption(option: DropdownSelectList): void {
    this.title = option.name;
    this.isChosenOption = true;
    this.isOpen = false;
    this.emitOption.emit(option.value);
  }

  clear(): void {
    this.title = this._title;
    this.isChosenOption = false;
    this.emitOption.emit(null);
  }

  public trackByIdx(index: number, item: string): string {
    return `${index}-${item}`;
  }

  public clickOutSide(): void {
    if (this.isOpen) {
      this.isOpen = false;
      if (!this.isChosenOption)
        this.emitOption.emit(undefined);
    }
  }

}
