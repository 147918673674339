import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PrayRequestService {
  private httpOptions: { headers: HttpHeaders };

  constructor(private http: HttpClient) {
  }

  prayRequest(uid: string, peticion: string) {
    const url = `https://enlace-peticionesapi.azurewebsites.net/api/peticiones`;
    const body = {
      EnlacePlusID: uid,
      Peticion: peticion
    };
    return this.http.post(url, body);
  }
}
