import {Injectable} from '@angular/core';
import * as Msal from 'msal';
import {Inject} from '@angular/core';
import {User} from 'msal/lib-commonjs/User';
import {PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {environment} from '@environment/environment';
import {LocalStorage } from 'ngx-webstorage';
import {Router} from '@angular/router';
import {IUser} from '@app/core/models/interfaces/i-user.interface';
import { FeatureFlagV3Service } from '@app/shared/services/feature-flags-v3.service';
import { TokenAuth, UserLoggedIn } from './models/entities/auth.interface';
import { AuthService } from './v3/auth.service';
import { Observable } from 'rxjs';
import { TypePropertyLocalStorage } from '@app/core/models/enums/type-property-localstorage.enum';

@Injectable()
export class AuthenticationService {

  private authority = `https://${environment.b2cDomain}/tfp/${environment.tenant}/${environment.signUpSignInPolicy}`;
  private authorityPR = `https://${environment.b2cDomain}/tfp/${environment.tenant}/${environment.passwordReset}`;

  private redirectUrl = "";
  private clientApplication: Msal.UserAgentApplication;
  private logger = new Msal
    .Logger(this.loggerCallback,
      {level: Msal.LogLevel.Verbose, correlationId: '12345'});


  @LocalStorage('token') // B2C Token
  private _token: string;
  @LocalStorage('enlaceToken')
  private _enlaceToken: TokenAuth;
  @LocalStorage('isUserAwait')
  private isUserAwait: boolean;
  @LocalStorage('user')
  private user: IUser;
  @LocalStorage('errorKey')
  private errorKey: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private featureFlagV3Service: FeatureFlagV3Service,
    private newLoginService: AuthService) {
      // this.newLoginEnable().then((result) => {
      //   if (result !== undefined && !result) {
              if (isPlatformBrowser(this.platformId)) {
                this.errorKey = 'b2cError';
                this.redirectUrl = window.location.pathname;
                this.clientApplication =
                  new Msal.UserAgentApplication(
                    environment.clientID,
                    this.authority,
                    this.authCallback.bind(this),
                    {
                      validateAuthority: false,
                      redirectUri: window.location.origin,
                      logger: this.logger,
                      cacheLocation: 'localStorage'
                    });
              }
      //       }
      // });
  }

  get isNewLoginEnabled(): boolean {
    return this.featureFlagV3Service.checkFeatureOn('NewLoginEnable');
  }

  newLoginEnable(): Promise<boolean> {
    return new Promise ((resolve, reject) => {
      const flag = this.featureFlagV3Service.checkFeatureOn('NewLoginEnable');
      resolve(flag);
    })
  }

  public getRedirectUrl() {
    return this.redirectUrl;
  }

  private loggerCallback(log, log2, log3) {
    // console.log(log, log2, log3);
  }

  get token(): string {
    return this._token;
  }

  public login(): void {
    this.clientApplication.loginRedirect(environment.b2cScopes, 'ui_locales=es');
  }

  public logout(): void {
    this.newLoginEnable().then((result) => {
      if (!result  && result !== undefined) {
      this.clientApplication.logout();
      this._token = null;
      } else {
        this._enlaceToken = null;
        this.user = null;
      }
      this.router.navigate(['/']);
    });
  }

  public refreshTokenFromOldLogin(token: string): Observable<UserLoggedIn> {
    return this.newLoginService.refreshToken(token);
  }

  public isOnline(): boolean {
    if (!this.isNewLoginEnabled  && this.isNewLoginEnabled !== undefined) {
      if (!this.clientApplication) return false;
      const _isOnline = this.clientApplication.getUser() != null;
      if (!_isOnline && this.isNewLoginEnabled !== undefined) {
        this.user = null;
      }
      return _isOnline;
    } else {
      const tokenString = localStorage.getItem(TypePropertyLocalStorage.ENLACE_TOKEN);
      if (tokenString) {
        return true;
      }
      return false;
    }
  }

  public getUser(): User {
    return this.clientApplication.getUser();
  }

  public getAuthenticationToken(): Promise<string> {
    return this.clientApplication.acquireTokenSilent([environment.clientID])
      .then(token => {
        this._token = token;
        return Promise.resolve(token);
      }).catch(error => {
        console.log(error);
        this.clientApplication.loginRedirect(environment.b2cScopes);
        return Promise.resolve(null);
      });
  }

  public userIsOlderThan13 (): boolean {
    if (this.user) {
      return new Date().getFullYear() - (this.user.yearOfBirth ? this.user.yearOfBirth : new Date().getFullYear()) > 13;
    } else {
      return false;
    }
  }

  private authCallback(errorDesc: any, token: any, error: any, tokenType: any) {
    this._token = token;
    if (error) {
      if (errorDesc) {
        if (errorDesc.indexOf('AADB2C90118') > -1) {
          this.clientApplication = new Msal.UserAgentApplication(
            environment.clientID,
            this.authorityPR,
            this.authCallback,
            {
              validateAuthority: false,
              redirectUri: window.location.origin
            });
          this.clientApplication.loginRedirect(environment.b2cScopes, 'ui_locales=es');
        }
      }
    }
  }

}
