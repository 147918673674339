import {Component, EventEmitter, NgZone, OnInit, Output , OnDestroy} from '@angular/core';
import {LocalStorage, LocalStorageService} from 'ngx-webstorage';
import {NgForm, NgModel} from '@angular/forms';
import {environment} from '@environment/environment';

import {IUser} from '@app/core/models/interfaces/i-user.interface';

import {SubscriptionService} from '@api/v2/subscription.service';
import { CountryV3Service } from '@shared/services/country-v3.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { SuscribePlusConfirmationModalComponent } from '@layout/components/suscribe-plus-confirmation-modal/suscribe-plus-confirmation-modal.component';
import { IBillingAgreementRequest } from '@app/core/models/interfaces/i-billing-agreements.interface';
import { Country } from '@app/core/models/interfaces/i-country';
import { UtilsService } from '@app/shared/utils/utils.service';
import { HomeCatalogStateService } from '@app/shared/services/home-catalog-state.service';
import { LoadingOverlayService } from '@app/shared/services/loading-overlay.service';

@Component({
  selector: 'app-suscriptions',
  templateUrl: './suscriptions.component.html',
  styleUrls: ['./suscriptions.component.scss']
})
export class SuscriptionsComponent implements OnInit, OnDestroy {

  @LocalStorage('user')
  user: IUser;

  @LocalStorage('subscriptionFromLanding')
  subscriptionFromLanding: boolean;

  target: string = 'base';
  isVariable: boolean = false;

  visible: boolean = false;
  amount: string = '4.99';
  errorAmount = false;
  planFounder: any;
  fromAmount: string;
  cardName: string;
  error: any;
  stripe: any;
  elements: any;
  card: any;
  complete = false;
  cardNumber: string;
  expiryMonth: string;
  expiryFull: any;
  expiryYear: string;
  cvc: string;
  countries: Country[] = [];
  countrySelected: Country = null;
  phone: string = '';
  internationalCard = {
    address: '',
    state: '',
    city: '',
    firstName: '',
    lastName: ''
  }

  elementsOptions: any = {
    locale: 'es'
  };

  // tslint:disable-next-line: no-output-rename
  @Output('onEnableSuscription')
  onEnableSuscription: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _zone: NgZone,
    public subscriptionService: SubscriptionService,
    private countryService: CountryV3Service,
    private modalService: NgbModal,
    private utils: UtilsService, // This is being used in template
    private router: Router,
    private catalogState: HomeCatalogStateService,
    private loadingOverlayService: LoadingOverlayService) {
  }

  ngOnInit() {
    this.loadingOverlayService.showLoadingOverlay();

    if (this.subscriptionFromLanding) {
      this.selectPlan('founder');
    }

    this.countryService.getAllCountries().subscribe(data => {
      this.countries = data;
    });
    this.subscriptionService.listActivePlans().then((data: any[]) => {
      this.planFounder = data.filter(function (item) {
        return item.id === 'fundador';
      })[0];
      this.amount = (this.planFounder.price / 100).toString();
      this.fromAmount = (this.planFounder.price / 100).toString();
      this.loadingOverlayService.hideLoadingOverlay();
    }, () => {
      this.loadingOverlayService.hideLoadingOverlay();
    });
  }

  ngOnDestroy() {
    this.subscriptionFromLanding = false;
  }

  private setPk() {
    this.stripe = Stripe.setPublishableKey(environment.stripePk);
  }

  selectPlan(target: string): void {
    this.target = target;
  }

  isVariableAmount(): void {
    this.isVariable = !this.isVariable;
    this.amount = (this.planFounder.price / 100).toString();
  }

  validatePlan(form: NgForm) {
    if (!form.valid) {
      this.error = { message: 'Todos los campos son requeridos. Por favor diligenciarlos.' };
      return;
    }
    this.loadingOverlayService.showLoadingOverlay();
    const value = (+this.amount) * 100;
    if (value < this.planFounder.price) {
      this.errorAmount = true;
      this.loadingOverlayService.hideLoadingOverlay();
      return;
    } else {
      this.errorAmount = false;
    }
    const dataObj = {
      number: this.cardNumber,
      exp_month: this.expiryMonth,
      exp_year: this.expiryYear,
      cvc: this.cvc,
      name: this.cardName,
      address_city: 'NA',
      address_country: 'NA'
    };

    const agreementPlanRequest: IBillingAgreementRequest = <IBillingAgreementRequest>{
      amount: value,
      cycles: 0,
      description: '',
      type: '',
      frequencyInterval: 0,
      frequencyType: '',
      organizationId: '',
      videoId: '',
      gateway: '',
      name: this.internationalCard.firstName + ' ' + this.internationalCard.lastName,
      origin: '',
      planId: 'fundador',
      phone: this.phone,
      cardInfo: {
        firstName: this.internationalCard.firstName,
        lastName: this.internationalCard.lastName,
        city: this.internationalCard.city,
        country: this.countrySelected.code,
        state: this.internationalCard.state,
        addressLine1: this.internationalCard.address,
        number: this.cardNumber,
        cVV: this.cvc,
        expiryYear: +this.expiryYear,
        expiryMonth: +this.expiryMonth
      }
    };

    this._zone.run(() => {
      this.subscriptionService.enableSuscriptionFounder(agreementPlanRequest, value, 'fundador').then(() => {
        this.loadingOverlayService.hideLoadingOverlay();
        this.catalogState.clearCatalog();
        this.showSuccessPopup();
      }, () => {
        this.error = { message: 'Parece que hubo un error al validar tus datos. Por favor, verifícalos e inténtalo nuevamente.' };
        this.loadingOverlayService.hideLoadingOverlay();
      });
    }, () => {
      this.error = { message: 'Parece que hubo un error al validar tus datos. Por favor, verifícalos e inténtalo nuevamente.' };
      this.loadingOverlayService.hideLoadingOverlay();
    });
  }

  showSuccessPopup() {
    const modalRef = this.modalService.open(SuscribePlusConfirmationModalComponent, {windowClass: 'dark-modal modal-confirmation-plus-suscribe', backdropClass: 'light-black-backdrop'});
    modalRef.componentInstance.emitFirstButton.subscribe(() => this.router.navigate(['/']));
  }

  showBetaSuccessPopup() {
    swal.fire({
      title: 'Su suscripción ha finalizado | Enlace+',
      text: 'Lamentamos que nos deje. Esperamos que haya disfrutado de nuestros servicios. Recuerde que puede volver cuando guste.',
      icon: 'success',
      confirmButtonText: 'Aceptar'
    }).then(() => {
      this.loadingOverlayService.hideLoadingOverlay();
    });
  }

  enableSuscriptionBeta() {
    this.subscriptionService.enableSuscriptionBeta().then(res => {
      this.catalogState.clearCatalog();
      this.showBetaSuccessPopup();
    });
  }

  testValueCardNumber(value: any) {
    if (value.length === 19 && Stripe.card.validateCardNumber(this.cardNumber)) {
      //this.expiryFullInput.nativeElement.focus();
    }
  }

  // Check only numbers.
  onlyNumbers(val) {

    const allowedKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'End', 'Home', 'Tab'];
    if (allowedKeys.some((key) => key === val.key)) {
      return true;
    }

    return /^[0-9]*$/g.test(val.key);
  }

  testValueExpired(value: string) {
    this.expiryFull=value;

    if (this.expiryFull && this.expiryFull.length === 2) {
      this.expiryFull += '/';
    }
    const dates = value.split('/');
    if (!dates[1]) {
      return;
    }
    this.expiryMonth = dates[0].replace(/\s/g, '');
    this.expiryYear = dates[1].replace(/\s/g, '');
    if (this.expiryYear &&
      (this.expiryMonth.length === 2 && ((+this.expiryMonth) >= 1 || (+this.expiryMonth) <= 12))
      && (this.expiryYear.length === 2 && ((+this.expiryYear) >= 1 || (+this.expiryYear) <= 99))
      && Stripe.card.validateExpiry(this.expiryMonth, this.expiryYear)) {
      // this.cvcInput.nativeElement.focus();
    }
  }

  expiredDateHasError(expiredDate: NgModel) {
    return expiredDate.hasError('pattern');
  }

  formatCurrency() {
    const valor = parseFloat(this.amount).toFixed(2);
    const amount = Math.abs(+valor);
    this.amount = amount.toString();
  }

  getSuscriptionBeta(): void {
    this.subscriptionService.enableSuscriptionBeta().then(resp => {
      this.catalogState.clearCatalog();
    });
  }
}

