import { Pipe, PipeTransform } from '@angular/core';
import { ContentType } from '@enums/type-content.enum';

@Pipe({
  name: 'getClassContentName'
})
export class GetClassContentNamePipe implements PipeTransform {

  private _dictionary = {
    [ContentType.video]: 'play',
    [ContentType.program]: 'play',
    [ContentType.article]: 'read',
    [ContentType.podcast]: 'listen',
    [ContentType.ebook]: 'read',
    [ContentType.audiobook]: 'listen',
    [ContentType.person]: 'new',
    [ContentType.organization]: 'new',
  }

  transform(value: string): string {
    return this._dictionary[value] || '';
  }

}
