<ng-container *ngIf="modalVersion">
  <div class="container-promotion-{{modalVersion | lowercase}}">
    <div class="modal-body">
      <ng-container *ngIf="modalVersion == 'CENTER'">
        <ng-container *ngTemplateOutlet="center"></ng-container>
      </ng-container>
      <ng-container *ngIf="modalVersion == 'TOPRIGHTV1'">
        <ng-container *ngTemplateOutlet="toprightv1"></ng-container>
      </ng-container>
      <ng-container *ngIf="modalVersion == 'BOTTOMRIGHTV1'">
        <ng-container *ngTemplateOutlet="bottomRightv1"></ng-container>
      </ng-container>
      <ng-container *ngIf="modalVersion == 'TOPRIGHTV2'">
        <ng-container *ngTemplateOutlet="toprightv2"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>


<ng-template #center>
  <button type="button" class="close closemodal-promotion-center" (click)="activeModal.dismiss()"
    aria-label="Close"><span aria-hidden="true">&times;</span></button>
  <div class="row body">
    <div class="col-xs-12 hidden-sm hidden-md hidden-lg text-center icon-superior">
      <span class="iconlace iconlace-Digital-missionary">
      </span>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-6 text-center body-text">
      <h2 class="title">{{title | uppercase}}</h2>
      <h2 class="titleUpper">{{titleUpper}}</h2>
      <p class="bodyTextpPartOne">{{bodyTextpPartOneV1}} <span class="strong">{{amountText}}</span>
        {{bodyTextpPartOneV2}}</p>
      <p class="bodyTextpPartTwo">{{bodyTextpPartTwo}}</p>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6  text-center">
      <span class="hidden-xs iconlace iconlace-Digital-missionary">
      </span>
      <div class="form-group center">
        <button routerLinkActive="router-link-active" (click)="navigateTo('center')"
          class="btn btn-subscribe">{{buttonName}}</button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #bottomRightv1>
  <button type="button" class="close closemodal-promotion-bottomRightv1" (click)="activeModal.dismiss()"
    aria-label="Close"><span aria-hidden="true">&times;</span></button>
  <div class="row body">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center icon-superior">
      <span class="iconlace iconlace-Digital-missionary">
      </span>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 text-center body-text">
      <h2 class="title">{{title | uppercase}}</h2>
      <h2 class="titleUpper">{{titleUpper}}</h2>
      <p class="bodyTextpPartOne">{{bodyTextpPartOneV1}} {{amountText}} {{bodyTextpPartOneV2}}</p>
      <p class="bodyTextpPartTwo">{{bodyTextpPartTwo}}</p>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12  text-center">
      <div class="form-group center">
        <button routerLinkActive="router-link-active" (click)="navigateTo('BRv1')"
          class="btn btn-subscribe">{{buttonName}}</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #toprightv1>
  <button type="button" class="close closemodal-promotion-toprightv1" (click)="activeModal.dismiss()"
    aria-label="Close"><span aria-hidden="true">&times;</span></button>
  <div class="row body">
    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 text-center">
      <span class="iconlace iconlace-Digital-missionary">
      </span>
    </div>
    <div class="col-xs-12 col-sm-10 col-md-10 col-lg-10">
      <h2 class="title">{{title | uppercase}}</h2>
      <h2 class="titleUpper">{{titleUpper}}</h2>
      <div class="form-group center">
        <button routerLinkActive="router-link-active" (click)="navigateTo('TRv1')"
          class="btn btn-subscribe">{{buttonName}}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #toprightv2>
  <button type="button" class="close closemodal-promotion-toprightv2" (click)="activeModal.dismiss()"
    aria-label="Close"><span aria-hidden="true">&times;</span></button>
  <div class="row body">
    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 text-center">
      <span class="iconlace iconlace-Digital-missionary">
      </span>
    </div>
    <div class="col-xs-12 col-sm-10 col-md-10 col-lg-10">
      <h2 class="title">{{title | uppercase}} <span class="titleUpper">{{titleUpper}}</span></h2>
      <p class="bodyTextpPartOneTwo">{{bodyTextpPartOneV1}} <span class="strong">{{amountText}}</span>
        {{bodyTextpPartOneV2}}{{bodyTextpPartTwo}}</p>
      <div class="form-group center">
        <button routerLinkActive="router-link-active" (click)="navigateTo('TRv2')"
          class="btn btn-subscribe">{{buttonName}}</button>
      </div>
    </div>
  </div>
</ng-template>
