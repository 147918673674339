import { Pipe, PipeTransform } from '@angular/core';
import { IContentImage } from '@interfaces/i-content-image';

@Pipe({
  name: 'getImageUrlByType'
})
export class GetImageUrlByTypePipe implements PipeTransform {

  transform(contentImages: IContentImage[], allowedImageTypes: string[]): any {
    let imageURL = '';
    if(contentImages != null && contentImages.length > 0) {
      const contentImage = contentImages.filter(x => allowedImageTypes.indexOf(x.image.imageType.name) != -1)[0];
      if (contentImage != null && contentImage.image != null) {
        imageURL = contentImage.image.url;
      }
    } else {
      imageURL = 'assets/img/img-no-available.webp';
    }
    return imageURL;
  }

}
