import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IUser } from '@app/core/models/interfaces/i-user.interface';
import { LogMarketing } from '@app/core/models/interfaces/promotion-alert-track';
import { LocalStorage } from 'ngx-webstorage';
import { environment as ENV } from '@environment/environment';
import { GtmService } from '../services/lib/gtm.service';

@Injectable({
  providedIn: 'root'
})
export class MarketingParamsService {

  @LocalStorage('user') private user: IUser;

  constructor(
    private router: Router,  
    private activatedRoute: ActivatedRoute,
    private gtmService: GtmService
  ) { }

  getQueryParamsMarketing() {
    return this.activatedRoute.snapshot.queryParams;
  }

  getSerializedUrl(url: string): string {
    const params = this.getQueryParamsMarketing();
    if (params) {
      const queryParams = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
      url += `?${queryParams}`;
    }
    const serializedUrl = decodeURIComponent(this.router.serializeUrl(this.router.createUrlTree([url])));
    return serializedUrl;
  }

  sendMarketingTrack(obj = {}): void {
    const campaing = this.activatedRoute.snapshot.queryParams['campaing'];
    const social = this.activatedRoute.snapshot.queryParams['social'];
    if (campaing) {
      this.buildMarketingTrack('campaing', campaing, obj);
    }
    if (social) {
      this.buildMarketingTrack('social', social, obj);
    }
  }


  buildMarketingTrack(eventName: string, value: string, obj): void {
    const logMarketing: LogMarketing = {
      userId : this.user?.id ?? null,
      pageUrl: this.router.routerState.snapshot.url,
      eventName: eventName,
      env: ENV.name,
      event: 'Marketing_Event',
      value: value,
      event_type: 'PPV',
      event_status: '',
      ...obj
    };
    this.gtmService.logMarketing(logMarketing);
  }


}
