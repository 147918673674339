import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DomManipulateService {

  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public setBodyScroll(state: boolean): void {
    if (typeof window === 'undefined') { return; }
    if (state) this.renderer.setStyle(document.body, 'overflow-y', 'auto')
    else this.renderer.setStyle(document.body, 'overflow-y', 'hidden');
  }

  public setBodyClass(state: boolean, typeClass: string): void {
    if (typeof window === 'undefined') { return; }
    if (state) this.renderer.addClass(document.body, typeClass)
    else this.renderer.removeClass(document.body, typeClass);
  }

  public setBackgroundBodyStyle(state: boolean, url?: string): void {
    if (typeof window === 'undefined') { return; }
    if (state) this.renderer.setStyle(document.body, 'background-image', `url(${url})`);
    else this.renderer.removeStyle(document.body, 'background-image');
  }

  public gotoTop() {
    if (typeof window === 'undefined') {
      return;
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
