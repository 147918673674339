import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageV3Service {

  private _locale: string = 'es-ES';

  constructor() { }

  get locale(): string {
    return this._locale;
  }
}
