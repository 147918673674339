import { Component, OnInit } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { environment as ENV } from '@environment/environment';
import { IUser } from '@app/core/models/interfaces/i-user.interface';
import { LoginModalComponent } from 'app/layout/components/login-modal/login-modal.component';
import { SuscribePlusModalComponent } from '@layout/components/suscribe-plus-modal/suscribe-plus-modal.component';
import { FeatureFlagV3Service } from '@app/shared/services/feature-flags-v3.service';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {

  @LocalStorage('user')
  private user: IUser;

  @LocalStorage('subscriptionFromLanding', false)
  private subscriptionFromLanding: boolean;

  subscriptionPrice = 4.99;
  planNameMisionary = 'fundador';
  verse = '"Dad de gracia, lo que de gracia recibiste." Mateo 10:8';

  get isLandingDonationEnabled(): boolean {
    return this.featureFlagService.checkFeatureOn('WebEnableDonations');
  }

  constructor(private modalService: NgbModal,
              private router: Router,
              private featureFlagService: FeatureFlagV3Service,) { }

  ngOnInit() {this.subscribe(false);}

  /** Method that triggers the subscription action. */
  subscribe(fromButton: boolean): any {
    // Set flag to enable subscription from landing page.
    this.subscriptionFromLanding = true;
    // Validation if user is logged in platform.
    if (this.user) {
      // User is already a Plus subscriptor.
      if (this.user.suscriptionPlanId && this.user.suscriptionPlanId.toLocaleUpperCase() === this.planNameMisionary.toLocaleUpperCase()) {
        this.showMessageAlreadyPlus();
      } else {
        ///Solo voy a la pagina de pago si apreta el boton
        if(fromButton)
          return this.router.navigate(['/get-subscription'], { queryParams: { version: 'Landing'} });
      }
    }else {
       ///Al apretar el boton abro la modal de login
      if(fromButton)
        this.showLoginMessage('need-to-login-for-subscription');
    }
  }

  // Method that shows a message with two optionos; it happens when the user is already a 'fundador'.
  showMessageAlreadyPlus() {
    const modalRef = this.modalService.open(SuscribePlusModalComponent, {windowClass: 'dark-modal modal-plus-suscribe', backdropClass: 'light-black-backdrop'});
    modalRef.componentInstance.emitFirstButton.subscribe(() =>
                                                         {
    if (this.user && this.user.id) {
      const urlWithUserId = `${ENV.donationUrl}?uid=${this.user.id}`;
      window.open(urlWithUserId, "_blank");
    } else {
      window.open(ENV.donationUrl, "_blank");
    }
    });
    modalRef.componentInstance.emitSecondButton.subscribe(() => this.router.navigate(['/']));
  }

  // Método que redirecciona a las tiendas dependiendo desde que dispositivo se consulte.
  goToStore() {
    const device = navigator.userAgent.toLowerCase();
    if (device.match(/android/i)) {
      window.open('http://bit.ly/descubreandroid');
    } else if ((device.match(/iphone/i)) || (device.match(/ipad/i))) {
      window.open('http://bit.ly/descubreios');
    } else {
      window.open('https://www.enlace.plus');
    }
  }

  showLoginMessage(message: string) {
    const modalRef = this.modalService.open(LoginModalComponent, {windowClass: 'dark-modal modal-outlogin', backdropClass: 'light-black-backdrop'});
    modalRef.componentInstance.customMessageTag = message;
  }

}
