import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as ENV } from '@environment/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MailingService  {

  private API = `${ENV.webApi}v2/mailing`;

  constructor(private http: HttpClient) {}

  public verifyEmail(email: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.API}/VerifyEmail`, {
      params: { email: email },
    });
  }


}
