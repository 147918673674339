<div class="configuration">
  <div class="configuration-header">
    <h3 class="configuration-header_title">Configuración</h3>
    <span class="configuration-header_close" (click)="modal.dismiss()"><i class="iconlace iconlace-cross"></i></span>
  </div>
  <div class="configuration-information">
    <div class="configuration-information_avatar" style="overflow: hidden">
      <img style="border-radius: 50%" src-fallback="http://laurauinteriordesign.com/wp-content/uploads/2018/03/avatar-placeholder.png" [src]="user.profileImage || '../../../assets/img/avatar.webp'" alt="Profile Avatar">
    </div>
    <p class="configuration-information_name">{{user.userName}}</p>
  </div>
  <div class="configuration-menu">
    <ul class="configuration-menu_list">
      <li (click)="changeTab('profile')" class="configuration-menu_list-item">
        <a class="configuration-menu_list-link" href="javascript:void(0)" [ngClass]="{'active': target === 'profile'}">Perfil de Usuario</a>
      </li>
      <li (click)="changeTab('suscriptions')" class="configuration-menu_list-item">
        <a class="configuration-menu_list-link" href="javascript:void(0)" [ngClass]="{'active': target === 'suscriptions'}">Suscripciones</a>
      </li>
    </ul>
  </div>
  <app-profile *ngIf="target === 'profile'"></app-profile>
  <app-notifications *ngIf="target === 'notifications'"></app-notifications>
  <app-suscriptions (onEnableSuscription)="modal.dismiss()" *ngIf="target === 'suscriptions'"></app-suscriptions>
</div>
