import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-enlace-plus',
  templateUrl: './button-enlace-plus.component.html',
  styleUrls: ['./button-enlace-plus.component.scss']
})
export class ButtonEnlacePlusComponent {

  @Input() className: string = '';
  @Output() actionButton: EventEmitter<Event> = new EventEmitter();

  butttonAction(): void {
    this.actionButton.emit();
  }

}
