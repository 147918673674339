import {
  Component, ElementRef, OnInit,
  ViewChild, Input, EventEmitter,
  Output, ChangeDetectorRef, AfterContentChecked, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import {IUser} from '@core/models/interfaces/i-user.interface';
import {LocalStorage} from 'ngx-webstorage';
import {IPodcastItemViewModel} from '@core/models/interfaces/i-podcast-view-model';
import { ContentV3Service } from '@shared/services/content-v3.service';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements OnChanges,OnInit, AfterContentChecked, OnDestroy {
  private _currentItem: IPodcastItemViewModel;
  @Input() allItems: any;
  @Input() lastItem: boolean;
  @ViewChild('audio', { static: true }) audioPlayerRef: ElementRef;
  @Output() active: EventEmitter<any> = new EventEmitter();
  @Output() paging: EventEmitter<boolean> = new EventEmitter();
  audioTotalSecond: number = 0;
  audioCurrentSecond: number = 0;
  percentAudio:string = '0%';
  enableDrag = false;
  countDisableDrag: number = 0;
  public isNext: boolean = false;
  public autoPlay: boolean = false;
  public isLoading = false;

  @LocalStorage('user')
  private _user: IUser;

  lastHearing = 0;

  isCanPlay = false;

  constructor(private cdRef: ChangeDetectorRef, private contentService: ContentV3Service) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.lastItem) {
      if (changes.allItems && this.isNext) {
        this.nextAudio(true);
        this.isNext = false;
      }
    } else {
      this.lastItem = false;
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.stop();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  @Input()
  set currentItem(item: IPodcastItemViewModel) {
    this.isCanPlay = false;
    this._currentItem = item;
    this.audioCurrentSecond = 0;
    this.percentAudio = '0%';
    if (this.autoPlay) setTimeout(() => this.play());
  }

  get currentItem(): IPodcastItemViewModel {
    return this._currentItem;
  }

  playOrStop(isPlay: boolean) {
    if(isPlay) {
      this.stop();
    } else {
      this.play();
    }
  }

  play() {
    this.audioPlayerRef.nativeElement.play();
    this.currentItem.playing = true;
  }

  public startLoad(): void {
     this.isLoading = true;
  }
  

  public loadedData(): void {
     this.isLoading = false;
  }

  stop() {
    this.audioPlayerRef.nativeElement.pause();
    this.currentItem.playing = false;
    if(this.lastHearing >= 0) {
      this.updateHeared(this.audioPlayerRef.nativeElement.currentTime);
    }
  }

  canPlay() {
    if (!this.isCanPlay) {
      this.isCanPlay = true;
      this.audioPlayerRef.nativeElement.currentTime = this.currentItem.heardPosition;
    }
  }

  loadAudio() {
    this.audioTotalSecond = this.audioPlayerRef.nativeElement.duration;
  }

  updateTime() {
    this.audioCurrentSecond = this.audioPlayerRef.nativeElement.currentTime;
    if (!this.enableDrag) {
      this.percentAudio = ((this.audioPlayerRef.nativeElement.currentTime / this.audioPlayerRef.nativeElement.duration) * 100) + '%';
      this.lastHearing++;
      if (this.percentAudio === '100%') this.nextAudio(true);
      if(this.lastHearing >= 100) {
        this.updateHeared(this.audioPlayerRef.nativeElement.currentTime);
      }
    } else {
      this.countDisableDrag += 1;
      if (this.countDisableDrag > 10) {
        this.enableDrag = !this.enableDrag;
        this.countDisableDrag = 0;
      }
    }
  }

  drag(event, bar) {
    if(event.buttons > 0 && this.enableDrag) {
      event.preventDefault();
      this.countDisableDrag = 0;
      const { clientWidth: clientW } = bar
      const { left } = bar.getBoundingClientRect();
      const { x } = event;

      this.percentAudio = ((x-Math.round(left)) / clientW) * 100 + '%';
      this.audioCurrentSecond = this.audioPlayerRef.nativeElement.duration *  ((x-Math.round(left)) / clientW);
      this.audioPlayerRef.nativeElement.currentTime = this.audioCurrentSecond;
    }

  }

  nextTime() {
    if(this.audioPlayerRef.nativeElement.currentTime + 15 < this.audioPlayerRef.nativeElement.duration) {
      this.audioPlayerRef.nativeElement.currentTime += 15;
      this.updateHeared(this.audioPlayerRef.nativeElement.currentTime);
    }
  }

  prevTime() {
    if(this.audioPlayerRef.nativeElement.currentTime > 0 && this.audioPlayerRef.nativeElement.currentTime > 15) {
      this.audioPlayerRef.nativeElement.currentTime -= 15;
    } else if(this.audioPlayerRef.nativeElement.currentTime > 0 && this.audioPlayerRef.nativeElement.currentTime <= 15) {
      this.audioPlayerRef.nativeElement.currentTime = 0;
    }
    this.updateHeared(this.audioPlayerRef.nativeElement.currentTime);
  }

  nextAudio(autoPlay: boolean = false) {
    this.autoPlay = autoPlay;
    for (const i in this.allItems) {
      if (this.allItems[i] === this.currentItem) {
        if (i !== (this.allItems.length - 1).toString()) {
          this.currentItem = this.allItems[parseInt(i, 10) + 1];
          break;
        } else {
          !this.lastItem ? this.emitPaging() : this.lastPodcastItem();
          break;
        }
      }
    }

    this.changeActive();
  }

  prevAudio() {
    for (const i in this.allItems) {
      if (this.allItems[i] === this.currentItem) {
        if (i !== '0') {
          this.currentItem = this.allItems[parseInt(i, 10) - 1];
          break;
        } else {
          this.currentItem = this.allItems[this.allItems.length - 1];
          break;
        }
      }
    }
    this.changeActive();
  }

  changeActive() {
    this.active.emit(this.currentItem.id);
  }

  updateHeared(second: number) {
    if (this._user) {
      this.currentItem.heardPosition = second;
      this.contentService.heared({contentId: this.currentItem.id, secondHeard: second}).subscribe();
      this.lastHearing = 0;
    }
  }

  public emitPaging(): void {
    this.paging.emit(true);
    this.isNext = true;
  }

  public lastPodcastItem(): void {
    this.currentItem = this.allItems[0];
  }

}
