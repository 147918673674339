import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export interface IRating {
  stars: number;
  bookCount: number;
}

@Component({
  selector: 'app-rating-filter',
  templateUrl: './rating-filter.component.html',
  styleUrls: ['./rating-filter.component.scss']
})
export class RatingFilterComponent implements OnInit {
  @Input() rating: Array<IRating>;
  emptyStars: Array<number> = [];
  Arr = Array;
  @Output() ratingChange = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
    this.countStars();
  }

  countStars() {
    for(const star of this.rating){
      const fullStars = star.stars;
      const totalStars: number = 5;
      const emptyStar: number = totalStars - fullStars;
      this.emptyStars.push(emptyStar);
   }
  }

  selectRating($event: any) {
    if($event.bookCount > 0)
      this.ratingChange.emit($event.stars);
  }
}
