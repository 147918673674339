import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, fromEvent } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { LocalStorageService } from 'ngx-webstorage';

import { IS_BROWSER, IS_MOBILE, VIEWPORT_RESIZE } from '@config/app.tokens';
import { CanRefreshTokenService } from '@utils/can-refresh-token.service';
import { FeatureFlagV3Service } from '@services/feature-flags-v3.service';
import { AuthService } from '@api/v3/auth.service';

function testRegex(regex: RegExp): boolean {
  return regex.test(navigator.userAgent.toLowerCase());
}

export function isMobile(): boolean {
  const isiPad = testRegex(/ipad/i);
  const isiPod = testRegex(/ipod/i);
  const isWebOS = testRegex(/webos/i);
  const isiPhone = testRegex(/iphone/i);
  const isAndroid = testRegex(/android/i);
  const isiDevice = testRegex(/ipad|iphone|ipod/i);
  const isWindowsPhone = testRegex(/windows phone/i);
  const isSamsungBrowser = testRegex(/SamsungBrowser/i);
  const isMobileSafariBrowser = testRegex(/mobile safari/i);
  return isAndroid || isiPad || isiPhone || isiPod || isiDevice
    || isWebOS || isWindowsPhone || isSamsungBrowser || isMobileSafariBrowser;
}

export function isMobileSafari(): boolean {
  const isiPad = testRegex(/ipad/i);
  const isiPod = testRegex(/ipod/i);
  const isiPhone = testRegex(/iphone/i);
  return (isiPad || isiPod || isiPhone);
}

export type ViewportSizeInfo = Readonly<{
  width: number;
  height: number;
  actionBarVisible: boolean;
}>;

export function viewportResize(): Observable<ViewportSizeInfo> {
  const getViewportSize = (): ViewportSizeInfo => {
    const windoWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const actionBarVisible = windowHeight < document.body.offsetHeight;
    return { width: windoWidth, height: windowHeight, actionBarVisible };
  };
  return fromEvent(window, 'resize').pipe(
    startWith(getViewportSize()),
    map(x => getViewportSize())
  );
}

// Este codigo solo será usado para B2C
function codeToB2C( featureFlagV3Service: FeatureFlagV3Service,
  authService: AuthService,
  canRefreshTokenService: CanRefreshTokenService,
  localStorage: LocalStorageService): void {
  const storageFlag = canRefreshTokenService.retrieveKey;
  featureFlagV3Service.getAllFeaturesStatus().subscribe((features => {
    featureFlagV3Service.fillFeaturesFlags(features);
     if (featureFlagV3Service.checkFeatureOn('NewLoginEnable')) {
       if (authService.enlaceToken) {
         if (storageFlag === null || storageFlag) {
           authService.refreshToken(authService.enlaceToken.refreshToken).subscribe(jwt => {
              const tokens = {jwtToken: jwt.jwtToken, refreshToken: jwt.refreshToken};
              localStorage.store('enlaceToken', tokens);
           });
           canRefreshTokenService.allowRefreshToken();
          }
       }
     }
  }));
}

export function onAppInit(
  featureFlagV3Service: FeatureFlagV3Service,
  authService: AuthService,
  canRefreshTokenService: CanRefreshTokenService,
  localStorage: LocalStorageService): () => Promise<Object> {
  return (): Promise<Object> => {
    const storageFlag = canRefreshTokenService.retrieveKey;
    featureFlagV3Service.getAllFeaturesStatus().subscribe();
    // if (authService.enlaceToken) {
    //   if (storageFlag === null || storageFlag) {
    //     const days = authService.dayBeforeItExpires();
    //     if (days <= 1) {
    //       authService.refreshToken(authService.enlaceToken.refreshToken).subscribe(jwt => {
    //          const tokens = {jwtToken: jwt.jwtToken, refreshToken: jwt.refreshToken};
    //          localStorage.store('enlaceToken', tokens);
    //       });
    //     }
    //     canRefreshTokenService.allowRefreshToken();
    //    }
    // }
    return Promise.resolve({});
  };
}

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    { provide: IS_BROWSER, useValue: true },
    { provide: IS_MOBILE, useFactory: isMobile, deps: [] },
    { provide: VIEWPORT_RESIZE, useFactory: viewportResize, deps: [] },
    {
      provide: APP_INITIALIZER,
      useFactory: onAppInit,
      multi: true,
      deps: [FeatureFlagV3Service, AuthService, CanRefreshTokenService, LocalStorageService]
    },
  ]
})
export class AppBaseBrowserModule { }
