import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingOverlayService {

  private _loading$ = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this._loading$.asObservable();

  private _useCustomAnimation$ = new BehaviorSubject<string>('');
  useCustomAnimation$: Observable<string> = this._useCustomAnimation$.asObservable();

  constructor() { }

  showLoadingOverlay(path?: string): void {
    this._loading$.next(true);
    this._useCustomAnimation$.next(path);
  }

  hideLoadingOverlay(): void {
    this._loading$.next(false);
    this._useCustomAnimation$.next('');
  }

}
