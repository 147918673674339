<div class="fontSizeManagerContainer" *ngIf="!isFirefox()">
  <span class="closed" *ngIf="!isOpen" (click)="open()">
    <i class="iconlace  iconlace-font-size"></i>
  </span>
  <span *ngIf="isOpen" class="opened">
    <i class="iconlace iconlace-less" (click)="decrease($event)"></i>
    <i class="iconlace  iconlace-font-size" (click)="close()"></i>
    <i class="iconlace iconlace-plus" (click)="increase($event)"></i>
  </span>
</div>
