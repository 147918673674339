
<ng-container *ngIf="ThereAreFilterTags">
  <div class="container-tags">
    <div class="content-tags">
      <div
        class="tag-selected"
        *ngFor="let item of filterTags; let last = last"
        [ngClass]="{'tag-rating': item.filter.type === 'rating'}"
        (click)="removeFilter(item)">
        <ng-container *ngIf="item.filter.type === 'price'">
          <div class="tag-name">Hasta ${{ item.filter.value }} USD</div>
          <i class="iconlace iconlace-cross icon-remove"></i>
        </ng-container>
        <ng-container *ngIf="item.filter.type === 'recent'">
          <div class="tag-name">Recientes</div>
          <i class="iconlace iconlace-cross icon-remove"></i>
        </ng-container>
        <ng-container *ngIf="item.filter.type === 'date-issue'">
          <div class="tag-name">{{ item.filter.name }}</div>
          <i class="iconlace iconlace-cross icon-remove"></i>
        </ng-container>
        <ng-container
          *ngIf="
            item.filter.type !== 'price' &&
            item.filter.type !== 'recent' &&
            item.filter.type !== 'date-issue' &&
            item.filter.type !== 'rating'">
          <div class="tag-name">{{ item | labelFilter }}</div>
          <i class="iconlace iconlace-cross icon-remove"></i>
        </ng-container>
        <ng-container *ngIf="item.filter.type === 'rating'">
          <ng-container *ngFor="let star of [].constructor(5); let index = index">
            <i class='fa' [ngClass]="{'fa-star': index < item.value, 'fa-star-o': index >= item.value }"
              [ngStyle]="{'color':'#FFF', 'margin-left': '0px'}">
            </i>
          </ng-container>
          <i class="iconlace iconlace-cross icon-remove"></i>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="content-close" (click)="clearFilters()">
    <img src="assets/img/clear-filter.svg" />
  </div>
</ng-container>
