<div class="search-item search-box" [class.new-section]="isNewSection" *ngIf="items && items.length > 0">
  <div class="d-inline-block simple-combo" [class.hidden-items]="isHiddenItems" [ngClass]="{'languages': filter.title === 'Idiomas'}">
    <h2 class="search-item-title">{{filter.title}}</h2>
    <div *ngFor="let item of items; let i=index" >
      <div  [style.display]="item.visible ? 'block' : 'none'"  class="dropdown-item filter-item" (click)="clickFilter(item)">
        <div class="search-item-row" [ngClass]="{'filter-small': filter.title === 'Lenguajes' || filter.title === 'Paises', '': filter.title !== 'Lenguajes' || filter.title !== 'Paises'}">
          <img *ngIf="!item.icon || isTopicOrEbookCategory" [src]="item | imageFilter"  src-fallback="assets/img/avatar.webp"/>
          <i *ngIf="item.hasOwnProperty('icon') && !isTopicOrEbookCategory" class="iconlace" [ngClass]="item.icon"></i>
          <label style="text-transform: capitalize;" [ngClass]="{'has-icon': item.hasOwnProperty('icon')  && !isTopicOrEbookCategory}">{{getLabel(item)}} <span class="count">({{item.count}})</span></label>
              <!-- item.count -->
        </div>
      </div>
    </div>
  </div>
  <span (click)="showFilterModal()" *ngIf="isSeeMore" class="filter-item-more">Ver más <i class="iconlace iconlace-chevron-next" ></i></span>
</div>
