<div class="featured-container">
  <div class="row">
    <div class="form-group" [ngClass]="availablePersonResults() && availableOrganizationResults() ?  'col-lg-6 col-md-6 col-sm-6 col-xs-12':
      availablePersonResults() && !availableOrganizationResults() ? 'col-lg-9 col-md-9 col-sm-9 col-xs-12':
      availableOrganizationResults() && !availablePersonResults() ? 'col-lg-9 col-md-9 col-sm-9 col-xs-12':
      !availablePersonResults() && !availableOrganizationResults()? 'col-lg-12':''" *ngIf="availableBibleResults()">
      <div class="featured-content" [class.space-bottom]="isMobileWebView">
        <div class="featured-title">
          <i class="iconlace iconlace-bible"></i>
          <span>{{searchBibleResults.verses.length > 0 ? searchBibleResults.verses[0].reference : searchBibleResults.passages[0].reference}}</span>
        </div>
        <div class="featured-body" [ngClass]="getClassOf()" [innerHTML]="searchBibleResults.verses.length > 0 ? searchBibleResults.verses[0].text : searchBibleResultsFirstVerse" style="height: 50px;"></div>
        <div class="featured-help verses" (click)="readMoreBible.emit(true)" *ngIf="!isMobileWebView">
          <span>Leer más</span>
          <i class="iconlace iconlace-chevron-next"></i>
        </div>
      </div>
    </div>
    <div class="form-group col-lg-3 col-md-3 col-sm-3 col-xs-6" *ngIf="availablePersonResults()">
      <div class="featured-content">
        <!-- <div class="featured-content" *ngIf="featuredPerson">  -->
        <div class="featured-title host">
          <span>{{searchPersonResults[0].name | cuttext: organizationCutAmount}}</span>
        </div>
        <div class="featured-body">
          <a [routerLink]="['/people', searchPersonResults[0].id]" href='javascript:void(0)'>
            <img [src]="searchPersonResults[0].imageUrl" [alt]="searchPersonResults[0].name">
          </a>
        </div>
        <div class="featured-help host" *ngIf="seeMorePerson && !isMobileWebView">
          <a href="javascript:void(0)" (click)="morePersonResults.emit(true)">
            <span>Ver más Personas</span>
            <i class="iconlace iconlace-chevron-next"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="form-group col-lg-3 col-md-3 col-sm-3 col-xs-6" *ngIf="availableOrganizationResults()">
      <div class="featured-content">
        <!-- <div class="featured-content" *ngIf="featuredOrganization">  -->
        <div class="featured-title host">
          <span>{{searchOrganizationResults[0].name | cuttext: organizationCutAmount}}</span>
        </div>
        <div class="featured-body">
          <a [routerLink]="['/organization', searchOrganizationResults[0].id]" href='javascript:void(0)'>
            <img [src]="searchOrganizationResults[0].imageUrl" [alt]="searchOrganizationResults[0].name">
          </a>
        </div>
        <div class="featured-help host" *ngIf="seeMoreOrganization && !isMobileWebView" (click)="moreOrganizationResults.emit(true)">
          <span>Ver más Ministerios</span>
          <i class="iconlace iconlace-chevron-next"></i>
        </div>
      </div>
    </div>
  </div>
  <!--
    Ticket: Bug 21577: [PS][Web][Busqueda] Reactivar biblia narrada como resultados de busqueda
  -->
</div>
