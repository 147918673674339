import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import {IUser} from '@app/core/models/interfaces/i-user.interface';
import {LocalStorage} from 'ngx-webstorage';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {

  @LocalStorage('user')
  user: IUser;

  target: string = 'profile';

  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
  }

  changeTab(target: string): void {
    this.target = target;
  }

}
