import { Injectable } from '@angular/core';
import { QueryUrlResolve, UrlTreeResolve } from '@core/models/interfaces/i-structure-url.interface';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class UtilsService {
  private readonly urlTwitter = 'https://twitter.com/intent/tweet';
  private readonly urlFacebook = 'http://www.facebook.com/sharer/sharer.php';
  private readonly windowConfig = 'menubar=no,toolbar=no,status=no,width=570,height=570';

  public hashTags = 'EnlacePlus,Enlace';
  public mobileRoutes: string[] = ['donate', 'suscribirme', 'payment', 'mobile', 'auth'];

  constructor(private router: Router) { }

  public shareToFacebook(url: string, text: string): void {
    const shareUrl = `${this.urlFacebook}?u=${url}`;
    window.open(shareUrl, 'NewWindow', this.windowConfig);
  }

  public shareToTwitter(url: string, text: string): void {
    const textEncoded = encodeURIComponent(text);
    const shareUrl = `${this.urlTwitter}?url=${url}&text=${textEncoded}&hashtags=${this.hashTags}`;
    window.open(shareUrl, 'NewWindow', this.windowConfig);
  }

  public isBoolean(val: any): boolean {
    return typeof val === 'boolean';
  }

  public isStringAndNotEmpty(val: any): boolean {
    return typeof val === 'string' && val !== '';
  }

  public base64ToBlob(dataURI: string): Blob {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)
    return new Blob([ia], { type: mimeString });
  }

  public resolveUrl(url: string): UrlTreeResolve {
    const urlSplit = url.split('?');
    return {
      route: urlSplit[0],
      querys: !!urlSplit[1] ? this.buildQueryParams(urlSplit[1]) : {}
    }
  }

  private buildQueryParams(query: string): QueryUrlResolve {
    const querys = query.split('&');
    let listParameters = {};
    querys.forEach(item => {
      const parameter = item.split('=');
      listParameters = { ...listParameters, [parameter[0]]: decodeURI(parameter[1]) }
    });
    return listParameters;
  }

  // Method to compare two objects deeply
  public objectsAreEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if (
        areObjects && !this.objectsAreEqual(val1, val2) ||
        !areObjects && val1 !== val2
      ) {
        return false;
      }
    }
    return true;
  }

  public isObject(object: any) {
    return object != null && typeof object === 'object';
  }

  get currentRoute(): string {
    return this.router.url.split('?')[0];
  }

  get isMobileRoute(): boolean {
    return this.currentRoute.split('/').some((el) => this.mobileRoutes.includes(el));
  }

  public isAnyAppleBrowser(navigator): boolean {
    return navigator.vendor && navigator.vendor.indexOf('Apple') > -1
        && navigator.userAgent
        && navigator.userAgent.indexOf('CriOS') == -1
        && navigator.userAgent.indexOf('FxiOS') == -1;
  }

  public limitCharactersOnAppleBrowsers(event, characters): boolean {
    // Discard any other browser
    if (!this.isAnyAppleBrowser) {
      return true;
    }

    if (event && event.target && event.target.value && event.target.value.length == characters) {
      return false;
    }
  }

  getValueParamFromUrl(param: string) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(param);
  }

    /*************
  * Width de la foto | Height de la foto | pWidthConfig = Width en DB | pHeightConfig = Height en DB
  */
    validateImageSize(file: File, pWidthConfig: number, pHeightConfig: number): Promise<boolean> {
      return new Promise((resolve, reject) => {
        let image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => {
          let imgWidthNative = image.width;
          let imgHeightNative = image.height;

          if (!this.CheckImageSize(imgWidthNative, imgHeightNative, pWidthConfig, pHeightConfig)) {
              resolve(false);
          }
          resolve(true);
        };
        image.onerror = reject;
      });
    }

    CheckImageSize(imgWidthNative: number, imgHeightNative: number, pWidthConfig: number, pHeightConfig: number) {
      //check min Width and Height
      // Se comparan tamanos de la imagen contra el que tenemos en base de datos
      if (imgWidthNative < pWidthConfig || imgHeightNative < pHeightConfig) {
        return false;
      }

      //Check Ratio
      //ancho / alto y redondeado a 2 decimales
      if ((imgWidthNative/imgHeightNative).toFixed(2) != (pWidthConfig/pHeightConfig).toFixed(2)) {
        return false;
      }

      return true;
    }

    base64MimeType(encoded) {
      var result = null;
    
      if (typeof encoded !== 'string') {
        return result;
      }
    
      var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    
      if (mime && mime.length) {
        result = mime[1];
      }
    
      return result;
    }
}
