import {Component, Input, OnInit} from '@angular/core';
import {IContentSocialInfoViewModel} from '@core/models/interfaces/i-content-social-info-view-model';
import {ContentV2Service} from '../../../api-client/v2/content-v2.service';
import {LoginModalComponent} from '../login-modal/login-modal.component';
import {LocalStorage} from 'ngx-webstorage';
import {IUser} from '@core/models/interfaces/i-user.interface';
import {MyMonitoringService} from '../../../shared/services/lib/monitoring.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-like',
  templateUrl: './like.component.html',
  styleUrls: ['./like.component.scss']
})
export class LikeComponent implements OnInit {
  private _socialInfo: IContentSocialInfoViewModel;
  requestingLike: boolean = false;
  @Input() onlyIcon: boolean;

  @Input()
  set socialInfo(socialInfo: IContentSocialInfoViewModel) {
    if (socialInfo) {
      this._socialInfo = socialInfo;
    }
  }

  @LocalStorage('user')
  user: IUser;

  get socialInfo(): IContentSocialInfoViewModel {
    return this._socialInfo;
  }

  constructor(private contentService: ContentV2Service,
              private modalService: NgbModal,
              private myMonitoringService: MyMonitoringService) {
  }

  ngOnInit() {
  }

  loadSocialInfo(id: string): void {
    this.socialInfo = <IContentSocialInfoViewModel>{isFollow: false};
    this.contentService.getSocialInfo(id).then((socialInfo: IContentSocialInfoViewModel) => {
      this.socialInfo = socialInfo;
    });
  }

  trackLike(value: boolean) {
    if (!this.user) {
      const modalRef = this.modalService.open(LoginModalComponent, {windowClass: 'dark-modal modal-outlogin', backdropClass: 'light-black-backdrop'});
      modalRef.componentInstance.customMessageTag = 'need-to-login';
      return;
    }

    this.requestingLike = true;
    this.myMonitoringService.logEvent({name: 'Like', properties: {'VideoId': this.socialInfo.contentId, 'SocialEventTypeId': 1}});
    this.socialInfo.isLike = !this.socialInfo.isLike;
    this.contentService.toggleLike({contentId: this.socialInfo.contentId}).then(() => {
      this.requestingLike = false;
    }, () => {
      this.requestingLike = false;
    });
  }
}
