import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChannelLoaderComponent } from './channel-loader.component';
import { LottieAnimationViewModule } from 'ng-lottie';

@NgModule({
  imports: [
    CommonModule,
    LottieAnimationViewModule.forRoot()
  ],
  declarations: [ChannelLoaderComponent],
  exports: [ChannelLoaderComponent]
})
export class ChannelLoaderModule { }
