import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { IVVideo } from '@interfaces/i-v-video';
import { TypeResultSearch } from '@api/models/entities/search.interface';
import { toNumber } from '@shared/utils/timepicker.utils';

import { IContentCatalogViewModel } from '@interfaces/i-content-catalog-view-model';
import { AdvanceSearchService } from '../services/advance-search.service';
import { PPVContentService } from '@app/shared/utils/ppvcontent.service';
import { ContentV3Service } from '@services/content-v3.service';
import { UtilsService } from '@utils/utils.service';
import { ModalManagementService } from '@app/shared/utils/modal-management.service';
declare const Android: any;
@Component({
  selector: 'app-content-search',
  templateUrl: './content-search.component.html',
  styleUrls: ['./content-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentSearchComponent {

  public originalList: any[];
  public totalRows: number = 0;
  public elementsRow: number = 12;
  public currentItem: any;
  public video: IVVideo;
  public contentTypeSearch = TypeResultSearch;
  public canPaging: boolean = true;

  private _searchResult: any;

  @Input()
  set searchResult(value: any) {
    this._searchResult = value;
    this.originalList = this._searchResult.list;
    this.totalRows = this._searchResult.totalItems;
  }

  get searchResult(): any {
    return this._searchResult;
  }

  @Input() currentContentFilter: string;
  @Input() isMobile: boolean = false;
  @Output() emitChangeTypeContent: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitLoadingPaging: EventEmitter<boolean> = new EventEmitter<boolean>();

  get canSeeMore(): boolean {
    return this.searchResult.totalItems > this.elementsRow && this.currentContentFilter === TypeResultSearch.ALL;
  }

  get canCloseResultsSearched(): boolean {
    return this.currentContentFilter !== TypeResultSearch.ALL;
  }

  get isMobileUrlWebview(): boolean {
    return this.utilsServ.isMobileRoute;
  }

  constructor(
    private router: Router,
    private advanceSearchService: AdvanceSearchService,
    private searchService: ContentV3Service,
    private utilsServ: UtilsService,
    private ppvContentService: PPVContentService,
    private modalManagementService: ModalManagementService
  ) { }

  trackByItems(index: number, item: any): string { return item.id }

  goToVideoSecond(milisecond: number, videoId: string, type: string): void {
    const second = toNumber(milisecond /1000);
    if(type === TypeResultSearch.NARRATED_BIBLE) {
      this.router.navigate(['/video', videoId, second], {queryParams: {indexer: this.advanceSearchService.searchText}});
    } else {
      this.router.navigate(['/video', videoId, second], {queryParams: {transcription: this.advanceSearchService.searchText}});
    }
  }

  elementRowByType(type: any): number {
    return type === this.contentTypeSearch.EBOOK || type === this.contentTypeSearch.AUDIOBOOK ? 12 : 8;
  }

  showIndexer(video): void {
    this.video = <IVVideo>{id: video.id};
    video.isShowIndexer = !video.isShowIndexer;
  }

  showTranscription(video): void {
    this.video = <IVVideo>{id: video.id};
    video.isShowTranscriptions = !video.isShowTranscriptions;
  }

  navigate(content: any): void {
    if (this.isMobileUrlWebview && (typeof Android !== 'undefined')) {
      if (content.type === TypeResultSearch.VIDEO ||  content.type === TypeResultSearch.NARRATED_BIBLE) {
        Android.redirectContent(`video/${content.id}`);
      } else if (content.type === TypeResultSearch.PROGRAM){
        Android.redirectContent(`program/${content.id}`);
      } else {
        Android.redirectContent(`${content.type}/${content.id}`);
      }
    } else {
      if (
        content.type === TypeResultSearch.NARRATED_BIBLE || 
        content.type === TypeResultSearch.PROGRAM || 
        content.type === TypeResultSearch.VIDEO || 
        content.type === TypeResultSearch.PODCAST
      ) {
          const contentType = content.type === 'narrated-bible'  ? 'video' : content.type;
          this.openModalDetail(content.id, contentType)
        } else {
          this.router.navigate(['/', content.type, content.id]);
        }    
    }
  }

  redirectIsContentAcquired(content: any): void {
    const contentIsPPV = this.ppvContentService.isPPV(content);

    if (content.type === TypeResultSearch.VIDEO ||  content.type === TypeResultSearch.NARRATED_BIBLE) {
      if (content.contentParentId) {
        if (!contentIsPPV || content.acquired) {
          this.router.navigate(['/', content.type == 'narrated-bible' ? 'video' : content.type, content.id]);
        } else {
          content.programId = content.contentParentId;
          content.contentId = content.id;
          content.urlImage = content.urlImage;
        }
      } else {
        if (!contentIsPPV || content.acquired) {
          if(content.type === 'video' && content.programId == undefined)
            this.router.navigate(['/', content.type == 'narrated-bible' ? 'video' : content.type, content.id], { queryParams: { standalone: 'true' }});
          else
            this.router.navigate(['/', content.type == 'narrated-bible' ? 'video' : content.type, content.id]);
        }
      }
    }
  }

  show(contentCatalog: IContentCatalogViewModel) {
    if (this.isMobileUrlWebview && (typeof Android !== 'undefined')) {
      Android.redirectContent(`${contentCatalog.type}/${contentCatalog.contentId}`);
    } else {
      if (contentCatalog.type === 'audiobook') {
        this.showAudioBookDetail(contentCatalog);
        return;
      }
      if (contentCatalog.type === 'ebook') {
        this.showEBookDetail(contentCatalog);
        return;
      }
    }
  }

  changeFilter(type: string): void {
    this.advanceSearchService.changeTypeFilter(type);
    this.emitChangeTypeContent.emit(type);
    this.canPaging = true;
  }

  private showAudioBookDetail(contentCatalog: IContentCatalogViewModel) {
    if(!this.isMobileUrlWebview){
      this.searchService
      .getAcquireContent(contentCatalog.contentId)
      .subscribe((res: boolean) => {
        if (res) {
          this.read(contentCatalog.type, contentCatalog.contentId);
        }
      });
    }else{
      this.router.navigate(['/audiobook', contentCatalog.contentId]);
    }
  }

  private showEBookDetail(contentCatalog: IContentCatalogViewModel) {
    if (!this.isMobileUrlWebview) {
      this.openModalDetail(contentCatalog.contentId, contentCatalog.type)
    }
  }


  onScroll(type: string): void {
     if(this.currentContentFilter !== TypeResultSearch.ALL && this.canPaging) {
      this.emitLoadingPaging.emit(true);
      this.searchResult.type = type;
      this.searchResult.pageInfo.page = this.searchResult.pageInfo.page + 1;
      this.searchService.advancedSearch(this.searchResult.pageInfo).subscribe((result) => {
        this.searchResult.list = this.searchResult.list.concat(result.result.list);
        this.canPaging = result.result.list.length > 0;
        this.emitLoadingPaging.emit(false);
      });
    }
  }

  read(contentType: string, contentId: string) {
    this.router.navigate(['/', contentType, contentId]);
  }

  mapSearchItemToBooks(searchItem) {
    searchItem.urlImage = searchItem.image;
    searchItem.contentId = searchItem.id;
    return searchItem;
  }

  openModalDetail(contentId: string, contentType: string): void{
    this.modalManagementService.openModalMoreInfo(contentId, contentType);
  }

  goTop(): void {
    if(typeof window !== 'undefined') window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  isPPV(item) {
    return this.ppvContentService.isPPV(item);
  }
}
