import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagPpvComponent } from './tag-ppv.component';

@NgModule({
  declarations: [TagPpvComponent],
  imports: [
    CommonModule
  ],
  exports: [TagPpvComponent]
})
export class TagPpvModule { }
