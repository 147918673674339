import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { IPodcastItemViewModel, IPodcastViewModel } from '@app/core/models/interfaces/i-podcast-view-model';
import { IPagingRequestModel } from '@app/core/models/interfaces/ipaging-request-model';
import { PodcastV3Service } from '@app/shared/services/podcast-v3.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PodcastPlayerService } from "@app/shared/components/podcast-player/podcast-player.service";
import { ModalManagementService } from '@app/shared/utils/modal-management.service';
import { MarketingParamsService } from '@app/shared/utils/marketing-params.service';

@Component({
  selector: 'app-content-podcast-modal',
  templateUrl: './content-podcast-modal.component.html',
  styleUrls: ['./content-podcast-modal.component.scss']
})
export class ContentPodcastModalComponent implements OnInit, OnDestroy, AfterViewInit {

  contentId: string;
  contentType: string;
  config = null;

  isPlayer = false;
  loadingSkeleton = false;
  isLoggin = false;
  private animationTimeout: any;
  contentDetailPodcast: IPodcastViewModel;
  paginRequest: IPagingRequestModel = <IPagingRequestModel>{
    page: 0,
    rowsPage: 10,
    customFilters: []
  }
  podcastItems: IPodcastItemViewModel[];

  constructor(
    private modalManagementService: ModalManagementService,
    private marketingParamsService: MarketingParamsService,
    private podcastPlayerService: PodcastPlayerService,
    private podcastService: PodcastV3Service,
    private activeModal: NgbActiveModal,
    private el: ElementRef,
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.checkIfModalExceedsViewport();
    }, 200);
  }

  ngOnInit(): void {
    this.marketingParamsService.sendMarketingTrack({
      event_location: 'more-info'
    });
    this.getContentDetail();
  }

  ngOnDestroy(): void {
    if (this.animationTimeout) {
      clearTimeout(this.animationTimeout);
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.checkIfModalExceedsViewport();
  }

  getContentDetail(): void {
    this.loadingSkeleton = true;
    this.paginRequest.customFilters.push({
      name: 'podcastId',
      value: this.contentId
    });
    this.podcastService.view(this.paginRequest).subscribe(
      (result) => {
        this.contentDetailPodcast = result;
        this.podcastItems = result.podcastItems.map((item) => {
          return {
            ...item,
            playing: false
          }
        });
        this.contentDetailPodcast.podcastItems = this.podcastItems;
        this.loadingSkeleton = false;
      },
      () => {
        this.closeModal(true);
      }
    );
  }

  redirectToContent(): void {
    const firstPodacastItem = { ...this.podcastItems[0], playing: true };
    this.podcastPlayerService.change(firstPodacastItem, 0);
  }

  closeModal(canHandleResult: boolean = false): void {
    this.executeAnimateBottom(canHandleResult);
  }

  private executeAnimateBottom(canHandleResult: boolean): void {
    this.modalManagementService.executeAnimateBottom(
      this.activeModal, 
      canHandleResult, 
      null, 
      '.modal-detail-content'
    );
  }

  checkIfModalExceedsViewport(): void {
    this.modalManagementService.checkIfModalExceedsViewport(this.el);
  }
}
