import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { SubscriptionSubmitService } from '@utils/subscription-submit.service';
import { HttpLoadingService } from '@utils/http-loading.service';
import { WalletV3Service } from '@services/wallet-v3.service';
import { DonationV3Service } from '@app/shared/services/donation-v3.service';
import { IResponseCardViewModel } from '@interfaces/i-response-card-view-model';
import { DonationHeaderForm, DonationPlanFrequency } from '@interfaces/i-donation-view-model';
import { IUser } from '@interfaces/i-user.interface';
import { TypeClassSubscriptionForm } from '@enums/type-class-subscription-forms.enum';
import { TypePropertyLocalStorage } from '@enums/type-property-localstorage.enum';
import { RoutesApp } from '@enums/type-routes-app.enum';
import { validateExpiredCard } from '@helpers/validate-expired-card.helper';
import { IBillingAgreementRequest } from '@app/core/models/interfaces/i-billing-agreements.interface';
import { DonationPlan } from '@app/core/models/enums/type-plan-active.enum';
import { GatewayTypes } from '@app/core/models/enums/type-gateways-card.enum';
import { DonationPopupSuccessComponent } from '../donation-popup-success/donation-popup-success.component';
import { UserCurrenciesService } from '@app/shared/utils/user-currencies.service';
import { DONATION_DETAIL_PLAN } from '@app/core/models/constants/donation-info.constants';
import { IParametersRequestDonation } from '@app/core/models/interfaces/i-parameters.request.donation';
import { FeatureFlagV3Service } from '@app/shared/services/feature-flags-v3.service';
import { environment as ENV } from '@environment/environment';
import { LoadingOverlayService } from '@app/shared/services/loading-overlay.service';
@Component({
  selector: 'app-checkout-donation-modal',
  templateUrl: './checkout-donation-modal.component.html',
  styleUrls: ['./checkout-donation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutDonationModalComponent implements OnInit, OnDestroy  {

  @LocalStorage(TypePropertyLocalStorage.USER) public user: IUser;

  styleFormSubscription: string = TypeClassSubscriptionForm.DONATION;
  userCurrency: string = 'USD';
  userCountry: string = '';
  userCards: IResponseCardViewModel[] = [];
  cardSeleted: IResponseCardViewModel;
  donationParametes: IParametersRequestDonation = <IParametersRequestDonation> {
    origin: 'donate'
  };
  thereIsError = false;
  allCardExpireds = false;
  private donationHeaderForm: DonationHeaderForm;
  private gateWay = GatewayTypes.CreditCard;

  constructor(
    private modalService: NgbModal,
    private userCurrenciesService: UserCurrenciesService,
    private httpLoadingService: HttpLoadingService,
    private subscriptionSubmitService: SubscriptionSubmitService,
    private walletService: WalletV3Service,
    private donationService: DonationV3Service,
    private activeModal: NgbActiveModal,
    private featureFlagService: FeatureFlagV3Service,
    private router: Router,
    private loadingOverlayService: LoadingOverlayService
  ) { }

  ngOnInit(): void {
    this.getUserCreditCards();
    this.loadUserCurrency();
  }

  ngOnDestroy(): void {
    this.subscriptionSubmitService.clear();
  }

  get isLoading$(): Observable<boolean> {
    return this.httpLoadingService.isLoading$;
  }

  get isUserLoggedIn(): boolean {
    return !!this.user;
  }

  get isLandingDonationEnabled(): boolean {
    return this.featureFlagService.checkFeatureOn('WebEnableDonations');
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  public submitForms(): void {
    this.subscriptionSubmitService.change(true);
  }

  public setDonationHeader(event: DonationHeaderForm): void {
    this.donationHeaderForm = event;
    if (!!this.donationHeaderForm && !!this.cardSeleted)
      this.donate();
  }

  public selectCard(event: IResponseCardViewModel): void {
    this.cardSeleted = event;
  }

  public loadUserCurrency(): void {
    const userCountry = this.user && this.user.country ? this.user.country : this.userCountry;
    if (!!userCountry) {
      const countryCurrency = this.userCurrenciesService.getUserCountryByName(userCountry);
      this.userCurrency = !!countryCurrency
        ? countryCurrency.IsoCurrencyCode
        : 'USD';
    }
  }

  public getUserCreditCards(): void {
    this.loadingOverlayService.showLoadingOverlay();
    this.walletService.getUserCreditCards().subscribe(res => {
      if (res.length > 0) {
        this.userCards = res.map(validateExpiredCard);
        this.allCardExpireds = this.userCards.filter(x=>x.expired == false).length == 0;
        if(this.allCardExpireds) {
          this.cardSeleted = null;
          this.activeModal.dismiss();
          this.goToLandingDonation();
        }else{
          const userCard = this.userCards.find(uc => uc.isDefault);
          this.cardSeleted = userCard && !userCard.expired ? userCard : null;
        }
      }
      else this.userCards = [];
      this.loadingOverlayService.hideLoadingOverlay();
      this.validateCards(this.userCards);
    });
  }

  public goToLandingDonation(): void {

    if (this.user && this.user.id) {
      const urlWithUserId = `${ENV.donationUrl}?uid=${this.user.id}`;
      window.open(urlWithUserId, "_blank");
    } else {
      window.open(ENV.donationUrl, "_blank");
    }
  }

  // We may need to check international cards only
  private validateCards(cards: IResponseCardViewModel[]): void {
    if (!!cards && cards.length === 0)
      this.goToLandingDonation();
  }

  public async showResponseModal(isSuccess: boolean, isLoggedIn: boolean): Promise<void> {
    this.loadingOverlayService.hideLoadingOverlay();
    const modalRef = this.modalService.open(DonationPopupSuccessComponent, {
      windowClass: 'dark-modal modal-donation-success',
      backdropClass: 'light-black-backdrop'
    });
    modalRef.componentInstance.isSuccess = isSuccess;
    modalRef.componentInstance.isLoggedIn = isLoggedIn;

    const response = await modalRef.result;
    if (response === 'ok') this.redirect();
    if (response === 'close') this.closeModal();
  }

  public redirect(): void {
    this.closeModal();
    const isLoggedIn = this.isUserLoggedIn;
    isLoggedIn
        ? this.router.navigate([RoutesApp.TRANSACTION])
        : this.router.navigate([RoutesApp.HOME]);
  }

  private donate(): void {
    const request = this.createBillingAgreementRequest();

    this.donationService.createDonation(request).subscribe((response) => {
      if (response === 'OK')
        this.showResponseModal(true, this.isUserLoggedIn);
      else
        this.showResponseModal(false, this.isUserLoggedIn)
    },
    (error) => this.showResponseModal(false, this.isUserLoggedIn));
  }

  private createBillingAgreementRequest(): IBillingAgreementRequest {
    const organization = this.donationParametes.organizationId ? this.donationParametes.organizationName : 'Enlace+';
    const description = (
      this.donationHeaderForm.frequency !== DonationPlan.Unique
        ? `Donación recurrente a ${organization}`
        : `Donación a ${organization}`);
    const donationPlanDetail: DonationPlanFrequency = DONATION_DETAIL_PLAN[this.donationHeaderForm.frequency];

    return <IBillingAgreementRequest>{
      amount: this.donationHeaderForm.amount,
      cycles: this.donationHeaderForm.cycleQuantity || 0,
      frequencyInterval: donationPlanDetail.frequencyQuantity,
      frequencyType: donationPlanDetail.frequencyType,
      description: description,
      gateway: this.gateWay,
      currency: 'USD',
      origin: this.donationParametes.origin,
      type: this.donationHeaderForm.cycle,
      organizationId: this.donationParametes.organizationId || null,
      videoId: this.donationParametes.videoId || null,
      name: `${this.cardSeleted.cardHolderName} ${this.cardSeleted.cardHolderLastName}`,
      planId: this.donationHeaderForm.frequency,
      cardId: this.cardSeleted.cardId
    };
  }

}
