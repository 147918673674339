import { Component, OnInit, Input } from '@angular/core';
import { PPVContentService } from '@app/shared/utils/ppvcontent.service';

@Component({
  selector: 'app-ppv-remaining-indicator',
  templateUrl: './ppv-remaining-indicator.component.html',
  styleUrls: ['./ppv-remaining-indicator.component.scss']
})
export class PpvRemainingIndicatorComponent implements OnInit {

  @Input() content: any;
  @Input() isCatalog: boolean = false;
  @Input() parentShowingRental: boolean = false;

  expireDate: string;
  validDays: number;
  showRental: boolean = false;
  ramainding: number = null;

  constructor(private ppvContentService: PPVContentService) { }

  ngOnInit() {
    if(this.content) {
      this.expireDate = this.content.expireDate;
      this.validDays = this.content.validDays;
      this.showRental =  this.ppvContentService.isRental(this.content);

      if(this.expireDate != null)
        this.ramainding = this.dateDiff(this.expireDate);
    }
  }

  get contentTypeIsBook(): boolean {
    return this.content.type === 'ebook' || this.content.type === 'audiobook';
  }

  get contentTypeIsCatalog(): boolean {
    return (this.content.type === 'ebook' || this.content.type === 'audiobook') && this.isCatalog;
  }

  dateDiff(date: string): number {
    let diffInMs: number = Date.parse(date) - Date.now();
    let diffInHours: number = Math.floor(diffInMs / 1000 / 60 / 60);
    let days: number = Math.floor(diffInHours / 24);
    return days
  }

  getTooltip() {
    if (this.expireDate != null) {
      if(this.ramainding == 0)
        return 'Último día de alquiler';
      else if(this.ramainding == 1)
        return 'Queda '+ this.ramainding +' día de alquiler';
      else
        return 'Quedan '+ this.ramainding +' días de alquiler';
    } else if (this.validDays != null) {
      return 'Te quedan ' + this.validDays + ' días para disfrutarlo a partir del momento que lo inicies';
    }
  }
}
