import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupFilterComponent } from '../popup-filter/popup-filter.component';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @Input() limitItems: number = 5;
  @Input() isNewSection: boolean = false;
  @Output() addFilter: EventEmitter<any> = new EventEmitter<any>();
  items: any[];
  public showItems: boolean = true;
  private _filter:any;


  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    this.filter.items.forEach((value) => {
      value.visible = true;
    });
  }

  get filter(): any {
    return this._filter;
  }

  get isSeeMore(): boolean {
    return this.filter.items.length > this.limitItems && this.showItems && this._filter.type !== 'contentType';
  }

  get isHiddenItems(): boolean {
    return this.isSeeMore && this.showItems && this._filter.type !== 'contentType';
  }

  get hasMaxItems(): boolean {
    return this.filter.items.length > 15;
  }

  get isTopicOrEbookCategory(): boolean {
    return !!this._filter && (this._filter.type === 'bookCategory' || this._filter.type === 'topic');
  }

  @Input()
  set filter(value: any) {
    this._filter = value;
    this.items = this._filter.items;
  }

  onLoaded(isFallback: boolean, item: any) {
    item.visible = true;
  }

  getLabel(item:any): string {
    let value =  item.name || item.fullName;
    value = value.toLowerCase();
    return value;
  }

  public showFilterModal() {
    this.hasMaxItems ? this.openModal() : this.showItems = false;
  }

  public openModal(): void {
    const modalRef = this.modalService.open(PopupFilterComponent, { windowClass: 'dark-modal filter-modal', backdropClass: 'light-black-backdrop' });
    modalRef.componentInstance.filter = this.filter;
    modalRef.componentInstance.addFilter.subscribe((value: any) => {
      this.clickFilter(value);
    });
  }

  public clickFilter(item:any) {
    this.addFilter.emit({filter: this.filter, item: item});
  }

}
