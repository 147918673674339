import {Injectable} from '@angular/core';
import {RestClient} from '../rest-client';
import {EnlaceRestHandler} from '../enlace-rest-handler';
import {AuthenticationService} from '../authentication.service';
import {IRestMethod, RestAction, RestParams, RestRequestMethod, RestResponseBodyType} from 'rest-core';
import {environment} from '../../../environments/environment';
import {IChargebeeCustomer} from '../models/v2/i-chargebee-customer';
import {UserV2Service} from './user-v2.service';
import {Router} from '@angular/router';
import {LocalStorageService} from 'ngx-webstorage';
import {ISuscriptionHistory} from '../models/v2/i-chargebee-SuscriptionHistory';
import { IUserSubscriptionPlanViewModel } from '@core/models/interfaces/i-user-suscription-plan-view-model';
import { ITransaction } from '@core/models/interfaces/i-transaction-response';
import { IPagingResponseModel } from '@core/models/interfaces/paging/ipaging-response-model';
import { IPagingRequestModel } from '@core/models/interfaces/ipaging-request-model';
import { IBillingAgreementRequest } from '@core/models/interfaces/i-billing-agreements.interface';
import { LoadingOverlayService } from '@app/shared/services/loading-overlay.service';

@Injectable({
  providedIn: 'root'
})
@RestParams({
  pathPrefix: environment.webApi + 'v2/chargebee'
})
export class SubscriptionService extends RestClient {

  @RestAction({
    path: '/ChangePlantoBETA',
    method: RestRequestMethod.Post,
    responseBodyType: RestResponseBodyType.Text
  })
  ChangePlantoBETA: IRestMethod<{}, string>;

  @RestAction({
    path: 'checkOutSubscription/{!planId}/{!unitPrice}',
    method: RestRequestMethod.Post,
    responseBodyType: RestResponseBodyType.Text
  })
  checkoutSubscription: IRestMethod<{ planId: string, unitPrice: number }, string>;

  @RestAction({
    path: '/checkOutSubscriptionFounder',
    method: RestRequestMethod.Post,
    responseBodyType: RestResponseBodyType.Text
  })
  checkOutSubscriptionFounder: IRestMethod<IBillingAgreementRequest, string>;

  @RestAction({
    path: '/recurringDonation/{!token}/{!planId}/{!unitPrice}',
    method: RestRequestMethod.Post,
    responseBodyType: RestResponseBodyType.Text
  })
  recurringDonation: IRestMethod<{ token: string, planId: string, unitPrice: number }, any>;

  @RestAction({
    path: '/managePaymentSources',
    method: RestRequestMethod.Get,
    responseBodyType: RestResponseBodyType.Text
  })
  managePaymentSources: IRestMethod<void, string>;

  @RestAction({
    path: '/createUser',
    method: RestRequestMethod.Post
  })
  createUser: IRestMethod<IChargebeeCustomer, IChargebeeCustomer>;

  @RestAction({
    path: '/updateBillingUser',
    method: RestRequestMethod.Post
  })
  updateBillingUser: IRestMethod<IChargebeeCustomer, IChargebeeCustomer>;

  @RestAction({
    path: '/updateUser',
    method: RestRequestMethod.Post
  })
  updateUser: IRestMethod<IChargebeeCustomer, IChargebeeCustomer>;

  @RestAction({
    path: '/listActivePlans',
    method: RestRequestMethod.Get,
  })
  listActivePlans: IRestMethod<void, any[]>;

  @RestAction({
    path: '/listSuccesTransactions',
    method: RestRequestMethod.Get,
  })
  listSuccesTransactionsV2: IRestMethod<{ transactionType: string }, ITransaction[]>;

  @RestAction({
    pathPrefix: environment.webApi + 'v3/chargebee',
    path: '/listSuccesTransactions',
    method: RestRequestMethod.Post,
  })
  listSuccesTransactionsV3: IRestMethod<IPagingRequestModel, IPagingResponseModel<ITransaction>>;

  @RestAction({
    path: '/GetSuscriptionHistory',
    method: RestRequestMethod.Post
  })
  getSuscriptionHistory: IRestMethod<{ status: number }, ISuscriptionHistory[]>;

  @RestAction({
    path: '/getNextPayment',
    method: RestRequestMethod.Get
  })
  getNextPayment: IRestMethod<void, any>;

  constructor(restHandler: EnlaceRestHandler,
              authService: AuthenticationService,
              private userService: UserV2Service,
              private localStorageService: LocalStorageService,
              private router: Router,
              private loadingOverlayService: LoadingOverlayService
             ) {
    super(restHandler, authService);
  }

  enableSuscriptionBeta(redirect: boolean = true): Promise<void> {
    return new Promise<void>((resolve, reject) => {
    this.loadingOverlayService.showLoadingOverlay();
      console.log('enableSubscriptionBEta');
      if (this.user && this.user.suscriptionPlanId === 'founder' || this.user.suscriptionPlanId === 'fundador') {
        this.ChangePlantoBETA().then((urlChargeBee: any) => {
          if (urlChargeBee === 'Ok') {
            this.userService.suscriptionEnableBeta().then(() => {
              ///Update User to Beta in DB
              /*Get User Plan to update info in context*/
              this.userService.getUserPlan().then((result: IUserSubscriptionPlanViewModel) => {
                this.user.isSuscriptionPlanEnabled = result.isSubscriptionPlanEnabled;
                this.user.suscriptionPlanId = result.subscriptionPlanId;
                this.user.planId = result.userPlan.id;
                this.user.planName = result.userPlan.name;
                this.localStorageService.store('user', this.user);
                if (redirect) this.router.navigate(['/']);
                this.loadingOverlayService.hideLoadingOverlay();
                resolve();
              });
            });

          } else if (urlChargeBee === 'Subscripcion Ya cancelada') {
            this.userService.suscriptionEnableBeta().then(() => {
              ///Update User to Beta in DB
              // Por as dudas que  en BD siga como fundador, actualizo la BD para Usuario FREE
              this.userService.getUserPlan().then((result: IUserSubscriptionPlanViewModel) => {
                this.user.isSuscriptionPlanEnabled = result.isSubscriptionPlanEnabled;
                this.user.suscriptionPlanId = result.subscriptionPlanId;
                this.user.planId = result.userPlan.id;
                this.user.planName = result.userPlan.name;
                this.localStorageService.store('user', this.user);
                if (redirect) this.router.navigate(['/']);
                this.loadingOverlayService.hideLoadingOverlay();
                resolve();
              });
            });
          }
        }, () => {
          this.localStorageService.store('user', this.user);
          this.loadingOverlayService.hideLoadingOverlay();
          if (redirect) this.router.navigate(['/']);
          resolve();
        });
      } else {
        this.userService.suscriptionEnableBeta().then(() => {
          ///Update User to Beta in DB
          /*Get User Plan to update info in context*/
          this.userService.getUserPlan().then((result: IUserSubscriptionPlanViewModel) => {
            this.user.isSuscriptionPlanEnabled = result.isSubscriptionPlanEnabled;
            this.user.suscriptionPlanId = result.subscriptionPlanId;
            this.user.planId = result.userPlan.id;
            this.user.planName = result.userPlan.name;
            this.localStorageService.store('user', this.user);
            if (redirect) this.router.navigate(['/']);
            this.loadingOverlayService.hideLoadingOverlay();
            resolve();
          });
        });
      }
    });
  }


  enableSuscriptionFounder(plan: IBillingAgreementRequest, amount: number, planId: string, redirect: boolean = true): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.loadingOverlayService.showLoadingOverlay();
      this.checkOutSubscriptionFounder(plan).then((urlChargeBee: any) => {
        if (urlChargeBee === 'Ok') {
          /*Get User Plan to update info in context*/
          this.userService.getUserPlan().then((result: IUserSubscriptionPlanViewModel) => {
            this.user.isSuscriptionPlanEnabled = result.isSubscriptionPlanEnabled;
            this.user.suscriptionPlanId = result.subscriptionPlanId;
            this.user.planId = result.userPlan.id;
            this.user.planName = result.userPlan.name;
            this.localStorageService.store('user', this.user);
            if (redirect) this.router.navigate(['/']);
            this.loadingOverlayService.hideLoadingOverlay();
            resolve();
          });
        }
      }, () => {
        this.loadingOverlayService.hideLoadingOverlay();
        reject();
      });
    });
  }

  enableRecurringDonation(token: string, planId: string, amount: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.recurringDonation({token: token, planId: planId, unitPrice: amount}).then((urlChargeBee: any) => {
        if (urlChargeBee === 'OK') {
          this.loadingOverlayService.hideLoadingOverlay();
          resolve();
        } else {
          this.loadingOverlayService.hideLoadingOverlay();
          reject('Ha ocurrido un error');
        }
      }, (error) => {
        this.loadingOverlayService.hideLoadingOverlay();
        reject('Ha ocurrido un error: ' + error);
      }).catch((error) => {
        console.error('Ha ocurrido un error: ' + error);
        this.loadingOverlayService.hideLoadingOverlay();
        reject('Ha ocurrido un error: ' + error);
      });
    });
  }
}
