<div class="modal-header">
  <button type="button" class="close closemodal-login" aria-label="Close" (click)="activeModal.close()"><span
    aria-hidden="true">&times;</span></button>
</div>
<div class="modal-body csb">
   <div class="row" *ngIf="icon">
      <div class="col-xs-12 icon">
        <i class="iconlace" [ngClass]="icon"></i>
      </div>
   </div>
   <div class="row message_1">
    <div class="col-xs-12">
      <p>{{title}} </p>
    </div>
  </div>
  <div class="row message_2" *ngIf="subTitle">
    <div class="col-xs-12">
      <p>{{subTitle}}</p>
    </div>
  </div>
  <div class="row buttons">
    <div class="col-xs-12 col-sm-6">
      <button class="button_no" (click)="clickCancel()">NO</button>
    </div>
    <div class="col-xs-12 col-sm-6">
      <button class="button_yes" [ngClass]="classConfirmButton" (click)="clickAcept()">SI</button>
    </div>
  </div>
</div>
