import { Injectable } from '@angular/core';
import {BaseService} from './base-service';
import {ISetting} from '../models/v2/i-setting';
import {AuthenticationService} from '../authentication.service';
import {EnlaceRestHandler} from '../enlace-rest-handler';
import {IRestMethod, RestAction, RestParams, RestResponseBodyType} from 'rest-core';
import {Settings} from '../models/settings';
import {LocalStorage} from 'ngx-webstorage';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
@RestParams({
  // IRestParams
  pathPrefix: environment.webApi + 'v2/setting'
})
export class SettingService extends BaseService<ISetting> {

  @LocalStorage('settings')
  settings: ISetting[];

  constructor(restHandler: EnlaceRestHandler, authService: AuthenticationService) {
    super(restHandler, authService);
  }

  @RestAction({
    path: '/geolocationip',
    responseBodyType: RestResponseBodyType.Text
  })
  geolocationip: IRestMethod<void, string>;

  listSettings() {
    this.list().then((settings: ISetting[]) => {
      this.settings = settings;
    });
  }

  get availableReproductions(): number {
    const setting = this.settings.find((s: ISetting) => {
      return s.name === 'AvailableReproductions';
    });

    return parseInt(setting ? setting.value : '1', 0);
  }

  get showDonateButton(): boolean {
    const setting = this.settings.find((s: ISetting) => {
      return s.name === 'ShowDonateButton';
    });

    return setting ? setting.value === 'false' ? false : true : false;
  }

}
