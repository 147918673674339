import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-suscribe-plus-modal',
  templateUrl: './suscribe-plus-modal.component.html',
  styleUrls: ['./suscribe-plus-modal.component.scss']
})
export class SuscribePlusModalComponent {

  @Output()
  emitFirstButton: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  emitSecondButton: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public activeModal: NgbActiveModal) { }

  public actionFirstButton(): void {
    this.emitFirstButton.emit(true);
    this.activeModal.close();
  }

  public actionSecondButton(): void {
    this.emitSecondButton.emit(true);
    this.activeModal.close();
  }
}
