import {Pipe} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {DatePipe} from "@angular/common";
import {LanguageService} from '../api-client/v2/language.service';

@Pipe({
  name: 'dateformat',
  pure: false
})
export class DateFormatPipe extends DatePipe{

  value: string;
  format: string;
  timezone: string;
  constructor(private _translate: LanguageService, sessionStorage: LocalStorageService){
    super(null);
    sessionStorage.observe('language').subscribe(() => {
      this.transform(this.value, this.format, this.timezone, this._translate.getLocale());
    });
  }

  transform(value: any, format?: string, timezone?: string, locale?: string): string | any {
    this.value = value;
    this.timezone = timezone;
    this.format = format;
    var loc = this._translate.getLocale();
    let date = super.transform(value, format, timezone, loc);

    if (loc == "es-ES" && date != null && date.indexOf('.') != -1)
      date = date.replace('.','');
    return date;  
  }
}
