import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-content-item-list',
  templateUrl: './content-item-list.component.html',
  styleUrls: ['./content-item-list.component.scss']
})
export class ContentItemListComponent implements OnInit {
  @Input()
  typeCaller: number;
  @Input() type: string;
  @Input() className: string = '';
  constructor() { }

  ngOnInit() {
  }

}
