<div class="profile">
  <div class="profile-item">
    <p class="profile-item_header">Nombre</p>
    <input class="profile-item_body" [(ngModel)]="user.userName" [readonly]="isReadOnly" />
  </div>
  <div class="profile-item">
    <p class="profile-item_header">Email</p>
    <input class="profile-item_body" value="{{user.email}}" [readonly]="true" />
  </div>
  <div class="profile-item" *ngIf="isReadOnly">
    <p class="profile-item_header">País</p>
    <input class="profile-item_body" value="{{user.country}}" [readonly]="true" />
  </div>
  <div class="profile-item" *ngIf="!isReadOnly">
    <p class="profile-item_header">País</p>
    <select [(ngModel)]="user.country" class="profile-item_body" [disabled]="isReadOnly">
      <option *ngFor="let c of Countries" [value]="c">{{c}}</option>
    </select>
  </div>

  <div class="profile-item" *ngIf="isReadOnly">
    <p class="profile-item_header">Año&nbsp;nacimiento</p>
    <input class="profile-item_body" value="{{user.yearOfBirth}}" [readonly]="true" />
  </div>

  <div class="profile-item" *ngIf="!isReadOnly">
    <p class="profile-item_header">Año&nbsp;nacimiento</p>
    <select [(ngModel)]="user.yearOfBirth" class="profile-item_body" [disabled]="isReadOnly">
      <option *ngFor="let n of numbers" [value]="n">{{n}}</option>
    </select>
  </div>

  <div class="profile-item" *ngIf="isReadOnly">
    <p class="profile-item_header">Género</p>
    <input class="profile-item_body" value="{{user.gender | translate}}" [readonly]="true" />
  </div>

  <div class="profile-item" *ngIf="!isReadOnly">
    <p class="profile-item_header">Género</p>
    <select [(ngModel)]="user.gender" class="profile-item_body" [disabled]="isReadOnly">
      <option value="Femenino">{{'Female' | translate}}</option>
      <option value="Masculino">{{'Male' | translate}}</option>
    </select>

  </div>
  <div class="profile-edit">
    <button class="profile-edit_button" (click)="toggleReadOnly()">{{buttonText}}</button>
  </div>
</div>