<div class="filters col-lg-12">
  <p class="filters-text">Ordenar</p>
  <span (click)="changeOrder(1)" class="filters-icons" [class.active]="order.type === 1">
    <i class="iconlace" [ngClass]="{'iconlace-history': currentOrderDirection['1'] === 1, 'iconlace-history-revert': currentOrderDirection['1'] === 0 }"></i>
  </span>
  <span class="separator">|</span>
  <span (click)="changeOrder(0)" class="filters-icons" [class.active]="order.type === 0">
    <i class="iconlace" [ngClass]="{'iconlace-sort-alpha-asc': currentOrderDirection['0'] === 1, 'iconlace-sort-alpha-asc-revert': currentOrderDirection['0'] === 0 }"></i>
  </span>
</div>
