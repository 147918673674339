export enum typeOptionHeader {
  LIVE = 'live',
  MORE = 'more',
  USER = 'user',
  SEARCH = 'search',
}

export enum ActionHeader {
  LOGOUT = 'logout',
  LOGIN = 'login',
  CONTACT = 'contact',
  WHO = 'who',
  DONATE = 'donate'
}

