import { Component, OnInit } from '@angular/core';
import { IUser } from '@app/core/models/interfaces/i-user.interface';
import { LocalStorage } from 'ngx-webstorage';
import { UserV2Service } from 'app/api-client/v2/user-v2.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  @LocalStorage('user')
  user: IUser;

  isReadOnly: boolean = true;
  buttonText: string = 'Editar';
  numbers: Array<number>;
  Countries: Array<string> = [];

  constructor(private userService: UserV2Service) {
    const year: any = (new Date()).getFullYear();
    this.numbers = this.range(year - 109, year - 9);
  }


  range = (start, end) => Array.from({ length: (end - start) }, (v, k) => k + start);


  ngOnInit() {
    this.loadCountries();
  }

  toggleReadOnly(): void {

    if (this.buttonText === 'Guardar') {
      this.userService.updateUser(this.user).then(() => {
        localStorage.setItem('ng2-webstorage|user', JSON.stringify(this.user));
      });
    }
    this.isReadOnly = !this.isReadOnly;
    if (this.isReadOnly) {
      this.buttonText = 'Editar';
    } else {
      this.buttonText = 'Guardar';
    }
  }
  loadCountries(){
    this.Countries.push("Argentina");
    this.Countries.push("Costa Rica");
    this.Countries.push("Brasil");
    this.Countries.push("Bolivia");
    this.Countries.push("Chile");
    this.Countries.push("Colombia");
    this.Countries.push("Cuba");
    this.Countries.push("Rep. Dominicana");
    this.Countries.push("Ecuador");
    this.Countries.push("Guatemala");
    this.Countries.push("Guyana");
    this.Countries.push("Honduras");
    this.Countries.push("México");
    this.Countries.push("Nicaragua");
    this.Countries.push("Panamá");
    this.Countries.push("Perú");
    this.Countries.push("Puerto Rico");
    this.Countries.push("El Salvador");
    this.Countries.push("Uruguay");
    this.Countries.push("Venezuela");
    this.Countries.push("Estados Unidos");
    this.Countries.push("Otro");
  }
}
