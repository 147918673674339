import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appClickOutsideModal]'
})
export class ClickOutsideModalDirective {

  @Output() clickOutside = new EventEmitter<MouseEvent>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  handleClick(event: MouseEvent) {
    const modalElement = this.elementRef.nativeElement;
    if (!modalElement.contains(event.target as Node)) {
      const otherModals = document.querySelectorAll('.over-lapping-modal');
      let isOverlapping = false;
      otherModals.forEach((otherModal: HTMLElement) => {
        if (otherModal.contains(event.target as Node)) {
          isOverlapping = true;
        }
      });
      if (!isOverlapping) {
        this.clickOutside.emit(event);
      }
    }
  }

}
