import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownSelectModule } from '@components/dropdown-select/dropdown-select.module';
import { IssueDateFilterComponent } from './issue-date-filter.component';

@NgModule({
  imports: [
    CommonModule,
    DropdownSelectModule
  ],
  declarations: [IssueDateFilterComponent],
  exports: [IssueDateFilterComponent]
})
export class IssueDateFilterModule { }
