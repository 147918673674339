<div class="search-item search-box">
  <div class="d-inline-block simple-combo">
    <h2 class="search-item-title">Valoración</h2>
  </div>
  <div class="search-item-row" [class.cursor]="item.bookCount !== 0" *ngFor='let item of rating; let $index = index;' (click)="selectRating(item)">
    <i *ngFor="let i of Arr(item.stars).fill(0)" class='fa fa-star' [ngStyle]="{'color':
    '#4b5881'}"></i>
    <i *ngFor="let j of Arr(emptyStars[$index]).fill(0)" class='fa fa-star-o' [ngStyle]="{'color':
    '#4b5881'}"></i>
    <label>({{item.bookCount}})</label>
  </div>
</div>
