import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { IPodcastItemViewModel } from "@app/core/models/interfaces/i-podcast-view-model";
import { BehaviorSubject, Observable } from "rxjs";

export interface PodcastItem {
  isNewItem: boolean;
  playing: boolean;
  isNext: boolean
  index: number;
  currentItem: IPodcastItemViewModel | null;
}

@Injectable({
  providedIn: "root",
})
export class PodcastPlayerService {
  private _podcastItem$ = new BehaviorSubject<PodcastItem>({} as PodcastItem);
  private _page$ = new BehaviorSubject<number>(null);
  private _state: PodcastItem = {
    isNewItem: false,
    playing: false,
    isNext: true,
    currentItem: null,
    index: 0,
  };
  private _podcast = {
    podcastItems: [],
    total: 0,
    canPage: true,
  };

  private isNext = true;
  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  get podcastItem$(): Observable<PodcastItem> {
    return this._podcastItem$.asObservable();
  }

  get page$(): Observable<number> {
    return this._page$.asObservable();
  }

  get canPage(): boolean {
    return this._podcast.canPage;
  }

  get isFirstItem(): boolean {
    return this._podcast.podcastItems[0]?.id === this._state.currentItem?.id;
  }

  get isLastItem(): boolean {
    return (
      this._podcast.podcastItems[this._podcast.podcastItems.length - 1]?.id ===
        this._state.currentItem?.id && !this.canPage
    );
  }

  setPodcast(podcast: any): void {
    this._podcast = {
      ...this._podcast,
      ...podcast,
    };
  }

  prev(index: number): void {
    const prevIndex = index - 1;
    const prevItem = this._podcast.podcastItems[prevIndex];
    if (prevItem) {
      this.isNext = false;
      this.change({ ...prevItem, playing: true }, prevIndex);
    }
  }

  next(index: number): void {
    const nextIndex = index + 1;
    const nextItem = this._podcast.podcastItems[nextIndex];
    if (nextItem) {
      this.isNext = true;
      this.change({ ...nextItem, playing: true }, nextIndex);
    } else if (this.canPage) {
      this._page$.next(index);
    }
  }

  clearPage(): void {
    this._page$.next(null);
  }

  clearState(): void {
    this._podcastItem$.next({
      isNewItem: false,
      playing: false,
      isNext: true,
      currentItem: null,
      index: 0,
    });
  }

  change(item: IPodcastItemViewModel, index: number): void {
    this._state = {
      ...this._state,
      isNewItem: item?.id !== this._state.currentItem?.id,
      playing: true,
      isNext: this.isNext,
      currentItem: item,
      index,
    };
    this._state.isNewItem && this.animation();
    this.updatePodcastItems(item, index);
    this._podcastItem$.next(this._state);
  }

  animation(): void {
    this.resetAnimations();

    if (this._state.currentItem?.playing) {
      const element = document.getElementById(
        `podcast-item-${this._state.currentItem.id}`
      );

      if (element) {
        const button = element.querySelector(".draw");
        const tag = element.querySelector(".draw-tag");
        this.renderer.removeClass(button, "draw-init");
        this.renderer.addClass(tag, "draw-points");

        setTimeout(() => {
          this.renderer.addClass(button, "draw-revert");
          this.renderer.addClass(tag, "draw-tag-show");
        }, 2000);
      }
    }
  }

  resetAnimations(): void {
    const buttons = document.querySelectorAll(".draw");
    buttons.forEach((button) => {
      this.renderer.removeClass(button, "draw-revert");
      this.renderer.addClass(button, "draw-init");
    });

    const tags = document.querySelectorAll(".draw-tag");
    tags.forEach((tag) => {
      this.renderer.removeClass(tag, "draw-tag-show");
    });
  }

  updatePodcastItems(item: IPodcastItemViewModel, index: number): void {
    this._podcast.podcastItems[index] = item;
  }
}
