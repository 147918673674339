import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ElementRef } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { debounceTime, take } from "rxjs/operators";

import { ConvertCurrencyService } from '@services/lib/convert-currency.service';
import { SubscriptionSubmitService } from '@utils/subscription-submit.service';

import { IConvertCurrencyResponse } from '@interfaces/i-convert-currency-response';
import { DonationHeaderForm } from '@interfaces/i-donation-view-model';
import { DonationPlan, TypeCycleDonation } from '@enums/type-plan-active.enum';

@Component({
  selector: 'app-donation-header',
  templateUrl: './donation-header.component.html',
  styleUrls: ['./donation-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DonationHeaderComponent implements OnInit, OnDestroy {

  @Input() style: string;
  @Input() organizationName: string;
  @Input() fromMobile: boolean;
  @Input() fromModal: boolean;
  @Input() userCurrency: string = 'USD';

  @Output() donationHeaderForm: EventEmitter<DonationHeaderForm> = new EventEmitter();
  @Output() onCancelDonation: EventEmitter<boolean> = new EventEmitter();

  public form: UntypedFormGroup;
  public formSubmitted: boolean;
  public currencyConverted: IConvertCurrencyResponse;
  public frecuencyPeriod = DonationPlan;
  public cycleDonation = TypeCycleDonation;
  private subscription: Subscription;
  private subscriptionHeader: Subscription;
  private scrollEvent$;

  constructor(
    private subscriptionSubmitService: SubscriptionSubmitService,
    private convertCurrencyService: ConvertCurrencyService,
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private el: ElementRef,
  ) { }

  ngOnInit() {
    this.buildForm();
    this.subscription = this.isSubmitted$.subscribe(val => {
      if (val) this.submitInfoHeaderDonation();
    });
    this.subscriptionHeader = this.isHeaderDonation$.subscribe(val => {
      if (!!val && val.amount) {
        this.form.patchValue(val);
        this.onAmountChange();
        this.toggleCycle();
        this.markAllTouched();
        this.cdr.markForCheck();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptionHeader.unsubscribe();
    if (this.scrollEvent$)
      this.scrollEvent$.unsubscribe();
  }

  get isSubmitted$(): Observable<boolean> {
    return this.subscriptionSubmitService.isSubmitSubscription$;
  }

  get isHeaderDonation$(): Observable<DonationHeaderForm> {
    return this.subscriptionSubmitService.isHeaderSubscription$;
  }

  get amountField(): AbstractControl {
    return this.form.get('amount');
  }

  get cycleField(): AbstractControl {
    return this.form.get('cycle');
  }

  get cycleQuantityField(): AbstractControl {
    return this.form.get('cycleQuantity');
  }

  get getAmountConverted(): number {
    return this.currencyConverted ? this.currencyConverted.result : 0;
  }

  get frecuencyIsUnique(): boolean {
    return !!this.form && this.form.get('frequency').value === DonationPlan.Unique;
  }

  public cancelDonation(): void {
    this.onCancelDonation.emit(true);
  }

  public exchange(value: string): void {
    this.convertCurrencyService.convertCurrency('USD', this.userCurrency, value)
    .subscribe( res => {
      this.currencyConverted = res;
      this.cdr.markForCheck();
    });
  }

  public markAllTouched(): void {
    Object.keys(this.form.controls)
    .forEach(controlName => this.form.controls[controlName]
    .markAsTouched());
  }

  public submitInfoHeaderDonation(): void {
    this.formSubmitted = true;
    this.cdr.detectChanges();
    if (this.form.valid)
      this.donationHeaderForm.emit(this.form.value);
    else {
      this.donationHeaderForm.emit();
      this.scrollToFirstInvalidControl();
    }
  }

  public onAmountChange(): void {
    if (this.amountField.value) {
      const valor = parseFloat(this.amountField.value).toFixed(2);
      const amount = Math.abs(+valor);
      this.exchange(amount.toString());
    } else {
      this.currencyConverted = undefined;
    }
  }

  public toggleCycle(): void {
    if (!this.frecuencyIsUnique) {
      this.cycleQuantityField.clearValidators();
      if (this.cycleField.value === TypeCycleDonation.fixed)
        this.setEnableCycleQuantity();
      else
        this.setDisableCycleQuantity();
    }
  }

  public setEnableCycleQuantity(): void {
    this.cycleQuantityField.enable();
    this.cycleQuantityField.setValidators([Validators.required, Validators.min(1)]);
    this.cycleQuantityField.updateValueAndValidity();
  }

  public setDisableCycleQuantity(): void {
    this.cycleQuantityField.setValue(0);
    this.cycleQuantityField.disable();
  }

  public isInValid(control: string): boolean {
    return (this.form.get(control).touched && !!this.form.get(control).errors)
    || (this.form.get(control).untouched && this.formSubmitted);
  }

  public hasError(type: string, control: string): boolean {
    return !!this.form.get(control).errors && this.form.get(control).errors[type];
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      amount: ['', [Validators.required, Validators.min(1)]],
      currency: [{value: 0, disabled: true}],
      frequency: [DonationPlan.Unique, [Validators.required]],
      cycle: [TypeCycleDonation.fixed, [Validators.required]],
      cycleQuantity: [0],
    });
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      '.ng-invalid'
    );

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: 'smooth'
    });

    this.scrollEvent$ = fromEvent(window, 'scroll')
      .pipe(
        debounceTime(100),
        take(1)
      )
      .subscribe(() => firstInvalidControl.focus());
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    if(!controlEl) return;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }
}
