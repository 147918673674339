import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownSelectList } from '@interfaces/i-dropdown-select.interface';

@Component({
  selector: 'app-issue-date-filter',
  templateUrl: './issue-date-filter.component.html',
  styleUrls: ['./issue-date-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IssueDateFilterComponent  {

  @Input() filters: DropdownSelectList[] = [];
  @Input() currentValue: DropdownSelectList;
  @Output() onChangeIssueDate: EventEmitter<string> = new EventEmitter();

  constructor() { }

  changeFilter(event: string): void {
    this.onChangeIssueDate.emit(event);
  }

}
