import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScrollIntoViewElementDirective } from './scroll-into-view-element.directive';
import { BlockCopyPasteDirective } from './block-copy-paste.directive';
import { FeatureIsOnDirective } from './feature-is-on.directive';
import { ScrollTo } from './scrollTo.directive';
import { OnlyNumberDirective } from './only-numbers.directive';
import { FocusOnShowDirective } from './focus.directive';
import { ValidatedEmailValidatorDirective } from './validated-email.directive';
import { ClickOutsideItemDirective } from './click-outside-item.directive';
import { CollapseComponent } from './collapse.directive';
import { SwiperCustomDirective } from './swiper-custom.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { ScrollListenerDirective } from './scroll-listener.directive';
import { ClickOutsideModalDirective } from './click-outside-modal.directive';
import { PromotionAnimationDirective } from './promotion-animation.directive';

@NgModule({
  declarations: [
    OnlyNumberDirective,
    FeatureIsOnDirective,
    ScrollTo,
    ScrollIntoViewElementDirective,
    BlockCopyPasteDirective,
    FocusOnShowDirective,
    ValidatedEmailValidatorDirective,
    ClickOutsideItemDirective,
    CollapseComponent,
    SwiperCustomDirective,
    ClickOutsideDirective,
    ScrollListenerDirective,
    ClickOutsideModalDirective,
    PromotionAnimationDirective
  ],
  imports: [CommonModule],
  exports: [
    OnlyNumberDirective,
    FeatureIsOnDirective,
    ScrollTo,
    ScrollIntoViewElementDirective,
    BlockCopyPasteDirective,
    FocusOnShowDirective,
    ValidatedEmailValidatorDirective,
    ClickOutsideItemDirective,
    CollapseComponent,
    SwiperCustomDirective,
    ClickOutsideDirective,
    ScrollListenerDirective,
    ClickOutsideModalDirective,
    PromotionAnimationDirective
  ],
})
export class DirectivesModule {}

