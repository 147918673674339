import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewTaxeModalComponent } from './preview-taxe-modal.component';

@NgModule({
    imports: [CommonModule],
    declarations: [PreviewTaxeModalComponent],
    exports: [PreviewTaxeModalComponent]
})
export class PreviewTaxeModalModule { }
