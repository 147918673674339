import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { environment as ENV } from '@environment/environment';

import { FromOutSide,
  StartMigration, ValidateToken,
  UserLoggedIn, UserPayload, ResetPasswordPayload, StartForgotPassword,
  TokenAuth, BaseResult } from '@api/models/entities/auth.interface';
import { IUser } from '@interfaces/i-user.interface';
import { TypePropertyLocalStorage } from '@enums/type-property-localstorage.enum';
import { daysBetween } from '@helpers/set-days-by-month.helper';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private API = `${ENV.webApi}v2/user`;
  private refreshTokenTimeout;
  private _token: TokenAuth;
  @LocalStorage(TypePropertyLocalStorage.ENLACE_TOKEN)
  private _enlaceToken: TokenAuth;
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  get enlaceToken(): TokenAuth {
    return this._enlaceToken;
  }

  public getToken(): TokenAuth {
    if (!this._token) {
      const tokenString = localStorage.getItem(TypePropertyLocalStorage.ENLACE_TOKEN);
      if (tokenString) {
        this._token = JSON.parse(tokenString);
      }
    }
    return this._token;
  }

  public checkEmail(email: string): Observable<FromOutSide> {
    const url = `${this.API}/fromoutside`;
    return this.http.get<FromOutSide>(url, {
      params: { email: email },
    });
  }

  public authenticate(payload: UserPayload): Observable<UserLoggedIn> {
    const url = `${this.API}/authenticate`;
    return this.http.post<UserLoggedIn>(url, payload);
  }

  public startMigration(email: string, isFromMobile: boolean): Observable<StartMigration> {
    const url = `${this.API}/startUserMigration`;
    return this.http.post<StartMigration>(url, {email: email, isFromMobile: isFromMobile});
  }

  public startForgotPassword(email: string, isFromMobile: boolean): Observable<StartForgotPassword> {
    const url = `${this.API}/startForgotPassword`;
    return this.http.post<StartForgotPassword>(url, {email: email, isFromMobile: isFromMobile});
  }

  public userTokenValidation(
    userId: string,
    token: string
  ): Observable<ValidateToken> {
    const url = `${this.API}/userTokenValidation`;
    return this.http.post<ValidateToken>(url, { userid: userId, token });
  }

  public migrateAndAuthenticate(payload: ResetPasswordPayload): Observable<UserLoggedIn> {
    const url = `${this.API}/migrateAndAuthenticate`;
    return this.http.post<UserLoggedIn>(url, payload);
  }

  public resetPasswordAndAuthenticate(payload: ResetPasswordPayload): Observable<UserLoggedIn> {
    const url = `${this.API}/resetPasswordAndAuthenticate`;
    return this.http.post<UserLoggedIn>(url, payload);
  }

  public registerAndAuthenticate(payload: FormData): Observable<UserLoggedIn> {
    const url = `${this.API}/registerAndAuthenticate`;
    return this.http.post<UserLoggedIn>(url, payload);
  }

  public refreshToken(token: string): Observable<UserLoggedIn> {
    const url = `${this.API}/refresh-token`;
    return this.http
      .post<UserLoggedIn>(url, { refreshToken: token })
      .pipe(
        tap((response) => {
          if (response.result.resultEnum === BaseResult.OK) {
            this.saveTokenStorage({
              jwtToken: response.jwtToken,
              refreshToken: response.refreshToken,
            });
          }
          return response;
        })
      );
  }

  public saveUserStorage(user: IUser): void {
    this.localStorageService.store('user', user);
  }

  public saveTokenStorage(token: TokenAuth): void {
    this._token = token;
    localStorage.setItem(TypePropertyLocalStorage.ENLACE_TOKEN, JSON.stringify(token));
  }

  logout(): void {
    this._token = null;
    localStorage.removeItem(TypePropertyLocalStorage.ENLACE_TOKEN);
  }

  private startRefreshTokenTimer() {
    const jwtToken = JSON.parse(atob(this._enlaceToken.jwtToken.split('.')[1]));
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(
      () => this.refreshToken(this._enlaceToken.refreshToken).subscribe(),
      timeout
    );
  }

  public dayBeforeItExpires(): number {
    const jwtToken = JSON.parse(atob(this.getToken()?.jwtToken.split('.')[1]));
    const expires = new Date(jwtToken.exp * 1000);
    const days = daysBetween(new Date(), expires);
    return days;
  }


}
