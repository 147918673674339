import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { environment as ENV } from '@environment/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

//https://www.concretepage.com/angular/angular-meta-service-for-meta-tags
//https://ogp.me/
//https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/summary

  constructor(
    private meta: Meta,
    private title: Title,
    private location: Location
  ) {
    this.meta.addTags([
      { charset: 'UTF-8' },
      { httpEquiv: 'Content-Type', content: 'text/html' },
      { name: 'robots', content: 'INDEX, FOLLOW' },
      { property: 'og:type', content: 'website' }
    ]);
    this.setDefaults();
  }

  public setDefaults() {
    this.meta.removeTag('property = "og:title"');
    this.meta.removeTag('name = "description"');
    this.meta.removeTag('name = "keywords"');
    this.meta.removeTag('name = "author"');

    this.meta.removeTag('property = "og:description"');
    this.meta.removeTag('name = "twitter:title"');
    this.meta.removeTag('name = "twitter:description"');

    this.meta.addTags([
      { property: 'og:title', content: 'Enlace+' },
      { name: 'description', content: 'Contenido Cristiano para toda la familia' },
      {
        name: 'keywords',
        // tslint:disable-next-line: max-line-length
        content: 'Enlace Plus, Contenido Cristiano, Enlace Television, Enlace TV, Predicas Cristianas, Pastores Cristianos, Música Cristiana, Películas Cristianas, Series Cristianas, Jesús, Biblia, Concordancia Bíblica, Plataforma Cristiana, Dios, Cristianos, Cristiano Evangélico, Enalce canal cristiano, Ver enlace en vivo, EnlaceTV en vivo, Pastores de Enlace en vivo, Canal Enlace'
      },
      { name: 'author', content: 'Enlace TV' },
      { property: 'og:description', content: 'Contenido Cristiano para toda la familia' },
      { name: 'twitter:title', content: 'Enlace+' },
      { name: 'twitter:description', content: 'Contenido Cristiano para toda la familia' }
    ]);

    this.title.setTitle('Enlace+')
    this.setUrl(`${ENV.webBaseUrl}${this.location.path()}`)
  }

  public setBasicInformation(title: string, description: string, keywords: string) {
    this.setTitle(title)
    this.setDescription(description)
    this.setKeywords(keywords)
  }

  public setTitle(title: string) {
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ name: 'twitter:title', content: title });
    this.title.setTitle(title)
  }

  public setDescription(description: string) {
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ name: 'twitter:description', content: description });
    this.meta.updateTag({ name: 'og:description', content: description });
  }

  public setKeywords(keywords: string) {
    this.meta.updateTag({ name: 'keywords', content: keywords });
  }

  public setAuthor(author: string) {
    this.meta.updateTag({ name: 'author', content: author });
  }

  public setDate(date: string) {
    this.meta.updateTag({ name: 'date', content: date, scheme: 'YYYY-MM-DD' });
  }

  public setUrl(url: string) {
    this.meta.updateTag({ property: 'og:url', content: url });
  }

  public updateSEO({ title, description, tags, topic}) {
    this.setTitle(
      'Enlace+ Mas info: ' + title
    );

    this.setDescription(description);

    let keywords = `${tags ? tags.join(",") : ''}${tags ? ',' : ''}enlace,Dios,Jesús`;

    if (topic) {
      keywords = `${keywords},${topic}`
    }

    this.setKeywords(keywords);
  }

  public resetSEO() {
    this.setBasicInformation(
      "Enlace+",
      "Contenido Cristiano para toda la familia",
      "Enlace Plus, Contenido Cristiano, Enlace Television, Enlace TV, Predicas Cristianas, Pastores Cristianos, Música Cristiana, Películas Cristianas, Series Cristianas, Jesús, Biblia, Concordancia Bíblica, Plataforma Cristiana, Dios, Cristianos, Cristiano Evangélico, Enalce canal cristiano, Ver enlace en vivo, EnlaceTV en vivo, Pastores de Enlace en vivo, Canal Enlace"
    );
  }
}
