import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IPagingResponseModel} from '@interfaces/paging/ipaging-response-model';
import {PagerService} from '../../../api-client/pager.service';

export class CustomFilter {
  name: string;
  value: string;
}

export class ChangePage {
  statusFilter: number;
  organizationIdFilter?: string;
  personIdFilter?: string;
  page: number;
  offset: number;
  search: string;
  orderProperty?: string;
  customFilters: Array<CustomFilter>;
}

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  totalPages: number = 1;
  pages = [];
  startPage = 1;
  private _totalRows = 0;
  private _currentPage = 1;
  private _rowsPerPage = 15;

  @Input() searchPlaceholder = 'Búsqueda';
  @Input() searchText = '';
  @Input() showInfoResults: boolean = true;

  @Input()
  set totalRows(value: number) {
    if (value) {
      this._totalRows = value;
    }
  }

  get totalRows(): number {
    return this._totalRows;
  }

  @Output() changePage: EventEmitter<ChangePage> = new EventEmitter<ChangePage>();

  @Input()
  set rowsPerPage(value: number) {
    if (value) {
      this._rowsPerPage = value;
    }
  }

  get rowsPerPage(): number {
    return this._rowsPerPage;
  }


  setPage(page: number) {
    if (page < 1 || page > this.totalPages || this.currentPage === page) {
      return;
    }
    this.currentPage = page;
    this.calculateTotalPages()
    this.changePage.emit(<ChangePage>{page: this.currentPage, offset: (this.currentPage * this.rowsPerPage - this.rowsPerPage)});
  }

  @Input()
  set currentPage(value: number) {
    this._currentPage = value;
  }

  get currentPage(): number {
    return this._currentPage;
  }

  constructor(private pagerService: PagerService) {
  }

  ngOnInit() {
    console.log('current page: ', this.currentPage);
    this.calculateTotalPages();
  }

  private calculateTotalPages() {
    const totalPages = Math.ceil(this.totalRows / this.rowsPerPage);
    let startPage: number, endPage: number;

    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (this.currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (this.currentPage + 1 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {

        if ((totalPages - (this.currentPage - 2)) === 5) {
          startPage = this.currentPage - 1;
          endPage = this.currentPage + 3;
        } else {
          startPage = this.currentPage - 2;
          endPage = this.currentPage + 2;
        }
      }
    }

    // calculate start and end item indexes
    const startIndex = (this.currentPage - 1) * this.rowsPerPage;
    const endIndex = Math.min(startIndex + this.rowsPerPage - 1, this._totalRows - 1);

    // create an array of pages to ng-repeat in the pager control
    this.pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    this.totalPages = totalPages;
  }
}
