<ng-container *ngIf="podcastDetail">
  <app-header-podcast (redirectToContent)="redirectToContent.emit()">
  </app-header-podcast>

  <div class="podcast-main" id="podcast-main">
    <div class="podcast-info">
      <div class="podcast-organization">
        <a
          *ngIf="podcastDetail?.organization"
          class="podcast-organization-text"
          (click)="redirectOrganization()"
          [ngClass]="{ 'cursor-none': !podcastDetail.organizationPublished }"
        >
          {{ podcastDetail.organization }}
        </a>
        <app-score
          [tooltipText]="'Para calificar este podcast escucha al menos un 90% de cualquier episodio'"
          [starInfo]= "enableScore"
          [average]= "podcastDetail.rating"
          [contentId]= "podcastDetail.contentId"
          [fullWidth]="true">
        </app-score>
      </div>
      <div class="podcast-title">{{ podcastDetail.title }}</div>
    </div>
    <div class="podcast-option">
      <app-follow
        [contentId]="podcastDetail.contentId"
        [isFollowing]="isFollow"
      >
      </app-follow>
      <app-share
        [mode]="'full'"
        [id]="podcastDetail.contentId"
        [contentType]="contentType"
        [urlHome]="false"
        [className]="'sharePosition'"
        [title]="podcastDetail.title"
        [description]="podcastDetail.description"
        [fromAnotherElement]="true"
      >
        <div textShare class="share-text">COMPARTIR</div>
      </app-share>
    </div>
    <div class="podcast-host">
      <ng-container *ngIf="podcastDetail?.hostsList">
        <app-host
          [fromEbook]="false"
          [hosts]="podcastDetail?.hostsList"
        ></app-host>
      </ng-container>
    </div>
    <div class="podcast-description">
      <p class="podcast-description-text">
        {{ podcastDetail?.description | cuttext : 192 : seeMore.seeMore }}
      </p>
      <span
        #seeMore
        class="podcast-description-see-more"
        (click)="
          seeMore.seeMore = !seeMore.seeMore; checkIfModalExceedsViewport()
        "
        style="cursor: pointer"
        [hidden]="podcastDetail?.description?.length <= 192"
      >
        {{ !seeMore.seeMore ? "LEER MÁS" : "LEER MENOS" }}
      </span>
    </div>
    <div class="podcast-list" id="list-podcast">
      <app-podcast-list
        [podcastItems]="podcastDetail.podcastItems"
        [podcastTitle]="podcastDetail.title"
        [canPage]="canPaging"
        [loadingSkeleton]="isPaging"
        (paginatePodcastList)="paginatePodcastList()">
      </app-podcast-list>
    </div>
    <div *ngIf="isPlayer" class="podcast-player" id="podcast-player" #podcastPlayer>
      <app-podcast-player [podcastId]="podcastDetail.contentId"></app-podcast-player>
    </div>
  </div>
</ng-container>
