
export interface IOfficesViewModel {
  name: string,
  latitude: string,
  longitude: string,
  email: string,
  workPhone: string,
  cellPhone: string,
  address: string,
  region: string,
  imageurl: string
}

export enum Region {
  SUDAMERICA = 'Sudamérica',
  NORTEAMERICA = 'Norteamérica',
  CARIBE = 'Caribe',
  CENTRO_AMERICA = 'Centroamérica'
}
