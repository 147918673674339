<div class="score-component" [class.inModal]="fullWidth">
  <div class="row">
    <div class="col-sm-12">
      <div class='stars-content'>
        <div
          ngbTooltip="{{tooltipText}}"
          triggers="manual"
          placement="top-left"
          #t="ngbTooltip"
          class="stars"
          (mouseover)="openTooltip(t)" (mouseout)="closeTooltip(t)" (click)="openTooltip(t)">
          <i [ngStyle]="{'color':
          overItem && !item.listItemHovered
          ? colorHover: color }"
             (mouseover)="hoverListItem(item)"
             (mouseout)="hoverListItem(item)"
             *ngFor='let item of items; let $index = index;' class='star-item to-rate fa'
             (click)='mark(item,t)'
             [ngClass]='className(item)'></i>
          <div class="number-container" [class.not-over-item]="!overItem">
            <span *ngIf="!overItem && activeTooltip" class="number"> {{average.toFixed(1) | number:'1.0-0' }}</span>
            <span *ngIf="overItem && totalVotes == 1 && activeTooltip" class="number" > {{totalVotes}} Persona </span>
            <span *ngIf="overItem && totalVotes != 1 && activeTooltip" class="number"> {{totalVotes}} Personas</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
