import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {Observer, Observable} from "rxjs";
import {Hero} from "./models/hero";
import {RestClient} from "./rest-client";
import {RestParams, IRestMethod, RestAction, RestHandler} from "rest-core";
import {EnlaceRestHandler} from "./enlace-rest-handler";
import {AuthenticationService} from "./authentication.service";
import { Router } from '@angular/router';


@RestParams({
  pathPrefix: environment.webApi + 'heros'
})
@Injectable()
export class HeroService extends  RestClient{

  result: Hero = null;

  constructor(restHandler: EnlaceRestHandler, authService: AuthenticationService, private router: Router) {
    super(restHandler, authService);
  }

  @RestAction({
    path: '/'
  })
  getRandom: IRestMethod<void, Hero>;


  @RestAction({
    path: '/{!id}',
  })
  get: IRestMethod<{id: any}, Hero[]>;


  getRandomCache(): Observable<Hero> {
    return Observable.create(observer => {
      if (this.result) {
        observer.next(this.result);
      } else {
        this.getRandom().then((result?: Hero) => {
          this.result = result;
          observer.next(this.result);
        }).catch((e) => {
          console.warn('called .catch on %o arguments %o', this, e);
        });
      }



    });
  }

}
