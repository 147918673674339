<div *ngIf="!fromGiftCode" class="payment-error">
    <div class="payment-header">
        <button type="button" class="payment-header-cross" aria-label="Close" (click)="closeModal()">
          <span class="payment-header-figure" aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="payment-content">
        <div class="payment-avatar">
            <i class="iconlace iconlace-Icono-alerta"></i>
        </div>
        <div class="payment-title-container">
            <h1 class="payment-title">
                ¡Hubo un error al {{title}} este contenido!
            </h1>
            <p class="payment-subtitle"> Verifica tus datos e inténtalo nuevamente. </p>
        </div>
        <div class="payment-button">
            <button type="button" class="btn payment-button-accept" (click)="closeModal()">ACEPTAR</button>
        </div>
        <div class="payment-info">
            <p class="payment-info-text">Si el problema persiste, escribe a:</p>
            <a class="payment-link"  href="mailto:soporte@enlace.plus">
                soporte@enlace.plus
            </a>
        </div>
    </div>
</div>
<div *ngIf="fromGiftCode"  class="payment-error-gf">
    <div class="payment-header">
        <button type="button" class="payment-header-cross-gf" aria-label="Close" (click)="closeModal()">
          <span class="payment-header-figure" aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="payment-content">
        <div class="payment-avatar">
            <i class="iconlace iconlace-Icono-alerta"></i>
        </div>
        <div class="payment-title-container">
            <h1 class="payment-title">
              Error
            </h1>

            <p class="payment-subtitle-giftCode" *ngIf="errorType != 'error'">
            Parece que ingresaste un código de acceso {{ errorType }}.
            </p>

            <p class="payment-subtitle-error" *ngIf="errorType == 'error'">
            Parece que hubo un error al intentar procesar tu adquisición.
            </p>

            <p class="payment-subtitle-giftCode-secundary">
            Verifícalo e intenta de nuevo.
            </p>
        </div>
        <div class="payment-button">
            <button type="button" class="btn payment-button-accept" (click)="closeModal()">ACEPTAR</button>
        </div>
    </div>
</div>
