import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'daysLeftText'
})
export class DaysLeftTextPipe implements PipeTransform {

  transform(expireDate: string, validDays: number): string {
    const ramainding = expireDate != null ? this.dateDiff(expireDate) : null;

    if (expireDate !== null) {
      if (ramainding === 0) return 'Último día de alquiler';
      if (ramainding === 1) return 'Queda ' + ramainding + ' día de alquiler';
      return 'Quedan ' + ramainding + ' días de alquiler';
    }

    if (validDays !== null) {
      return (
        'Te quedan ' +
        validDays +
        ' días para disfrutarlo a partir del momento que lo inicies'
      );
    }

    return '';
  }

  private dateDiff(date: string): number {
    const diffInMs: number = Date.parse(date) - Date.now();
    const diffInHours: number = Math.floor(diffInMs / 1000 / 60 / 60);
    const days: number = Math.floor(diffInHours / 24);
    return days;
  }

}
