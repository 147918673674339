<div class="search-wrapper" [ngClass]="{'padding-fixed': isHiddenHeader()}">
  <div class="search-background">
    <div class="search-content" [class.isMobile]="isMobile">
      <div class="search-filter"  *ngIf="!isMobile">
        <button type="button" class="filter-btn">
          <i class="iconlace iconlace-filter-mixer"></i>
          Filtros
        </button>
      </div>
      <div class="search-v2">
        <app-search-v2
          [active]="true"
          [isMobile]="isMobile"
          [isMobileWebView]="isMobileWebView"
          [showFilter]="isMobile"
          [classType]="'section-search'"
          (emitToggleFilter)="toggleFilter($event)">
        </app-search-v2>
      </div>
      <div class="search-cancel" *ngIf="!isMobile" (click)="goBack()">
        <button type="button" class="cancel-btn">Cancelar</button>
      </div>
    </div>
  </div>
</div>

