<div class="share-container-component">
  <button (click)="showShare = !showShare" class="share-button" [style.color]="color">
    <i class="iconlace iconlace-share"></i>
    <span class="share-text-container">
      {{'share' | translate}}
    </span>
  </button>

  <div class="options-share" [ngClass]="{'show': showShare}">
    <ul class="social-share">
      <li>
        <a class="social-link btn-twitter"
          (click)="trackShare('3')">
          <share-button button="twitter" [url]="url" [description]="description" [title]="title">
          </share-button>
        </a>
      </li>
      <li>
        <a class="social-link btn-facebook"
          (click)="trackShare('1')">
          <share-button button="facebook" [url]="url" [description]="description" [title]="title">
          </share-button>
        </a>
      </li>
      <li>
        <a class="social-link btn-link"
          (click)="copyToClipboard()">
          <button>
            <i class="iconlace iconlace-link clipboard"></i>
          </button>
        </a>
      </li>
    </ul>
  </div>
</div>
