import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IContentCatalogViewModel} from '@core/models/interfaces/i-content-catalog-view-model';
declare const Android: any;
@Component({
  selector: 'app-content-item',
  templateUrl: './content-item.component.html',
  styleUrls: ['./content-item.component.scss']
})
export class ContentItemComponent implements OnInit {

  @Input()
  content: IContentCatalogViewModel;

  @Input()
  type: string;

  @Input()
  imageUrl: string;

  @Input()
  title: string;

  @Input()
  subtitle: string;

  @Input()
  isWebview: boolean = false;

  @Input() textTooltip: string = 'Dejar de seguir';

  @Input() isFollow: boolean = true;

  @Input()
  contentId: string;
  typeRedirect: string;
  @Output()
  unFollow = new EventEmitter<string>();
  unFollowClicked: boolean;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {

  }

  ngOnInit() {
    switch (this.type) {
      case 'person':
        this.typeRedirect = 'people';
        break;
      case 'podcastitem':
        this.typeRedirect = 'podcast';
        break;
      case 'Organization':
        this.typeRedirect = 'organization';
        break;
      default:
        this.typeRedirect = this.type;
        break;
    }
  }

  get isTopic(): boolean {
    return this.type === 'topic';
  }

  MyUnFollow(contentFollowId: string) {
    this.unFollow.emit(contentFollowId);
    this.unFollowClicked = true;
  }

  navigate() {
    if (this.isWebview && (typeof Android !== 'undefined')) {
      Android.redirectContent(`${this.typeRedirect}/${this.contentId}`);
    }else{
      this.router.navigate(['/' + this.typeRedirect, this.contentId]);
    }
  }

  isBook(type) {
    return type === 'audiobook' || type === 'ebook';
  }
}
