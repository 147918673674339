import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {

  transform(values: any[], joinCaracter: string, joinField: string): any {
    if(values && values.length > 0) {
      const array: any[] = [];
      values.forEach((value) => {
        array.push(value[joinField]);
      });
      return array.join(joinCaracter);
    }

    return '';

  }

}
