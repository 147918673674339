import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubscriptionBodyModule } from '@components/subscription-body/subscription-body.module';
import { DonationHeaderModule } from '@components/donation-header/donation-header.module';

import { DonationFormComponent } from '@components/donation-form/donation-form.component';

@NgModule({
  imports: [
    CommonModule,
    DonationHeaderModule,
    SubscriptionBodyModule,
  ],
  exports: [DonationFormComponent],
  declarations: [DonationFormComponent]
})
export class DonationFormModule { }
