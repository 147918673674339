<div class="checkbox-container">
  <label class="checkbox-label">
    <input
      id="{{'checkbox-id-' + label}}"
      type="checkbox"
      [ngModel]="model"
      [disabled]="disabled"
      (ngModelChange)="onChanges($event)">
    <span class="checkbox-custom rectangular {{classType}}"></span>
  </label>
  <label class="label-text {{classType}}" for="{{'checkbox-id-' + label}}">{{label}}</label>
</div>
