import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { IPodcastViewModel } from '@core/models/interfaces/i-podcast-view-model';
import { IContentCategoryViewModel } from '@core/models/interfaces/i-content-category-view-model';
import { IArticleViewModel } from '@core/models/interfaces/i-articles-view-model';
import { IContentImage } from '@app/core/models/interfaces/i-content-image';

@Component({
  selector: 'app-carousel-center',
  templateUrl: './carousel-center.component.html',
  styleUrls: ['./carousel-center.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselCenterComponent implements OnInit {

  @Input() podcastsList: IPodcastViewModel[];
  @Input() catList: IContentCategoryViewModel[] | any[];
  @Input() article: IArticleViewModel;
  @Input() newHeroArticle: boolean;
  @Input() isLibrary: boolean = false;
  @Input() showFilter: boolean;
  @Input() featured = [];
  @Input() comunityList = [];
  @Input() isComunity: boolean;
  @Output() cpsItem = new EventEmitter<string>();
  @Output() openMoreInfoModal = new EventEmitter<string[]>();

  IsPodcast: boolean = false;
  IsArticle: boolean = false;
  options: any = {
    wrapAround: true,
    freeScroll: true,
    pageDots: false,
    draggable: false,
    autoPlay: true,
    groupCells: false,
    resize: true,
    imagesLoaded: true,
    initialIndex: 0,
    cellAlign: 'center'
  };

  constructor() { }

  ngOnInit() {
    if(this.podcastsList && this.podcastsList.length > 0) {
      this.IsPodcast = true;
    } else {
      if (!this.isLibrary && !this.newHeroArticle)
        this.IsArticle = true;
    }
  }

  openMoreInfo(itemId: any, itemType: any){
    this.openMoreInfoModal.emit([itemId, itemType]);
  }

  openDetail(item: any): void {
    this.cpsItem.emit(item.id);
  }

}
