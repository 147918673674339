import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-donation-popup-success',
  templateUrl: './donation-popup-success.component.html',
  styleUrls: ['./donation-popup-success.component.scss']
})
export class DonationPopupSuccessComponent {

  public isSuccess: boolean;
  public isLoggedIn: boolean;
  public fromMobile: boolean;

  constructor(private modal: NgbActiveModal) {}

  public actionFirstButton(): void {
    this.modal.close('ok');
  }

  public clickCancel(): void {
    this.modal.close('close');
  }
}
