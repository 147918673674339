import { Pipe, PipeTransform } from '@angular/core';
import { StatusDonation } from '@interfaces/i-donation-view-model';

@Pipe({
  name: 'getStatusIconTransactions'
})
export class GetStatusIconTransactionsPipe implements PipeTransform {

  private _dictionary = {
    [StatusDonation.UniqueReturned]: 'iconlace-status-unique-returned',
    [StatusDonation.Active]: 'iconlace-status-approved',
    [StatusDonation.Inactive]: 'iconlace-status-inactivated',
    [StatusDonation.Reclaimed]: 'iconlace-status-reclaimed',
    [StatusDonation.Rejected]: 'iconlace-status-rejected',
    [StatusDonation.RejectedReturned]: 'iconlace-status-rejected-returned',
    [StatusDonation.RecurrentCanceled]: 'iconlace-status-recurrent-canceled',
    [StatusDonation.RecurrentReturned]: 'iconlace-status-unique-returned',
    [StatusDonation.RequestCancellation]: 'iconlace-status-request-cancellation',
    [StatusDonation.Approved]: 'iconlace-status-approved',
    [StatusDonation.Success]: 'iconlace-status-approved',
  }

  transform(value: string): string {
    return this._dictionary[value] || 'iconlace-info-circle-solid';
  }

}
