import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';


@Component({
  selector: 'app-filter-tags',
  templateUrl: './filter-tags.component.html',
  styleUrls: ['./filter-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterTagsComponent implements OnChanges {

  @Input() filterTags: any[];
  @Output() emitRemoveFilter: EventEmitter<any> = new EventEmitter();
  @Output() emitClearFilters: EventEmitter<any> = new EventEmitter();

  private flickity = {
    freeScroll: false,
    cellAlign: 'left',
    contain: true,
    draggable: false,
    prevNextButtons: false,
    pageDots: false
  }

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterTags.currentValue.length > 5)
      this.flickity = {...this.flickity, draggable: true, freeScroll: true};
  }

  get ThereAreFilterTags(): boolean {
    return !!this.filterTags && this.filterTags.length > 0;
  }
  public removeFilter(item: any): void {
    this.emitRemoveFilter.emit(item);
  }
  public clearFilters(): void {
    this.emitClearFilters.emit();
  }

}
