import {Injectable} from '@angular/core';
import {Observer, Observable} from 'rxjs';
import {RestParams, IRestMethod, RestAction, RestHandler, RestRequestMethod} from 'rest-core';
import {EnlaceRestHandler} from '../enlace-rest-handler';
import {AuthenticationService} from '../authentication.service';
import {RestClient} from '../rest-client';
import {IPagingRequestModel} from '@core/models/interfaces/ipaging-request-model';
import {IPagingResponseModel} from '@core/models/interfaces/paging/ipaging-response-model';

export abstract class BaseService<T> extends RestClient {

  protected _list: T[];

  constructor(restHandler: EnlaceRestHandler, authService: AuthenticationService) {
    super(restHandler, authService);
  }

  @RestAction({
    path: '/paging',
    method: RestRequestMethod.Post
  })
  paging: IRestMethod<IPagingRequestModel, IPagingResponseModel<T>>;

  @RestAction({
    path: '/list',

  })
  list: IRestMethod<void, T[]>;


  @RestAction({
    path: '/get/{!id}',
  })
  get: IRestMethod<{ id: any }, T>;


  @RestAction({
    path: '/add',
    method: RestRequestMethod.Put
  })
  add: IRestMethod<T, T>;

  @RestAction({
    path: '/update',
    method: RestRequestMethod.Post

  })
  update: IRestMethod<T, T>;

  @RestAction({
    path: '/delete/{!id}',
    method: RestRequestMethod.Delete
  })
  remove: IRestMethod<{ id: any }, void>;

  listCache(): Promise<T[]> {
    return new Promise<T[]>((resolve, reject) => {
      if (this._list) {
        resolve(this._list);
      } else {
        this.list().then((list: T[]) => {
          this._list = list;
          resolve(this._list);
        });
      }
    });
  }
}
