import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked} from '@angular/core';
import {ITranscription} from '@interfaces/i-transcriptions';
import { VideoV3Service } from '@services/video-v3.service';
import {IVVideo} from '@interfaces/i-v-video';

@Component({
  selector: 'app-transcriptions',
  templateUrl: './transcriptions.component.html',
  styleUrls: ['./transcriptions.component.scss']
})
export class TranscriptionsComponent implements OnInit, AfterViewChecked {

  private static instance: TranscriptionsComponent;
  _video: IVVideo;
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() clickTranscription: EventEmitter<number> = new EventEmitter<number>();
  @Input()
  fromSearch = false;
  @Input()
  search = '';
  @Input() index: string;
  @Input() isLast: boolean = false;
  @Input() isOdd: boolean = false;
  @Input() isEven: boolean = false;
  @Input() currentTime: number;

  isSearchOpen: boolean = false;
  transcriptions: ITranscription[];
  transcriptionsSearch: ITranscription[] = [];
  transcription: ITranscription;
  showTranscriptionsClose: boolean = false;
  currentTranscription: ITranscription[];
  counter: number = 0;
  removeDiacritics: any;

  constructor(
    private transcriptionsServices: VideoV3Service
  ) { }

  ngOnInit() {
    this.removeDiacritics = require('diacritics').remove;
  }

  ngAfterViewChecked() {
    this.scrollTranscription();
  }

  @Input()
  set video(value: IVVideo) {
    this._video = value;
    this.showTranscriptions(this.search);
    if (TranscriptionsComponent.instance) {
      TranscriptionsComponent.instance.close.emit();
    }
    TranscriptionsComponent.instance = this;
  }

  get video(): IVVideo {
    return this._video;
  }

  clearTranscriptions() {
    this.transcriptions = this.transcriptionsSearch;
    this.search = '';
    this.showTranscriptionsClose = false;
  }

  filterTranscriptions() {
    this.transcriptions = this.transcriptionsSearch.filter((search) => {
      return search.lines.find((line) => {
        const searchTerm = this.removeDiacritics(this.search);
        const lineTerm = this.removeDiacritics(line);
        return new RegExp('\\b' + searchTerm.trim().toLowerCase() + '\\b').test(lineTerm.toLowerCase());
      });
    });
    if (this.search === '') {
      this.showTranscriptionsClose = false;
    } else {
      this.showTranscriptionsClose = true;
    }
  }

  timeUpdate(time: number) {
    if(this.transcriptions != null) {
      const t = this.transcriptions.find(f => {
        const sec = f.startTime / 1000;
        return Math.trunc(time) === Math.trunc(sec);
      })

      if (t) {
        const myElement = document.getElementById('time' + (Math.trunc(t.startTime / 1000)));
        myElement.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'end'}); // Top
      }
    }
  }

  private showTranscriptions(value) {
    if (this.video) {
      this.transcriptionsServices.getTranscriptions(this.video.id, null).subscribe((transcriptions: ITranscription[]) => {
        this.transcriptions = transcriptions;
        this.transcriptionsSearch = this.transcriptions;
        if (this.search) {
          this.filterTranscriptions();
        }
      }, () => this.transcriptions = []);
    }
  }

  toggleTranscriptions() {
    this.close.emit(false);
  }

  searchTranscriptions(value) {
    this.showTranscriptions(value);
  }

  onClickTranscription(transcription: ITranscription) {
    this.clickTranscription.emit(transcription.startTime);
  }

  scrollTranscription() {
    if (this.transcriptions) {
      this.currentTranscription = this.transcriptions.filter(transcription => Math.trunc(this.currentTime) === Math.trunc(transcription.startTime / 1000));
      // console.log(this.currentTranscription);
      // this.transcriptions.forEach(transcription => {
      //   if (Math.trunc(this.currentTime) === Math.trunc(transcription.startTime / 1000)) {
      //     let textTranscription = jQuery('.transcriptions-duration').eq(this.counter).text();
      //     console.log(textTranscription);
      //     this.counter = this.counter + 1;
      //     // this.transcriptionsContent.push(transcription);
      //   }
      // });
      if (this.currentTranscription.length !== 0) {
        // let content = jQuery('.transcriptions-duration ~ p');
        // console.log(content);
      }
      if (this.currentTranscription.length !== 0) {
        // if (jQuery('.transcriptions-duration').text() === this.currentTranscription[0].startTime) {
        //   let durationText = jQuery('.transcriptions-duration').text()
        //   console.log(durationText);
        // }
      }
    }
  }
}
