import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getImageMethodDonations'
})
export class GetImageMethodDonationsPipe implements PipeTransform {
  transform(value: string): string {
    const gateWay = value.toLowerCase();
    if (gateWay === 'paypal')
      return 'assets/img/payment/logo-paypal.webp';
    if (gateWay === 'paypalpro')
      return 'assets/img/payment/cards/card-UN.svg';
    if (gateWay === 'stripe')
      return 'assets/img/payment/cards/card-VI.svg';
    if (gateWay === 'apple pay')
      return 'assets/img/payment/logo-applepay.webp';
    if (gateWay === 'google pay')
      return 'assets/img/payment/logo-googlepay.webp';
    if (gateWay === 'dlocal')
      return 'assets/img/payment/logo-dlocal.webp';
    if (gateWay === 'amazon pay')
      return 'assets/img/payment/logo-amazonpay.webp';
    if (gateWay === 'creditcard')
      return 'assets/img/payment/cards/card-VI.svg';
  }

}
