import { Component, OnDestroy, OnInit } from "@angular/core";
import { LoadingOverlayService } from "@app/shared/services/loading-overlay.service";
import { AnimationOptions } from "ngx-lottie";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-loading-overlay",
  templateUrl: "./loading-overlay.component.html",
  styleUrls: ["./loading-overlay.component.scss"],
})
export class LoadingOverlayComponent implements OnInit, OnDestroy {

  basePath = "/assets/json/lottie-loading-overlay.json";
  configLottie: AnimationOptions;

  private destroy$ = new Subject<void>();

  constructor(private loadingOverlayService: LoadingOverlayService) {}

  ngOnInit(): void {
    this.loadingOverlayService.useCustomAnimation$
    .pipe(takeUntil(this.destroy$))
    .subscribe((path) => 
      this.configLottie = {
        ...this.configLottie,
        path: path ? path : this.basePath
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  get loadingOverlay$(): Observable<boolean> {
    return this.loadingOverlayService.isLoading$;
  }

}
