import { IPagingRequestModel } from '@interfaces/ipaging-request-model';

export interface ISearchRequestModel extends IPagingRequestModel {
  catalogId: string;
  organizationId: string;
  countryId: string;
  languageId: string;
  topicId: string;
  bookCategoryId: string;
  search: string;
  newSearch: boolean;
  clearSearch: boolean;
  type: string;
  personHostId: string;
  showAll: boolean;
  orders: IOrderBy[];
  sectionName: string;
  price: number;
  rating: number;
  isRecent: boolean;
  airDateFilter: string;
  airDateBegin: string;
  airDateEnd: string;
}

export interface IOrderBy {
  column: string;
  desc: boolean;
}

export interface PayloadAcquireByAccessCode {
  contentId: string;
  accessCode: string;
  userId: string;
};


export interface ResultAcquireByAccessCode {
  result: EnumAcquireByAccessCode;
}

export enum EnumAcquireByAccessCode {
  SUCCESS = 0,
  INVALID_CODE = 1,
  EXPIRED_CODE = 2,
  ERROR = 3
};
