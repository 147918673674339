import { Component, Input } from '@angular/core';
import { IContentCatalogViewModel } from '@core/models/interfaces/i-content-catalog-view-model';

@Component({
  selector: 'app-slider-image',
  templateUrl: './slider-image.component.html',
  styleUrls: ['./slider-image.component.scss'],
})
export class SliderImageComponent {

  srcFallback: string;
  @Input() isMyList: boolean = false;
  @Input() isMySeen: boolean = false;
  @Input() isSeason: boolean = false;
  @Input() isViewTracking: boolean = false;
  @Input() catalogType: string;
  _contentCatalog: IContentCatalogViewModel;
  srcImage: string;
  //esto hay que sacarlo
  @Input()
  videoImages: any;
  @Input()
  catalogTypeId: any;

  constructor() {}

  @Input()
  set contentCatalog(contentCatalog: IContentCatalogViewModel) {
    this._contentCatalog = contentCatalog;
    if (this.isMyList || this.isMySeen) {
      this.srcImage = this._contentCatalog.thumbnailUrl;
    } else if (this.isViewTracking && this._contentCatalog.urlBackgroundImage || this.isPerson) {
      this.srcImage = this._contentCatalog.urlBackgroundImage;
    } else {
      if (this.isVideo)
        this.srcImage = !!this._contentCatalog.urlImage
          ? this._contentCatalog.urlImage
          : this._contentCatalog.thumbnailUrl;
      else this.srcImage = this._contentCatalog.urlImage;
    }
  }

  get contentCatalog(): IContentCatalogViewModel {
    return this._contentCatalog;
  }

  get isVideo(): boolean {
    return this.contentCatalog.type === 'video';
  }

  get isPerson(): boolean {
    return this.contentCatalog.type === 'person';
  }

  public handleError(): void {
    if (this.isMyList) {
      this.srcImage = 'assets/img/no-image.webp';
    } else if (this.catalogType === 'Vertical') {
      this.srcImage = 'assets/img/no-image-v.webp';
    } else {
      this.srcImage = 'assets/img/no-image.webp';
    }
  }
}
