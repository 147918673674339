import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'NameMenuClassIcon'
})

export class NameClassMenuIconPipe implements PipeTransform {
    transform(value: string, contentType: any): string {
        switch (contentType) {
            case 'AUDIOLIBROS':
                return value + ' iconlace iconlace-audiobook';
            case 'ARTICULOS':
            case 'ARTÍCULOS':
                return value + ' iconlace iconlace-article';
            case 'LIBROS DIGITALES':
                return value + ' iconlace iconlace-ebook';
            case 'MINISTERIOS':
                return value + ' iconlace iconlace-ministries';
            case 'PERSONAS':
                return value + ' iconlace iconlace-people';
            case 'EPISODIOS DE PODCAST':
            case 'PODCASTS':
                return value + ' iconlace iconlace-podcast';
            case 'PROGRAMAS':
                return value + ' iconlace iconlace-program';
            case 'TEMAS':
                return value + ' iconlace iconlace-topic';
            case 'VIDEOS':
                return value + ' iconlace iconlace-video';
        }
        return '';
    }
}