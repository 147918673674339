<div class="slider-audiobook slider-audiobook--strip" (click)="onClickCaratuta.emit()" 
[class.not-library]="!myLibrary && !isWebview" [class.not-library-webview]="!myLibrary && isWebview" [class.social]="mySocial">
  <div class="slider-audiobook-body">
     <app-ppv-remaining-indicator [content]="audiobook"></app-ppv-remaining-indicator>
     <img *ngIf="audiobook" class="slider-audiobook-img slider-audiobook-img--strip"
         src="{{getImage()}}"
         src-fallback="{{srcFallback}}"/>
     <div *ngIf="!audiobook" class="slider-audiobook-img--strip"></div>
     <div class="unfollow-container">
          <a *ngIf="popupText"
               (click)="unfollowAudioBook.emit($event)"
               class="iconlace iconlace-cross unfollow unfollow-audiobook"
               [ngbTooltip]="popupText"
               container="body"
               placement="top"></a>
     </div>
  </div>
  <div class="slider-audiobook-footer"
     [ngClass]="{ 'slider-audiobook-footer--acquired': audiobook?.acquired, 'slider-audiobook-footer--price': !audiobook?.acquired}">
     <p class="slider-audiobook-button" *ngIf="audiobook?.acquired">escuchar</p>
     <p class="slider-audiobook-button" *ngIf="!audiobook?.acquired">{{ getPrice() }}</p>
  </div>
</div>
