import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {Observer, Observable} from "rxjs";
import {Hero} from "./models/hero";
import {RestClient} from "./rest-client";
import {RestParams, IRestMethod, RestAction, RestHandler, RestRequestMethod} from "rest-core";
import {EnlaceRestHandler} from "./enlace-rest-handler";
import {AuthenticationService} from "./authentication.service";
import { Router } from '@angular/router';
import {UserNotification} from "./models/user-notification";


@RestParams({
  pathPrefix: environment.webApi + 'notifications'
})
@Injectable()
export class NotificationService extends  RestClient {

  result: UserNotification[];

  constructor(restHandler: EnlaceRestHandler, authService: AuthenticationService, private router: Router) {
    super(restHandler, authService);
  }

  @RestAction({
    path: '/me/list',
    isArray: true,
  })
  list: IRestMethod<any, UserNotification[]>;


  @RestAction({
    path: '/{!userId}/list',
  })
  getByUserId: IRestMethod<{userId: any}, UserNotification[]>;


  @RestAction({
    path: '/{!notificationId}/read',
    method: RestRequestMethod.Post
  })
  read: IRestMethod<{notificationId: number}, any>;


  listCache(): Observable<UserNotification[]> {
    return Observable.create(observer => {
      if (this.result) {
        observer.next(this.result);
      } else {
        this.list().then((result: UserNotification[]) => {
          this.result = result;
          observer.next(this.result);
        }).catch((e) => {
          console.warn('called .catch on %o arguments %o', this, e);
        });
      }
    });
  }

}
