import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClickOutsideModule } from 'ng4-click-outside';

import { DropdownSelectComponent } from '@components/dropdown-select/dropdown-select.component';
import { PipesModule } from '@app/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  imports: [CommonModule, ClickOutsideModule, PipesModule, TranslateModule],
  exports: [DropdownSelectComponent],
  declarations: [DropdownSelectComponent],
})
export class DropdownSelectModule { }
