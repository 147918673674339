<div class="user-card" *ngIf="userCards.length > 0">
  <div class="title {{classType}}">Método de pago</div>
  <div class="user-card-rows" *ngFor="let card of userCards" [ngClass]="{'expired': card.expired}"
    (click)="selectCard(card)">
    <div class="brand-name-card">
      <i class="iconlace iconlace-check-status {{classType}}"
        [ngClass]="{'active-check': (cardSeleted?.cardId == card.cardId && !card.expired),'expired': card.expired}">
      </i>
      <span class="text-card" [ngClass]="{'expired': card.expired}">{{card.cardBrandName}} que termina en
        {{card.last4}}</span>
      <span *ngIf="card.expired" class="expired-card" placement="top" [ngbTooltip]="tooltipText">VENCIDA</span>
    </div>
    <div class="holder-name-card">
      <div class="holder-name" [ngClass]="{'expired': card.expired}">{{card.cardHolderName}}</div>
      <div class="expiration-date" [ngClass]="{'expired': card.expired}">{{card.expMonth}} / {{card.expYear}}</div>
    </div>
  </div>
  <div class="divisor {{classType}}"></div>
  <span class="primary-payment" *ngIf="classType === 'missionary'">*Este será tu medio de pago
    primario.</span>
</div>
