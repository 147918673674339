<div *ngIf="componentLocation == 'reader'">
  <span *ngIf="hovered" class="tag-number" [ngStyle]="{'left': 'calc(' + percentValue + '% - 15px)'}">{{ percentBar }}</span>
  <div class="bar-container"></div>
  <div [style.width]="percentBar" class="progress-bar-line"></div>
</div>

<div *ngIf="componentLocation == 'slider'">
  <div class="bar-container" [ngClass]="{hoveredLine: hovered}"></div>
  <div [style.width]="percentBar" class="progress-bar-line" [ngClass]="{hoveredLine: hovered}">
    <span *ngIf="hovered">{{ percentBar }}</span>
  </div>
</div>