<div class="transcriptions-wrapper">
  <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 transcriptions" [ngClass]="{'last': isLast, 'even': isEven, 'odd': isOdd}" id="{{index}}">
    <div class="transcriptions-shadow"></div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 transcriptions-header">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 transcriptions-title" [ngClass]="{'fromSearch': fromSearch}">
            <i class="iconlace iconlace-transcription"></i>
            <p class="transcriptions-title" [ngClass]="{'fromSearch': fromSearch}">
              <strong>Transcripciones Automatizadas</strong>
            </p>
            <i class="iconlace iconlace-cross" (click)="toggleTranscriptions()"></i>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 transcriptions-search"
              *ngIf="!fromSearch && transcriptionsSearch && transcriptionsSearch.length > 0">
            <input #searchText type="text" name="search" [(ngModel)]="search" (keyup.enter)="filterTranscriptions()"/>
            <i class="iconlace iconlace-cross transcriptions-close-button" *ngIf="showTranscriptionsClose" (click)="clearTranscriptions()"></i>
            <i class="iconlace iconlace-search transcriptions-search-button" (click)="filterTranscriptions()"></i>
          </div>
          <!-- <i class="iconlace iconlace-search" *ngIf="!isSearchOpen" (click)="toggleSearch()"></i> -->
        </div>
      </div>
    </div>
    <div class="row" style="height: 100%; position: relative;">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 transcriptions-body csb">
        <div *ngIf="transcriptions && transcriptions.length === 0" class="row">
          <p>No se encontraron resultados para "<strong>{{search}}</strong>" dentro de la transcripción automatizada.</p>
        </div>
        <div *ngIf="!transcriptions" class="row">
          <p>Buscando transcripciones ...</p>
        </div>
        <div id="time{{transcription.startTime | timeFormatSec}}" *ngFor="let transcription of transcriptions" class="row" (click)="onClickTranscription(transcription)">
          <p class="transcriptions-duration">{{transcription.startTime | timeFormat}}</p>
          <p [innerHTML]="transcription.lines[0] | highlight: search"></p>
          <!-- <p>{{transcription.lines[0]}}</p> -->
        </div>
      </div>
    </div>
  </div>
</div>
