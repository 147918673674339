import {
  Component,
  Input,
  OnInit,
  ElementRef,
  AfterViewChecked,
  Output,
  EventEmitter,
} from '@angular/core';
import { toNumber } from '@shared/utils/timepicker.utils';
import { Router } from '@angular/router';

import { ContentV3Service } from '@services/content-v3.service';
import { AdvanceSearchService } from '../services/advance-search.service';
import { PPVContentService } from '@app/shared/utils/ppvcontent.service';

import { IContentCatalogViewModel } from '@interfaces/i-content-catalog-view-model';
import { ICatalog } from '@interfaces/i-catalog';
import { IVVideo } from '@interfaces/i-v-video';
import { ModalManagementService } from '@app/shared/utils/modal-management.service';

@Component({
  selector: 'app-search-content',
  templateUrl: './search-content.component.html',
  styleUrls: ['./search-content.component.scss'],
})
export class SearchContentComponent implements OnInit, AfterViewChecked {
  @Input()
  defaultRow: number = 10;
  elementsRow: number = 10;
  totalRows: number = 0;
  @Input()
  isShowFilter: boolean = false;
  isShowAllElements: boolean = false;
  currentCatalog: ICatalog = null;
  originalList: any[];
  _searchResult: any;
  filtersShow: boolean;
  catalogName: string = '';
  hostsList: any[];
  video: IVVideo;
  prevsroll: number = 0;
  preloading: boolean = false;
  currentVideo: any;
  @Input() isLibrary: boolean = false;
  @Output() resetFilter: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public advanceSearchService: AdvanceSearchService,
    private searchService: ContentV3Service,
    private router: Router,
    private ppvContentService: PPVContentService,
    private elem: ElementRef,
    private modalManagementService: ModalManagementService
  ) {}

  @Input()
  set visible(content: SearchContentComponent) {}

  get viewType(): string {
    return this.advanceSearchService.viewAllType;
  }

  get isVisible(): boolean {
    return (
      this.advanceSearchService.typeSelected.value ===
        this.searchResult.typeFilter ||
      this.advanceSearchService.getContentType() === 'all'
    );
  }

  @Input()
  set searchResult(value: any) {
    this._searchResult = value;
    this.originalList = this._searchResult.list;
    this.totalRows = this._searchResult.totalItems;
  }

  get searchResult(): any {
    return this._searchResult;
  }

  get showResults(): boolean {
    return this.isLibrary
      ? true
      : this.advanceSearchService.typeSelected &&
          (this.advanceSearchService.typeSelected.value === 'all' ||
            this.advanceSearchService.typeSelected.value ===
              this.searchResult.typeFilter);
  }

  get hasScroll(): boolean {
    return jQuery('#advanceSearchList').get(0)
      ? jQuery('#advanceSearchList').get(0).scrollHeight >
          jQuery('#advanceSearchList').innerHeight()
      : false;
  }

  ngAfterViewChecked() {
    const elementTranscriptions = this.elem.nativeElement.querySelector(
      '.transcriptions-main-search .transcriptions'
    );
    if (elementTranscriptions) {
      this.checkWidthTranscriptions();
    }

    const elementIndexer = this.elem.nativeElement.querySelector(
      '.indexer-main-search .indexer'
    );
    if (elementIndexer) {
      this.checkWidthIndexer();
    }
  }

  mapSearchItemToAudioBook(searchItem) {
    searchItem.urlImage = searchItem.image;
    searchItem.contentId = searchItem.id;
    return searchItem;
  }

  mapSearchItemToEBook(searchItem) {
    searchItem.urlImage = searchItem.image;
    searchItem.contentId = searchItem.id;
    return searchItem;
  }

  read(contentType, contentId) {
    this.router.navigate(['/', contentType, contentId]);
  }

  show(contentCatalog: IContentCatalogViewModel) {
    if (contentCatalog.type === 'audiobook') {
      this.showAudioBookDetail(contentCatalog);
      return;
    }
    if (contentCatalog.type === 'ebook') {
      this.openModalDetail(contentCatalog.contentId, contentCatalog.type)
      return;
    }
  }

  private showAudioBookDetail(contentCatalog: IContentCatalogViewModel) {
    this.searchService
      .getAcquireContent(contentCatalog.contentId)
      .subscribe((res: boolean) => {
        if (res == true) {
          this.read(contentCatalog.type, contentCatalog.contentId);
        }
      });
  }

  private showEBookDetail(contentCatalog: IContentCatalogViewModel) {
    this.searchService
      .getAcquireContent(contentCatalog.contentId)
      .subscribe((res: boolean) => {
        if (res == true) {
          this.read(contentCatalog.type, contentCatalog.contentId);
        } 
      });
  }

  openModalDetail(contentId: string, contentType: string): void{
    this.modalManagementService.openModalMoreInfo(contentId, contentType);
  }

  ngOnInit() {
    jQuery('.advanced-search').scroll((event) => {
      if (this.advanceSearchService.typeSelected.type !== 'all') {
        const scrollHeight = jQuery('.advanced-search')[0].scrollHeight;
        const scrollPosition =
          jQuery('.advanced-search').height() +
          jQuery('.advanced-search').scrollTop();
        const percent = ((scrollHeight - scrollPosition) / scrollHeight) * 100;
        const direcionUp = percent < this.prevsroll;

        if (percent > 0 && percent < 35 && direcionUp) {
          if (!this.preloading) {
            this.paging();
            console.log('paging');
          }
        }
        this.prevsroll = percent;
      }
    });

    if (this.advanceSearchService.typeSelected.type === 'all') {
      if (this.advanceSearchService.isOpenFilter) {
        this.elementsRow = 8;
      } else {
        this.elementsRow = 10;
      }
    } else {
      this.elementsRow = 100;
    }

    this.advanceSearchService.subjectChangeOpenFilter.subscribe(
      (value: boolean) => {
        if (
          this.advanceSearchService.typeSelected.value !==
            this.searchResult.typeFilter &&
          value
        ) {
          this.elementsRow = 8;
        } else if (
          this.advanceSearchService.typeSelected.value !==
            this.searchResult.typeFilter &&
          !value
        ) {
          this.elementsRow = 10;
        }
      }
    );

    if (this.searchResult != null && this.searchResult.list != null) {
      this.searchResult.list.forEach((content) => {
        //tOdO: use prop ppv
        content.isPPV = this.ppvContentService.isPPV(content);
      });
    }

    this.hostsList = this.searchResult.result.hosts;
  }

  getHostImage(hosts) {
    let srcImage = 'assets/img/icons/user.svg';
    if (
      hosts != null &&
      hosts[0] != null &&
      hosts[0] != '' &&
      hosts[0].indexOf('|') != -1
    ) {
      if (hosts[0].split('|')[2] != '') {
        srcImage = hosts[0].split('|')[2];
      }
    }
    return srcImage;
  }

  subtitle(item: any): string {
    try {
      if (item.hosts && item.hosts.length > 0) {
        return item.hosts[0].split('|')[1];
      }
    } catch (err) {}
    return item.subtitle;
  }

  changeTypeFilter(contentType) {
    this.searchResult.pageInfo.rowsPage = 10;
    if (contentType !== 'all') {
      this.searchResult.pageInfo.rowsPage = 100;
      if (this.isLibrary) {
        this.searchResult.pageInfo.rowsPage = 20;
        this.searchResult.type = contentType;
      }
    } else {
      if (this.isLibrary) this.searchResult.type = '';
    }
    this.advanceSearchService.changeTypeFilter(contentType);
    if (this.isLibrary) this.resetFilter.emit(contentType);
  }

  paging() {
    this.preloading = true;
    this.searchResult.isShowAllElements = true;
    this.elementsRow += 100;
    this.searchResult.pageInfo.top = 100;
    this.searchResult.pageInfo.rowsPage = 100;
    this.searchService
      .advancedSearch(this.searchResult.pageInfo)
      .subscribe((result) => {
        jQuery('#paging').blur();
        this.preloading = false;
        this.searchResult.list = this.searchResult.list.concat(
          result.result.list
        );
      });

    this.searchResult.pageInfo.page = this.searchResult.pageInfo.page + 1;
  }

  public onScroll(): void {
    if (this.advanceSearchService.typeSelected.type !== 'all') {
      if (this.isLibrary) this.pagingLibrary();
    }
  }

  pagingLibrary() {
    this.preloading = true;
    this.searchResult.isShowAllElements = true;
    this.elementsRow += 100;
    this.searchService
      .advancedSearch(this.searchResult.pageInfo)
      .subscribe((result) => {
        this.preloading = false;
        this.searchResult.list = this.searchResult.list.concat(
          result.result.list
        );
      });
    this.searchResult.pageInfo.page = this.searchResult.pageInfo.page + 1;
  }

  goTop() {
    const body = jQuery('#advanceSearchList');
    body.stop().animate({ scrollTop: 0 }, 500, 'swing', function () {
      jQuery('#goTop').blur();
    });
  }

  showTranscription(video) {
    this.video = <IVVideo>{ id: video.id };
    video.isShowTranscriptions = !video.isShowTranscriptions;
  }

  showIndexer(video) {
    this.video = <IVVideo>{ id: video.id };
    video.isShowIndexer = !video.isShowIndexer;
  }

  checkWidthTranscriptions() {
    const element = this.elem.nativeElement.querySelector(
      '.transcriptions-main-search .transcriptions'
    );
    const bound = element.getBoundingClientRect();
    if (bound.right > window.innerWidth) {
      jQuery('.transcriptions').css('left', '0');
    }
  }

  checkWidthIndexer() {
    const element = this.elem.nativeElement.querySelector(
      '.indexer-main-search .indexer'
    );
    const bound = element.getBoundingClientRect();
    if (bound.right > window.innerWidth) {
      jQuery('.indexer').css('left', '0');
    }
  }

  goToVideoSecond(milisecond, videoId, type) {
    const second = toNumber(milisecond / 1000);
    if (type == 'narrated-bible') {
      this.router.navigate(['/video', videoId, second], {
        queryParams: { indexer: this.advanceSearchService.searchText },
      });
    } else {
      this.router.navigate(['/video', videoId, second], {
        queryParams: { transcription: this.advanceSearchService.searchText },
      });
    }
  }

  getPPVPrice(content: any): string {
    if (content.purchasePrice || content.rentalPrice)
      return `$${content.purchasePrice || content.rentalPrice || 0}`;
    else
      return `$${
        content.programPurchasePrice || content.programRentalPrice || 0
      }`;
  }

  navigate(content: any) {
    if (content.type === 'video' || content.type == 'narrated-bible') {
      if (content.programId != null) {
        if (!content.isPPV || content.acquired) {
          this.router.navigate([
            '/',
            content.type == 'narrated-bible' ? 'video' : content.type,
            content.id,
          ]);
        }
      } else {
        if (!content.isPPV || content.acquired) {
          this.router.navigate([
            '/',
            content.type == 'narrated-bible' ? 'video' : content.type,
            content.id,
          ]);
        }
      }
    } else {
      this.router.navigate(['/', content.type, content.id]);
    }
  }
}
