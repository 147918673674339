import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as ENV } from '@environment/environment';

import { DonationLandingText, IDonationViewModel } from '@interfaces/i-donation-view-model';
import { IBillingAgreementRequest } from '@interfaces/i-billing-agreements.interface';
import { IPagingResponseModel } from '@interfaces/paging/ipaging-response-model';
import { IPagingRequestModel } from '@interfaces/ipaging-request-model';
import { IOrganization } from '@interfaces/i-organization';

@Injectable({
  providedIn: 'root',
})
export class DonationV3Service {

  private APIV2 = `${ENV.webApi}v2/donation`;
  private APIV3 = `${ENV.webApi}v3/donation`;
  private urlDonationLandingText = 'https://enlaceimages.blob.core.windows.net/images/unavailable/donation.json';

  constructor(private http: HttpClient) {}

  public getDonationLandingText(): Observable<DonationLandingText> {
    return this.http.get<DonationLandingText>(this.urlDonationLandingText);
  }

  public getAllOrganizationsWithDonations(): Observable<IOrganization[]> {
    const url = `${this.APIV2}/getAllOrganizationsWithDonations`;
    return this.http.get<IOrganization[]>(url);
  }

  public userV3Donations(payload: IPagingRequestModel): Observable<IPagingResponseModel<IDonationViewModel>> {
    return this.http.post<IPagingResponseModel<IDonationViewModel>>(`${this.APIV3}/getUserPayments`, payload);
  }

  public createDonation(payload: IBillingAgreementRequest): Observable<string> {
    const url = `${this.APIV2}/agreement/createDonation`;
    return this.http.post(url, payload, {responseType: 'text'});
  }

  public addAgreement(payload: IBillingAgreementRequest): Observable<string> {
    const url = `${this.APIV2}/agreement/add`;
    return this.http.post(url, payload, {responseType: 'text'});
  }

  public paypalconfirm(paymentId: string): Observable<any> {
    const url = `${this.APIV2}/paypal/confirm/${paymentId}`;
    return this.http.get(url);
  }

  public agreementConfirm(agreementId: string): Observable<any> {
    const url = `${this.APIV2}/billingAgreement/approved/${agreementId}`;
    return this.http.get(url);
  }

  public getUserDonation(method: string, paymentId: string): Observable<IDonationViewModel> {
    const url = `${this.APIV2}/getUserDonation`;
    return this.http.get<IDonationViewModel>(url,  {
      params: { method, paymentId },
    });
  }

  public updateDonation(payload: IDonationViewModel): Observable<IDonationViewModel> {
    const url = `${this.APIV2}/updateDonation`;
    return this.http.post<IDonationViewModel>(url, payload);
  }

  public requestCancellation(payload: IDonationViewModel): Observable<IDonationViewModel> {
    const url = `${this.APIV2}/requestCancellation`;
    return this.http.post<IDonationViewModel>(url, payload);
  }

}
