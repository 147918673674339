import {
  Component, Input, Output,
  ChangeDetectionStrategy,
  EventEmitter, HostListener,
  OnDestroy, OnInit,
} from '@angular/core';

import { CountryTimeZone } from '@interfaces/i-country';
import { IUser } from '@interfaces/i-user.interface';
import { ActionHeader, typeOptionHeader } from '@enums/type-header-option.enum';
import { RoutesApp } from '@enums/type-routes-app.enum';

import { DomManipulateService } from '@utils/dom-manipulate.service';
@Component({
  selector: 'app-header-link',
  templateUrl: './header-link.component.html',
  styleUrls: ['./header-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderLinkComponent implements OnInit, OnDestroy {

  @Input() user: IUser;
  @Input() ageRestriction: boolean;
  @Input() typeClass: string;
  @Input() active: boolean;
  @Input() isMobile: boolean;
  @Input() isSmallTablet: boolean;
  @Input() countrySelected: CountryTimeZone;
  @Input() countries: CountryTimeZone[];
  @Input() isComunnityEnabled: boolean;
  @Input() activeTab = '';
  @Output() emitOption: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitRoutes: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitCountry: EventEmitter<CountryTimeZone> = new EventEmitter<CountryTimeZone>();

  public routes = RoutesApp;
  public action = ActionHeader;
  public openCountries: boolean = false;
  public openOptionsUser: boolean = false;
  public icon = 'isotipo'

  get typeLive(): boolean {
    return this.typeClass === typeOptionHeader.LIVE;
  }

  get typeMore(): boolean {
    return this.typeClass === typeOptionHeader.MORE;
  }

  get typeUser(): boolean {
    return this.typeClass === typeOptionHeader.USER;
  }

  get profileImage(): string {
    if (!!this.user && this.user.imageAvatar && this.user.imageAvatar.url) return this.user.imageAvatar.url;
    return '';
  }

  constructor(
    private domManipulateService: DomManipulateService,
  ) {}

  ngOnInit(): void {
    if (this.isMobile && !this.isSmallTablet) this.domManipulateService.setBodyScroll(false);
  }

  ngOnDestroy(): void {
    if (this.isMobile && !this.isSmallTablet) this.domManipulateService.setBodyScroll(true);
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    const path = event.composedPath ? event.composedPath() : (event as any).path;
    if (!!path && this.active) {
      const selection = path.find(
        (paths: any) =>
        !!paths.classList &&
          !!paths.classList.length &&
          (paths.classList.contains('header-option') ||
           paths.classList.contains('header-link') ||
           paths.classList.contains('content-modal'))
      );
      if (!selection) {
        this.sendOption('');
      }
    }
  }

  @HostListener('document:scroll', ['$event'])
  scrollout(event: Event): void {
    const path = event.composedPath ? event.composedPath() : (event as any).path;
    if (!!path && this.active) {
      const selection = path.find(
        (paths: any) =>
        !!paths.classList &&
          !!paths.classList.length &&
          (paths.classList.contains('header-option') ||
           paths.classList.contains('header-link') ||
           paths.classList.contains('content-modal'))
      );
      if (!selection) {
        this.sendOption('');
      }
    }
  }


  public sendOption(action: string): void {
    this.emitOption.emit(action);
  }

  sendRedirect(route: string, queryParams: { [key: string]: string } = {}): void {
    this.activeTab = route;
      this.emitRoutes.emit({ route, queryParams });
  }

  sendRedirectasdfas(route: string, queryParams: { [key: string]: string } = {}): void {
    this.emitRoutes.emit({ route, queryParams });
  }
  public sendHighLights(route: string): void {
    this.emitRoutes.emit(`${route}/${Date.now()}`);
  }

  public changeCountry(country: CountryTimeZone): void {
    this.emitCountry.emit(country);
    this.toggleCountry();
  }

  public toggleCountry(): void {
    this.openOptionsUser = false;
    this.openCountries = !this.openCountries;
  }

  public toggleOptionsUser(user: IUser): void {
    if(!user) return;
    this.openCountries = false;
    this.openOptionsUser = !this.openOptionsUser;
  }
}
