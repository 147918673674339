import { Injectable } from '@angular/core';
import {LocalStorageService} from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  constructor(private localStorageService: LocalStorageService) { }

  add(key: string, value: any) {
    this.localStorageService.store(key,<CacheItem>{
      createDate: new Date(),
      value: value,
      expire: 400000
    });
  }

  get<T>(key: string): any {
    const cacheItem = <CacheItem>this.localStorageService.retrieve(key);
    if(cacheItem !== null) {
      return <T>cacheItem.value;
    }

    return null;
  }

  has(key: string): boolean {
    const cacheItem = this.localStorageService.retrieve(key);
    return cacheItem !== null;
  }
}

export interface CacheItem {
  createDate: Date;
  value: any,
  expire: number;
}
