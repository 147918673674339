import { Pipe, PipeTransform } from '@angular/core';
import { ContentType } from '@enums/type-content.enum';

@Pipe({
  name: 'getNamePurchaseByContentType'
})
export class GetNamePurchaseByContentTypePipe implements PipeTransform {

  private _dictionary = {
    [ContentType.video]: 'Video',
    [ContentType.program]: 'Programa',
    [ContentType.article]: 'Artículo',
    [ContentType.podcast]: 'Podcast',
    [ContentType.ebook]: 'Libro Digital',
    [ContentType.audiobook]: 'Audiolibro',
  }

  transform(value: string): string {
    return this._dictionary[value] || '';
  }

}
