import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { BrowserModule, Title, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';

// Librerias externas
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApplicationInsightsModule, AppInsightsService } from '@markpieszak/ng-application-insights';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AgmCoreModule } from '@shared/components/google-maps';
import { DeviceDetectorService } from 'ngx-device-detector';

// Servicios - Modulos internos

import { ErrorInterceptorService } from '@api/v2/error-interceptor.service';
import { HttpLoadingInterceptorService } from '@core/interceptors/http-loading-interceptor.service';
import { JwtInterceptorService } from '@api/v3/jwt-interceptor.service';
import { ApiClientModule } from '@api/api-client.module';
import { MyMonitoringService } from '@app/shared/services/lib/monitoring.service';
import { LanguageService } from '@api/v2/language.service';
import { LayoutModule } from '@layout/layout.module';
import { SeoService } from '@app/shared/utils/seo.service';
import { AppBaseBrowserModule } from '@config/browser/app-base-browser.module';
import { environment } from '@environment/environment';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { BtnPrayRequestModule } from '@app/shared/components/btn-pray-request/btn-pray-request.module';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { register } from 'swiper/element/bundle';
import { LoadingOverlayModule } from './shared/components/loading-overlay/loading-overlay.module';

registerLocaleData(localeEn, 'en-US');
registerLocaleData(localeEs, 'es-ES');

register();

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BtnPrayRequestModule,
    LoadingOverlayModule,
    FontAwesomeModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AppBaseBrowserModule,
    NgxWebstorageModule.forRoot(),
    ApiClientModule,
    LayoutModule,
    ApplicationInsightsModule.forRoot({
      instrumentationKey: environment.appInsights.instrumentationKey
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAqZ_pUuQPSe_igdtqY_dDEI_l3zwKH55w'
    })
  ],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [LanguageService],
      useFactory: (languageService: LanguageService): string => {
        return languageService.getLocale();
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLoadingInterceptorService,
      multi: true
    },
    LanguageService,
    MyMonitoringService,
    AppInsightsService,
    Title,
    Meta,
    SeoService,
    DeviceDetectorService
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {
  deviceInfo = null;
  constructor(
    private deviceService: DeviceDetectorService,
    translate: TranslateService,
    seo: SeoService,
    library: FaIconLibrary
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('es');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('es');
    library.addIconPacks(fas, fab);
  }

  ngOnInit() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }
}


