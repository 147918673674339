<form [formGroup]="form" (ngSubmit)="saveCard()" novalidate>
  <div class="row">

    <div class="col-lg-3 col-sm-3 col-xs-12">
      <label class="lbl_input" for="form-card-holder">Nombre:</label>
      <input formControlName="cardName" [class.is-invalid]="isInValid('cardName')" type="text" class="form-control" id="form-card-holder"
        autocomplete="off" placeholder="Nombre" required/>
      <div *ngIf="isInValid('cardName')" class="help-block">
        <span>El nombre es requerido.</span>
      </div>
    </div>

    <div class="col-lg-3 col-sm-3 col-xs-12">
      <label class="lbl_input" for="local_Card_LastName">Apellido (s):</label>
      <input formControlName="payerlastName" [class.is-invalid]="isInValid('payerlastName')" type="text" class="form-control" id="local_Card_LastName"
        autocomplete="off" placeholder="Apellido (s)" required/>
      <div *ngIf="isInValid('payerlastName')" class="help-block">
        <span>El apellido (s) es requerido.</span>
      </div>
    </div>

    <div class="col-lg-6 col-sm-6 col-xs-12">
      <label class="lbl_input" for="local_Card_Address">Dirección de facturación:</label>
      <input formControlName="address" [class.is-invalid]="isInValid('address')" type="text" class="form-control" id="local_Card_Address"
        autocomplete="off" placeholder="Ingrese dirección exacta" required/>
      <div *ngIf="isInValid('address')" class="help-block">
        <span>La dirección de facturación es requerida.</span>
      </div>
    </div>
  </div>

  <div class="row mt-15">

    <div class="col-lg-3 col-sm-3 col-xs-12">
      <label class="lbl_input" for="newLocalCardCountry">País de facturación:</label>
      <select formControlName="country" [ngClass]="{'is-invalid': isInValid('country')}"
        class="select-input" id="newLocalCardCountry"required>
        <option [ngValue]="null" disabled>Elige el país</option>
        <option [ngValue]="country" *ngFor="let country of localCountries | forsort: 'name'">{{country.name}}</option>
      </select>
      <i></i>
      <div *ngIf="isInValid('country')" class="help-block">
        <span>El país de facturación es requerido.</span>
      </div>
    </div>

    <div class="col-lg-3 col-sm-3 col-xs-12">
      <label class="lbl_input" for="localCardState">Provincia:</label>
      <input formControlName="state" [class.is-invalid]="isInValid('state')" type="text" class="form-control" id="localCardState"
        autocomplete="off" placeholder="Ingrese la provincia" required/>
      <div *ngIf="isInValid('state')" class="help-block">
        <span>La provincia es requerida.</span>
      </div>
    </div>

    <div class="col-lg-3 col-sm-3 col-xs-12">
      <label class="lbl_input" for="localCardCity">Ciudad:</label>
      <input formControlName="city" [class.is-invalid]="isInValid('city')" type="text" class="form-control" id="localCardCity"
        autocomplete="off" placeholder="Ingresa la ciudad" required/>
      <div *ngIf="isInValid('city')" class="help-block">
        <span>La ciudad es requerida.</span>
      </div>
    </div>

  </div>
  <div class="row mt-15">
 <!-- smarts Fields -->
    <div class="col-lg-3 col-sm-3 col-xs-12">
      <label class="lbl_input">Número de tarjeta:</label>
      <div #formPanField id="form-pan-field" class="dlocal-input" [class.is-invalid]="(isPanError$ | async)?.isError"></div>
      <div *ngIf="(isPanError$ | async)?.isError" class="text-danger" id="pan-errors" role="alert">
        {{(isPanError$ | async).message}}
      </div>
    </div>

    <div class="col-lg-3 col-sm-3 col-xs-12">
      <label class="lbl_input">Fecha de vencimiento:</label>
      <div class="input-group">
        <div #formExpirationField [class.is-invalid]="(isExpirationError$ | async)?.isError" id="form-expiration-field" class="dlocal-input"></div>
        <div class="input-group-addon" [class.is-invalid]="(isExpirationError$ | async)?.isError">
          <span>
            <i class="iconlace iconlace-card"></i>
          </span>
        </div>
      </div>
      <div *ngIf="(isExpirationError$ | async)?.isError" class="text-danger" id="expiration-errors" role="alert">
        {{(isExpirationError$ | async).message}}
      </div>
    </div>

    <div class="col-lg-3 col-sm-3 col-xs-12">
      <label class="lbl_input">Código de seguridad:</label>
      <div class="input-group">
        <div #formCvvField [class.is-invalid]="(isCvvError$ | async)?.isError" id="form-cvv-field" class="dlocal-input"></div>
        <div class="input-group-addon" [class.is-invalid]="(isCvvError$ | async)?.isError">
          <span>
            <i class="iconlace iconlace-calendar"></i>
          </span>
        </div>
      </div>
      <div *ngIf="(isCvvError$ | async)?.isError" class="text-danger" id="cvv-errors" role="alert">
        {{(isCvvError$ | async).message}}
      </div>
    </div>
  <!-- End smarts Fields -->
    <div class="col-lg-3 col-sm-3 col-xs-12">
      <label class="lbl_input" for="newLocalCardDocumentNumber">Número de identificación:</label>
      <div class="input-group">
        <input formControlName="payerDocument" [class.is-invalid]="isInValid('payerDocument')" type="number" class="form-control withoutRightBorder"
          id="newLocalCardDocumentNumber"  autocomplete="off"
          placeholder="Ingresa tu número de documento" maxlength="10" required/>
        <div class="input-group-addon" [class.is-invalid]="isInValid('payerDocument')">
          <span>
            <i class="iconlace iconlace-user"></i>
          </span>
        </div>
      </div>
      <div *ngIf="isInValid('payerDocument')" class="help-block">
        <span>El número de documento es requerido.</span>
      </div>
    </div>
  </div>

</form>
