import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appScrollIntoViewElement]',
})
export class ScrollIntoViewElementDirective {

  @Input('appScrollIntoViewElement') canClick: boolean = false;
  @Input() canScroll: boolean = false;
  @Input() typeBlock: string = 'start';

  constructor(private _elementRef: ElementRef) {}

  @HostListener('focus', ['$event.target'])
  onFocus() {
    if (this.canScroll && !this.canClick) {
      setTimeout(() => {
        this._elementRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: this.typeBlock });
      }, 200);
    }
  }

  @HostListener('click', ['$event.target'])
  onClick() {
    if (this.canScroll && this.canClick) {
      setTimeout(() => {
        this._elementRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: this.typeBlock });
      }, 200);
    }
  }
}
