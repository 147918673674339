<div class="header-donation" [formGroup]="form">
  <div class="header-donation-title {{style}}">
    {{organizationName ? 'Donar a el Ministerio ' + organizationName : 'Donar'}}
  </div>
  <div class="header-donation-amount">
    <div class="header-donation-amount-input">
      <div class="amount-form">
        <label class="lbl_input mr-15 weight-normal" for="amount">Monto que deseas donar:</label>
        <div class="input-group ml-aprox">
          <div class="input-group-addon {{style}}" [class.is-invalid]="isInValid('amount')">
            <span> USD $ </span>
          </div>
          <input
            formControlName="amount"
            [class.is-invalid]="isInValid('amount')"
            (ngModelChange)="onAmountChange()"
            class="form-control w-100 text-right {{style}}"
            type="number" id="amount"
            placeholder="0"
            min="1" step=".01" required/>
        </div>
      </div>
    </div>
    <div class="header-donation-amount-input ml" [class.from-modal]="fromModal">
      <div class="amount-form">
        <label class="lbl_input mr-15 weight-normal" for="amount">Aproximado en tu moneda:</label>
        <div class="input-group">
          <div class="input-group-addon {{style}}">
            <span> {{ userCurrency }} </span>
          </div>
          <input
            formControlName="currency"
            currencyMask
            [options]="{prefix: '', thousands: '', decimal: '.'}"
            [value]="getAmountConverted"
            class="form-control w-100 text-right {{style}} bg-transparent"
            type="number" placeholder="0" id="currency"/>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isInValid('amount')" class="help-block">
    <span>El monto debe ser mayor a US$ 0.00. </span>
  </div>
  <div class="header-donation-recurrence">
    <div class="header-donation-recurrences">
      <label class="lbl_input weight-normal">Recurrencia:</label>
      <div class="header-donation-radios">
        <div class="header-donation-group">
          <label class="method" for="one-time">
            <input
              formControlName="frequency"
              [value]="frecuencyPeriod.Unique"
              (change)="toggleCycle()"
              type="radio" name="frequency" id="one-time">
            <span class="checkmark {{style}}"></span>
            <span class="custom-radio">Única vez</span>
          </label>
          <label class="method" for="bi-weekly">
            <input
              formControlName="frequency"
              [value]="frecuencyPeriod.Biweekly"
              (change)="toggleCycle()"
              type="radio" name="frequency" id="bi-weekly">
            <span class="checkmark {{style}}"></span>
            <span class="custom-radio">Quincenal</span>
          </label>
        </div>
        <div class="header-donation-group">
          <label class="method" for="monthly">
            <input
              formControlName="frequency"
              [value]="frecuencyPeriod.Monthly"
              (change)="toggleCycle()"
              type="radio" name="frequency" id="monthly">
            <span class="checkmark {{style}}"></span>
            <span class="custom-radio">Mensual</span>
          </label>
          <label class="method" for="quarterly">
            <input
              formControlName="frequency"
              [value]="frecuencyPeriod.Quarterly"
              (change)="toggleCycle()"
              type="radio" name="frequency" id="quarterly">
            <span class="checkmark {{style}}"></span>
            <span class="custom-radio">Trimestral</span>
          </label>
        </div>
      </div>
    </div>
    <div class="header-donation-cancel-recurrences ml" *ngIf="!frecuencyIsUnique" [class.from-modal]="fromModal">
      <label class="lbl_input weight-normal">Cancelar recurrencia:</label>
      <div class="header-donation-radios">
        <div class="header-donation-group">
          <label class="method" for="never">
            <input
              formControlName="cycle"
              [value]="cycleDonation.infinite"
              (change)="toggleCycle()"
              type="radio" name="cycle" id="never">
            <span class="checkmark {{style}}"></span>
            <span class="custom-radio">Nunca</span>
          </label>
        </div>
        <div class="header-donation-group inline">
          <label class="method" for="after-time">
            <input
              formControlName="cycle"
              [value]="cycleDonation.fixed"
              (change)="toggleCycle()"
              type="radio" name="cycle" id="after-time">
            <span class="checkmark {{style}}"></span>
            <span class="custom-radio">Después de:</span>
          </label>
          <div class="form-group">
            <label class="lbl_input ml-15" for="after-time-quantity">pagos.</label>
            <input
            formControlName="cycleQuantity" [class.is-invalid]="isInValid('cycleQuantity')"
            type="number" class="form-control weight-normal w-100 text-right {{style}}"
            id="after-time-quantity" placeholder="0">
          </div>
        </div>
        <div *ngIf="isInValid('cycleQuantity')" class="help-block">
          <span *ngIf="hasError('required', 'cycleQuantity')">Debes elegir cuándo cancelar la recurrencia de tu donación.</span>
          <span *ngIf="hasError('min', 'cycleQuantity')">Debes elegir cuándo cancelar la recurrencia de tu donación.</span>
        </div>
      </div>
    </div>
  </div>
</div>
