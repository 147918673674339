<div class="prev-detail-wrap" [class.hasAirDate]="contentCatalog?.airDate && isShow" [ngClass]="{hidedetail: !isShow, 'prev-detail-wrap-program': contentCatalog?.type == 'program'}" id="detail-predicas">
  <div class="container-breadcrumb-inline" *ngIf="showBreadCrumb">
    <app-breadcrumb-v2></app-breadcrumb-v2>
  </div>
  <div [ngClass]="{'detailbg-wide' : hasBackgroundImageWide, 'detailbg': !isMobile}" [style.backgroundImage]="'url(' + getBackground() + ')'"></div>
  <div class="prev-detail {{typeContent}}" [ngClass]="color" [ngClass]="{'no-border-bottom': programSeason || noBoderBottom}">

    <div class="close-predicas close_detail" *ngIf="!hiddeClose">
      <img src="assets/img/icons/close_prev_detail.svg" (click)="close()"
           placement="bottom-right" container="body">
    </div>
    <div class="col-xs-12 col-md-7 col-45 text-detail">
      <p class="ministry" *ngIf="contentCatalog?.organization && contentCatalog?.program">
        <span *ngIf="!contentCatalog.organizationPublished">{{contentCatalog.organization}}</span>
         <!-- @TODO-CR: ABRIR MODAL DE PROGRAMA -->
        <a *ngIf="contentCatalog.organizationPublished" class="nav-link"  data-toggle="tab" href="/organization/{{contentCatalog.organizationId}}">{{contentCatalog.organization}}</a>
         -
        <span *ngIf="!contentCatalog.organizationPublished">{{contentCatalog.organization}}</span>
        <a *ngIf="contentCatalog.organizationPublished" class="nav-link"  data-toggle="tab" href="/program/{{contentCatalog.programId}}">{{contentCatalog.program}}</a>
      </p>
      <p class="ministry" *ngIf="!contentCatalog?.organization && contentCatalog?.program">
        <span *ngIf="!contentCatalog.organizationPublished">{{contentCatalog.organization}}</span>
         <!-- @TODO-CR: ABRIR MODAL DE PROGRAMA -->
        <a *ngIf="contentCatalog.organizationPublished" class="nav-link"  data-toggle="tab" href="/program/{{contentCatalog.programId}}" >{{contentCatalog.program}}</a>
      </p>
      <p class="ministry" *ngIf="contentCatalog?.organization && !contentCatalog?.program">
      <span *ngIf="!contentCatalog.organizationPublished">{{contentCatalog.organization}}</span>
      <a *ngIf="contentCatalog.organizationPublished" class="nav-link"  data-toggle="tab" href="/organization/{{contentCatalog.organizationId}}" >{{contentCatalog.organization}}</a>
    </p>
    <a *ngIf="contentCatalog" (click)="goToLanding(contentCatalog)">
      <h3 class="chapter-title">{{contentCatalog?.title}}</h3>
    </a>

      <div *ngIf="contentCatalog?.type === 'video' && contentCatalog?.airDate" class="video-airdate">
        <span class="video-airdate-title">Emitido: </span>
        <span class="airdate">{{getAirDate(contentCatalog.airDate)}}</span>
        <i [ngbTooltip]="tooltipText" class="iconlace iconlace-info-circle"></i>
      </div>

      <div *ngIf="hosts || guests" class="program-people">

        <div *ngIf="hosts.length > 0" class="program-host">
          <span *ngIf="hosts.length == 1"class="program-host-title">Anfitrión: </span>
          <span *ngIf="hosts.length >= 2" class="program-host-title">Anfitriones: </span>

          <ng-container *ngFor="let host of hosts; let last = last">
            <a (click)="redirectPerson(host.id,host.published)" [class.cursor-none]="!host.published">{{host.fullName}}<span *ngIf="last">.</span></a>

            <ng-template [ngIf]="hosts[hosts.length - 1 ].id == host.id" [ngIfElse]="templateSeparator">
            </ng-template>
            <ng-template #templateSeparator>
              <ng-template [ngIf]="hosts[hosts.length - 2 ].id == host.id" [ngIfElse]="templateComma">
                <span class="andSeparator"> y </span>
              </ng-template>
              <ng-template #templateComma>
                <span class="andSeparator">,&nbsp;</span>
              </ng-template>
            </ng-template>
          </ng-container>
          <!-- Se quita punto final según bug: 20692. -->
          <!-- <span>.</span> -->
        </div>

        <div *ngIf="guests.length > 0" class="program-guest">
          <span *ngIf="guests.length == 1" class="program-guest-title">Invitado: </span>
          <span *ngIf="guests.length >= 2" class="program-guest-title">Invitados: </span>

          <ng-container *ngFor="let guest of guests; let index = index; let last = last">
            <a (click)="redirectPerson(guest.id,guest.published)" class=""
              [class.cursor-none]="!guest.published">{{guest.fullName}}</a>

            <ng-template [ngIf]="guests[guests.length - 1 ].id == guest.id" [ngIfElse]="templateSeparator">
            </ng-template>
            <ng-template #templateSeparator>
              <ng-template [ngIf]="guests[guests.length - 2 ].id == guest.id" [ngIfElse]="templateComma">
                <span class="andSeparator"> y </span>
              </ng-template>
              <ng-template #templateComma>
                <span class="andSeparator">,&nbsp;</span>
              </ng-template>
            </ng-template>
          </ng-container>
          <span>.</span>
        </div>

      </div>
      <p class="description">{{contentCatalog?.description | cuttext: 300}}</p>
      <app-score
        *ngIf="showStars && contentCatalog?.contentId && showRanking"
        [tooltipText]="'Para calificar este Podcast escucha al menos un 90% de cualquier episodio'"
        [canRate]="enableScore"
        [average]="contentCatalog.rating"
        [contentId]="contentCatalog.contentId"
        [color]="'#19ab7a'"
        [colorHover]="'#00724C'"
         #stars>
      </app-score>
      <div class="mobile playbtn_mobile" [ngClass]="color" *ngIf="contentCatalog">
        <a *ngIf="!isPPV(contentCatalog) && showPlayButtom" (click)="navigate(contentCatalog)" class="btn btn-primary {{typeContent}}">{{'play'+contentCatalog.type | translate}}</a>
        <a *ngIf="!contentCatalog.acquired && isPPV(contentCatalog)" (click)="navigate(contentCatalog)" class="btn btn-primary {{typeContent}}">{{'acquire'+contentCatalog.type | translate}}</a>
      </div>
      <div *ngIf="!_user">
        <div class="options">
          <ul class="option-list inline-list">
            <li class="option option-like" *ngIf="socialInfo && !(contentCatalog?.type | containWord : ['video', 'article'])">
              <app-follow [toolTip]="toolTipFollow" [color]="color" [socialInfo]="socialInfo" [preloading]="preloading" [type]="contentCatalog?.type"></app-follow>
            </li>
            <li class="option liPaddingFix" *ngIf="contentCatalog">
              <app-social-share
                share
                [title]="contentCatalog.title"
                [description]="contentCatalog.description"
                [contentId]="contentCatalog.contentId"
                [urlPath]="'/' + typeContent + '/' + contentCatalog.contentId">
              </app-social-share>
            </li>
            <br>
            <!--This code will be use when the favorites functionality is changed to its correct behavior-->
            <!--<app-add-to-favorites *ngIf="videoInfo && currentVideo?.id" [userId]="appUserId" [videoId]="currentVideo?.id"></app-add-to-favorites>-->
          </ul>
        </div>
        <ng-content select="[tags]"></ng-content>
      </div>
      <div *ngIf="_user">
        <div class="options">
          <ul class="option-list inline-list">
            <li class="option option-watchlist " *ngIf="socialInfo && contentCatalog?.type === 'video'">
              <app-add-to-watchlist [color]="color" [isMyList]="isMyList"
                                    [contentCatalog]="_contentCatalog"
                                    [socialInfo]="socialInfo"
                                    (closeDetail)="closeDetail.emit()"></app-add-to-watchlist>
            </li>
            <li class="option option-like liPaddingFix" *ngIf="(socialInfo || !_user) && (contentCatalog?.type | containWord : ['video', 'article'])">
              <app-video-like [color]="color" [socialInfo]="socialInfo"></app-video-like>
            </li>
            <li class="option option-like liPaddingFix" *ngIf="socialInfo && !(contentCatalog?.type | containWord : ['video', 'article'])">
              <app-follow [toolTip]="toolTipFollow" [color]="color" [socialInfo]="socialInfo" [preloading]="preloading" [type]="contentCatalog?.type"></app-follow>
            </li>
            <li class="option liPaddingFix" *ngIf="contentCatalog">
              <app-social-share
                share
                [title]="contentCatalog.title"
                [description]="contentCatalog.description"
                [contentId]="contentCatalog.contentId"
                [urlPath]="'/' + typeContent + '/' + contentCatalog.contentId">
              </app-social-share>
            </li>
            <br>
            <!--This code will be use when the favorites functionality is changed to its correct behavior-->
            <!--<app-add-to-favorites *ngIf="videoInfo && currentVideo?.id" [userId]="appUserId" [videoId]="currentVideo?.id"></app-add-to-favorites>-->
          </ul>
        </div>
        <ng-content select="[tags]"></ng-content>
      </div>

    </div>


    <div class="col-md-5 col-lg-7 btnside">
      <!-- <div class="gradient"></div> -->
      <div class="playbtn">
        <a *ngIf="currentVideo" [routerLink]="[contentCatalog?.type,contentCatalog?.contentId]">
          <img src="assets/img/icons/prevplay_btn.svg" ngbTooltip="{{'watch-now' | translate}}" placement="top"
               container="body">
        </a>
      </div>
    </div>
  </div>
  <!--DEGRADADO-->
  <div class="gradient" *ngIf="contentCatalog?.type == 'program' ? true : !hasBackgroundImageWide"></div>
  <!--DEGRADADO-->
</div>

<ng-content *ngIf="isShow" select="app-seasons"></ng-content>
<!--app-seasons *ngIf="programSeason && isShow" [hostsList]="contentCatalog?.hostsList" [programSeasons]="programSeason"></app-seasons-->
<!--div *ngIf="programSeason" class="gradient" style="box-shadow: -70px -20px 20px rgba(0,0,0,0.6);
    transition: all ease-in-out 0.4s;
-moz-transition: all ease-in-out 0.4s;
-webkit-transition: all ease-in-out 0.4s;
-ms-transition: all ease-in-out 0.4s;
height: 100px;
margin-bottom: -100px;
margin-top: -3px;
width: 150%;"></div-->
