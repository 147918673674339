<app-skeleton-more-info *ngIf="loadingSkeleton" [loading]="loadingSkeleton"></app-skeleton-more-info>

<div *ngIf="!loadingSkeleton" class="content-detail" appClickOutsideModal (clickOutside)="closeModal()">
    <div class="content-close">
        <button
          type="button"
          class="content-close-button"
          (click)="closeModal(true)"
          aria-label="Close">
          <span class="content-close-icon" aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-header-more-info
        [contentDetail]="contentDetailPodcast"
        [contentType]="contentType"
        (redirectToContent)="redirectToContent()">
    </app-header-more-info>
    <app-podcast-detail-modal
        *ngIf="contentType === 'podcast'"
        [podcastDetail]="contentDetailPodcast"
        [contentType]="contentType"
        [paginRequest]="paginRequest"
        (checkModalExceedsViewport)="checkIfModalExceedsViewport()"
        (closeModal)="closeModal()"
        (redirectToContent)="redirectToContent()">
    </app-podcast-detail-modal>
</div>
