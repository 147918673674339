import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContentDetail } from '@app/core/models/interfaces/content-detail';
import { SoaApi } from '@app/core/models/interfaces/i-cancel-subscription';
import { environment as ENV } from '@environment/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentDetailService {

  private API = `${ENV.soaApiBaseUrl}content/view`;

  constructor(private http: HttpClient) { }

  getContentDetail(contentId: string): Observable<SoaApi<ContentDetail>> {
    const url = `${this.API}/${contentId}`;
    return this.http.get<SoaApi<ContentDetail>>(url);
  }
}
