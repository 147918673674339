<div *ngIf="subscriptionFromLanding" class="inline-list">
  <h2>Apoyar a Enlace+</h2>
  <h3>Tu donación nos permite seguir desarrollando la plataforma de Enlace+, una visión que tiene como objetivo presentar un mensaje acertado de Dios, para la persona correcta, en el momento oportuno.</h3>
</div>

<div class="csb" [ngClass]="{'max-height': subscriptionFromLanding}">
  <div *ngIf="!subscriptionFromLanding" class="suscriptions-information">
    <p class="suscriptions-information_paragraph">
      Enlace+ es un proyecto que busca utilizar la tecnología para compartir el mensaje acertado, con la persona correcta, en el momento oportuno;
      poniendo al alcance de cada usuario miles de contenidos edificantes y Cristo céntricos en diferentes formatos.
    </p>
    <p class="suscriptions-information_paragraph">
      Actualmente puedes inscribirte en los siguientes planes:
    </p>
  </div>
  <div class="suscriptions-cards">
    <div class="suscriptions-card" *ngIf="!subscriptionFromLanding" [ngClass]="{'grayed': user.suscriptionPlanId === 'fundador'}">
      <div class="suscriptions-card-ribbon" *ngIf="user.suscriptionPlanId !== 'fundador'">
        <span class="suscriptions-card-ribbon_text">Tu plan actual</span>
      </div>
      <div class="suscriptions-card-information">
        <h3 class="suscriptions-card_supra-title">Plan</h3>
        <h2 class="suscriptions-card_title">Básico</h2>
        <h5 class="suscriptions-card_sub-title" [ngClass]="{'grayed': user.suscriptionPlanId === 'fundador'}">
          ¡Gratis!
        </h5>
        <p class="suscriptions-card_paragraph">
          Gracias a los <strong>Misioneros Digitales</strong> que han donado voluntariamente, puedes acceder gratis a:
        </p>
        <ul class="suscriptions-card_list">
          <li class="suscriptions-card_list-item">
            Todo el contenido de Enlace+ que incluye: videos, podcasts, artículos y más.
          </li>
          <li class="suscriptions-card_list-item">
            Secciones de: Biblia, En Vivo, Oración.
          </li>
        </ul>
        <div class="suscriptions-card_register">
          <button [ngClass]="{'grayed': user.suscriptionPlanId === 'fundador'}" class="suscriptions-card_button"
                  *ngIf="user.suscriptionPlanId === 'fundador'"
                  (click)="enableSuscriptionBeta()">Cambia tu plan
          </button>
        </div>
      </div>
    </div>
    <div id="purple" class="suscriptions-card">
      <div class="suscriptions-card-ribbon" *ngIf="user.suscriptionPlanId === 'fundador'">
        <span class="suscriptions-card-ribbon_text">Tu plan actual</span>
      </div>
      <div class="suscriptions-card-information">
        <h3 class="suscriptions-card_supra-title">Plan</h3>
        <h2 class="suscriptions-card_title">Misionero Digital</h2>
        <h5 class="suscriptions-card_sub-title">${{fromAmount}} al mes*</h5>

        <p class="suscriptions-card_paragraph">
          Con tu suscripción te conviertes en un <strong> Misionero Digital.</strong>
        </p>
        <p class="suscriptions-card_paragraph">
          Tu aporte mensual permite que miles de personas puedan acceder gratuitamente al contenido que transmite el mensaje de salvación. Disfruta de:
        </p>
        <ul class="suscriptions-card_list">
          <li class="suscriptions-card_list-item">
            Todo el contenido en diferentes formatos y secciones de la plataforma. Además de adelantos e información exclusiva del avance del proyecto.
          </li>
        </ul>
        <p class="suscriptions-card_paragraph legal">*Puedes cancelar tu suscripción en cualquier momento.</p>
        <div class="suscriptions-card_register">
          <button class="suscriptions-card_button" *ngIf="user.suscriptionPlanId !== 'fundador' && !subscriptionFromLanding"
                  (click)="selectPlan('founder')">Mejora tu plan
          </button>
        </div>
      </div>
      <div class="suscriptions-card_chevron" *ngIf="target === 'founder'"></div>
    </div>
  </div>
  <form class="dark-forms col-xs-12 col-sm-12 col-md-12 needs-validation" autocomplete="off" #form="ngForm" novalidate
        [autocomplete]="false"
        [ngClass]="{'was-validated': form.submitted}" *ngIf="target === 'founder' || subscriptionFromLanding">
    <div class="suscriptions-options">
      <h3 class="suscriptions-options_title">
        Elige tu aporte
      </h3>
      <p class="suscriptions-options_paragraph">
        Agradecemos mucho que hayas elegido el Plan Misionero Digital.
      </p>
      <p class="suscription-options_paragraph">
        Si deseas, puedes apoyarnos con una contribución mensual superior a ${{fromAmount}}.
      </p>
      <div class="suscriptions-options_radios">
        <label class="col-lg-6" for="fixed-amount">
          <input type="radio" class="form-control" aria-label="..." name="amount" id="fixed-amount"
                 [checked]="!isVariable" (click)="isVariableAmount()">
          <span class="custom-radio"></span>
          <span>${{fromAmount}} al mes</span>
        </label>
        <div class="suscriptions-options_radios-group">
          <label class="" for="variable-amount">
            <input type="radio" class="form-control" aria-label="..." name="amount" id="variable-amount"
                   [checked]="isVariable" (click)="isVariableAmount()">
            <span class="custom-radio"></span>
            <span>Otro aporte</span>
          </label>
          <label class="input-group" for="variable-amount-number">
            <input oninput=""
                   type="number"
                   min="0"
                   step=".01"
                   placeholder="$ USD"
                   pattern="^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$"
                   class="form-control" aria-label="..." name="amountInput"
                   [(ngModel)]="amount" id="variable-amount-number"
                   (blur)="formatCurrency()"
                   #amountInput="ngModel"
                   [disabled]="!isVariable"
                   [required]="isVariable"
            />
          </label>
          <span class="frequency">al mes</span>
        </div>
        <span [hidden]="amountInput.valid || (amountInput.pristine && !form.submitted)" class="text-danger">
          <span [hidden]="!amountInput.hasError('required')">El aporte es requerido</span>
          <span [hidden]="!amountInput.hasError('pattern')">El aporte tiene que ser mayor que 0</span>
        </span>
        <span *ngIf="planFounder" [hidden]="!errorAmount" class="text-danger">
          <span>El aporte debe ser mayor a {{(planFounder.price / 100) | number: '1.2-2'}}</span>
        </span>
        <div class="input-group col-lg-12">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-lg-6">
              <p>Número de tarjeta</p>
              <div class="input-group col-xs-12 col-lg-12">
                <input #number="ngModel" type="text" maxlength="19"
                       [ngModel]="cardNumber | creditCardSeparator" (ngModelChange)="cardNumber=$event" 
                       (keypress)="utils.limitCharactersOnAppleBrowsers($event, 19)"
                       (keydown)="onlyNumbers($event)"
                       class="form-control" id="cardNumber" name="number"
                       placeholder="Número de tarjeta válido" required autofocus data-stripe="number"
                       autocomplete="off"/>
                <div [hidden]="number.valid || (number.pristine && !form.submitted)"
                     class="text-danger">
                  <span [hidden]="!number.hasError('required')">El número de tarjeta es requerido</span>
                </div>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-3">
              <p class="nowrap">Fecha de vencimiento</p>
              <input #expiredDateSuscription="ngModel"
                     [ngModel]="expiryFull"
                     (keydown)="onlyNumbers($event)"
                     (ngModelChange)="testValueExpired($event)"
                     class="form-control"
                     name="year-1234"
                     placeholder="MM/YY"
                     required
                     type="text"
                     [maxLength]="5"
                     style="height: 35px"/>
              <div [hidden]="expiredDateSuscription.valid || (expiredDateSuscription.pristine && !form.submitted)"
                   class="text-danger">
                <span
                  [hidden]="!expiredDateSuscription.hasError('required')">La Fecha de vencimiento es requerida</span>
                <span *ngIf="expiredDateHasError(expiredDateSuscription)">Formato inválido, debe ser MM/YY</span>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-3">
              <p class="nowrap">Código de seguridad</p>
              <input #cvcInput="ngModel" [(ngModel)]="cvc" minlength="3" maxlength="4" autocomplete="off" type="password"
                     class="form-control"
                     name="cvcInput" id="cvCode" placeholder="CVV" required data-stripe="cvc"/>
              <div [hidden]="cvcInput.valid || (cvcInput.pristine && !form.submitted)"
                   class="text-danger">
                <span [hidden]="!cvcInput.hasError('required')">El Código de seguridad es requerido</span>
                <span *ngIf="cvcInput.hasError('maxlength')">
                  El CVV no debería tener más de {{cvcInput?.errors.maxlength.requiredLength}} caracteres.
                </span>
                <span *ngIf="cvcInput.hasError('minlength')">
                  CVV inválido.
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 namePerson">
              <span>Nombre completo como aparece en la tarjeta</span>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-md-3">
              <p for="cardFirstName">Nombre:</p>
              <input value="{{internationalCard.firstName}}"
                     [(ngModel)]="internationalCard.firstName" maxlength="30" type="text" class="form-control"
                     name="international_Card_FirstName"
                     #international_Card_FirstName="ngModel" id="cardFirstName"
                     placeholder="Nombre" required/>
              <div
                [hidden]="international_Card_FirstName.valid || (international_Card_FirstName.pristine && !form.submitted)"
                class="text-danger">
                <span [hidden]="!international_Card_FirstName.hasError('required')">El Nombre es requerido</span>
              </div>
            </div>
            <div class="col-xs-12 col-md-3">
              <p for="cardLastName">Apellido(s):</p>
              <input value="{{internationalCard.lastName}}"
                     [(ngModel)]="internationalCard.lastName" maxlength="30" type="text" class="form-control"
                     name="international_Card_FirstName"
                     #international_Card_LastName="ngModel" id="cardLastName"
                     placeholder="Apellido(s)" required/>
              <div
                [hidden]="international_Card_LastName.valid || (international_Card_LastName.pristine && !form.submitted)"
                class="text-danger">
                <span [hidden]="!international_Card_LastName.hasError('required')">El Apellido es requerido</span>
              </div>
            </div>
            <div class="col-xs-12 col-md-6">
              <p for="international_Card_Country">País de facturación:</p>
              <div class="input-group col-xs-12">
                <select [(ngModel)]="countrySelected" id="international_Card_Country"
                        name="international_Card_Country" required #international_Card_Country="ngModel"
                        class="country-select">
                  <option [ngValue]="null" [disabled]="true">Elige el país</option>
                  <option [ngValue]="country" *ngFor="let country of countries">{{country.name}}</option>
                </select>
                <div
                  [hidden]="international_Card_Country.valid || (international_Card_Country.pristine && !form.submitted)"
                  class="text-danger">
                  <span [hidden]="!international_Card_Country.hasError('required')">El País es requerido</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-md-6 cardAddress">
              <p for="cardAddress">Dirección de facturación de la tarjeta:</p>
              <input value="{{internationalCard.address}}" [(ngModel)]="internationalCard.address" maxlength="30"
                     type="text" class="form-control"
                     name="international_Card_Address" #international_Card_Address="ngModel" id="cardAddress"
                     placeholder="Dirección" required/>
              <div
                [hidden]="international_Card_Address.valid || (international_Card_Address.pristine && !form.submitted)"
                class="text-danger">
                <span [hidden]="!international_Card_Address.hasError('required')">La Dirección es requerida</span>
              </div>
            </div>
            <div class="col-xs-12 col-md-6 cardState">
              <p for="cardState">Provincia / Estado:</p>
              <input [(ngModel)]="internationalCard.state" maxlength="30" type="text" class="form-control"
                     name="international_Card_State" #international_Card_State="ngModel" id="cardState"
                     placeholder="Provincia / Estado" required/>
              <div [hidden]="international_Card_State.valid || (international_Card_State.pristine && !form.submitted)"
                   class="text-danger">
                <span [hidden]="!international_Card_State.hasError('required')">La Provincia / Estado es requerida</span>
              </div>
            </div>
          </div>
          <div class="text-danger mt-20">
            {{error?.message}}
          </div>
        </div>

      </div>
      <div class="suscriptions-card_register" *ngIf="target === 'founder'">
        <button [routerLink]="['/']" *ngIf="subscriptionFromLanding" type="submit" class="suscriptions-card_button mr-20">Cancelar</button>
        <button type="button" class="suscriptions-card_button" (click)="validatePlan(form)">Continuar</button>
      </div>
    </div>
    <div class="suscriptions-card_register" *ngIf="target === 'base'">
      <button class="suscriptions-card_button" (click)="getSuscriptionBeta()">Ingresar</button>
    </div>
  </form>

</div>
