export enum LandingSeason {
  PROGRAM = 'program',
  HOME = 'home',
  PERSON = 'person',
  ORGANIZATION = 'organization',
}

// un aproximado a lo que se necesita actualmente
export enum BreakpointLimit {
  BREAK_MEDIUM_DESKTOP = 1500,
  BREAK_SMALL_LAPTOP = 1200,
  BREAK_TABLET = 992,
  BREAK_SMALL_TABLET = 768,
  BREAK_MOBILE = 580,
  BREAK_MEDIUM_MOBILE = 450,
}

export const BREAKPOINT_LIMIT = [
  {
    [BreakpointLimit.BREAK_MEDIUM_DESKTOP]: 13,
    [BreakpointLimit.BREAK_SMALL_LAPTOP]: 10,
    [BreakpointLimit.BREAK_TABLET]: 10,
    [BreakpointLimit.BREAK_SMALL_TABLET]: 7,
    [BreakpointLimit.BREAK_MOBILE]: 5,
    [BreakpointLimit.BREAK_MEDIUM_MOBILE]: 3,
  },
  {
    [BreakpointLimit.BREAK_MEDIUM_DESKTOP]: 9,
    [BreakpointLimit.BREAK_SMALL_LAPTOP]: 7,
    [BreakpointLimit.BREAK_TABLET]: 7,
    [BreakpointLimit.BREAK_SMALL_TABLET]: 5,
    [BreakpointLimit.BREAK_MOBILE]: 5,
    [BreakpointLimit.BREAK_MEDIUM_MOBILE]: 3,
  },
];

export const BREAKPOINT_SEASON = {
  [LandingSeason.PROGRAM]: { ...BREAKPOINT_LIMIT[0] },
  [LandingSeason.HOME]: { ...BREAKPOINT_LIMIT[0] },
  [LandingSeason.PERSON]: { ...BREAKPOINT_LIMIT[1] },
  [LandingSeason.ORGANIZATION]: { ...BREAKPOINT_LIMIT[1] },
};
