import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { UserCanSubscribeGuard } from '@guards/user-can-subscribe.guard';
import { UserHasSubscribeGuard } from '@guards/user-has-subscribe.guard';
import { UserCanLoginGuard } from '@guards/user-can-login.guard';

const routes: Routes = [
  { path: 'enlace/auth',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule),
    canActivate: [UserCanLoginGuard],
  },
  {
    path: 'subscription',
    loadChildren: () => import('./pages/suscription/suscription.module').then(m => m.SuscriptionModule),
    canActivate: [UserCanSubscribeGuard],
  },
  { path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule) },
  {
    path: 'terms-of-use',
    loadChildren: () => import('./pages/termsofuse/termsofuse.module').then(m => m.TermsofuseModule),
  },
  {
    path: 'privacy-policies',
    loadChildren: () => import('./pages/privacypolicies/privacypolicies.module').then(m => m.PrivacypoliciesModule),
  },
  {
    path: 'get-subscription',
    loadChildren: () => import('./pages/get-subscription/get-subscription.module').then(m => m.GetSubscriptionModule),
  },
  {
    path: 'watch',
    loadChildren: () => import('./pages/watch/watch.module').then(m => m.WatchModule),
  },
  {
    path: '',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    canActivate: [UserHasSubscribeGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: '**',
    loadChildren: () => import('./config/redirect/redirect.module').then(m => m.RedirectModule),
  },
];

const config: ExtraOptions = {
    useHash: false,
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
