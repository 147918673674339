import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ImgFallbackModule } from 'ngx-img-fallback';
import { AgmCoreModule } from '@shared/components/google-maps';

import { DirectivesModule } from '@directives/directives.module';
import { ModalTemplateModule } from '@components/modals/modal-template/modal-template.module';

import { HeaderComponent } from '@components/header/header.component';
import { OfficesModalComponent } from '@components/header/offices-modal/offices-modal.component';
import { OfficeModalComponent } from '@components/header/office-modal/office-modal.component';
import { HeaderLinkComponent } from '@components/header/header-link/header-link.component';
import { SearchV2Module } from '@components/search-v2/search-v2.module';
import { CheckoutDonationModalModule } from '@components/modals/checkout-donation-modal/checkout-donation-modal.module';

@NgModule({
    declarations: [
        HeaderComponent,
        OfficesModalComponent,
        OfficeModalComponent,
        HeaderLinkComponent
    ],
    imports: [
        CommonModule,
        AgmCoreModule,
        ImgFallbackModule,
        NgbModalModule,
        DirectivesModule,
        ModalTemplateModule,
        SearchV2Module,
        CheckoutDonationModalModule,
    ],
    exports: [HeaderComponent]
})
export class HeaderModule { }
