import {Injectable} from '@angular/core';
import {RestClient} from '../rest-client';
import {Subject} from 'rxjs';

import {AuthenticationService} from '../authentication.service';
import {ContentV2Service} from './content-v2.service';

import {ICatalogViewModelPagingRequest} from '@interfaces/i-catalog-view-model-paging-request';
import {IContentCatalogViewModel} from '@interfaces/i-content-catalog-view-model';
import {IRestMethod, RestAction, RestParams, RestRequestMethod} from 'rest-core';
import {ICatalogViewModelPaging} from '@interfaces/i-catalog-view-model-paging';
import {ICatalogViewModel} from '@interfaces/icatalog-view-model';
import {environment} from '../../../environments/environment';
import {IVideoUserView} from '@interfaces/i-video-user-view';
import {EnlaceRestHandler} from '../enlace-rest-handler';
import {ICatalog} from '@interfaces/i-catalog';
import { LoadingOverlayService } from '@app/shared/services/loading-overlay.service';

@RestParams({
  // IRestParams
  pathPrefix: environment.webApi + 'v2/catalog'
})
@Injectable({
  providedIn: 'root'
})
export class CatalogV2Service extends RestClient {

  catalogs: ICatalogViewModel[];
  ebookCatalogs: ICatalogViewModel[];
  audiobookCatalogs: ICatalogViewModel[];
  libraryCatalogs: ICatalogViewModel[];
  libraryFeatured: ICatalogViewModel[];
  myLibraryCatalogs: ICatalogViewModel[];
  recommendedCatalogs: ICatalogViewModel[];
  mySeenCatalogs: ICatalogViewModel[];
  AfterSeeVideo: boolean = false;
  catalogsPaging: ICatalogViewModelPaging;
  totalCatalogs: number;
  totalFeatured: number;
  loadCatalogComplete = false;
  pagingRequest: ICatalogViewModelPagingRequest = <ICatalogViewModelPagingRequest>{
    page: 0,
    pagingDisable: false,
    topDisable: false,
    top: 2,
    pagesLoaded: 0,
    contentType: 'all'
  };

  changeMyListItemSubscriber: Subject<any> = new Subject<any>();

  get findCatalogMyList(): ICatalogViewModel {
    if(!!this.catalogs)
      return this.catalogs.find((catalog: ICatalogViewModel) => catalog.isMyList);
  }

  constructor(restHandler: EnlaceRestHandler,
              authService: AuthenticationService,
              private contentService: ContentV2Service,
              private loadingOverlayService: LoadingOverlayService
             ) {
    super(restHandler, authService);
  }

  @RestAction({
    path: '/public/view',
    method: RestRequestMethod.Post
  })
  _listCatalogs: IRestMethod<ICatalogViewModelPagingRequest, ICatalogViewModelPaging>;

  @RestAction({
    path: '/mobile/public/view/{!userId}/{!contentType}/{!sectionName}/{!sliderItemsPerPage}/{!top}/{!orderByField}',
    method: RestRequestMethod.Get
  })
  _mobileListCatalogs: IRestMethod<{ userId: string, contentType: string, sectionName: string, sliderItemsPerPage: string, top: string, orderByField: string }, ICatalogViewModelPaging>;

  @RestAction({
    path: '/public/paging',
    method: RestRequestMethod.Post
  })
  catalogPaging: IRestMethod<ICatalogViewModelPagingRequest, IContentCatalogViewModel[]>;

  @RestAction({
    path: '/mobile/public/paging/{!userId}/{!contentType}/{!catalogId}/{!sliderItemsPerPage}/{!sliderPage}',
    method: RestRequestMethod.Get
  })
  mobileCatalogPaging: IRestMethod<{ userId: string, contentType: string, catalogId: string, sliderItemsPerPage: string, sliderPage: string }, IContentCatalogViewModel[]>;

  @RestAction({
    path: '/published',
    method: RestRequestMethod.Get
  })
  published: IRestMethod<void, ICatalog[]>;

  @RestAction({
    path: '/for/video/{!videoId}/{!programId}',
    method: RestRequestMethod.Get
  })
  catalogsForVideo: IRestMethod<{ programId: string, videoId: string }, ICatalogViewModel[]>;

  @RestAction({
    path: '/recomended',
    method: RestRequestMethod.Get
  })
  catalogsRecomended: IRestMethod<void, ICatalogViewModel[]>;

  listCatalogs(pagingRequest: ICatalogViewModelPagingRequest): Promise<ICatalogViewModel[]> {
    return new Promise<ICatalogViewModel[]>((resolve, reject) => {
      if (this.catalogs && this.catalogs.length === this.totalCatalogs) {
        this.loadCatalogComplete = true;
        this.loadingOverlayService.hideLoadingOverlay();
        resolve(this.catalogs);
      } else if (pagingRequest.sectionName === 'home') {
        this._listCatalogs(pagingRequest).then((result: ICatalogViewModelPaging) => {
          this.totalCatalogs = result.totalCatalogs;
          if (!this.catalogs) {
            this.catalogs = [];
          }
          this.catalogs = this.catalogs.concat(result.catalogs);
          this.loadingOverlayService.hideLoadingOverlay();
          resolve(this.catalogs);
        });
      }
    });
  }

  listLibraryCatalogs(pagingRequest: ICatalogViewModelPagingRequest): Promise<ICatalogViewModel[]> {
    return new Promise<ICatalogViewModel[]>((resolve, reject) => {
      this._listCatalogs(pagingRequest).then((result: ICatalogViewModelPaging) => {
        this.totalCatalogs = result.totalCatalogs;
        if (!this.libraryCatalogs) {
          this.libraryCatalogs = [];
        }
        this.libraryCatalogs = result.catalogs;
        resolve(this.libraryCatalogs);
      });
    });
  }

  listLibraryFeatured(pagingRequest: ICatalogViewModelPagingRequest): Promise<ICatalogViewModel[]> {
    return new Promise<ICatalogViewModel[]>((resolve, reject) => {
      this._listCatalogs(pagingRequest).then((result: ICatalogViewModelPaging) => {
        this.totalFeatured = result.totalCatalogs;
        if (!this.libraryFeatured) {
          this.libraryFeatured = [];
        }
        this.libraryFeatured = result.catalogs;
        resolve(this.libraryFeatured);
      });
    });
  }

  listMyLibraryCatalogs(pagingRequest: ICatalogViewModelPagingRequest): Promise<ICatalogViewModel[]> {
    return new Promise<ICatalogViewModel[]>((resolve, reject) => {
      this._listCatalogs(pagingRequest).then((result: ICatalogViewModelPaging) => {
        this.totalCatalogs = result.totalCatalogs;
        if (!this.myLibraryCatalogs) {
          this.myLibraryCatalogs = [];
        }
        this.myLibraryCatalogs = result.catalogs;
        resolve(this.myLibraryCatalogs);
      });
    });
  }

  listRecomendedLibraryCatalogs(paginrequest: ICatalogViewModelPagingRequest): Promise<ICatalogViewModel[]> {
    return new Promise<ICatalogViewModel[]>((resolve, reject) => {
      this._listCatalogs(paginrequest).then((result: ICatalogViewModelPaging) => {
        if(!this.recommendedCatalogs) {
          this.recommendedCatalogs = [];
        }
        this.recommendedCatalogs = result.catalogs;
        resolve(this.recommendedCatalogs);
      })
    })
  }

  listEbookCatalogs(pagingRequest: ICatalogViewModelPagingRequest): Promise<ICatalogViewModel[]> {
    return new Promise<ICatalogViewModel[]>((resolve, reject) => {
      this._listCatalogs(pagingRequest).then((result: ICatalogViewModelPaging) => {
        this.totalCatalogs = result.totalCatalogs;
        if (!this.ebookCatalogs) {
          this.ebookCatalogs = [];
        }
        this.ebookCatalogs = result.catalogs;
        resolve(this.ebookCatalogs);
      });
    });
  }

  addMyList(contentCatalog: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.catalogs) {
        let myList = this.findCatalogMyList;
        if (!myList) {
          myList = this.buildCatalogMyList(contentCatalog);
          myList.contentCatalogViewModels.push(contentCatalog);
          this.catalogs.splice(1, 0, myList);
        } else {
          if (myList.defaultContentType === contentCatalog.type) {
            myList.totalItems += 1;
            myList = {...this.changeTotalItemsPerType(myList, contentCatalog)};
            myList.contentCatalogViewModels.push(contentCatalog);
            this.refreshCatalogMyList(myList);
          } else {
            const found = myList.contentTypesCatalog.find(item => item.type === contentCatalog.type);
            if (!!found) {
              myList = {...this.changeTotalItemsPerType(myList, contentCatalog)};
            } else {
              myList.contentTypesCatalog.push({type: contentCatalog.type, totalItemsPerType: 1});
            }
            this.refreshCatalogMyList(myList);
          }
        }
        if (contentCatalog.type === 'video') {
          contentCatalog = Object.assign({}, contentCatalog);
          contentCatalog.isShow = false;
          contentCatalog.preLoad = false;
          this.contentService.changeMyListSocialInfo(true, contentCatalog.contentId);
        }
        this.changeMyListSubscriber('add');
      }
      resolve();
    });
  }

  buildCatalogMyList(contentCatalog: any): ICatalogViewModel {
    return <ICatalogViewModel>{
      id: '00000000-0000-0000-0000-000000000200',
      contentCatalogViewModels: [],
      contentTypesCatalog: [{type: contentCatalog.type, totalItemsPerType: 1}],
      defaultContentType: contentCatalog.type,
      isMyList: true,
      name: 'Mi Lista',
      type: 'Horizontal',
      isShowDetail: false,
      totalItems: 1
    };
  }

  changeTotalItemsPerType(myList: ICatalogViewModel, contentCatalog: any): ICatalogViewModel {
     myList.contentTypesCatalog.map((item) => {
      if (item.type === contentCatalog.type)
        return (item.totalItemsPerType += 1);
      else return item;
    });
    return myList;
  }

  discounTotalItemsPerType(myList: ICatalogViewModel, contentCatalog: any): ICatalogViewModel {
    myList.contentTypesCatalog.map(contentType => {
      if (contentType.type === contentCatalog.type)
        contentType.totalItemsPerType -= 1;
      return contentType;
    });
    myList.contentTypesCatalog = myList.contentTypesCatalog.filter(item => item.totalItemsPerType > 0);
    return myList;
  }

  refreshCatalogMyList(myList: ICatalogViewModel): void {
    this.catalogs.map(catalog => {
      if (catalog.isMyList) return catalog = myList;
      return catalog;
    });
  }

  /* TODO: Crear un nuevo flujo para catalogos mi-lista, continua-viendo..  */
  deleteCatalogMyList(): void {
    if(!!this.catalogs) {
      const myList = this.findCatalogMyList;
      const isMySee = this.catalogs.find((catalog: ICatalogViewModel) => catalog.isMySee);
      if (!!isMySee) this.catalogs.splice(this.catalogs.indexOf(isMySee), 1);
      if (!!myList) this.catalogs.splice(this.catalogs.indexOf(myList), 1);
    }
  }

  addMySeens(contentCatalog: IContentCatalogViewModel) {
    if (this.catalogs) {
      let mySeen = this.catalogs.find((catalog: ICatalogViewModel) => {
        return catalog.isMySee === true;
      });

      if (!mySeen) {
        mySeen = <ICatalogViewModel>{
          contentCatalogViewModels: [],
          isMyList: false,
          isMySee: true,
          name: 'CONTINÚA VIENDO',
          type: 'Horizontal',
          isShowDetail: false,
          contentTypesCatalog: [
            {
              totalItemsPerType: 1,
              type: contentCatalog.type
            }
          ]
        };
        this.catalogs.unshift(mySeen);
      } else if (mySeen.contentCatalogViewModels.length > 0) {
        // Get Last Seen Video Index
        var item = this.catalogs[0].contentCatalogViewModels.find(x => x.contentId == contentCatalog.contentId);
        // if Item is not New
        if (item) {
          var SeenIndex = this.catalogs[0].contentCatalogViewModels.indexOf(item);
          // set Last of Seen Video Index to same index
          this.catalogs[0].contentCatalogViewModels = this.moveMySeenElement(SeenIndex, 0);
        } else {// if Item is New
          this.catalogs[0].contentCatalogViewModels.push(contentCatalog);
          var item = this.catalogs[0].contentCatalogViewModels.find(x => x.contentId == contentCatalog.contentId);
          var SeenIndex = this.catalogs[0].contentCatalogViewModels.indexOf(item);
          // set Last of Seen Video Index to same index
          this.catalogs[0].contentCatalogViewModels = this.moveMySeenElement(SeenIndex, 0);
        }
      }
      const content = mySeen.contentCatalogViewModels.find((value) => {
        return value.contentId === contentCatalog.contentId;
      });
      if (content) {
        return;
      }
      contentCatalog = Object.assign({}, contentCatalog);
      if (!contentCatalog.urlBackgroundImage) {
        contentCatalog.urlBackgroundImage = contentCatalog.urlImage;
      }
      contentCatalog.isShow = false;
      if (mySeen.contentCatalogViewModels.length >= 15) {
        mySeen.contentCatalogViewModels.splice(mySeen.contentCatalogViewModels.length - 1, 1);
      }
      mySeen.contentCatalogViewModels.push(contentCatalog);
    }

  }

  // summary
  // Move items from old position to new in the catalogs Array
  moveMySeenElement(old_index, new_index) {
    while (old_index < 0) {
      old_index += this.catalogs[0].contentCatalogViewModels.length;
    }
    while (new_index < 0) {
      new_index += this.catalogs[0].contentCatalogViewModels.length;
    }
    if (new_index >= this.catalogs[0].contentCatalogViewModels.length) {
      var k = new_index - this.catalogs[0].contentCatalogViewModels.length;
      while ((k--) + 1) {
        this.catalogs[0].contentCatalogViewModels.push(undefined);
      }
    }
    this.catalogs[0].contentCatalogViewModels.splice(new_index, 0, this.catalogs[0].contentCatalogViewModels.splice(old_index, 1)[0]);
    return this.catalogs[0].contentCatalogViewModels;
  }

  removeMyList(contentCatalog: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.catalogs) {
        let myList = this.findCatalogMyList;
        if (myList.defaultContentType === contentCatalog.type) {
          myList.totalItems -= 1;
          myList = this.discounTotalItemsPerType(myList, contentCatalog);
          myList.contentCatalogViewModels =
            myList.contentCatalogViewModels.filter(content => content.contentId !== contentCatalog.contentId);
        } else {
          myList = this.discounTotalItemsPerType(myList, contentCatalog);
        }
        if (contentCatalog.type === 'video') this.contentService.changeMyListSocialInfo(false, contentCatalog.contentId);
        if (myList.contentCatalogViewModels.length === 0 && myList.contentTypesCatalog.length === 0) {
          this.catalogs.splice(this.catalogs.indexOf(myList), 1);
        } else {
          if (myList.contentCatalogViewModels.length === 0) {
            myList.defaultContentType = myList.contentTypesCatalog[0].type;
            myList.totalItems = myList.contentTypesCatalog[0].totalItemsPerType;
            this.refreshCatalogMyList(myList);
            this.changeMyListSubscriber('change');
            resolve();
          } else {
            myList.defaultContentType = contentCatalog.type;
            this.refreshCatalogMyList(myList);
            this.changeMyListSubscriber('remove');
            resolve();
          }
        }
      }
      resolve();
    });
  }

  changeMyListSubscriber(text: string): void {
    this.changeMyListItemSubscriber.next(text);
  }

  checkCompleteMySeenVideo(videoUserView: IVideoUserView) {
    if (this.catalogs) {
      const mySeenCatalog = this.catalogs.find((catalog) => {
        return catalog.isMySee;
      });
      if (mySeenCatalog && (videoUserView.totalSeconds - videoUserView.secondEnd) <= 60) {
        const video = mySeenCatalog.contentCatalogViewModels.find((item) => {
          return item.contentId === videoUserView.videoId;
        });
        if (video) {
          const indexItem = mySeenCatalog.contentCatalogViewModels.indexOf(video);
          mySeenCatalog.contentCatalogViewModels.splice(indexItem, 1);
        }
        if (mySeenCatalog.contentCatalogViewModels.length === 0) {
          this.catalogs.splice(this.catalogs.indexOf(mySeenCatalog), 1);
        }
      }
    }
  }

  updateProgressVideo(videoId: string, progress: number) {
    this.catalogs.forEach((catalog: ICatalogViewModel) => {
      catalog.contentCatalogViewModels.forEach((content: IContentCatalogViewModel) => {
        if (content.contentId === videoId) {
          content.percentAdvance = progress;
        }
      });
    });
  }

  updatePPVContentInfo(contentSource: any) {
    const contentId: string = contentSource.id || contentSource.contentId;
    if(this.catalogs != null && contentId) {
      this.catalogs.forEach((catalog: ICatalogViewModel) => {
        catalog.contentCatalogViewModels.forEach((content: IContentCatalogViewModel) => {
          if (content.contentId === contentId) {
            content.acquired = contentSource.acquired;
            content.expireDate = contentSource.expireDate;
            content.validDays = contentSource.validDays;
            content.ppv = contentSource.ppv;
          }
        });
      });
    }
  }
}
