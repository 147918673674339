import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnPrayRequestComponent } from './btn-pray-request.component';
import { PrayRequestModalModule } from '../modals/pray-request-modal/pray-request-modal.module';

@NgModule({
  declarations: [
    BtnPrayRequestComponent
  ],
  exports: [
    BtnPrayRequestComponent
  ],
  imports: [
    CommonModule,
    PrayRequestModalModule
  ]
})
export class BtnPrayRequestModule { }
