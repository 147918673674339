import { Component, OnInit } from '@angular/core';
import {UserV2Service} from '../../../../api-client/v2/user-v2.service';
import {IUserNotificationTypeViewModel} from '../../../../api-client/models/v2/i-user-notification-type-view-model';
import {INotificationType} from '../../../../api-client/models/v2/i-notification-type';
import { LoadingOverlayService } from '@app/shared/services/loading-overlay.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notificationsModel: IUserNotificationTypeViewModel;

  constructor(private userService: UserV2Service,
              private loadingOverlayService: LoadingOverlayService
             ) { }

  ngOnInit() {
    this.loadingOverlayService.showLoadingOverlay();
    this.userService.notificationtypes().then((result: IUserNotificationTypeViewModel) => {
      this.loadingOverlayService.hideLoadingOverlay();
      result.notificationTypes.forEach((n) => {
        const nn = result.userNotificationTypes.filter((un) => un.notificatioTypeId === n.id);
        n.isChecked = nn.length > 0;
      });
      this.notificationsModel = result;
    });
  }


  addOrRemoveUserNotification(notification: INotificationType) {
    if(notification.isChecked) {
      this.userService.addNotification({notificationTypeId: notification.id});
    } else {
      this.userService.removeNotification({notificationTypeId: notification.id});
    }
  }

}
