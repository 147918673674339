import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  @Input()
  title: string;
  @Input()
  subTitle: string;
  @Output() aceptAction: EventEmitter<any> = new EventEmitter();
  
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  clickAcept(){
    this.activeModal.close();
    this.aceptAction.emit('Acept terms');
  }
}
