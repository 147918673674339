import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as ENV } from '@environment/environment';
import { IssueRequest } from '@api/models/v2/i-trace-errors';

@Injectable({
  providedIn: 'root',
})
export class IssueReportService {
  public api = 'v2/issuereport/add';

  constructor(private http: HttpClient) {}

  addIssueReport(body: IssueRequest): Observable<any> {
    return this.http.put<any>(`${ENV.webApi}${this.api}`, body);
  }
}
