//import 'rxjs/Rx';
import {LocalStorage} from 'ngx-webstorage';
import {Rest, IRestActionInner, IRestResponse, IRestAction} from 'rest-core';
import {EnlaceRestHandler} from './enlace-rest-handler';

import {AuthenticationService} from './authentication.service';
import {IUser} from '@app/core/models/interfaces/i-user.interface';
import { TokenAuth } from './models/entities/auth.interface';

export abstract class RestClient extends Rest {

  @LocalStorage('user')
  user: IUser;

  @LocalStorage('enlaceToken')
  enlaceToken: TokenAuth;

  constructor(private restHandler: EnlaceRestHandler, private authService: AuthenticationService) {
    super(restHandler);
  }

  $getHeaders(actionOptions?: IRestAction): any | Promise<any> {

    return new Promise((resolve, reject) => {
      const headers = super.$getHeaders(actionOptions);
      headers['Access-Control-Allow-Origin'] = '*';
      if (this.authService.isOnline() && this.authService.isNewLoginEnabled !== undefined) { // Diferenciar newLogin
        if (this.authService.isNewLoginEnabled) {
          // desactivar headers['Authorization'] = 'Bearer ' + this.enlaceToken.jwtToken;
          resolve(headers);
        } else {
          this.authService.getAuthenticationToken().then((value) => {
            headers['Authorization'] = 'Bearer ' + value;
            resolve(headers);
          });
        }
      } else {
        resolve(headers);
      }
    });


  }

  $handleErrorResponse(options: IRestActionInner, resp: IRestResponse): any {
    return super.$handleErrorResponse(options, resp);
  }

  $handleSuccessResponse(options: IRestActionInner, resp: IRestResponse): any {
    return super.$handleSuccessResponse(options, resp);
  }

  getRestHandler(): EnlaceRestHandler {
    return this.restHandler;
  }

}
