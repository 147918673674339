import {Component, Input, OnInit} from '@angular/core';
import { IUser } from '@app/core/models/interfaces/i-user.interface';
import { PPVContentService } from '@app/shared/utils/ppvcontent.service';
import { LocalStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-ppv-label-indicator',
  templateUrl: './ppv-label-indicator.component.html',
  styleUrls: ['./ppv-label-indicator.component.scss']
})
export class PpvLabelIndicatorComponent implements OnInit {

  @Input() content: any;
  @Input() parentShowingPPV: boolean = false;

  price: string;
  showPPV: boolean = false;

  constructor(private ppvContentService: PPVContentService) {
  }

  ngOnInit() {
    if(this.content) {
      this.showPPV = this.ppvContentService.isPPV(this.content) && !this.content.acquired;
      if(this.showPPV)
         this.price = this.ppvContentService.getPrice(this.content);
    }
  }
}
