import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'labelFilter',
})
export class LabelFilterPipe implements PipeTransform {
  transform({item}: any, args: string): string {
    if (!!item) {
        if (item.organization) {
          return item.organization;
        } else if (item.name) {
          return item.name;
        } else if (item.fullName) {
          return item.fullName;
        }
    }
    return '';
  }
}
