<div class="slide-filter">
  <ul [ngClass]="{'hide': types.length === 1}">
    <li (click)="slideFilter(type)"
        [ngClass]="{'active': current && current.type === type.type}"
        class= "{{getContentType(current)}}"
        *ngFor="let type of types">
      <i class="iconlace iconlace-{{type.type == 'podcastitem' ? 'podcast' : type.type}}"></i>
      <span>{{'filter' + type.type | translate}}</span>
    </li>
  </ul>
</div>
