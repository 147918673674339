import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observer, Observable } from "rxjs";
import { StreamingEndpoint } from "./models/streaming-endpoint";
import { RestClient } from "./rest-client";
import { IRestMethod, RestAction, RestParams, RestRequestBodyType, RestResponseBodyType } from "rest-core";
import { EnlaceRestHandler } from "./enlace-rest-handler";
import { AuthenticationService } from "./authentication.service";
import { Router } from '@angular/router';

@Injectable()
@RestParams({
    pathPrefix: environment.webApi + 'streamingendpoints'
})
export class StreamingEndpointService extends RestClient {

  resultList: StreamingEndpoint[];

  constructor(restHandler: EnlaceRestHandler, authService: AuthenticationService, private router: Router) {
    super(restHandler, authService);
  }

  @RestAction({
    path: '/',
    isArray: true,
  })
  list: IRestMethod<any, StreamingEndpoint[]>;


  @RestAction({
    path: '/{!id}',
  })
  get: IRestMethod<{ id: any }, StreamingEndpoint>;

  listCache(): Observable<StreamingEndpoint[]> {
    return Observable.create(observer => {
      if (this.resultList) {
        observer.next(this.resultList);
      } else {
        this.list().then((result: StreamingEndpoint[]) => {
          this.resultList = result;
          observer.next(this.resultList);
        }).catch((e) => {
          console.warn('called .catch on %o arguments %o', this, e);
        });
      }
    });
  }

}
