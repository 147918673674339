<div class="like"
     *ngIf="this.socialInfo && this.socialInfo.contentId"
     [ngClass]="{'active' : socialInfo?.isLike}">

  <a [ngStyle]="{'cursor': enabledLike ? 'pointer' : 'wait' }"
     (click)="trackLike(true)"
     class="button-link">
      <i [ngStyle]="{'color': !socialInfo?.isLike ? '#FFF' : color}"
         [ngClass]="{'iconlace-heart': !socialInfo?.isLike, 'iconlace-heart-solid': socialInfo?.isLike}"
         class="iconlace"></i>
      <span>ME GUSTA</span>
  </a>
</div>
