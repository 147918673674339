import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-payment-error',
  templateUrl: './payment-error.component.html',
  styleUrls: ['./payment-error.component.scss']
})
export class PaymentErrorComponent {

  @Input() action: string;
  @Input() errorType: string;
  @Input() fromGiftCode: boolean = false;

  constructor(private activeModal: NgbActiveModal) { }


  get title(): string {
    return this.action.toLocaleLowerCase() === 'purchase' ? 'comprar' : 'alquilar'
  }

  closeModal(isClose: boolean = false): void {
    this.activeModal.close(isClose);
  }

}
