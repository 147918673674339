<div (mouseover)="showFollows = true" (mouseleave)="onLeave()" class="share-container-component {{className}}">
  <button (click)="showFollows = !showFollows" class="share-button" [style.color]="color">
    <i class="iconlace iconlace-share"></i>
    <span class="share-text-container" *ngIf="!onlyIcon">
      {{'share-uppercase' | translate}}
    </span>
  </button>

  <div class="list-container" (mouseover)="inModal = true" (mouseleave)="onLeaveModal()" [ngClass]="{'show': showFollows, 'tooltip-left': onlyIcon}">
    <ul class="share-options-list">
      <li class="share-button-container">
        <a class="social-link btn-twitter share-button-container" (click)="trackShare('3')">
          <share-button class="height: 30px" button="twitter" [url]="urlShare" [description]="description" [title]="title" theme="moon">
          </share-button>
        </a>
      </li>
      <li class="share-button-container">
        <a class="social-link btn-facebook share-button-container" (click)="trackShare('1')">
          <share-button button="facebook" [url]="urlShare" [description]="description" [title]="title" theme="moon">
          </share-button>
        </a>
      </li>
      <li class="share-button-container">
        <a class="social-link share-button-container"
          (click)="copyToClipboard()">
          <button class="clipboard-button">
            <i class="iconlace iconlace-link clipboard"></i>
          </button>
        </a>
      </li>
    </ul>
  </div>
</div>
