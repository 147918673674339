export const daysPerMonthOrYear = (month: number, year: number): number =>
  new Date(year, month, 0).getDate();

export const createDays = (numberDays: number) => Array.apply(null, Array(numberDays)).map(
  (value: any, i: number) => {
    return { name: i + 1, value: i + 1 };
  }
);

export const daysBetween = (date1: any, date2: any) =>
 Math.ceil(Math.abs(date1 - date2) / (1000 * 60 * 60 * 24));
