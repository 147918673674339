<div class="header-button-container">
    <div class="header-button">
        <ng-container *ngTemplateOutlet="buttonListen"></ng-container>
    </div>
</div>

<!-- template buttonListen -->
<ng-template #buttonListen>
    <app-button-enlace-plus [className]="'btn-podcast-listen'" (actionButton)="redirectToContent.emit()">
        <i iconButton class="iconlace iconlace-podcast"></i>
        <div textButton>ÚLTIMO EPISODIO</div>
    </app-button-enlace-plus>
</ng-template>
