import { ChangeDetectionStrategy, Component, EventEmitter,
  Input, Output,
} from "@angular/core";
import { UserStateService } from "@app/shared/services/user-state.service";
import { ModalManagementService } from "@app/shared/utils/modal-management.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GiftCodeModalComponent } from "./gift-code-modal/gift-code-modal.component";
import { ContentDetail } from "@app/core/models/interfaces/content-detail";

@Component({
  selector: "app-gift-code",
  templateUrl: "./gift-code.component.html",
  styleUrls: ["./gift-code.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GiftCodeComponent {

  @Input() contentDetail: ContentDetail;
  @Input() contentType: string;

  constructor(
    private modalManagementService: ModalManagementService,
    private authService: UserStateService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {}

  get isUserLoggedIn(): boolean {
    return this.authService.isUserLoggedIn;
  }

  openModalGift(): void {
    if (!this.isUserLoggedIn) {
      this.modalManagementService
        .openModalLogin()
        .catch((result) => result && this.activeModal.close());
      return;
    }

    const modalRef = this.modalService.open(GiftCodeModalComponent, {
      windowClass: "dark-modal  over-lapping-modal modal-gift-code",
      backdropClass: "light-black-backdrop",
    });
    modalRef.componentInstance.contentDetail = this.contentDetail;
    modalRef.componentInstance.contentType = this.contentType;
  }

}
