import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'DateSeparate',
})
export class DateSeparatePipe implements PipeTransform {

  transform(date: string, args: string): string {
    const dateFeatures = date.split('/');
    return `${dateFeatures[0]} / ${this.capitalizeMonth(dateFeatures[1])} / ${dateFeatures[2]}`;
  }

  capitalizeMonth(month: string): string {
    return month.trim().replace(/^\w/, (c) => c.toUpperCase());
  }
}
