import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeroService} from './hero.service';
import {AuthenticationService} from './authentication.service';
import {RestModule} from './http';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {EnlaceRestHandler} from './enlace-rest-handler';
import {SearchService} from './search.service';
import {ClientLoginService} from './client-login.service';
import {StreamingEndpointService} from './endpoint.service';
import {ChannelService} from './channel.service';
import {PrayerService} from './prayer.service';
import {NotificationService} from './notification.service';
import {TranslatorService} from './translator.service';
import {MinistryService} from './ministry.service';
import {CountryService} from './country.service';
import {FollowService} from './follow.service';
import {ProgramService} from './v2/program.service';
import {Router} from '@angular/router';
import {BibleFeaturedService} from './v2/bible-featured.service';
import { TraceIdentifierService } from '@api/v2/trace-identifier.service';
import { LoadingOverlayService } from '@app/shared/services/loading-overlay.service';


export function enlaceHandlerFactory(http: HttpClient, router: Router, traceService: TraceIdentifierService, loadingOverlayService: LoadingOverlayService) {
  return new EnlaceRestHandler(http, router, traceService,  loadingOverlayService);
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RestModule.forRoot(
      {handler: {provide: EnlaceRestHandler, useFactory: (enlaceHandlerFactory), deps: [HttpClient,Router]}}
    )
  ],
  providers: [HeroService, AuthenticationService, EnlaceRestHandler,
    SearchService, ClientLoginService, StreamingEndpointService, ChannelService, PrayerService, NotificationService,
    TranslatorService, MinistryService, CountryService, FollowService, ProgramService, BibleFeaturedService]
})
export class ApiClientModule {
  static forRoot(): ModuleWithProviders<ApiClientModule> {
    return {
      ngModule: ApiClientModule,
      providers: [HeroService, AuthenticationService, EnlaceRestHandler,
         SearchService, ClientLoginService, StreamingEndpointService, ChannelService, PrayerService, NotificationService,
        TranslatorService, MinistryService, CountryService, FollowService, ProgramService, BibleFeaturedService]
    };
  }
}

