import {IContentSocialInfoViewModel} from '@interfaces/i-content-social-info-view-model';
import {ContentV3Service} from '@shared/services/content-v3.service';
import {LoginModalComponent} from '../login-modal/login-modal.component';
import {MyMonitoringService} from '../../../shared/services/lib/monitoring.service';
import {IUser} from '@interfaces/i-user.interface';
import {Component, OnInit, Input} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LocalStorage} from 'ngx-webstorage';
declare const Android: any;

@Component({
  selector: 'app-video-like',
  templateUrl: './video-like.component.html',
  styleUrls: ['./video-like.component.scss']
})
export class VideoLikeComponent implements OnInit {

  @LocalStorage('user')
  user: IUser;
  enabledLike = true;
  _socialInfo: IContentSocialInfoViewModel;
  like: number = 0;

  @Input()
  color: string;

  @Input()
  set socialInfo(content: IContentSocialInfoViewModel) {
    this._socialInfo = content;
  }

  @Input() fromMobile: boolean = false;
  @Input() userId: string;

  get socialInfo(): IContentSocialInfoViewModel {
    return this._socialInfo;
  }

  constructor(private modalService: NgbModal,
              private myMonitoringService: MyMonitoringService,
              private contentService: ContentV3Service) {
  }

  ngOnInit() {
  }

  trackLike(like: boolean) {
    if (!this.user && !this.fromMobile) {
      const modalRef = this.modalService.open(LoginModalComponent, {windowClass: 'dark-modal modal-outlogin', backdropClass: 'light-black-backdrop'});
      modalRef.componentInstance.customMessageTag = 'need-to-login';
      return;
    }

    this.enabledLike = false;
    this.myMonitoringService.logEvent({name: 'Like',
    properties: {'VideoId': this.socialInfo.contentId, 'SocialEventTypeId': 1}});
    this.socialInfo.isLike = !this.socialInfo.isLike;

    if (this.fromMobile && (typeof Android !== 'undefined')) {
      Android.CallPreloginClip('toggle-like');
    }
    else {
      this.contentService.toggleLike(this.socialInfo.contentId, this.userId).subscribe(() => {
        this.enabledLike = true;
      }, () => {
        this.enabledLike = true;
      });
    }
  }
}
