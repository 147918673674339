import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild, AfterViewInit } from '@angular/core';
import {$} from 'protractor';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { HtmlElementZoom } from '@shared/helpers/html-element-zoom.helper';

@Component({
  selector: 'app-font-size-manager',
  templateUrl: './font-size-manager.component.html',
  styleUrls: ['./font-size-manager.component.scss']
})
export class FontSizeManagerComponent implements OnInit, OnDestroy {

  isOpen: boolean = false;
  deviceInfo: DeviceInfo;

  @Output() onFontChange: EventEmitter<boolean> = new EventEmitter();
  _elementTyModify: HTMLElement;

  @Input() step: number = 0.1;
  @Input() max: number = 2;
  @Input() min: number = 0.5;
  @Input() currentZoom?: number = null;

  listener: any;

  @Input()
  set elementTyModify(element: HTMLElement) {
    if (element) {
      this._elementTyModify = element;
      HtmlElementZoom.setZoomElement(element, '1');
    }
  };

  onClick = (event: MouseEvent) => {
    if (event.defaultPrevented) {
      return;
    }
    this.close();
  };

  constructor(private renderer: Renderer2,
              private deviceService: DeviceDetectorService) {
  }

  ngOnInit() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (this.currentZoom) {
      setTimeout(() => {
        HtmlElementZoom.setZoomElement(this._elementTyModify, this.currentZoom.toString());
      }, 250);
    }
  }

  increase(event) {
    event.preventDefault();
    this.changeFontSize(true);
  }

  decrease(event) {
    event.preventDefault();
    this.changeFontSize(false);
  }

  changeFontSize(event: boolean) {
    let zoom = this._elementTyModify && (this.currentZoom ? this.currentZoom : parseFloat(HtmlElementZoom.getZoomElement(this._elementTyModify)));

    if (event) {
      if ((zoom + this.step) <= this.max) {
        zoom += this.step;
        this.onFontChange.emit(true);
      }
    } else {
      if ((zoom - this.step) >= this.min) {
        zoom -= this.step;
        this.onFontChange.emit(false);
      }
    }

    HtmlElementZoom.setZoomElement(this._elementTyModify, zoom.toString());
  }

  open() {
    this.isOpen = true;
    window.setTimeout(($event) => {
      document.addEventListener('click', this.onClick);
    }, 500);
  }

  close() {
    this.isOpen = false;
    window.setTimeout(($event) => {
      document.removeEventListener('click', this.onClick);
    }, 500);
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', this.onClick);
  }

  isFirefox() {
    return this.deviceInfo.browser === 'Firefox';
  }
}
