<div class="modal-header">
  <span class="close-button" (click)="clickCancel()" *ngIf="!hideClose">
    <span>&times;</span>
  </span>
  <div class="icon-container">
    <i class="iconlace iconlace-user"></i>
  </div>
  <h1 class="modal-title">{{'welcome' | translate}}</h1>
</div>
<div class="modal-body">
  <h2 *ngIf="textSize == 'big'">{{customMessageTag | translate}}</h2>
  <h3 *ngIf="textSize == 'medium'">{{customMessageTag | translate}}</h3>
  <div class="container-buttons">
    <a (click)="login()"  class="btn btn-primary">{{'enter' | translate}}</a>
  </div>
  <div class="content-register-now">
    <div class="sign-up">¿No tienes cuenta?</div>
    <a class="action-text" (click)="registerNow()">Regístrate ahora</a>
  </div>
</div>
