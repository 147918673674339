import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrayRequestModalComponent } from './pray-request-modal.component';
import { ReactiveFormsModule } from '@angular/forms';


import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { DirectivesModule } from '@app/shared/directives/directives.module';

export function playerFactory(): any {
  return import('lottie-web');
}

@NgModule({
  declarations: [
    PrayRequestModalComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DirectivesModule,
    LottieModule.forRoot({ player: playerFactory })
  ],
  exports: [PrayRequestModalComponent]
})
export class PrayRequestModalModule { }
