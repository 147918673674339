import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-answer-modal',
  templateUrl: './answer-modal.component.html',
  styleUrls: ['./answer-modal.component.scss']
})
export class AnswerModalComponent implements OnInit {

  success: boolean = true;
  @Input()
  title: string = 'No title';
  @Input()
  subTitle: string;
  @Input()
  icon: string;
  @Input()
  classConfirmButton: string;
  @Output() aceptAction: EventEmitter<any> = new EventEmitter();
  @Output() cancelAction: EventEmitter<any> = new EventEmitter();
  
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  clickAcept(){
    this.aceptAction.emit('Acept terms');
    this.activeModal.close();
  }

  clickCancel(){
    this.cancelAction.emit('Cancel terms');
    this.activeModal.close();
  }
}
