export interface IFollow {
  resourceId: string[];
}
// TODO Class must be converted to Interface
export class GenericContentFollowItem {
  name: string;
  id: string;
  img?: string;
  isFollowed: boolean;
}

export class GenericContentFollowTemplate {
  title: string;
  type: string;
  img?: string;
  list: GenericContentFollowItem[] = [];

  constructor(title: string, type: string) {
    this.title = title;
    this.type = type;
  }
}

export class GenericContentFollow {
  template: GenericContentFollowTemplate[];

  constructor() {
    this.template = [];
  }
}
