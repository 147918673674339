<div class="auto-play-container" *ngIf="video && seconds <= 15">
  <div *ngIf="!fullScreen">
    <div class="thumbnail-container" (click)="next()">
      <div class="autoplay-thumbnail" [style.backgroundImage]="'url('+ video.thumbnailUrl +')'">
        <img class="ng-tns-c16-2" style="opacity: 0.5"
             src="assets/img/icons/play_thumb.svg">
      </div>
      <div class="title-container">
        <span class="video-title" *ngIf="hasProgram">{{video.program.program}}</span>
        <span class="video-title" [class.weight]="hasProgram">{{video.title}}</span>
        <span *ngIf="!!video.duration" class="video-duration">{{video.duration | timeHumanFormat}}</span>
      </div>
    </div>
    <div class="next-container" (click)="next()">
      <span>Siguiente en {{seconds}} <i class="iconlace iconlace-play"></i></span>
    </div>
  </div>
</div>
