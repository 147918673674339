import { AMERICAN_CODES, LOCAL_CODES } from '@constants/country-codes.constants';
import { Country } from '@interfaces/i-country';

export const sortCountries = (a: Country, b: Country) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

export const getInternationalCountries = (countries: Country[]): Country[] => {
  if (countries) {
    const americanCountries = countries.filter(c => AMERICAN_CODES.includes(c.code));
    const otherCountries = countries.filter(c => !LOCAL_CODES.includes(c.code));

    if (americanCountries.length > 0 && otherCountries.length > 0) {
      return [
        ...americanCountries.sort(sortCountries),
        ...otherCountries.sort(sortCountries)
      ];
    }
  }
  return [];
};
