import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { ContentV3Service } from '@shared/services/content-v3.service';
import {NgbTooltip, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { IRatingViewModel } from '@interfaces/i-rating';
import { IUser } from '@interfaces/i-user.interface';
import { LocalStorage } from 'ngx-webstorage';

export interface IScore {
  index: number,
  listItemHovered: boolean,
  isMarked: boolean
}

enum ScoreStyle {
  active = 'fa-star',
  inactive = 'fa-star-o',
  half = 'fa-star-half-o'
}

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss']
})
export class ScoreComponent implements OnInit {

  @LocalStorage('user')  private _user: IUser;

  overItem = false;
  items: Array<IScore> = [];
  qualification: number = 0;

  private _average = 0;
  private _totalVotes = 0;

  @Input() tooltipText: string;
  @Input() canRate: boolean;
  @Input() fullWidth: boolean = false;
  @Input() maxScore = 5;
  @Input() color = '#113EEB';
  @Input() colorHover = '#0b2272';
  @Input() contentId: string;
  @Input()
  set average(value: any) {
    if (value) {
      this._average = +value;
    }
  }

  @Input() votes: number = 0;
  @Input() activeTooltip: boolean = true;

  get average() {
    return Math.round(this._average * 2) / 2
  }

  @Output() scoreChange = new EventEmitter<number>();

  get totalVotes(): number {
    return this._totalVotes || this.votes;
  }

  constructor(private contentService: ContentV3Service, private modalService: NgbModal) {
  }

  ngOnInit() {
    this.searchRating(this.contentId);
    for (let i = 0; i < this.maxScore; i++) {
      const star: IScore = {
        index: i,
        isMarked: false,
        listItemHovered: false
      };
      this.items.push(star);
    }
  }

  hoverListItem(opportunity): void {
    opportunity.listItemHovered = !opportunity.listItemHovered;
  }

  searchRating(id: string) {
    if (id) {
      this.contentService.getRating(id).subscribe((podcastViewModel: IRatingViewModel) => {
        if (podcastViewModel && podcastViewModel.contentId) {
          this._average = podcastViewModel.ratingAverage != null ? podcastViewModel.ratingAverage : 0;
          this._totalVotes = podcastViewModel.ratingVotes != null ? podcastViewModel.ratingVotes : 0;
        }
      });
    }
  }

  className(item: IScore): string {
    if (item.index + 1 <= this.average) {
      return ScoreStyle.active;
    }
    if (item.index + 1 > this.average) {
      if (item.index + 1 > this.average + 0.5) {
        return ScoreStyle.inactive;
      }
      if (this.average % 0.5 === 0) {
        return ScoreStyle.half;
      }
    }
    return ScoreStyle.inactive;
  }

  mark(item: IScore, tooltip: NgbTooltip) {
    if (!this._user) {
      const modalRef = this.modalService.open(LoginModalComponent, { windowClass: 'dark-modal modal-outlogin', backdropClass: 'light-black-backdrop' });
      modalRef.componentInstance.customMessageTag = 'need-to-login';
      return;
    }
    if(this.canRate) {
      for (const i of this.items) {
        i.isMarked = i.index <= item.index;
      }
      this.qualification = item.index + 1;
      this.calculateAverage(item);
      this.scoreChange.emit(this.qualification);
      if (this.contentId) {
        this.contentService.rating(this.contentId, this.qualification).subscribe((rating: string) => {
          this._average = parseFloat(rating);
          this.contentService.getRating(this.contentId).subscribe((podcastViewModel: IRatingViewModel) => {
            if (podcastViewModel) {
              this._totalVotes = podcastViewModel.ratingVotes != null ? podcastViewModel.ratingVotes : 0;
            }
          });
        });
      }
    }else {
      tooltip.isOpen() ? tooltip.close() : tooltip.open();
      return;
    }
  }

  calculateAverage(item: IScore): void {
    this.average = (this.average + item.index + 1) / 2;
  }

  closeTooltip(tooltip: NgbTooltip)
  {
      this.overItem = false;
      tooltip.close();
  }

  openTooltip(tooltip: NgbTooltip)
  {
    this.overItem = false;
    tooltip.close();
    this.overItem = true;
    tooltip.open();
  }
}
