import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observer, Observable } from "rxjs";
import { User } from "./models/user";
import { Rest, IRestActionInner, IRestResponse, IRestAction, RestParams, RestAction, IRestMethod, RestHandler, RestRequestMethod } from "rest-core";
import { EnlaceRestHandler } from "./enlace-rest-handler";
import {RestClient} from "./rest-client";
import {AuthenticationService} from "./authentication.service";
import {LocalStorage, SessionStorage} from 'ngx-webstorage';

@Injectable()
@RestParams({
  // IRestParams
    pathPrefix: environment.webApi + 'login'
})
export class ClientLoginService extends Rest {

  @LocalStorage('token')
  private _token: string;

  constructor(restHandler: EnlaceRestHandler) {
    super(restHandler);
  }

  @RestAction({
    method: RestRequestMethod.Get,
    path: '/add',
  })
  add: IRestMethod<any, User>;


  $getHeaders(actionOptions?: IRestAction): any | Promise<any>{

    return new Promise((resolve, reject) => {
      let headers = super.$getHeaders(actionOptions);
      if(this._token)
      {
        headers["Authorization"] = "Bearer " + this._token;
      }
      resolve(headers);

    });


  }


}

