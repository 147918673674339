import { Injectable } from '@angular/core';
import { DonationHeaderForm } from '@interfaces/i-donation-view-model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionSubmitService {

  private submitSubscription$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private headerSubscription$: BehaviorSubject<DonationHeaderForm> = new BehaviorSubject({} as DonationHeaderForm);

  constructor() {}

  get isSubmitSubscription$(): Observable<boolean> {
    return this.submitSubscription$.asObservable();
  }

  get isHeaderSubscription$(): Observable<DonationHeaderForm> {
    return this.headerSubscription$.asObservable();
  }

  public change(event: boolean): void {
    this.submitSubscription$.next(event);
  }

  public changeHeader(event: DonationHeaderForm): void {
    this.headerSubscription$.next(event);
  }

  public clear(): void {
    this.submitSubscription$.next(null);
    this.headerSubscription$.next(null);
  }
}
