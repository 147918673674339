<div class="row fotter-news" [class.isFirst]="isFirst">
  <div class="col-lg-12 slide-title">
    <h3 class="b-title" [class.isFirst]="isFirst" style="cursor: pointer"
    (click)="goToCategoryPage()"><span class="isotipo catalog"></span>{{title}} <span *ngIf="showSeeAll" class="slide-see-all"> Ver todo <i class="iconlace iconlace-chevron-next"></i></span></h3>
  </div>
</div>
<div class="{{clazz}}">
  <ng-content select="app-article-item"></ng-content>
  <ng-content select="[app-article-item]"></ng-content>
</div>

