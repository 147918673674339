import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InternationalCardFormInlineModule } from '@components/international-card-form-inline/international-card-form-inline.module';
import { DlocalFormInlineModule } from '@components/dlocal-form-inline/dlocal-form-inline.module';

import { SubscriptionBodyComponent } from '@components/subscription-body/subscription-body.component';

@NgModule({
  imports: [
    CommonModule,
    InternationalCardFormInlineModule,
    DlocalFormInlineModule
  ],
  exports: [SubscriptionBodyComponent],
  declarations: [SubscriptionBodyComponent]
})
export class SubscriptionBodyModule { }
