<audio #audio src="{{currentItem?.url}}" (canplay)="canPlay()" (canplaythrough)="loadAudio()"
      (timeupdate)="updateTime()"
      (loadeddata)="loadedData()"
      (loadstart)="startLoad()"></audio>
<div class="container-fluid podcast-container" style="margin-top: 25px">
  <div class="row align-items-center ">
    <div class="player-buttons">
      <button class="btn btn-link" (click)="prevAudio()"><span class="iconlace iconlace-player-back"></span> </button>
      <button class="btn btn-link" (click)="prevTime()"><span class="iconlace iconlace-player-back-15" ></span> </button>

      <button (click)="playOrStop(currentItem?.playing)" class="btn btn-success btn-play" [ngClass]="{'is-loading': isLoading}" [class.playing]="currentItem?.playing">
        <span class="iconlace iconlace-player-play" *ngIf="!currentItem?.playing && !isLoading"></span>
        <span class="loader" *ngIf="isLoading"></span>
        <span class="iconlace iconlace-player-pause" *ngIf="currentItem?.playing && !isLoading"></span>
      </button>

      <button class="btn btn-link" n-submit [DISABLE_TIME]="1000" (click)="nextTime()"><span class="iconlace iconlace-player-next-15"></span> </button>
      <button class="btn btn-link"><span class="iconlace iconlace-player-next" (click)="nextAudio()"></span> </button>
    </div>
    <div class="player-bar">
      <div class="time-reproducing">
        <p><small>{{audioCurrentSecond | allTimeFormatFromSecond}}</small></p>
      </div>
      <div class="time-progress progress" (mousemove)="drag($event, bar)" #bar>
        <div class="progress-bar bg-success" role="progressbar" [style.width]="percentAudio" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">
          <div class="player-position" (mousedown)="enableDrag = true" (mouseup)="enableDrag = false" ></div>
        </div>
        <p>{{currentItem?.title}}</p>
      </div>
      <div class="time-total">
        <p><small>{{audioTotalSecond | allTimeFormatFromSecond}}</small></p>
      </div>
      <div class="volume">
        <button class="btn btn-link" (click)="audio.muted = true" *ngIf="!audio.muted"><span class="iconlace iconlace-sound-full"></span> </button>
        <button class="btn btn-link" (click)="audio.muted = false" *ngIf="audio.muted"><span class="iconlace iconlace-sound-muted"></span> </button>
      </div>
    </div>
  </div>
</div>
