import {IContentSocialInfoViewModel} from '@interfaces/i-content-social-info-view-model';
import {IContentCatalogViewModel} from '@interfaces/i-content-catalog-view-model';
import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {ContentV3Service} from '@shared/services/content-v3.service';
import {CatalogV2Service} from '../../../api-client/v2/catalog-v2.service';
import {LoginModalComponent} from '../login-modal/login-modal.component';
import {MyMonitoringService} from '../../../shared/services/lib/monitoring.service';
import {IUser} from '@interfaces/i-user.interface';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LocalStorage} from 'ngx-webstorage';

@Component({
  selector: 'app-add-to-watchlist',
  templateUrl: './add-to-watchlist.component.html',
  styleUrls: ['./add-to-watchlist.component.scss']
})
export class AddToWatchlistComponent implements OnInit {

  @LocalStorage('user')
  private _user: IUser;

  @Input() isMyList: boolean;

  @Input() color: string;

  @Input() socialInfo: IContentSocialInfoViewModel;

  @Input() contentCatalog: IContentCatalogViewModel;

  @Output() closeDetail: EventEmitter<void> = new EventEmitter<void>();

  isWorking = false;
  enabledList = true;

  constructor(private contentService: ContentV3Service,
              private modalService: NgbModal,
              private myMonitoringService: MyMonitoringService,
              private catalogService: CatalogV2Service) {
  }


  addRemoveToWhatchList() {
    if (this._user) {
      if (!this.isWorking && this.socialInfo && this.socialInfo.contentId) {
        this.isWorking = true;
        this.enabledList = false;
        this.socialInfo.isMyContent = !this.socialInfo.isMyContent;
        if(this.socialInfo.isMyContent) {
          this.catalogService.addMyList(this.contentCatalog).then(() => {
            this.enabledList = true;
          }, () => {
            this.enabledList = true;
          });
        } else {
          this.catalogService.removeMyList(this.contentCatalog).then(() => {
            this.enabledList = true;
          }, () => {
            this.enabledList = true;
          });
          if (this.isMyList) {
            this.closeDetail.emit();
          }
        }

        this.contentService.toggleMyLIst(this.socialInfo.contentId).subscribe(() => {
          this.isWorking = false;
          if(!this.socialInfo.isMyContent) {
            this.myMonitoringService.logEvent({name: 'RemoveVideoFromWatchlist', properties: {'ContentId': this.socialInfo.contentId}});
          } else {
            this.myMonitoringService.logEvent({name: 'AddVideoToWatchlist', properties: {'ContentId': this.socialInfo.contentId}});
          }
        });
      }
    } else {
      const modalRef = this.modalService.open(LoginModalComponent, {windowClass: 'dark-modal modal-outlogin', backdropClass: 'light-black-backdrop'});
      modalRef.componentInstance.customMessageTag = 'need-to-login';
    }
  }

  ngOnInit() {}


}

