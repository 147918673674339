import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as ENV } from '@environment/environment';
import { Observable } from 'rxjs';
import { ISearchUserHistory } from '../../api-client/models/v2/i-search-user-history';

@Injectable({
  providedIn: 'root'
})
export class SearchUserHistoryService {

  private API = `${ENV.webApi}v2/UserSearch`;

  constructor(
    private http: HttpClient
  ) { }

  public getUserSearches(userId: string = null): Observable<ISearchUserHistory[]> {
    const url = userId ? `${this.API}/GetUserSearches/${userId}` : `${this.API}/GetUserSearches/`;
    return this.http.get<ISearchUserHistory[]>(url);
  }

  public addUserSearch(term: string): Observable<ISearchUserHistory> {
    const url = `${this.API}/add`;
    const request = <ISearchUserHistory>{
      searchTerm: term
    }
    return this.http.put<ISearchUserHistory>(url, request);
  }

  public addUserSearchForWebView(term: string, userId: string = null): Observable<ISearchUserHistory> {
    const url = `${this.API}/add/${term}/${userId}`;
    return this.http.get<ISearchUserHistory>(url);
  }

  public deleteSearch(userSearch: string): Observable<any> {
    const url = `${this.API}/delete/${userSearch}`;
    return this.http.delete(url);
  }

  public deleteHistory(userId: string = null) {
    const url = `${this.API}/DeleteHistory/${userId}`;
    return this.http.delete(url);
  }
}
