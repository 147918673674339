import { Pipe, PipeTransform } from '@angular/core';
import { StatusDonation } from '@interfaces/i-donation-view-model';

@Pipe({
  name: 'getTextStatusTransaction'
})
export class GetTextStatusTransactionPipe implements PipeTransform {

  private _dictionary = {
    [StatusDonation.UniqueReturned]: 'Devolución realizada',
    [StatusDonation.Active]: 'Confirmada',
    [StatusDonation.Approved]: 'Confirmado',
    [StatusDonation.Inactive]: 'Pendiente',
    [StatusDonation.Reclaimed]: 'Devolución solicitada',
    [StatusDonation.Rejected]: 'Rechazada',
    [StatusDonation.RejectedReturned]: 'Devolución rechazada',
    [StatusDonation.RecurrentCanceled]: 'Recurrencia cancelada',
    [StatusDonation.RecurrentReturned]: 'Devolución realizada',
    [StatusDonation.RequestCancellation]: 'Cancelación de recurrencia solicitada',
    [StatusDonation.Success]: 'Confirmado',
  }

  transform(value: string): string {
    return this._dictionary[value] || 'Status no soportado';
  }

}
