import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditCardSeparator'
})
export class CreditCardSeparatorPipe implements PipeTransform {

  transform(cardNumber: string): string {
    
    if (cardNumber) {
      cardNumber = cardNumber.replace(/\s+/g, '').substring(0, 16);
      return cardNumber.replace(/\s+/g, '').replace(/(\d{4})/g, '$1 ').trim();
    }
    return cardNumber;
  }

  // The following is to get the type of card; any moment when it could be necessary.
  getType(creditCardNumber: string): string {
    let result = '';

    // first check for MasterCard
    if (/^5[1-5]/.test(creditCardNumber)) {
      result = 'mastercard';
    }
    // then check for Visa
    if (/^4/.test(creditCardNumber)) {
      result = 'visa';
    }
    // then check for AmEx
    if (/^3[47]/.test(creditCardNumber)) {
      result = 'amex';
    }
    // then check for Diners
    if (/3(?:0[0-5]|[68][0-9])[0-9]{11}/.test(creditCardNumber)) {
      result = 'diners'
    }
    // then check for Discover
    if (/6(?:011|5[0-9]{2})[0-9]{12}/.test(creditCardNumber)) {
      result = 'discover';
    }

    return result;
  }
}
