<div (mouseover)="showFollows = true" (mouseleave)="onLeave()" class="follow {{type}}"
  *ngIf="socialInfo && !preloading && !isGuest" [ngClass]="{'active' : socialInfo?.isFollow || isFollowing()}">
  <a (click)="followUnFollow()" *ngIf="!hasContentFollowList && show" class="button-link">
    <i class="iconlace iconlace-plus" [ngStyle]="socialInfo.isFollow && {'color': color}"></i>
    <span class="text" *ngIf="!onlyIcon">
      <span *ngIf="!socialInfo?.isFollow">SEGUIR</span>
      <span *ngIf="socialInfo?.isFollow">SIGUIENDO</span>
    </span>
  </a>
  <a *ngIf="hasContentFollowList && show" class="generic-follow-container button-link">
    <i class="iconlace iconlace-plus" (click)="this.toggleShowFollows()"
      [ngStyle]="socialInfo.isFollow && {'color': color}"></i>
    <span *ngIf="!onlyIcon" (click)="this.toggleShowFollows()" class="text">
      <span *ngIf="!isFollowing()">SEGUIR</span>
      <span *ngIf="isFollowing()">SIGUIENDO</span>
    </span>
    <div class="generic-content-elements" *ngIf="hasContentFollowList"
      [ngClass]="{'show': showFollows, 'tooltip-left': onlyIcon, 'content-center': isReader}"
      (mouseover)="inModal = true" (mouseleave)="onLeaveModal()">
      <ul>
        <li *ngFor="let temp of contentFollowList?.template; let isLast = last;" [ngClass]="{'last': isLast}">
          <div class="follow-list-title">
            {{temp.title}}
          </div>
          <div class="follow-list-item" (click)="followUnFollowContent(element, temp)"
            *ngFor="let element of temp.list;">
            {{element.name}}
            <i class="iconlace iconlace-check" [ngClass]="{'selected':element?.isFollowed}"></i>
          </div>
        </li>
      </ul>
    </div>
  </a>
</div>
<div class="follow" *ngIf="socialInfo && isGuest" [ngClass]="{'active' : socialInfo?.isFollow}">
  <a (click)="followUnFollow()" class="button-link">
    <i class="iconlace iconlace-plus" [ngClass]="color"></i>
    <span>Seguir</span>
  </a>
</div>
