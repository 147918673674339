import {Injectable} from '@angular/core';
import {IRestAction, IRestMethod, Rest, RestAction, RestParams, RestRequestMethod} from 'rest-core';
import {LocalStorage, LocalStorageService} from 'ngx-webstorage';
import {environment} from '@environment/environment';

import {EnlaceRestHandler} from '../enlace-rest-handler';
import {IUser} from '@core/models/interfaces/i-user.interface';

import {ICatalogViewModelPaging} from '@core/models/interfaces/i-catalog-view-model-paging';
import {IUserFollowPagingRequest} from '@core/models/interfaces/i-user-follow-paging-request';
import {ICatalogViewModel} from '@core/models/interfaces/icatalog-view-model';
import {IUserNotificationTypeViewModel} from '@api/models/v2/i-user-notification-type-view-model';
import { IUserInfo } from '@api/models/v2/i-user-info';
import { IUserSubscriptionPlanViewModel } from '@app/core/models/interfaces/i-user-suscription-plan-view-model';
import { IContentUserPlayed } from '@core/models/interfaces/i-content-user-played.interface';
import { IVideoUserView } from '@core/models/interfaces/i-video-user-view';
import { BehaviorSubject } from 'rxjs';
import { IResetPassword } from '@core/models/interfaces/i-reset-password';

@Injectable({
  providedIn: 'root'
})
@RestParams({
  // IRestParams
  pathPrefix: environment.webApi + 'v2/user'
})
export class UserV2Service extends Rest {

  private isEditingConfSource = new BehaviorSubject(false);
  isEditingConf$ = this.isEditingConfSource.asObservable();

  @LocalStorage('token')
  private _token: string;

  @LocalStorage('user')
  private _user: IUser;

  @RestAction({
    path: '/add',
    method: RestRequestMethod.Get,
  })
  addUser: IRestMethod<void, IUser>;

  @RestAction({
    path: '/getVideoUserViews',
    method: RestRequestMethod.Get,
  })
  getVideoUserViews: IRestMethod<void, IVideoUserView[]>;

  @RestAction({
    path: '/notificationtypes',
    method: RestRequestMethod.Get,
  })
  notificationtypes: IRestMethod<void, IUserNotificationTypeViewModel>;

  @RestAction({
    path: '/notificationtype/add/{!notificationTypeId}',
    method: RestRequestMethod.Post,
  })
  addNotification: IRestMethod<{notificationTypeId: string}, void>;

  @RestAction({
    path: '/suscription/enablebeta',
    method: RestRequestMethod.Post,
  })
  suscriptionEnableBeta: IRestMethod<void, void>;

    @RestAction({
    path: '/getuserplan',
    method: RestRequestMethod.Post
  })
  getUserPlan: IRestMethod<void, IUserSubscriptionPlanViewModel>;

  @RestAction({
    path: '/suscription/enableFounder/{!planId}/{!customerId}',
    method: RestRequestMethod.Post,
  })
  suscriptionEnableFounder: IRestMethod<{planId: string, customerId: string}, void>;

  @RestAction({
    path: '/notificationtype/remove/{!notificationTypeId}',
    method: RestRequestMethod.Delete,
  })
  removeNotification: IRestMethod<{notificationTypeId: string}, void>;



  @RestAction({
    path: '/getUserMyFavorites',
    method: RestRequestMethod.Post
  })
  getMyFavorites: IRestMethod<IUserFollowPagingRequest, ICatalogViewModel>;

  @RestAction({
    path: '/getUserMyList',
    method: RestRequestMethod.Post
  })
  getMyList: IRestMethod<IUserFollowPagingRequest, ICatalogViewModel>;

  @RestAction({
    path: '/getUserMyFollows',
    method: RestRequestMethod.Post
  })
  getMyFollows: IRestMethod<IUserFollowPagingRequest, ICatalogViewModelPaging>;



  @RestAction({
    path: '/updateuser',
    method: RestRequestMethod.Post,
  })
  updateUser: IRestMethod<IUser, void>;

  @RestAction({
    path: '/getUserInfo',
    method: RestRequestMethod.Get,
  })
  getUserInfo: IRestMethod<void, IUserInfo>;

  @RestAction({
    path: '/getContentsUserPlayeds/{!puserId}',
    method: RestRequestMethod.Get
  })
  getContentsUserPlayeds: IRestMethod<{puserId?: string}, IContentUserPlayed[]>;

  @RestAction({
    path: '/resetPassword',
    method: RestRequestMethod.Post
  })
  resetPassword: IRestMethod<IResetPassword, any>;

  @RestAction({
    path: '/uploadAvatar',
    method: RestRequestMethod.Post
  })
  uploadAvatar: IRestMethod<FormData, any>;

constructor(restHandler: EnlaceRestHandler, private storage: LocalStorageService) {
  super(restHandler);
  }

  updateContentsUserPlayed(puserId: string) : Promise<void> {
    return new Promise ((resolve, reject) => {
      this.getContentsUserPlayeds({puserId: puserId})
        .then((contentsUserPlayed) => {
          if (contentsUserPlayed) {
            this._user.contentUserPlayed = contentsUserPlayed;
            this.storage.store('user', this._user);
          }
          resolve();
        }).catch(err => resolve())
    })
  }

  updateVideoUserViews(): void {
    if(this._user) {
      this.getVideoUserViews().then(results => {
        if (!!results) {
          this._user.videoUserViews = results;
          this.storage.store('user', this._user);
        }
      })
    }
  }

  public setIsEditing(value) {
    this.isEditingConfSource.next(value);
  }

}
