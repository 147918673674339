import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SearchUserHistoryComponent } from '@components/search-user-history/search-user-history.component';

@NgModule({
  declarations: [SearchUserHistoryComponent],
  imports: [CommonModule],
  exports: [SearchUserHistoryComponent]
})
export class SearchUserHistoryModule { }
