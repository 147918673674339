<!-- SE DIBUJAN LOS DINSTINTOS POPUPS -->
<div class="header-option {{ typeClass }}" [class.active]="active" [class.isMobile]="isMobile">
  <div class="options">
    <ng-container *ngIf="typeLive">
      <ng-container *ngTemplateOutlet="templateLive"></ng-container>
    </ng-container>
    <ng-container *ngIf="typeMore">
      <ng-container *ngTemplateOutlet="templateMore"></ng-container>
    </ng-container>
    <ng-container *ngIf="typeUser">
      <ng-container *ngTemplateOutlet="templateUser"></ng-container>
    </ng-container>
  </div>
</div>

<!--  TODO: Esto es un mockup -->
<ng-template #templateLive>
  <div class="option">
    <img class="option-img" src="assets/img/flags/arg.webp" alt="Enlace" />
    <span class="option-title">ENLACE TV</span>
  </div>
  <div class="option">
    <img class="option-img" src="assets/img/flags/arg.webp" alt="Enlace" />
    <span class="option-title">EJ TV</span>
  </div>
  <div class="option">
    <img class="option-img" src="assets/img/flags/arg.webp" alt="Enlace" />
    <span class="option-title">TBN</span>
  </div>
  <div class="option">
    <img class="option-img" src="assets/img/flags/arg.webp" alt="Enlace" />
    <span class="option-title">Hillsong Chanel</span>
  </div>
</ng-template>

<!-- POPUP - TODAS LAS OPCIONES DISPONIBLES PARA NAVEGAR -->
<ng-template #templateMore>
  <ng-container *ngIf="isMobile">
    <div class="option" (click)="sendRedirect(routes.LIVE)">
      <i class="iconlace iconlace-play-circle"></i>
      <span class="option-title header-link text" [ngClass]="{ 'active': activeTab === routes.LIVE}">En vivo</span>
    </div>
    <div class="option" (click)="sendRedirect(routes.SEARCH_V2, { format: 'program' })">
      <i class="iconlace iconlace-program"></i>
      <span class="option-title header-link text" [ngClass]="{ 'active': activeTab === routes.PROGRAMS || activeTab === routes.PROGRAM}">Programas</span>
    </div>
    <div *appFeatureIsOn="'WebEnableBible'" class="option" (click)="sendRedirect(routes.BIBLE)">
      <i class="iconlace iconlace-bible"></i>
      <span class="option-title header-link text" [ngClass]="{ 'active': activeTab === routes.BIBLE}">Biblia</span>
    </div>
    <div *ngIf="isComunnityEnabled" class="option" (click)="sendRedirect(routes.COMUNITY)">
      <i class="iconlace iconlace-comunity"></i>
      <span class="option-title header-link text" [ngClass]="{ 'active': activeTab === routes.COMUNITY}">Comunidad</span>
    </div>
    <div *appFeatureIsOn="'WebEnableLibrary'" class="option" (click)="sendRedirect(routes.LIBRARY)">
      <i class="iconlace iconlace-bookshop"></i>
      <span class="option-title header-link text" [ngClass]="{ 'active': activeTab === routes.LIBRARY}">Librería</span>
    </div>
    <div class="option" (click)="sendRedirect(routes.ARTICLE)">
      <i class="iconlace iconlace-article"></i>
      <span class="option-title header-link text" [ngClass]="{ 'active': activeTab === routes.ARTICLE || activeTab === routes.DETAIL_ARTICLE}">Articulos</span>
    </div>
  </ng-container>
  <div class="option"  (click)="sendRedirect(routes.PODCAST)">
    <i class="iconlace iconlace-podcast"></i>
    <span class="option-title header-link text" [ngClass]="{ 'active': activeTab === routes.PODCAST}">Podcasts</span>
  </div>
  <div class="option" (click)="sendRedirect(routes.SEARCH_V2, { format: 'organization' })">
    <i class="iconlace iconlace-ministries"></i>
    <span class="option-title header-link text" [ngClass]="{ 'active': activeTab === routes.ORGANIZATION || activeTab === routes.DETAIL_ORGANIZATION}">Ministerios</span>
  </div>
  <div class="option" (click)="sendRedirect(routes.SEARCH_V2, { format: 'person' })">
    <i class="iconlace iconlace-people"></i>
    <span class="option-title header-link text" [ngClass]="{ 'active': activeTab === routes.PEOPLE}">Personas</span>
  </div>
  <div class="border"></div>
  <ng-container *ngIf="isMobile && !isSmallTablet">
    <!-- SE TIENE QUE TRABAJAR EL TEMPLATE PARA DONAR -->
    <div class="option" (click)="sendOption(action.DONATE)">
      <i class="iconlace iconlace-donate"></i>
      <span class="option-title">Donar</span>
    </div>
    <div class="border"></div>
  </ng-container>
  <div class="option" (click)="sendOption(action.CONTACT)">
    <i class="iconlace iconlace-phone"></i>
    <span class="option-title header-link text">Contacto</span>
  </div>
  <div class="option" (click)="sendOption(action.WHO)">
    <span [class]="icon"></span>
    <span class="option-title header-link text">¿Quiénes somos?</span>
  </div>
</ng-template>

<!-- POPUP - USUARIO -->
<ng-template #templateUser>
  <!-- TEMPLATE PARA DESKTOP - TABLET -->
  <ng-container *ngIf="!isMobile || isSmallTablet; else userMobile">
     <ng-container *ngTemplateOutlet="userOptions"></ng-container>
  </ng-container>
  <!-- TEMPLATE PERSONALIZADO PARA MOBILE -->
  <ng-template #userMobile>
    <div class="option header-mobile">
      <a class="navbar-brand">
        <img src="assets/img/logoEplus.svg" alt="Logo Enlace+" />
      </a>
      <img class="option-close" src="assets/img/icons/close_prev_detail.svg" (click)="sendOption('')">
    </div>
    <div class="border"></div>
    <div class="option user" *ngIf="!openCountries" (click)="toggleOptionsUser(user)">
      <div *ngIf="openOptionsUser" class="option-icon" [class.arrow-left]="openOptionsUser">
        <i class="iconlace iconlace-chevron-previous"></i>
      </div>

      <img *ngIf="profileImage !== ''" class="option-img" [src]="profileImage" alt="user Image">
      <i *ngIf="profileImage == ''" class="iconlace iconlace-user icon-dimensions"></i>

      <ng-container *ngIf="user; else notUser">
        <div class="info-user">
          <div class="option-title">{{user?.userName}}</div>
          <div class="option-subtitle">{{user?.email}}</div>
        </div>
        <div *ngIf="!openOptionsUser" class="option-icon" [class.arrow-right]="!openOptionsUser">
          <i class="iconlace iconlace-chevron-next"></i>
        </div>
      </ng-container>
      <ng-template #notUser>
        <div class="option-title" (click)="sendOption(action.LOGIN)">Ingresar</div>
      </ng-template>
    </div>
    <div class="border" *ngIf="!openCountries"></div>
    <!-- OPCIONES  DEFAULT -->
    <ng-container *ngIf="!openCountries && !openOptionsUser">
      <div class="option country">
        <div class="wrapper-country" (click)="toggleCountry()">
          <div class="option-country">
            <img class="option-img" [src]="countrySelected.imgFlag" [alt]="countrySelected.name" />
            <span class="option-title">{{countrySelected.name}}</span>
          </div>
          <div class="option-icon">
            <i class="iconlace iconlace-chevron-next"></i>
          </div>
        </div>
      </div>
      <div class="border"></div>
      <ng-container *ngTemplateOutlet="templateMore"></ng-container>
    </ng-container>
     <!-- TIMEZONE MOBILE -->
    <ng-container *ngIf="openCountries && !openOptionsUser">
      <ng-container *ngTemplateOutlet="countryList"></ng-container>
    </ng-container>
    <!-- OPCIONES DE USUARIO -->
    <ng-container *ngIf="!openCountries && openOptionsUser">
      <ng-container *ngTemplateOutlet="userOptions"></ng-container>
    </ng-container>
  </ng-template>
</ng-template>

<!-- OPCIONES DISPONIBLES PARA USERS LOGUEADOS -->
<ng-template #userOptions>
  <ng-container *ngIf="!isMobile || isSmallTablet">
    <div class="container-avatar" (click)="sendRedirect(routes.SETTING)">
      <div class="option user">
        <img *ngIf="profileImage !== ''" class="option-img" [src]="profileImage" alt="user Image">
        <i *ngIf="profileImage == ''" class="iconlace iconlace-user icon-dimensions"></i>
        <div class="info-user">
          <div class="option-title">{{user?.userName}}</div>
          <div class="option-subtitle">{{user?.email}}</div>
        </div>
      </div>
    </div>
    <div class="border"></div>
  </ng-container>
  <div class="option" (click)="sendRedirect(routes.FAVORITE)">
    <i class="iconlace iconlace-heart"></i>
    <span class="option-title">Favoritos</span>
  </div>
  <div class="option" (click)="sendRedirect(routes.FOLLOW)">
    <i class="iconlace iconlace-plus"></i>
    <span class="option-title">Seguidos</span>
  </div>
  <div class="option" (click)="sendRedirect(routes.TRANSACTION)">
    <i class="iconlace iconlace-transactions"></i>
    <span class="option-title">Transacciones</span>
  </div>
  <div class="option" (click)="sendRedirect(routes.MY_HIGHLIGHT)">
    <i class="iconlace iconlace-marker"></i>
    <span class="option-title">Versículos resaltados</span>
  </div>
  <div class="option" (click)="sendRedirect(routes.MY_CONTENT)">
    <i class="iconlace iconlace-Mi-contenido"></i>
    <span class="option-title">Mi contenido</span>
  </div>
  <div class="option" (click)="sendRedirect(routes.SETTING)">
    <i class="iconlace iconlace-settings2"></i>
    <span class="option-title">Configuración</span>
  </div>
  <div class="option country" *ngIf="!isMobile || isSmallTablet">
    <div class="wrapper-country" (click)="toggleCountry()">
      <div class="option-country">
        <img class="option-img" [src]="countrySelected.imgFlag" [alt]="countrySelected.name" />
        <span class="option-title">{{countrySelected.name}}</span>
      </div>
      <div class="option-icon">
        <i class="iconlace iconlace-chevron-next"></i>
      </div>
    </div>
    <div class="country-list" *ngIf="openCountries">
      <ng-container *ngTemplateOutlet="countryList"></ng-container>
    </div>
  </div>
  <div class="border"></div>
  <div class="option" (click)="sendOption(action.LOGOUT)">
    <i class="iconlace iconlace-exit"></i>
    <span class="option-title">Cerrar Sesión</span>
  </div>
</ng-template>

<!-- POPUP - TIMEZONE -->
<ng-template #countryList>
  <div class="countries" [class.isMobile]="isMobile">
    <div class="country-header">
      <ng-container *ngIf="isMobile && !isSmallTablet">
        <div class="option-icon" (click)="toggleCountry()">
          <i class="iconlace iconlace-chevron-previous"></i>
        </div>
      </ng-container>
      <span>Zona horaria</span>
      <img class="option-img" [src]="countrySelected.imgFlag" [alt]="countrySelected.name" />
    </div>
    <div class="border"></div>
    <div class="country-body">
      <div class="country-item" *ngFor="let country of countries" (click)="changeCountry(country)">
        <span>{{country.name}}</span>
        <img class="option-img" [src]="country.imgFlag" [alt]="country.name" />
      </div>
    </div>
  </div>
</ng-template>
