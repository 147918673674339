import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';

const ROUND_NUMBER = 5;
@Component({
  selector: 'app-filter-by-price',
  templateUrl: './filter-by-price.component.html',
  styleUrls: ['./filter-by-price.component.scss'],
})
export class FilterByPriceComponent implements OnInit, AfterViewInit {

  @ViewChild('inputPrice') inputPrice: ElementRef;
  @ViewChild('output') output: ElementRef;
  @Input() value: number = 0;
  @Input() maxValue: number;
  @Output() priceChange = new EventEmitter<number>();

  public minPrice: number = 0;
  public maxPrice: number = 0;
  public outputValue: string = '';
  public style: string = '';
  constructor(private renderer: Renderer2) {}

  @HostListener('input', ['$event'])
  onInput() {
    this.updateInput();
  }

  ngOnInit() {
    this.maxValue === 0 ? (this.value = 0) : this.calculateMaxPrice();
  }

  ngAfterViewInit(): void {
    this.updateInput();
  }

  get isValue(): boolean {
    return this.value > 0 && this.value !== this.maxPrice;
  }

  get isMaxPrice(): boolean {
    return this.value === this.maxPrice;
  }

  get isMaxValue(): boolean {
    return this.value === this.maxValue;
  }

  get isNativeElement(): boolean {
    return !!this.output;
  }

  setFilter($event: any) {
    this.isMaxPrice
      ? this.priceChange.emit(this.maxValue)
      : this.priceChange.emit(this.value);
  }

  public updateInput(): void {
    const unidad: number = (this.maxPrice - this.minPrice) / 100;
    const currentValue: number = (this.value - this.minPrice) / unidad;
    this.style = `--value:${currentValue}`;
    this.outputValue = `calc(${currentValue}% + (${
      8 - currentValue * 0.15
    }px))`;
    this.setPropertyStyle(this.style, this.outputValue);
  }

  public setPropertyStyle(style: string, outputValue: string): void {
    this.renderer.setProperty(this.inputPrice.nativeElement, 'style', style);
    if (this.isNativeElement) {
      this.renderer.setStyle(this.output.nativeElement, 'left', outputValue);
    }
  }

  public calculateMaxPrice(): void {
    const rest = this.maxValue % ROUND_NUMBER;
    if (rest === 0) {
      this.maxPrice = this.maxValue;
    } else {
      this.maxPrice = Math.ceil(this.maxValue / ROUND_NUMBER) * ROUND_NUMBER;
    }
    if (this.isMaxValue) {
      this.value = this.maxPrice;
    }
  }
}
