<div class="content">
  <div class="info-icon">
    <i class="iconlace iconlace-circle circle"></i>
    <i class="iconlace iconlace-check check"></i>
  </div>
  <div class="info-title">
    ¡Gracias por hacerte Misionero Digital!
  </div>
  <div class="info-content">
    <p>Tu apoyo permite que miles disfruten de esta plataforma.</p>
  </div>
  <div class="content-buttons">
    <button type="button" class="btn btn-primary" (click)="actionFirstButton()">ACEPTAR</button>
  </div>
  <div class="info-footer">
    <p>Mira el detalle de tu suscripción en Configuración.</p>
  </div>
</div>
