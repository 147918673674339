<ng-container *ngIf="!isNewSection; else newSection">
  <!-- Evaluar esto en libreria -->
  <!-- <div class="filter-tags" *ngIf="getFiltersToClear().length > 0">
   <div class="tags-content">
     <div class="tag-selected" *ngFor="let item of getFiltersToClear()" (click)="removeFilter(item)">
       <div *ngIf="item.filter.type != 'price'">
         <span>{{getLabel(item.item)}}<i class="iconlace iconlace-cross icon-remove"></i></span>
       </div>
     </div>
   </div>
   <div>
     <a><i (click)="clearFilters()" class="iconlace iconlace-cross filter-close-icon"></i></a>
      <i class="fa fa-backspace"></i> 
    </div>
  </div> -->
  <div #bodyFilter class="col-sm-3 col-md-3 filter csb" [ngClass]="{'mobile': isMobile, 'library': isLibrary}">
    <div class="filter-title-row" [ngClass]="{'mobile': isMobile}">
      <div class="col-sm-6 col-md-6 filter-title">
        <h3>Filtros</h3>
      </div>
      <div class="col-sm-6 col-md-6 filter-reset">
        <p (click)="clearFilters()"><i class="iconlace iconlace-cross"></i></p>
      </div>
    </div>
    <div class="col-md-12 filter-body" [ngClass]="{'mobile': isMobile}">
      <div *ngFor="let filter of filters">
        <div *ngIf="filter.visible" class="filter-section">
          <app-filter
            *ngIf="filter.visible && filter.type != 'price' && filter.type != 'recent' && filter.type != 'rating'"
            (addFilter)="addFilter($event)" [filter]="filter" limitItems="5"></app-filter>
          <app-filter-by-price *ngIf="filter.type =='price'" (priceChange)="changePrice($event, filter)"
            [value]="filter.value" [maxValue]="filter.maxValue"></app-filter-by-price>
          <app-rating-filter *ngIf="filter.type == 'rating'" [rating]="filter.items"
            (ratingChange)="changeRating($event, filter)"></app-rating-filter>
          <div class="search-filter-item-recents" *ngIf="filter.type == 'recent'" (click)="selectRecents(filter)">
            <h2 class="search-filter-item-recents-title">Recientes ({{getRecentsCount(filter)}})</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>


<!-- LA IDEA SERIA DEJAR SOLO UN DESARROLLO DE TEMPLATE Y QUE SEA MOLDEABLE
    DE ACUERDO AL PARENT COMPONENT - EVALUAR CAMBIOS EN LIBRERIA
-->
<ng-template #newSection>
  <div class="content-filter" [class.pt-52]="filtersSelected.length !== 0 && !isMobile">
    <div #bodyFilter class="new-filter">
      <div class="option header-mobile" *ngIf="isMobile" (click)="closeFilter.emit(true)">
        <span>Filtros</span>
        <img class="option-close" src="assets/img/icons/close_prev_detail.svg">
      </div>
      <div class="filter-body">
        <div *ngFor="let filter of filters">
          <app-filter
            *ngIf="filter.visible && filter.type != 'price' && filter.type != 'recent' && filter.type != 'rating' && filter.type != 'date-issue'"
            (addFilter)="addFilter($event)" [filter]="filter" [isNewSection]="true" limitItems="5">
          </app-filter>
          <app-issue-date-filter *ngIf="filter.type == 'date-issue'" [filters]="filter.items"
            [currentValue]="currentFilterIssueDate" (onChangeIssueDate)="changeIssueDate($event)">
          </app-issue-date-filter>
          <app-filter-by-price *ngIf="filter.visible && filter.type == 'price'"
            (priceChange)="changePrice($event, filter)" [value]="filter.value" [maxValue]="filter.maxValue">
          </app-filter-by-price>
          <app-rating-filter *ngIf="filter.type == 'rating'" [rating]="filter.items"
            (ratingChange)="changeRating($event, filter)"></app-rating-filter>
          <div class="search-filter-item-recents"
            *ngIf="filter.type == 'recent' && getRecentsCount(filter) !== 0 && !isThereRecents"
            (click)="selectRecents(filter)">
            <h2 class="search-filter-item-recents-title">Recientes ({{getRecentsCount(filter)}})</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>