import {Component, Input, OnInit} from '@angular/core';
import {MyMonitoringService} from '../../../shared/services/lib/monitoring.service';
import * as copy from 'copy-to-clipboard';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss']
})
export class SocialShareComponent implements OnInit {
  @Input() onlyIcon: boolean;
  @Input() contentId: string;
  @Input() urlPath: string;
  @Input() description: string;
  @Input() title: string;
  @Input() color: string = null;
  @Input() isMobile: boolean = false;
  @Input() className: string = '';
  showFollows: boolean;
  inModal = false;

  url: string;
  private lastInserted: number[] = [];
  constructor(private myMonitoringService: MyMonitoringService, private toastr: ToastrService) {}

  ngOnInit() {
    this.url = this.urlPath ? window.location.origin + this.urlPath : window.location.href;
  }

  trackShare(SocialAppId: string) {
    this.url = this.urlPath ? window.location.origin + this.urlPath : window.location.href;
    this.myMonitoringService.logEvent({name: 'Share', properties:
    {'BookId': this.contentId, 'SocialEventTypeId': 2, 'SocialAppId': SocialAppId}});
  }

  // Los dos siguientes métodos son necesarios para la interacción de mostrar/ocultar modal automáticamente. BUG: #17753
  onLeave() {
    setTimeout(() => {
      if (!this.inModal) {
        this.showFollows = false;
      }
    }, 250);
  }

  onLeaveModal() {
    this.showFollows = false;
    this.inModal = false;
  }

  copyToClipboard() {
    this.url = this.urlPath ? window.location.origin + this.urlPath : window.location.href;
    copy(this.url);
    this.toastr.clear();
    const inserted = this.toastr.show('', '¡Link copiado!');
    if (inserted) {
      this.lastInserted.push(inserted.toastId);
    }
  }

  get urlShare(): string {
    return this.urlPath ? window.location.origin + this.urlPath : window.location.href;
  }
}
