import {Component, Input, OnInit} from '@angular/core';
import {LocalStorage} from 'ngx-webstorage';
import { IArticleViewModel } from '@interfaces/i-article-view-model';
import { IUser } from '@interfaces/i-user.interface';
import { Router } from '@angular/router';
import { ModalManagementService } from '@app/shared/utils/modal-management.service';
declare const Android: any;
@Component({
  selector: 'app-content-item-big',
  templateUrl: './content-item-big.component.html',
  styleUrls: ['./content-item-big.component.scss']
})
export class ContentItemBigComponent implements  OnInit {

  @LocalStorage('user')
  user: IUser;

  @Input()
  type: string;

  @Input()
  imageUrl: string;

  @Input()
  titleLbl: string;

  @Input()
  subtitle: string;

  @Input()
  contentId: string;

  @Input()
  isWebview: boolean = false;

  @Input()
  video: any;

  @Input()
  typeCaller: number;

  @Input()
  article: IArticleViewModel;
  public percent: number = 0;
  constructor(private modalManagementService: ModalManagementService, private router: Router) { }

  ngOnInit() {
    this.calculateProgress();
  }

  get isContentVideo(): boolean {
    return this.type === 'video' || this.type === 'program';
  }

  get isProgram(): boolean {
    return this.type === 'program';
  }

  get isEbook(): boolean {
    return this.type === 'ebook';
  }

  get isAudiobook(): boolean {
    return this.type === 'audiobook';
  }

  get isBoth(): boolean {
    return this.type === 'organization' || this.type === 'podcast' || this.type === 'podcastitem';
  }

  get hasPercent(): boolean {
    return this.percent > 0;
  }

  public calculateProgress(): void {
    if (this.user && this.user.videoUserViews && !!this.video) {
      const videoUserView = this.user.videoUserViews.find((f) => {
        return f.videoId === this.video.contentId;
      });
      this.percent = 0;
      if (
        videoUserView &&
        videoUserView.totalSeconds - videoUserView.secondEnd > 60
      ) {
        this.percent = videoUserView.secondEnd / videoUserView.totalSeconds;
      }
    }
  }

  navigate() {
    if (this.isWebview && (typeof Android !== 'undefined')) {
      Android.redirectContent(`${this.type}/${this.contentId}`);
    }else{
      if (this.isProgram) {
        this.openModalDetail();
      } else {
        this.router.navigate(['/' + this.type, this.contentId]);
      }
    }
  }

  openModalDetail(): void{
    this.modalManagementService.openModalMoreInfo(this.contentId, this.type);
  }
}
