import { Injectable } from "@angular/core";
import {
  CatalogCarousel,
  CatalogItem,
  HomeCatalog,
} from "@app/core/models/interfaces/home-catalog.interface";
import { IUser } from "@app/core/models/interfaces/i-user.interface";
import { LocalStorage } from "ngx-webstorage";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class HomeCatalogStateService {

  @LocalStorage('user') private user: IUser;
  private catalogCache: HomeCatalog = {} as HomeCatalog;
  private catalogSubject = new BehaviorSubject<HomeCatalog>(
    this.catalogCache
  );
  private loadingCarouselSubject = new BehaviorSubject<{
    loading: boolean;
    catalogId: string;
  }>({ loading: false, catalogId: "" });
  private currentPage: number = 0;
  private canPage = true;
  private mappingType = {
    video: {
      horizontal: 'video',
      vertical: 'vertical'
    },
    program: {
      horizontal: 'program',
      vertical: 'vertical'
    },
    podcast: 'podcast',
    audiobook: 'audiobook',
    article: 'article',
    ebook: 'ebook'
  };

  get catalogCache$(): Observable<HomeCatalog> {
    return this.catalogSubject.asObservable()
    .pipe(
      map(catalog => {
        if (catalog?.items) {
          return {
            ...catalog,
            items: this.getCatalogType(catalog.items)
          }
        }
        return catalog;
      })
    );
  }

  get loadingCarousel$(): Observable<{ loading: boolean; catalogId: string }> {
    return this.loadingCarouselSubject.asObservable();
  }

  setCurrentPage(page: number) {
    this.currentPage = page;
  }

  getCurrentPage(): number {
    return this.currentPage;
  }

  setCanPage(flag: boolean) {
    this.canPage = flag;
  }

  getCanPage(): boolean {
    return this.canPage;
  }

  getCatalog(): HomeCatalog | undefined | null {
    return this.catalogCache;
  }

  setCatalog(catalog: HomeCatalog): void {
    if (this.catalogCache?.items) {

      const combinedItems = [...this.catalogCache.items, ...catalog.items];

      const uniqueItems = [];
      const seenIds = new Set<string>();

      for (const item of combinedItems) {
        if (!seenIds.has(item.id)) {
          seenIds.add(item.id);
          uniqueItems.push(item);
        }
      }
      this.catalogCache.items = uniqueItems;
    }else {
      this.catalogCache = catalog;
    }
    this.catalogSubject.next(this.catalogCache);
  }

  setCarouselCatalog(catalogId: string, carousel: CatalogCarousel): void {
    const currentCatalog = this.getCatalog();
    const catalogItems = currentCatalog.items.map((catalog) => {
      if (catalog.id === catalogId) {
        const items = catalog.carousel.items.filter(item => !item.isSkeleton);
        return {
          ...catalog,
          carousel: {
            ...carousel,
            items: [...items, ...carousel.items],
          },
          canPage: carousel.items.length === 0 ? false : catalog.carousel.items.length < catalog.carousel.total,
        };
      }
      return catalog;
    });

    this.catalogCache = {
      ...currentCatalog,
      items: catalogItems,
    };
    this.catalogSubject.next(this.catalogCache);
  }

  setCarouselItem(videoId: string): void {
    if (this.user && this.user.id) {
      const videoUserView = this.user.videoUserViews.find((video) => {
        return video.videoId === videoId
      });
      if (videoUserView && videoUserView.totalSeconds - videoUserView.secondEnd > 60) {
        const percent = videoUserView.secondEnd / videoUserView.totalSeconds;
        this.setPercentItem(videoId, percent);
      }
    }
  }
  
  setPercentItem(videoId: string, percent: number): void {
    if (this.catalogCache && this.catalogCache.items) {
      this.catalogCache.items.forEach(catalog => {
        if (catalog.contentType === 'video') {
          catalog.carousel.items.forEach(item => {
            if (item.contentId === videoId) {
              item.consumption = (percent * 100.0);
            }
          })
        }
      });
      this.catalogSubject.next(this.catalogCache);
    }
  }

  setCarouselItemEbook(ebookId: string): void {
    if (this.user && this.user.id) {
      const contentUserPlayed = this.user.contentUserPlayed.find((ebook) => {
        return ebook.contentId === ebookId;
      });
      if (contentUserPlayed && contentUserPlayed.contentId) {
        const percent = contentUserPlayed.percentage;
        this.setPercentItemEbook(ebookId, percent)
      }
    }
  }

  setPercentItemEbook(ebookId: string, percent: number): void {
    if (this.catalogCache && this.catalogCache.items) {
      this.catalogCache.items.forEach(catalog => {
        if (catalog.contentType === 'ebook') {
          catalog.carousel.items.forEach(item => {
            if (item.contentId === ebookId) {
              item.consumption = percent;
            }
          })
        }
      });
      this.catalogSubject.next(this.catalogCache);
    }
  }

  setLoading(loading: boolean, catalogId: string): void {
    this.loadingCarouselSubject.next({ loading, catalogId });
  }

  clearCatalog(): void {
    this.catalogCache = null;
    this.currentPage = 0;
    this.canPage = true;
    this.catalogSubject.next(this.catalogCache);
  }

  clearLoading(): void {
    this.loadingCarouselSubject.next({ loading: false, catalogId: "" });
  }

  private getCatalogType(items: CatalogItem[]): CatalogItem[] {
    return items.map(catalog => {
      const contentType = catalog.contentType;
      const typeCatalog = catalog.type.toLowerCase();
      const componentType = typeof this.mappingType[contentType] === 'string'
      ? this.mappingType[contentType]
      : this.mappingType[contentType]?.[typeCatalog] || '';
      return {
        ...catalog,
        canPage: catalog.canPage !== undefined ? catalog.canPage : catalog.carousel.items.length < catalog.carousel.total,
        componentType
      }
    });
  }
}
