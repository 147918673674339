import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EbookNavigation, Highlight } from "@app/core/models/interfaces/ebook-reader";
import { ResponseSoaApi } from "@app/core/models/interfaces/i-cancel-subscription";
import { Observable } from "rxjs";
import { environment as ENV } from '@environment/environment';

@Injectable({ providedIn: 'root' })
export class EbookApiService {
    private API = 'https://readerapi-qa-dtcwa6eyd3grfnft.eastus-01.azurewebsites.net/reader'
    private OLD_API = `${ENV.webApi}v2/content`;

    constructor(private http: HttpClient) { }

    getReaderEbook(userId: string, ebookId: string): Observable<ResponseSoaApi> {
        const url = `${this.API}/${userId}/${ebookId}`;
        return this.http.get<ResponseSoaApi>(url);
    }

    saveHighlight(payload: Highlight): Observable<ResponseSoaApi>{
        const url = `${this.API}/highlight`;
        return this.http.post<ResponseSoaApi>(url, payload);
    }

    updateHighlight(payload: Highlight): Observable<ResponseSoaApi>{
        const url = `${this.API}/highlight`;
        return this.http.put<ResponseSoaApi>(url, payload);
    }

    deleteHighlight(payload: any): Observable<ResponseSoaApi>{
        const url = `${this.API}/highlight`;
        return this.http.delete<ResponseSoaApi>(url, { body: payload });
    }

    readerNavigation(payload: EbookNavigation): Observable<ResponseSoaApi>{
        const url = `${this.API}/navigation`;
        return this.http.post<ResponseSoaApi>(url, payload);
    }

    readerNavigationUpdate(payload: EbookNavigation): Observable<ResponseSoaApi>{
        const url = `${this.API}/navigation`;
        return this.http.put<ResponseSoaApi>(url, payload);
    }

    setPercentage(contentId: string, percentage: number): Observable<any>{
        const url = `${this.OLD_API}/played/${contentId}/${percentage}`;
        return this.http.get<any>(url);
    }
}