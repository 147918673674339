import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-video-progress-bar',
  templateUrl: './video-progress-bar.component.html',
  styleUrls: ['./video-progress-bar.component.scss']
})
export class VideoProgressBarComponent {

  percentBar: string = '0%';
  percentRaw: number = 0;
  minsTotal: number = 0;
  minsElapsed: number = 0;

  @Input() duration: string;

  @Input()
  set percent(percent: number) {
      this.percentRaw = percent;
      this.percentBar = (percent * 100.0) + '%'
  }

  constructor() {
  }

}
