import { DonationPlan, FrequencyDonation } from '@enums/type-plan-active.enum';

export const DONATION_DETAIL_PLAN = {
  [DonationPlan.Unique]: {
    frequencyType: FrequencyDonation.unique,
    frequencyQuantity: 0
  },
  [DonationPlan.Biweekly]: {
    frequencyType: FrequencyDonation.biweekly,
    frequencyQuantity: 15
  },
  [DonationPlan.Quarterly]: {
    frequencyType: FrequencyDonation.quarterly,
    frequencyQuantity: 3
  },
  [DonationPlan.Monthly]: {
    frequencyType: FrequencyDonation.monthly,
    frequencyQuantity: 1
  }
}

export const DONATION_PLAN = {
  [FrequencyDonation.unique]: DonationPlan.Unique,
  [FrequencyDonation.biweekly]: DonationPlan.Biweekly,
  [FrequencyDonation.quarterly]: DonationPlan.Quarterly,
  [FrequencyDonation.monthly]: DonationPlan.Monthly,
}

export const URL_BACKGROUND_IMAGE = 'https://enlaceimages.blob.core.windows.net/images/donation_screenview/donation-background.webp';
