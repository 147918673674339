import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TraceIdentifierService {

  private _mobileRoutes: string[] = [
    'donate',
    'suscribirme',
    'payment',
    'mobile',
  ];
  private _fromMobile: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute) {}

  get currentRoute(): string[] {
    return this.router.url.split('?')[0].split('/');
  }

  get isFromMobile(): boolean {
    // @ts-ignore
    return this.currentRoute.some((el) => this._mobileRoutes.includes(el));
  }

  public identifyTraceIdentifier(error: HttpErrorResponse): void {
    this.route.queryParams
      .subscribe((params) => {
        !!params.fromMobile
          ? (this._fromMobile = params.fromMobile)
          : (this._fromMobile = this.isFromMobile);
        if (error?.error?.traceIdentifier) {
          this.redirectError(error.error.traceIdentifier, this._fromMobile, error);
        } else {
          typeof error.error === 'string' &&
          error.error.includes('traceIdentifier')
            ? this.parseError(error, this._fromMobile)
            : this.redirectDefault(error);
        }
      })
      .unsubscribe();
  }

  public parseError(error: HttpErrorResponse, fromMobile: boolean): void {
    const parse = JSON.parse(error.error);
    this.redirectError(parse.traceIdentifier, fromMobile, error);
  }

  public redirectError(traceIdentifier: string, fromMobile: boolean, error: HttpErrorResponse): void {
    !!traceIdentifier
      ? this.redirectWithParams(traceIdentifier, fromMobile)
      : this.redirectDefault(error);
  }

  public redirectWithParams(
    traceIdentifier: string,
    fromMobile: boolean
  ): void {
    this.router.navigate(['/500'], {
      queryParams: { traceIdentifier: traceIdentifier, fromMobile: fromMobile },
    });
  }

  public redirectDefault(error: HttpErrorResponse): void {
    if (error.status !== 403) this.router.navigate(['/500']);
  }

}
