import {
  ElementRef,
  Injectable,
  Renderer2,
  RendererFactory2,
} from "@angular/core";
import { IUser } from "@app/core/models/interfaces/i-user.interface";
import { ContentDetail } from "@app/core/models/interfaces/content-detail";
import { LoginModalComponent } from "@app/layout/components/login-modal/login-modal.component";
import { PrayRequestModalComponent } from "@app/shared/components/modals/pray-request-modal/pray-request-modal.component"
import { CheckoutModalComponent } from "@app/layout/components/modals/checkout-modal/checkout-modal.component";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LocalStorage } from "ngx-webstorage";
import { ContentDetailModalComponent } from "../components/more-info/content-detail-modal/content-detail-modal.component";
import { ContentPodcastModalComponent } from "../components/more-info/content-podcast-modal/content-podcast-modal.component";
import { MarketingParamsService } from "./marketing-params.service";
import { DomManipulateService } from "./dom-manipulate.service";

const CONTENT = {
  video: "/video/",
  program: "/program/",
  ebook: "/ebook/",
};

const REDIRECT = {
  video: "/home",
  program: "/programs",
  ebook: "/ebook",
};

const CONTENT_TYPE_MODAL = {
  video: ContentDetailModalComponent,
  program: ContentDetailModalComponent,
  ebook: ContentDetailModalComponent,
  podcast: ContentPodcastModalComponent,
};

const CONTENT_TYPE_CLASS = {
  program: "dark-modal modal-detail-content program-class",
  podcast: "dark-modal modal-detail-content program-class",
};

@Injectable({
  providedIn: "root",
})
export class ModalManagementService {
  @LocalStorage("user") private user: IUser;

  private renderer: Renderer2;
  animationTimeout: any;

  constructor(
    private marketingParamsService: MarketingParamsService,
    private domManipulateService: DomManipulateService,
    private rendererFactory: RendererFactory2,
    private modalService: NgbModal
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  openModalLogin(
    contentType: string = "",
    contentId: string = "",
    backdrop: any = true
  ): Promise<any> {
    const modalInstance = this.modalService.open(LoginModalComponent, {
      windowClass: "dark-modal modal-outlogin over-lapping-modal",
      backdropClass: "light-black-backdrop",
      backdrop: backdrop,
    });
    const url = CONTENT[contentType];
    if (url) {
      const navigationUrl = `${url}${contentId}`;
      const serializedUrl =
        this.marketingParamsService.getSerializedUrl(navigationUrl);
      modalInstance.componentInstance.redirectTo = serializedUrl;
    }
    modalInstance.componentInstance.hideClose = backdrop === "static";
    return modalInstance.result;
  }

  openModalCheckout({
    content,
    contentType,
    isRent,
    parentModal,
    isParentPPV,
  }): Promise<any> {
    const paymentContent = this.createPaymentContent(
      content,
      contentType,
      isRent,
      isParentPPV
    );

    const modalInstance = this.modalService.open(CheckoutModalComponent, {
      windowClass: "dark-modal-book checkout-modal",
      backdrop: "static",
      backdropClass: "light-black-backdrop",
    });

    modalInstance.componentInstance.parentModal = parentModal;
    modalInstance.componentInstance.urlRedirect = REDIRECT[contentType] ?? "";
    modalInstance.componentInstance.paymentContent = paymentContent;
    modalInstance.componentInstance.contentDetail = content;

    return modalInstance.result;
  }

  private createPaymentContent(
    content: ContentDetail,
    contentType: string,
    isRent: boolean,
    isParentPPV: boolean
  ) {
    const paymentContent = {
      contentId: isParentPPV ? content.program.id : content.id,
      planId: this.user.planId,
      contentType: contentType,
      amount: isRent
        ? content.contentPpv.rentalPrice
        : content.contentPpv.purchasePrice,
      isRent: isRent,
      rentalDays: isRent ? content.contentPpv.rentalDays : "0",
      userId: this.user.id,
      imageContent: content.imageUrl,
      title: isParentPPV ? content.program.title : content.title,
      action: "COMPRAR",
    };

    return paymentContent;
  }

  openModalMoreInfo(
    contentId: string,
    contentType: string,
    config = null,
    onlyInfo = false,
    isPpvWatch = false,
  ): Promise<any> {
    this.domManipulateService.setBodyScroll(false);

    const modalComponent = CONTENT_TYPE_MODAL[contentType];
    const modalClass =
      CONTENT_TYPE_CLASS[contentType] || "dark-modal modal-detail-content";
    const modalRef = this.modalService.open(modalComponent, {
      windowClass: modalClass,
      backdropClass: "light-black-backdrop",
      backdrop: "static",
    });

    modalRef.componentInstance.contentId = contentId;
    modalRef.componentInstance.contentType = contentType;
    modalRef.componentInstance.config = config;
    modalRef.componentInstance.onlyInfo = onlyInfo;
    modalRef.componentInstance.isPpvWatch = isPpvWatch;

    modalRef.result
      .then(() => this.domManipulateService.setBodyScroll(true))
      .catch(() => this.domManipulateService.setBodyScroll(true));

    return modalRef.result;
  }

  openModalPray(): void {
    if (!this.user) {
      this.openModalLogin();
    } else {
      const modalRef = this.modalService.open(PrayRequestModalComponent, {
        windowClass: 'modal dark-modal modal-pray-request2',
        backdropClass: 'light-black-backdrop',
        backdrop: "static"
      });
      modalRef.componentInstance.user = this.user;
    }
  }

  executeAnimateBottom(
    activeModal: NgbActiveModal,
    canHandleResult: boolean,
    callBack = null,
    className: string
  ): void {
    const modalElement = document.querySelector(className);
    if (modalElement) {
      this.renderer.addClass(modalElement, "closing-animation");
      this.animationTimeout = setTimeout(() => {
        activeModal.close(canHandleResult);
        if (callBack) {
          callBack();
        }
        this.renderer.removeClass(modalElement, "closing-animation");
      }, 1000);
    }
  }

  checkIfModalExceedsViewport(el: ElementRef): void {
    const modalElement = el.nativeElement;
    const viewportHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const modalHeight = modalElement.offsetHeight;
    if (modalHeight > viewportHeight) {
      const parentElement = document.querySelector(".modal-detail-content");
      if (modalElement) {
        this.renderer.addClass(parentElement, "scroll-auto");
      }
    }
  }
}
