import {Component, EventEmitter, Input, Output} from '@angular/core';
import { TypeButtonPPV } from './constants/type-buttons.entities';

@Component({
  selector: 'app-ppv-buttons',
  templateUrl: './ppv-buttons.component.html',
  styleUrls: ['./ppv-buttons.component.scss']
})
export class PpvButtonsComponent {

  color: string = '#6C00C0';

  @Input() purchasePrice: number;
  @Input() rentalPrice: number;
  @Input() availableBy: number;
  @Input() type: string = TypeButtonPPV.DEFAULT;
  @Input() contentType: string;
  @Input() contentId: string;
  @Input() fromModal: boolean;

  @Output() purchase: EventEmitter<string> = new EventEmitter();
  @Output() rent: EventEmitter<string> = new EventEmitter();

  constructor() {}

  acquireAction() {
    this.purchase.emit();
  }

  rentAction() {
    this.rent.emit();
  }

  showAcquireButton(): boolean {
    return this.purchasePrice != null;
  }

  showRentButton(): boolean {
    return this.rentalPrice != null;
  }

}
