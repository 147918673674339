import { Component } from '@angular/core';

@Component({
  selector: 'app-tag-plus',
  styles: [
    `
      .tag-plus {
        position: absolute;
        top: 2px;
        right: -70px;
        height: 30px;
        width: 152px;
        background: linear-gradient(to right, #F9B000, #AA7800);
        text-align: center;
        transform: rotate(45deg);
        padding-top: 16px;
        padding-right: 15px;
        font-family: "Lato";
        font-size: 12px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }
    `,
  ],
  template: `
    <div class="tag-plus">PLUS</div>
  `,
})

export class TagPlusComponent {

  constructor() { }
}
