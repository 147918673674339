import { ActivatedRoute, Router } from '@angular/router';
import {
  Component, Input, Output,
  EventEmitter, ChangeDetectionStrategy, HostListener,
  OnInit, ChangeDetectorRef, AfterViewInit, ViewChild, ElementRef
} from '@angular/core';

import { AdvanceSearchService } from '@layout/components/search-advanced/services/advance-search.service';
import { ISearchUserHistory } from '@app/api-client/models/v2/i-search-user-history';
import { SearchUserHistoryService } from '@services/search-user-history.service';
import { TypeFitlter } from '@api/models/entities/search-advanced.interface';
import { UserStateService } from '@services/user-state.service';
import { UtilsService } from '@app/shared/utils/utils.service';
import { PopupService } from '@app/shared/utils/popup.service';

@Component({
  selector: 'app-search-v2',
  templateUrl: 'search-v2.component.html',
  styleUrls: ['search-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchV2Component implements OnInit, AfterViewInit  {
  @ViewChild('searchInput', { static: false }) private _inputElement: ElementRef;
  currentRoute = '';
  @Input() active: boolean;
  @Input() classType: string = 'default';
  @Input() isMobile: boolean = false;
  @Input() showFilter: boolean = false;
  @Input() isMobileWebView: boolean;
  @Output() emitOption: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitToggleFilter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closeComponent: EventEmitter<void> = new EventEmitter<void>();

  toogleFocusValue: boolean;
  searchHistoryActive: boolean;
  searchTerm: ISearchUserHistory;
  public _isOpenOption: boolean = false;
  set isOpenOption(value: boolean) {
    this._isOpenOption = value;
  }
  get isOpenOption(): boolean {
    return this._isOpenOption;
  }
  constructor(
    public advanceSearchService: AdvanceSearchService,
    public searchUserService: SearchUserHistoryService,
    private activatedRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private userService: UserStateService,
    public popupService: PopupService,
    private router: Router,
    private utilsService: UtilsService
  ) { }

  ngAfterViewInit(): void {
    if(!this.isMobileWebView){
      this._inputElement.nativeElement.focus();
    }
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((param) => {
      this.advanceSearchService.searchText = param.q;
    });
    //set default when is bible Site
    const currentRouter = this.router.url.split('/')[1];
    let isBibleSite = currentRouter.includes('bible');
    if (isBibleSite) {
      let item = this.listFilters.find(x => x.type == 'all');
      this.changeFilter(item);
      this.isOpenOption = false;
    }
    if (this.isMobileRoute()) {
      this.searchUserService.addUserSearchForWebView(this.advanceSearchService.searchText, this.utilsService.getValueParamFromUrl('userId'))
        .subscribe(res => {
          this.searchTerm = res;
          this.changeDetector.detectChanges();
        });
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    const path = event.composedPath ? event.composedPath() : (event as any).path;
    if (!!path && this.active) {
      const selection = path.find(
        (paths: any) =>
        !!paths.classList &&
          !!paths.classList.length &&
          (paths.classList.contains('content-filter') ||
           paths.classList.contains('header-option') ||
           paths.classList.contains('header-link'))
      );
      if (!selection) {
        this.searchHistoryActive = false;
        this.sendOption('');
      }
      const isOutsideDropdown = path.find(
        (paths: any) =>
        !!paths.classList &&
          !!paths.classList.length &&
          (paths.classList.contains('select-filter'))
      );
      if (!isOutsideDropdown) {
        this.isOpenOption = false;
      }
    }
  }

  @HostListener('document:scroll', ['$event'])
  onScroll(event: Event): void {
    const path = event.composedPath ? event.composedPath() : (event as any).path;
    if (!!path && this.active) {
      const isInsideComponent = path.some(
        (paths: any) =>
        !!paths.classList &&
          !!paths.classList.length &&
          (paths.classList.contains('content-filter') ||
           paths.classList.contains('header-option') ||
           paths.classList.contains('header-link') ||
           paths.classList.contains('select-filter'))
      );
      if (!isInsideComponent) {
        // Cerrar el componente usando la lógica existente
        this.searchHistoryActive = false;
        this.isOpenOption = false;
        this.sendOption('');
      }
    }
  }


  get listFilters(): TypeFitlter[] {
    return this.validateFiltersMobile(this.advanceSearchService.typesFilters);
  }

  get typeSelected(): TypeFitlter {
    return this.advanceSearchService.typeSelected;
  }

  set typeSelected(value: TypeFitlter) {
    this.advanceSearchService.typeSelected = value;
  }

  // Para B2C toca usar el authenticationService
  get isLogin(): boolean {
    return this.userService.isUserLoggedIn;
  }

  validateFiltersMobile(filters: TypeFitlter[]): TypeFitlter[] {
    if (this.utilsService.isMobileRoute) {
      filters.find(x => x.value == 'program').visible = false;
      filters.find(x => x.value == 'bible').visible = false;
    }
    return filters;
  }

  onEnter() {
    this.search();
    this.onCloseComponent();
  }

  search() {
    const searchText = this.advanceSearchService.searchText;
    if (!searchText || searchText.length < 2) { 
      return; 
    }
    if (this.isLogin) {
      const userId = this.utilsService.getValueParamFromUrl('userId');
      const addUserSearch = this.isMobileWebView
       ? this.searchUserService.addUserSearchForWebView(searchText, userId)
       : this.searchUserService.addUserSearch(searchText);
       addUserSearch.subscribe(res => {
        this.searchTerm = res;
        this.changeDetector.detectChanges();
       })
    }
    this.clearSearch();
  }

  clearSearch(): void {
    this.advanceSearchService.advancedSearchText();
    this.searchHistoryActive = false;
    this.isOpenOption = false;
    this.sendOption('');
  }

  onCloseComponent() {
    this.emitOption.emit('close');
  }

  public sendOption(action: string): void {
    this.emitOption.emit(action);
  }

  public changeFilter(item: TypeFitlter): void {
    this.typeSelected = item;
    this.advanceSearchService.changeCategory();
    this.toggleOpenOption();
  }

  public toggleOpenOption(): void {
    this.isOpenOption = !this.isOpenOption;
  }

  public toggleFilter(): void {
    this.emitToggleFilter.emit(true);
  }

  public toogleFocus(tactive: boolean = true): void {
    this.toogleFocusValue = tactive;
    this.searchHistoryActive = tactive;
  }

  public toogleBlur(): void {
    this.toogleFocusValue = false;
  }

  public research(term: string) {
    this.searchHistoryActive = false;
    this.advanceSearchService.searchText = term;
    this.search();
  }

  public isBibleSite(item: TypeFitlter) {
    const currentRouter = this.router.url.split('/')[1];
    let isBibleSite = currentRouter.includes('bible');
    if (item.type == 'all' && isBibleSite) {
      this.changeFilter(item);
    }
    if (item.type == 'bible' && isBibleSite) {
      return false;
    }
    return true;
  }

  isMobileRoute() {
    return this.utilsService.isMobileRoute;
  }

}
