import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {IContentSocialInfoViewModel} from '@interfaces/i-content-social-info-view-model';
import {GenericContentFollow, GenericContentFollowTemplate} from '@interfaces/i-follow';
import {ILaunchbookResponseModel} from '@api/models/v2/i-launchbook-response-model';
import {IAudiobookViewModel} from '@api/models/v2/i-audiobook-view-model';
import {AudiobookService} from '@api/v2/audiobook.service';
import {ContentV2Service} from '@api/v2/content-v2.service';
import {CatalogV2Service} from '@api/v2/catalog-v2.service';

import {CanRefreshTokenService} from '@app/shared/utils/can-refresh-token.service';
import {BreadcrumbService} from '@shared/utils/breadcrumb.service';
import {ColorBreadCrumb} from '@enums/type-color-breadcrumb.enum';
import {TypeContent} from '@enums/type-features-content.enum';
import {RoutesApp} from '@enums/type-routes-app.enum';
import { LoadingOverlayService } from '@app/shared/services/loading-overlay.service';

@Component({
  selector: 'app-audiobook-listener',
  templateUrl: './audiobook-listener.component.html',
  styleUrls: ['./audiobook-listener.component.scss']
})
export class AudiobookListenerComponent implements OnInit, OnDestroy {

  socialInfo: IContentSocialInfoViewModel;
  preloading = false;
  toolTipFollow = 'tooltip';
  color = 'red';
  bookSrc: string;
  urlSafe: SafeResourceUrl;
  contentFollowList: GenericContentFollow;
  _showComponent: boolean = false;

  private _bookId: string;
  public audiobook: IAudiobookViewModel;

  @Input()
  set bookId(bookId: string) {
    if (bookId) {
      this._bookId = bookId;
      this.loadAudioBookDetail();
    }
  }

  get bookId(): string {
    return this._bookId;
  }

  constructor(
    public activeModal: NgbActiveModal,
    public sanitizer: DomSanitizer,
    private contentService: ContentV2Service,
    private breadCrumbService: BreadcrumbService,
    private eService: AudiobookService,
    private catalogService: CatalogV2Service,
    private canRefreshTokenService: CanRefreshTokenService,
    private loadingOverlayService: LoadingOverlayService
  ){}

  ngOnInit() {
    this.breadCrumbService.change({ title: TypeContent.MY_LIBRARY, firstLink: RoutesApp.LIBRARY, color: ColorBreadCrumb.BLUE })
  }

  loadAudioBookDetail(): void {
    this.loadingOverlayService.showLoadingOverlay();
    this.eService.view({audiobookId: this.bookId})
      .then((audiobookViewModel: IAudiobookViewModel) => {
        this.audiobook = audiobookViewModel;
        this.getBookSource();
        this.loadFollowList();
        this.loadSocialInfo();
        this.loadingOverlayService.hideLoadingOverlay();
      }, (error) => {
        console.error(error);
        this.loadingOverlayService.hideLoadingOverlay();
      });
  }

  getBookSource(): void {
    this.eService.launchAudiobook( { bookId: this.bookId, pageNumber: null }).then( (res: ILaunchbookResponseModel) => {
      if(res.code == '200') {
        this.canRefreshTokenService.stopRefreshToken();
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(res.readerUrl);
      }
    });
  }

  updatePPVContentInfoCatalogCache(contentId: string) {
    this.eService.view({ audiobookId: contentId}).then((audiobook: IAudiobookViewModel) => {
      //Update catalog cache
      this.catalogService.updatePPVContentInfo(audiobook);
      //Update current audiobook listener
      this.audiobook.acquired = audiobook.acquired;
      this.audiobook.expireDate = audiobook.expireDate;
      this.audiobook.validDays = audiobook.validDays;
      this.audiobook.ppv = audiobook.ppv;
    });
  }

  loadSocialInfo(): void {
    this.socialInfo = <IContentSocialInfoViewModel>{isFollow: false};
    this.contentService.getSocialInfo(this.bookId).then((socialInfo: IContentSocialInfoViewModel) => {
      this.socialInfo = socialInfo;
      this.preloading = false;
    });
  }

  loadFollowList() {
    const followList = new GenericContentFollow();
    if (this.audiobook.topicId) {
      const topic = new GenericContentFollowTemplate('Tema', 'topic');
      topic.list.push({
        name: this.audiobook.topicName,
        id: this.audiobook.topicId,
        isFollowed: false
      });
      followList.template.push(topic);
    }
    if (this.audiobook.hostsList && this.audiobook.hostsList.length) {
      const hosts = new GenericContentFollowTemplate('Autor', 'person');
      this.audiobook.hostsList.forEach(item => {
        if (item.published) {
          hosts.list.push({
            name: item.fullName,
            id: item.id,
            isFollowed: false
          });
        }
      });
      if (hosts.list.length) {
        followList.template.push(hosts);
      }
    }
    if (followList.template.length) {
      this.contentFollowList = followList;
      this._showComponent = true;
    }
  }

  ngOnDestroy(): void {
    this.breadCrumbService.clear();
    this.canRefreshTokenService.clearKey();
  }
}
