<div class="indexer-wrapper">
  <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 indexer" [ngClass]="{'last': isLast, 'even': isEven, 'odd': isOdd}" id="{{index}}">
    <div class="indexer-shadow"></div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 indexer-header">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 indexer-title" [ngClass]="{'fromSearch': fromSearch}">
            <i class="iconlace iconlace-narrated-bible"></i>
            <p class="indexer-title" [ngClass]="{'fromSearch': fromSearch}">
              <strong>VERSÍCULOS</strong>
            </p>
            <i class="iconlace iconlace-cross" (click)="toggleIndexes()"></i>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 indexer-search"
               *ngIf="!fromSearch && indexerSearch && indexerSearch.length > 0">
            <input #searchText type="text" name="search" [(ngModel)]="search" (keyup.enter)="filterIndexes()"/>
            <i class="iconlace iconlace-cross indexer-close-button" *ngIf="showIndexesClose" (click)="clearIndexes()"></i>
            <i class="iconlace iconlace-search indexer-search-button" (click)="filterIndexes()"></i>
          </div>
          <!-- <i class="iconlace iconlace-search" *ngIf="!isSearchOpen" (click)="toggleSearch()"></i> -->
        </div>
      </div>
    </div>
    <div class="row" style="height: 100%; position: relative;">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 indexer-body csb" #transcripcions>
        <div *ngIf="indexer && indexer.length === 0" class="row">
          <p>No se encontraron resultados para "<strong>{{search}}</strong>" dentro de los versículos.</p>
        </div>
        <div *ngIf="!indexer" class="row">
          <p>Buscando versículos ...</p>
        </div>
        <div id="indexTime{{index.startTime | timeFormatSec}}" *ngFor="let index of indexer" class="row" (click)="onClickIndexer(index)">
          <div class="{{'text-indent-' + index.tabs}}">
            <p class="indexer-duration">{{index.startTime | timeFormat}}</p>
            <p>
              <strong>{{index.title}}</strong>
            </p>
            <p [innerHTML]="index.lines[0] | highlight: search"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
