<div class="like-container-component"
     [ngClass]="{'active' : socialInfo?.isLike}">

  <a [ngStyle]="{'cursor': requestingLike ? 'wait' : 'pointer' }"
     (click)="trackLike(true)"
     class="button-link">
      <i [ngClass]="{'iconlace-heart': !socialInfo?.isLike, 'iconlace-heart-solid': socialInfo?.isLike}"
         class="iconlace"></i>
    <span *ngIf="!onlyIcon">
      ME GUSTA
    </span>
  </a>
</div>
