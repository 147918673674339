export interface TypeFitlter {
  value: string;
  label: string;
  type: string;
  icon: string;
  routeKey: string;
  visible: boolean;
}

/*
  {value: 'ebook', label: 'Libros Digitales', type: 'ebook'},
   {value: 'audiobook', label: 'Audiolibros', type: 'audiobook'},
   {value: 'narrated-bible', label: 'Biblia', type: 'narrated-bible'}
*/
export const SEARCH_FILTER: TypeFitlter[] = [
  {
    value: 'all',
    label: 'Todo el sitio',
    type: 'all',
    icon: 'iconlace-home',
    routeKey: '',
    visible: true,
  },
  {
    value: 'video',
    label: 'Videos',
    type: 'video',
    icon: 'iconlace-video',
    routeKey: '',
    visible: true,
  },
  {
    value: 'program',
    label: 'Programas',
    type: 'program',
    icon: 'iconlace-program',
    routeKey: 'programs',
    visible: true,
  },
  {
    value: 'article',
    label: 'Artículos',
    type: 'article',
    icon: 'iconlace-article',
    routeKey: 'articles',
    visible: true,
  },
  {
    value: 'library',
    label: 'Librería',
    type: '',
    icon: 'iconlace-bookshop',
    routeKey: 'library',
    visible: true,
  }, //** TODO */
  {
    value: 'podcast',
    label: 'Podcasts',
    type: 'podcast',
    icon: 'iconlace-podcast',
    routeKey: 'podcast',
    visible: true,
  },
  {
    value: 'organization',
    label: 'Ministerios',
    type: 'organization',
    icon: 'iconlace-ministries',
    routeKey: 'organizations',
    visible: false,
  }, //** TODO */
  {
    value: 'people',
    label: 'Personas',
    type: 'people',
    icon: 'iconlace-people',
    routeKey: 'people',
    visible: false,
  }, //** TODO */
  {
    value: 'ebook',
    label: 'Libros Digitales',
    type: 'ebook',
    icon: '',
    routeKey: '',
    visible: false,
  },
  {
    value: 'audiobook',
    label: 'Audiolibros',
    type: 'audiobook',
    icon: '',
    routeKey: '',
    visible: false,
  },
  {
    value: 'bible',
    label: 'Biblia',
    type: 'bible',
    icon: 'iconlace-bible',
    routeKey: 'bible',
    visible: true,
  }
];
