export enum ColorBreadCrumb {
  GREEN_LIGHT = '#39CB9A',
  PURPLE = '#6C00C0',
  LILA = '#809AFF',
  BLUE_LIGHT = '#0AAEDE',
  BLUE = '#0035FF',
  YELLOW_LIGHT = '#F9B000',
  BLUE_SHADE = '#5B699A',
  NEUTRO = '#667399',
  WHITE= '#FFFFFF'
}
