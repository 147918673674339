<div class="body-subscription">
  <div class="body-subscription-title">Método de Pago</div>
  <div class="body-options">
    <div class="international option {{style}}" [ngClass]="{'no-border-bottom': (enableDLocal || enablePaypal)}">
      <div class="option-header">
        <label class="method" for="gateway-international">
          <input type="radio" name="gateway-card" id="gateway-international"
            [checked]="isInternationalOption" (click)="handleGateway(gatewayTypes.NewInternationalCreditCard)">
          <span class="checkmark {{style}}"></span>
          <span class="custom-radio">Tarjeta de débito / crédito internacional</span>
        </label>
        <div class="option-img">
          <img src="/assets/img/payment/international-cards.webp" alt="Tarjeta">
        </div>
      </div>
      <div class="option-form" *ngIf="isInternationalOption">
        <app-international-card-form-inline
          [countries]="countries"
          [style]="style"
          [showEmail]="showEmail"
          (onSubmitCard)="submitCard($event)">
        </app-international-card-form-inline>
      </div>
    </div>
    <div class="local option {{style}}" *ngIf="enablePaypal">
      <div class="option-header paypal">
        <label class="method" for="gateway-paypal">
          <input type="radio" name="gateway-card" id="gateway-paypal"
            [checked]="isPaypalOption" (click)="handleGateway(gatewayTypes.PayPal)">
          <span class="checkmark {{style}}"></span>
          <span class="custom-radio">Paypal</span>
        </label>
        <div class="option-img paypal">
          <img class="img-paypal" src="/assets/img/payment/logo-paypal.webp" alt="paypal">
        </div>
      </div>
      <div class="option-form" *ngIf="isPaypalOption">
        <div class="help-text">
          Serás dirigido a la página de PayPal para realizar el proceso de pago.
        </div>
      </div>
    </div>
    <!-- Disabled DLocal option -->
    <div class="local option {{style}}" *ngIf="enableDLocal">
      <div class="option-header">
        <label class="method" for="gateway-local">
          <input type="radio" name="gateway-card" id="gateway-local"
            [checked]="isDlocalOption" (click)="handleGateway(gatewayTypes.NewLocalCreditCard)">
          <span class="checkmark {{style}}"></span>
          <span class="custom-radio">Tarjeta de débito / crédito local</span>
        </label>
        <div class="option-img local">
          <!-- <img *ngFor="let image of paymentMethodProvider.paymentMethods" class="img-local"
            [src]="image.logo" [alt]="image.name"> -->
        </div>
      </div>
      <div class="option-form" *ngIf="isDlocalOption">
        <app-dlocal-form-inline
          [countries]="countries">
        </app-dlocal-form-inline>
      </div>
    </div>
    <div class="help-text" *ngIf="!isHidden">*Este será tu medio de pago primario.</div>
  </div>
</div>
