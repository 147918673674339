<div class="fluid-container">
  <div class="fluid-container slider-container {{className}}" [ngClass]="getCatalogType()" #slider>
    <div class="row slide-header" [class.hidden]="!showHeader">
      <div
      class="col-xs-6 col-sm-6 slide-title {{typeSection}}"
      [ngClass]="{'col-md-12 col-lg-12': sectionTypeContent}"
      [ngClass]="{'col-md-6 col-lg-6': !sectionTypeContent}">
        <h3 class="b-title"
            [class.article]="isArticle"
            [class.seasons]="seasons"
            [class.myContent]="sectionTypeContent"
            [class.library]="sectionTypeLibrary"
            [ngStyle]="{
              'cursor': !sectionTypeContent ? 'pointer' : ''
            }"
            *ngIf="(catalog?.id | isEmptyGuid) !== null"
            >
            <i *ngIf="showMenuIcon" [class]="'icon-menu-section' | NameMenuClassIcon:catalog?.name"></i>
            <span class="isotipo catalog"></span>
            {{catalog?.name}}
            <ng-container *ngIf="sectionTypeContent; else hoverSeeMore">
              <span class="slide-see-all {{typeSection}}" *ngIf="showRight" (click)="goToCatalogPage()"> Ver más <i class="iconlace iconlace-chevron-next"></i></span>
            </ng-container>
            <ng-template #hoverSeeMore>
              <span *ngIf="!contentTracking?.isViewTracking" class="slide-see-all" (click)="goToCatalogPage()"> Ver todo <i class="iconlace iconlace-chevron-next"></i></span>
            </ng-template>
        </h3>
        <h3 class="b-title"
            [class.article]="isArticle"
            [class.seasons]="seasons"
            *ngIf="(catalog?.id | isEmptyGuid) === null && !catalog?.isMyList">
            <span class="isotipo catalog"></span>
            {{catalog?.name}}
        </h3>
        <h3 class="b-title"
            [class.article]="isArticle"
            [class.seasons]="seasons"
            style="cursor: pointer;"
            *ngIf="(catalog?.id | isEmptyGuid) === null && catalog?.isMyList"
            (click)="goToMyList()">
            <span class="isotipo catalog"></span>
            {{catalog?.name}}
            <span class="slide-see-all"> Ver todo</span>
        </h3>
      </div>
      <app-slider-filter class="slider-filter-container" #slideFilter (filter)="changeFilter($event)"></app-slider-filter>
    </div>
    <div class="slider-body">
      <!-- New Slider -->
      <div class="slider-controls" *ngIf="showArrows" [ngClass]="{'mobile': isMobile}">
          <button n-submit [DISABLE_TIME]="1000" (click)="left(items)" *ngIf="x < 0"
            class="slider-controls-left"
            [ngClass]="{'mobile': isMobile, 'controls-vertical': catalog && catalog.type && catalog.type.toLowerCase() !== 'horizontal'}">
            <span [ngClass]="{'mobile': isMobile}"><i class="iconlace iconlace-chevron-previous"></i></span>
          </button>
          <button n-submit [DISABLE_TIME]="1000" (click)="right(items)"
            class="slider-controls-right"
            [ngClass]="{'mobile': isMobile, 'controls-vertical': catalog && catalog.type && catalog.type.toLowerCase() !== 'horizontal'}">
            <span [ngClass]="{'mobile': isMobile}"><i class="iconlace iconlace-chevron-next"></i></span>
          </button>
      </div>
      <ul #items id="catalog{{catalog?.id}}" class="programs-slide slider" [class.isArticle]="isArticle" [class.landing]="isLanding" [@slide]="{value: activePane, params: {x: x}}">
        <li #item (mouseover)="mouseOverItem(content)" (mouseleave)="mouseOutItem(content)" [class.noPadding]="noPadding"
            *ngFor="let content of catalogItems;let last = last; let first = first; let i = index" class="slider-item"
            [ngClass]="{
              'home-vertical': catalog && catalog.type && catalog.type.toLowerCase() !== 'horizontal',
              'mobile': isMobile,
              'selected': content.isShow,
              'selected-vertical': content.isShow && catalog && catalog.type && catalog.type.toLowerCase() !== 'horizontal',
              'book': content.type === 'ebook' || content.type === 'audiobook',
              'landing': isLanding,
              'people': isPeople,
              'article': isArticle,
              'library': sectionTypeLibrary
              }">

          <div *ngIf="content.type !== 'ebook' && content.type !== 'audiobook'">
            <a class="slider-info" *ngIf="!content?.preLoad" [ngClass]="{'preload': content.loaded}">
              <app-ppv-label-indicator [content]="content" [parentShowingPPV]="parentShowingPPV"></app-ppv-label-indicator>
              <app-ppv-remaining-indicator [content]="content" [parentShowingRental]="parentShowingRental"></app-ppv-remaining-indicator>
              <app-slider-image *ngIf="!preloading || content.loaded"
                                (click)="navigateFromSeason(content)"
                                [isSeason]="seasons"
                                [isMySeen]="catalog.isMySee || catalog.isContinueWatching" [isMyList]="catalog.isMyList"
                                [catalogType]="catalog?.defaultContentType" [contentCatalog]="content"
                                [isViewTracking]="contentTracking?.isViewTracking"></app-slider-image>
              <div class="slider-info-detail" (click)="navigate(content); $event.stopPropagation()"
                   [ngClass]="{'mobile': isMobile, 'article': content.type === 'article'}">
                <div class="slider-clickable"></div>
                <div class="slider-info-detail-play content" (click)="goToContent(content); $event.stopPropagation()" *ngIf="content?.type !== 'video'"></div>

                <div  class="slider-info-detail-play" *ngIf="content?.type === 'video'">
                  <img src="assets/img/icons/play_thumb.svg" placement="top"
                       container="body">
                </div>

                <div class="slider-info-detail-actions" [ngClass]="content.type"
                     [ngStyle]="{'bottom': ((catalog.isMySee || catalog.isContinueWatching) || catalog.isMyList) ? '10px' : '30px'}">
                  <ul class="slider-info-detail-actions-list" *ngIf="!seasons">
                    <li class="slider-info-detail-actions-list_item" *ngIf="overSocialInfo && content?.type === 'video' && !contentTracking?.isViewTracking">
                      <i class="iconlace iconlace-list like" (click)="addRemoveToWhatchList(content)"
                         [ngStyle]="{'cursor': enabledList? 'pointer' : 'wait' }"
                         [ngClass]="{'active' : overSocialInfo?.isMyContent}"></i>
                    </li>
                    <li class="slider-info-detail-actions-list_item" *ngIf="content.type != 'article' && overSocialInfo && showHeart">
                      <!-- <app-video-like [socialInfo]="socialInfo"></app-video-like> -->
                      <i class="iconlace like" (click)="trackLike(content)"
                         [ngStyle]="{'cursor': enabledLike? 'pointer' : 'wait' }"
                         [ngClass]="{'iconlace-heart-solid': overSocialInfo?.isLike, 'iconlace-heart': !overSocialInfo?.isLike, 'active' : overSocialInfo?.isLike}"></i>
                    </li>
                    <li class="slider-info-detail-actions-list_item unfollow-container" *ngIf="contentTracking">
                      <i *ngIf="contentTracking?.popup"
                        class="iconlace iconlace-cross unfollow unfollow-{{content.type}}" (click)="unfollowItem($event, content)"
                        [ngbTooltip]="contentTracking?.popup"
                        container="body"
                        placement="top"
                        [ngStyle]="{'cursor': enabledLike? 'pointer' : 'wait' }"></i>
                    </li>
                  </ul>
                </div>

                <div class="slider-info-detail-text" [ngClass]="content.type">
                  <div class="slider-info-detail-text_person" [ngClass]="content.type">
                    <img
                      [src]="content.hostsList && content.hostsList.length > 0 && content.hostsList[0].image != null && content.hostsList[0].image != '' ? content.hostsList[0].image : 'assets/img/icons/user.svg'"/>
                  </div>
                  <div class="slider-info-detail-text_title" *ngIf="content.topTitle"
                       [ngClass]="content.type">{{content.topTitle}}</div>
                  <div class="slider-info-detail-text_title" [ngClass]="content.type" (click)="navigateFromSeason(content)">{{content.title}}</div>
                  <div class="slider-info-detail-text_description" *ngIf="seasons" (click)="navigateFromSeason(content)">{{content.description | cuttext: 200}}</div>
                  <div class="slider-info-detail-text_description" *ngIf="content?.type === 'video' && !seasons">{{content.description}}</div>
                <div class="slider-info-detail-text_description" *ngIf="seasons && content?.type != 'video'">{{content.description}}</div>
                  <!--div class="slider-info-detail-text_host" *ngIf="content.hostsList && content.hostsList.length > 0">{{content.hostsList[0].fullName}}</div-->
                  <p class="slider-info-detail-text_timestamp"
                     *ngIf="content.duration && !seasons">{{content.duration | timeHumanFormat}}</p>
                </div>


              </div>

              <ng-container *ngIf="!preloading && content && content.percent > 0">
                <app-video-progress-bar
                  style="left: 2px;bottom: 0;position: absolute;width: 100%;"
                  #progress
                  [percent]="content?.percent">
                </app-video-progress-bar>
              </ng-container>
            </a>
          </div>

          <!--------------------------     Book  ----------------------->
          <div *ngIf="content.type === 'ebook'">
            <a class="slider-info book" *ngIf="!content?.preLoad" [ngClass]="{'preload': content.loaded}"
               (click)="goToContent(content)">
              <div class="slider-info-detail book"
                   [ngClass]="{'mobile': isMobile, 'article': content.type === 'ebook'}">
                  <app-slider-book-item
                    class="slider-clickable"
                    [ngClass]="{'disabledOnClick': loadingModal}"
                    (unfollowEbook)="unfollowItem($event, content)"
                    (onClickCaratuta)="loadingModal = true; show(content);"
                    [book]="content"
                    [popupText]="contentTracking?.popup"></app-slider-book-item>
              </div>
            </a>
          </div>
          <!--------------------------     End book  ----------------------->

          <!--------------------------     Audiobook  ----------------------->
          <div *ngIf="content.type === 'audiobook'">
            <a class="slider-info audiobook" *ngIf="!content?.preLoad" [ngClass]="{'preload': content.loaded}"
               (click)="goToContent(content)">
              <div class="audiobook"
                   [ngClass]="{'mobile': isMobile, 'article': content.type === 'audiobook'}">

                  <app-slider-audiobook-item
                    class="slider-clickable"
                    [ngClass]="{'disabledOnClick': loadingModal}"
                    (unfollowAudioBook)="unfollowItem($event, content)"
                    (onClickCaratuta)="loadingModal = true; show(content)"
                    [audiobook]="content"
                    [popupText]="contentTracking?.popup"></app-slider-audiobook-item>
              </div>
            </a>
          </div>
          <!--------------------------  End audiobook  ----------------------->
        </li>

        <!-- Content Preview -->
        <ng-container *ngIf="catalogItems.length === 0">
          <li class="slider-item" *ngFor="let some of [].constructor(10)"
            [ngClass]="{'book': paging.contentType === 'ebook' || paging.contentType === 'audiobook'}">

            <!-- Preview Ebook -->
            <div *ngIf="paging.contentType === 'ebook'">
              <a class="slider-info book preload">
                <div class="slider-info-detail book"
                    [ngClass]="{'mobile': isMobile, 'article': paging.contentType === 'ebook'}">
                    <app-slider-book-item class="slider-clickable"></app-slider-book-item>
                </div>
              </a>
            </div>
            <!-- Preview End Ebook -->

            <!-- Preview Audiobook -->
            <div *ngIf="paging.contentType === 'audiobook'">
              <a class="slider-info audiobook">
                <div class="audiobook"
                     [ngClass]="{'mobile': isMobile, 'article': paging.contentType === 'audiobook'}">
                    <app-slider-audiobook-item class="slider-clickable"></app-slider-audiobook-item>
                </div>
              </a>
            </div>
            <!-- Preview End Audiobook -->

            <div *ngIf="paging.contentType !== 'ebook' && paging.contentType !== 'audiobook'">
              <a class="slider-info">
                <div class="preload-content" [ngClass]="{
                  'preload-content--podcast': paging.contentType === 'podcast',
                  'preload-content--default': paging.contentType !== 'podcast'
                }"></div>
              </a>
            </div>
          </li>
        </ng-container>
        <!-- Content End Preview -->
      </ul>
      <!-- End New Slider -->
    </div>

  </div>
</div>
<app-video-detail-inline [color]="color" [showStars]="showStars" #videoDetail [isMyList]="catalog.isMyList"
                         *ngIf="layout == 1 && catalog"
                         [isShow]="contentCatalogSelected?.isShow && catalog?.isShowDetail"
                         [contentCatalog]="contentCatalogSelected"
                         (closeDetail)="closeDetail()" [hiddeClose]="hiddeCloseDetail">
  <app-seasons *ngIf="programSeason && programSeason.length > 0 && catalog?.isShowDetail"
               [hostsList]="contentCatalogSelected?.hostsList" [programSeasons]="programSeason" [landingSeason]="landingSeason"
               [parentShowingPPV]="contentCatalogSelected?.type === 'program' && isPPV(contentCatalogSelected)"
               [parentShowingRental]="contentCatalogSelected?.type === 'program' && (contentCatalogSelected?.acquired && contentCatalogSelected?.expireDate != null)"></app-seasons>
</app-video-detail-inline>



