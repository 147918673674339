import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {

  currentYear: number = new Date().getFullYear();
  private hiddenRoutes: string[] = ['auth', 'mySubscription', 'myPaymentMethods', 'mobile'];
  private stickies: string[] = ['ofrendar'];
  private absolute: string[] = ['mytransactions'];
  
  constructor(private router: Router, private route: ActivatedRoute) {
  }

  get currentRoute(): string {
    return this.router.url.split('?')[0];
  }

  get queryParams(): string {
    return this.route.snapshot.queryParams['fromMobile'];
  }

  get isHiddenFooter(): boolean {
    const currentRoute = this.currentRoute;
    const queryParams = this.queryParams;

    // Agregar lógica adicional para ocultar el footer en una ruta específica con parámetros
    if (currentRoute === '/community' && queryParams === 'true') {
      return true;
    }

    return currentRoute.split('/').some((el) => this.hiddenRoutes.includes(el));
  }

  get isPositionSticky(): boolean {
    return this.currentRoute.split('/').some((el) => this.stickies.includes(el));
  }

  get isPositionAbsolute(): boolean {
    return this.currentRoute.split('/').some((el) => this.absolute.includes(el));
  }

}

