<div
  class="header-image"
  [ngStyle]="{ 'background-image': 'url(' + imageUrl + ')', 'pointer-events': onlyInfo ? 'none' : 'auto'}"
  [ngClass]="{ 'not-allowed': contentPPV !== null }"
  (click)="redirectToContent.emit()"
  >
  <ng-container  *ngIf="contentType === 'video' || contentType === 'program'">
    <ng-container *ngTemplateOutlet="headerVideo"></ng-container>
  </ng-container>

  <ng-container *ngIf="contentType === 'ebook'">
    <ng-container *ngTemplateOutlet="headerEbook"></ng-container>
  </ng-container>

  <ng-container *ngIf="contentType === 'podcast'">
    <ng-container *ngTemplateOutlet="headerPodcast"></ng-container>
  </ng-container>
</div>

<ng-template #headerVideo>
  <div class="header-gradient">
    <div class="header-image-container">
      <img class="header-title" [src]="contentDetail?.imageTitleUrl" />
      <app-header-video
        [contentDetail]="contentDetail"
        [contentType]="contentType"
        [onlyInfo]="onlyInfo"
        (rent)="rent.emit()"
        (purcharse)="purcharse.emit()"
        (redirectToContent)="redirectToContent.emit()">
      </app-header-video>
      <app-tag-progress-bar *ngIf="contentDetail?.consumption"
        [progress]="contentDetail.consumption"
        [classBottom]="'bottom'"
        [className]="'progressDefault'">
      </app-tag-progress-bar>
    </div>
  </div>
</ng-template>

<ng-template #headerEbook>
  <div class="header-ebook-container">
    <img class="header-ebook-container-image" [src]="imageUrl" />
    <app-header-ebook
      [contentDetail]="contentDetail"
      [onlyInfo]="onlyInfo"
      (rent)="rent.emit()"
      (purcharse)="purcharse.emit()"
      (redirectToContent)="redirectToContent.emit()"
      (redirectToFree)="redirectToFree.emit()"
      >
    </app-header-ebook>
      <div *ngIf="contentDetail?.percentage" class="header-ebook-container-progress">
        <app-tag-progress-bar
          [progress]="contentDetail.percentage"
          [className]="'progressEbook'"
          [classBottom]="'bottom'">
        </app-tag-progress-bar>
      </div>
  </div>
</ng-template>

<ng-template #headerPodcast>
  <div class="header-gradient podcast">
    <img class="header-gradient-image" [src]="imageUrl"/>
    <app-header-podcast
      (redirectToContent)="redirectToContent.emit()"
      >
    </app-header-podcast>
  </div>
</ng-template>
