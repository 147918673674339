<ng-container *ngIf="loading">
    <div class="more-info-skeleton">
        <div class="more-info-header" [ngClass]="{ animation: loading}"></div>
        <div class="more-info-body">
            <div class="more-info-ministry common-style" [ngClass]="{ animation: loading}"></div>
            <div class="more-info-name common-style" [ngClass]="{ animation: loading}"></div>
            <div class="more-info-season common-style" [ngClass]="{ animation: loading}" ></div>
            <div class="more-info-social common-style" [ngClass]="{ animation: loading}"></div>
            <div class="more-info-description common-style" [ngClass]="{ animation: loading}"></div>
            <div class="more-info-host common-style" [ngClass]="{ animation: loading}"></div>
            <div class="more-info-guest common-style" [ngClass]="{ animation: loading}"></div>
            <ng-container *ngIf="contentType === 'program'">
                <div class="more-info-dropdown common-style" [ngClass]="{ animation: loading}"></div>
                <div class="more-info-episode common-style" [ngClass]="{ animation: loading}"></div>
                <div class="more-info-first-episode">
                    <div class="more-info-first-episode-item">
                        <div class="more-info-first-episode-image" [ngClass]="{ animation: loading}"></div>
                        <div class="more-info-first-episode-detail">
                            <div class="more-info-first-episode-detail-duration common-style" [ngClass]="{ animation: loading}"></div>
                            <div class="more-info-first-episode-detail-title common-style" [ngClass]="{ animation: loading}"></div>
                        </div>
                    </div>
                    <div class="more-info-first-episode-description common-style" [ngClass]="{ animation: loading}"></div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>



