import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from "@angular/core";
import { Router } from "@angular/router";
import { IContentSocialInfoViewModel } from "@app/core/models/interfaces/i-content-social-info-view-model";
import {
  IPodcastItemViewModel,
  IPodcastViewModel,
} from "@app/core/models/interfaces/i-podcast-view-model";
import { IPagingRequestModel } from "@app/core/models/interfaces/ipaging-request-model";
import { PodcastItem, PodcastPlayerService } from "@app/shared/components/podcast-player/podcast-player.service";
import { ContentV3Service } from "@app/shared/services/content-v3.service";
import { PodcastV3Service } from "@app/shared/services/podcast-v3.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-podcast-detail-modal",
  templateUrl: "./podcast-detail-modal.component.html",
  styleUrls: ["./podcast-detail-modal.component.scss"],
})
export class PodcastDetailModalComponent
  implements OnInit, OnDestroy
{
  @ViewChild("podcastPlayer") podcastPlayerRef: ElementRef;


  @Input() podcastDetail: IPodcastViewModel;
  @Input() contentType: string;
  @Input() paginRequest: IPagingRequestModel;
  @Output() redirectToContent: EventEmitter<void> = new EventEmitter();
  @Output() closeModal: EventEmitter<void> = new EventEmitter();
  @Output() checkModalExceedsViewport: EventEmitter<void> = new EventEmitter();

  isPaging = false;
  isPlayer = false;
  isNewItem = false;
  isFollow: boolean = false;
  isNext = true;
  canPaging: boolean;
  totalEpisode: number;
  seasonEpisode = [];
  socialInfo: IContentSocialInfoViewModel;
  currentItem: IPodcastItemViewModel;
  currentItemCopy: IPodcastItemViewModel;
  modalElement = null;
  nextItem = null;

  private unlistener: () => void;
  private ngUnsubscribe = new Subject();

  enableScore = false;

  constructor(
    private podcastService: PodcastV3Service,
    private contentService: ContentV3Service,
    private podcastPlayerService: PodcastPlayerService,
    private router: Router,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.totalEpisode = this.podcastDetail.totalPodcastItems;
    this.seasonEpisode = this.podcastDetail.podcastItems;
    this.canPaging = this.totalEpisode > this.seasonEpisode.length;
    this.initPodcastState();
    this.getSocialInfo();
    this.modalElement = document.querySelector(".modal-detail-content");
    if (this.modalElement) {
      this.unlistener = this.renderer.listen(
        this.modalElement,
        "scroll",
        () => {
          this.scrollEvent();
        }
        );
    this.loadScoring();
    }

   this.podcastPlayerService.page$
   .pipe(takeUntil(this.ngUnsubscribe))
   .subscribe(index => {
      if (index) {
        this.nextItem = index;
        this.paginatePodcastList();
      }
    })

   this.podcastPlayerService.podcastItem$
   .pipe(takeUntil(this.ngUnsubscribe))
   .subscribe(
      (item) => {this.handlePodcastItem(item)
        this.loadScoring()
      }
    );

  }

  ngOnDestroy(): void {
    if (this.unlistener) {
      this.unlistener();
    }
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
    this.podcastPlayerService.clearPage();
    this.podcastPlayerService.clearState();
    this.currentItem = null;
    this.isPlayer = false;
    this.isNewItem = false;
    this.isNext = false;
  }

  handlePodcastItem(state: PodcastItem): void {
    if (state.currentItem) {
      this.currentItem = state.currentItem;
      this.isPlayer = state.playing;
      this.isNewItem = state.isNewItem;
      this.isNext = state.isNext;
      this.scrollEvent();
    }
  }

  scrollModal() {
    const scrollTop = this.modalElement.scrollTop;
    const scrollHeight = this.modalElement.scrollHeight;
    const offsetHeight = this.modalElement.offsetHeight;

    return {
      scrollTop,
      scrollHeight,
      offsetHeight,
    }
  }

  scrollEvent() {
    const { scrollTop, scrollHeight, offsetHeight } = this.scrollModal();
    const listItem = document.getElementById("list-podcast");
    const play = document.getElementById('podcast-player');
    const scrollPosition = offsetHeight + scrollTop;

    if (this.currentItem?.id !== this.currentItemCopy?.id && this.isNewItem) {
      this.currentItemCopy = this.currentItem;
      const addTop = this.isNext ? 200 : -200;
      // this.modalElement.scrollTo({ behavior: 'smooth', top: addTop + scrollTop, block: "end" });
    }

    if (play && this.isPlayer) {
      play.style.top = `${scrollPosition - 250}px`;
      listItem.style.marginBottom = `200px`;

      if (scrollHeight - (offsetHeight + scrollTop) <= 30) {
        play.style.position = 'absolute';
        play.style.bottom = `${0}px`;
        play.style.top = `auto`;
      } else {
        play.style.position = 'fixed';
      }
    }
  }

  initPodcastState(): void {
    this.podcastPlayerService.setPodcast({
      podcastItems: this.seasonEpisode,
      total: this.totalEpisode,
      canPage: this.canPaging,
    })
  }

  getSocialInfo(): void {
    this.contentService
      .getContentSocialInfo(this.podcastDetail.contentId, true)
      .subscribe((socialInfo: IContentSocialInfoViewModel) => {
        this.isFollow = socialInfo?.isFollow;
      });
  }

  paginatePodcastList(): void {
    if (this.isPaging || !this.canPaging) {
      return;
    }

    this.paginRequest.page++;
    this.pagePodcastList(this.paginRequest.page);
  }

  pagePodcastList(page: number): void {
    this.isPaging = true;
    this.paginRequest.page = page;
    this.podcastService
      .view(this.paginRequest)
      .subscribe((podcastViewModel: IPodcastViewModel) => {
        if (podcastViewModel.podcastItems.length === 0) {
          this.canPaging = false;
        }

        const newItems = podcastViewModel.podcastItems.map((item) => {
          return {
            ...item,
            playing: false,
          };
        });

        this.podcastDetail.podcastItems = [
          ...this.podcastDetail.podcastItems,
          ...newItems,
        ];
        this.seasonEpisode = this.podcastDetail.podcastItems;
        this.totalEpisode = podcastViewModel.totalPodcastItems;
        this.canPaging =
          this.totalEpisode > this.podcastDetail.podcastItems.length;
        this.initPodcastState();
        if (this.nextItem) {
          this.podcastPlayerService.next(this.nextItem);
          this.nextItem = null;
        }
        this.isPaging = false;
      });
    this.scrollEvent();
  }

  redirectOrganization() {
    if (this.podcastDetail.organizationPublished) {
      this.router.navigate([
        "/organization",
        this.podcastDetail.organizationId,
      ]);
      this.closeModal.emit();
    }
  }

  checkIfModalExceedsViewport(): void {
    this.checkModalExceedsViewport.emit();
  }

  loadScoring() {
    if(this.podcastDetail) {
      this.podcastDetail.podcastItems.forEach(x =>{
        if(x?.heardPosition/x?.durationSeconds > 0.90){
          this.enableScore = true;
        }
      });
    }
  }
}
