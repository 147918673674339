import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorage } from 'ngx-webstorage';

import { IUser } from '@interfaces/i-user.interface';
import { TypePropertyLocalStorage } from '@enums/type-property-localstorage.enum';
import { TokenAuth } from '@app/api-client/models/entities/auth.interface';
import { RoutesApp } from '@enums/type-routes-app.enum';
import { AuthService } from '@app/api-client/v3/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserCanLoginGuard implements CanActivate {

  @LocalStorage(TypePropertyLocalStorage.USER) private user: IUser;

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (next.queryParams && next.queryParams.fromMobile) {
      const mobile = next.queryParams.fromMobile.toLowerCase() === 'true';
      if (mobile) return true;
    }
    const token = this.authService.getToken();
    if (this.user && this.user.id && token && token?.jwtToken) {
      return this.router.navigate([RoutesApp.HOME]) && false;
    }
    return true;
  }
}
