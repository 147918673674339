import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AnimationBuilder, style, animate, AnimationPlayer } from '@angular/animations';


@Directive({
  selector: '[appPromotionAnimation]'
})
export class PromotionAnimationDirective implements OnInit{
  @Input('appAnimateOnParam') animationType: string;

  constructor(private el: ElementRef, private animationBuilder: AnimationBuilder) {}

  ngOnInit() {
    if (this.animationType === 'TRv3') {
      this.backInRight();
    }else{
      this.backInUp();
    }
  }

  backInRight() {
    const animation = this.animationBuilder.build([
      style({ transform: 'translateX(100%)' }),
      animate('500ms ease-out', style({ transform: 'translateX(0)' }))
    ]);

    const player: AnimationPlayer = animation.create(this.el.nativeElement);
    player.play();
  }

  backInUp() {
    const animation = this.animationBuilder.build([
      style({ transform: 'translateY(100%)' }), // Inicia oculto abajo
      animate('500ms ease-out', style({ transform: 'translateY(0)' })) // Se desplaza hacia arriba
    ]);

    const player: AnimationPlayer = animation.create(this.el.nativeElement);
    player.play();
  }
}
