import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SearchUserHistoryModule } from '@components/search-user-history/search-user-history.module';
import { InfoResultsComponent } from '@components/search-v2/info-results/info-results.component';
import { SearchV2Component } from '@components/search-v2/search-v2.component';
import { DirectivesModule } from '@directives/directives.module';

@NgModule({
  declarations: [SearchV2Component, InfoResultsComponent],
  imports: [CommonModule, FormsModule, SearchUserHistoryModule, DirectivesModule],
  exports: [SearchV2Component, InfoResultsComponent]
})
export class SearchV2Module { }
