import { ChangeDetectorRef, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import ePub from "epubjs";
import { BehaviorSubject } from "rxjs";
import { handledAsyncFunction } from "../utils/try-catch-handler";
import { UserStateService } from "@app/shared/services/user-state.service";
import { EbookApiService } from "./ebook-api.service";
import { EbookReader, ListHighlight, UserInteractions } from "@app/core/models/interfaces/ebook-reader";
import CryptoJS from "crypto-js";
@Injectable({ providedIn: "root" })
export class EbookBLService {

  private _ebook: any;
  private _rendition: any;
  private eventHandlers: { [key: string]: Function[] } = {};
  private renderer: Renderer2;

  private chapterChangedSource = new BehaviorSubject<string>("");
  chapterChanged$ = this.chapterChangedSource.asObservable();

  private progressSource = new BehaviorSubject<number>(0);
  progress$ = this.progressSource.asObservable();

  private isTextSelectedSource = new BehaviorSubject<boolean>(false);
  isTextSelected$ = this.isTextSelectedSource.asObservable();

  private isDeleteHighlightSubject = new BehaviorSubject<boolean>(false);
  isDeleteHighlight$ = this.isDeleteHighlightSubject.asObservable();

  private ebookId: string;
  private userId: string;
  private currentLocation = "";
  private ebookNavigation: UserInteractions;
  private _cfiRange = "";
  private _textSelect = "";
  private _listhighlights: ListHighlight[] = [];
  private _nameChapter: string;
  private cdr: ChangeDetectorRef;
  private furthestPages: { [ebookId: string]: string } = {};
  private lastfurthestPage: string;
  progress: number;

  private _palettePosition = { top: "0px", bottom: "", left: "0px", right: "" };
  selectedHighlightCfi: string;
  private touchStartX: number;
  private touchEndX: number;
  private _highlightedColor: "";

  constructor(
    private authService: UserStateService,
    private ebookApiService: EbookApiService,
    private rendererFactory: RendererFactory2
  ){
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  get ebookJS(): any {
    return this._ebook;
  }

  get renditionJS(): any {
    return this._rendition;
  }

  get metadata(): Promise<any> {
    return this.ebookJS.loaded.metadata;
  }

  get navigation(): Promise<any> {
    return this.ebookJS.loaded.navigation;
  }

  get isUserLoggedIn(): boolean {
    return this.authService.isUserLoggedIn;
  }

  get cfiRange(): string {
    return this._cfiRange;
  }

  get textSelect(): string {
    return this._textSelect;
  }

  get nameChapter(): string {
    return this._nameChapter;
  }

  get listhighlights(): ListHighlight[] {
    return this._listhighlights;
  }

  get highlightedColor(): string {
    return this._highlightedColor;
  }

  // Cambiar el nombre;
  get palettePosition(): any {
    // return this._palettePosition;
    const screenWidth = window.innerWidth;
    if (screenWidth < 767) {
      return {
        top: null,
        bottom: "0px"
      };
    }
    return this._palettePosition;
  }

  async initEbook(cdr: ChangeDetectorRef, ebook: EbookReader, ebookId: string, userId: string):Promise<void> {
    const decryptEbook = this.decrypt(ebook.key, ebook.cipherText);
    const file = Uint8Array.from(atob(decryptEbook), c => c.charCodeAt(0));
    this._ebook = ePub(file.buffer);
    this._rendition = this._ebook.renderTo("viewer", {
      width: "100%",
      height: "100%",
      flow: "paginated",
    });
    this.cdr = cdr;
    this.ebookId = ebookId;
    this.userId = userId;
    this.lastfurthestPage = ebook.userInteractions?.navigation.furthestPage;
    this.setupEvents();
    this.displayLastReadLocation(ebook.userInteractions?.navigation.currentNavigation);
    this.ValidateUserInteractions(ebook);
    await this.ebookJS.ready;
    await this.ebookJS.locations.generate(0);
    this.progressSource.next(ebook.userInteractions?.navigation.percentage);
    this.addEventListenerHooks();
  }

  private decrypt(keyHash: string, cipherText: any): string {
    const ivHash = 'A+wDX3O3ZO3bmtaj4zhJsw=='
    const keyBytes = Uint8Array.from(atob(keyHash), c => c.charCodeAt(0))
    const ivBytes = Uint8Array.from(atob(ivHash), c => c.charCodeAt(0))
    const cjsKey = CryptoJS.lib.WordArray.create(keyBytes);
    const cjsIV = CryptoJS.lib.WordArray.create(ivBytes);
  
    const decryptEbook = CryptoJS.AES.decrypt(
      cipherText, 
      cjsKey, 
      { iv: cjsIV }
    )
  
    return CryptoJS.enc.Base64.stringify(decryptEbook);
  }

  private ValidateUserInteractions(ebook: EbookReader): void {
    if (ebook.userInteractions) {
      this.ebookNavigation = ebook.userInteractions;
      if (this.ebookNavigation.highlights) {
        const highlights = Array.isArray(ebook.userInteractions?.highlights)
          ? ebook.userInteractions?.highlights
          : ebook.userInteractions?.highlights
          ? [ebook.userInteractions?.highlights]
          : [];
        this._listhighlights = highlights;
        if (this._listhighlights.length > 0) {
          this.getHighlight();
        }
      }
    } 
  }

  private setupEvents() {
    this.eventHandlers["relocated"] = [
      (location: any) => this.handleRelocated(location),
    ];
    this.eventHandlers["rendered"] = [() => this.handleRendered()];
    this.eventHandlers["selected"] = [
      (cfiRange: string, contents: any) =>
        this.handleSelected(cfiRange, contents),
    ];

    for (const [event, handlers] of Object.entries(this.eventHandlers)) {
      handlers.forEach((handler) => {
        this.renditionJS.on(event, handler);
      });
    }
  }

  private handleRelocated(location: any) {
    if (location) {
      this.currentLocation = location.start.cfi;
      this.currentNavigation();
      this.progressBar();
      this.getCurrentChapter(location.start.href);
      this.furthestPages[this.ebookId] = this.saveFurthestPage(location);
    }
  }

  private handleRendered() {
    // this.applyCustomStyles();
    const chapterCurrentLocation = this.renditionJS.currentLocation();
    if (chapterCurrentLocation) {
      this.getCurrentChapter(chapterCurrentLocation.start.href);
    }
    this.disableCopyInIframe();
  }

  private handleSelected(cfiRange: string, contents: any) {
    this._textSelect = contents.window.getSelection().toString();
    this._cfiRange = cfiRange;
    this.setPositionForColorPalette(contents);
    this.isTextSelectedSource.next(true);
    this.checkHighlightSelect(cfiRange);
    this.cdr.detectChanges();
  }

  display(location: string): void {
    location ? this.renditionJS.display(location) : this.renditionJS.display();
  }

  spread(isSinglePage: boolean): void {
    this.renditionJS.spread(isSinglePage ? "none" : "auto");
  }

  goToPreviousPage(): void {
    this._rendition.prev();
    // window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  goToNextPage(): void {
    this._rendition.next();
    // window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  cleanupEvents() {
    for (const [event, handlers] of Object.entries(this.eventHandlers)) {
      handlers.forEach((handler) => this.renditionJS.off(event, handler));
    }
  }

  saveEbookNavigation():void {
    const payload = {
      userId: this.userId,
      ebookId: this.ebookId,
      percentage: this.progressBar(),
      furthestPage: this.furthestPages[this.ebookId],
      currentNavigation: this.currentNavigation(),
    };
    this.ebookApiService.readerNavigation(payload).subscribe();
  }

  private currentNavigation(): string {
    if (this.isUserLoggedIn && this.renditionJS && this.renditionJS.location) {
      const currentNavigation = this.renditionJS.location.start.cfi;
      return currentNavigation;
    }
  }

  private progressBar(): number {
    if (this.isUserLoggedIn) {
      if (this.ebookJS.locations && this.currentLocation) {
        let currentProgress = this.ebookJS.locations.percentageFromCfi(this.currentLocation);
        if (currentProgress >= 0.995) currentProgress = 1;
        this.progress = +(currentProgress * 100).toFixed(2);
        this.progressSource.next(this.progress);
        return this.progress;
      }
    }
  }

  private displayLastReadLocation(currentNavigation: string): void {
    currentNavigation 
    ? this.renditionJS.display(currentNavigation) 
    : this.renditionJS.display();
  }

  private isValidFurthestPage(furthestPage: string | null): boolean {
    return furthestPage !== null && furthestPage.trim() !== '' && furthestPage !== '0';
  }

  private saveFurthestPage(location: any): string {
    const lastReadLocation = this.furthestPages[this.ebookId] || null;
    if (this.isValidFurthestPage(lastReadLocation)) {
      const lastPercentage = this.ebookJS.locations.percentageFromCfi(lastReadLocation);
      const currentPercentage = this.ebookJS.locations.percentageFromCfi(location.start.cfi);
      if (currentPercentage > lastPercentage) {
        this.furthestPages[this.ebookId] = location.start.cfi;
      }
    } else {
      this.furthestPages[this.ebookId] = this.lastfurthestPage ? this.lastfurthestPage : location.start.cfi;;
    }
    return this.furthestPages[this.ebookId];
  }

  public navigatefurthestPage(): void {
    const furthestLocation = this.furthestPages[this.ebookId];  
    if (furthestLocation) {
      this.renditionJS.display(furthestLocation);
    }
  }

  private async getCurrentChapter(href: string) {
    const [navigation] = await handledAsyncFunction<any, null>(this.navigation);
    const chapter = navigation.toc.find((ch: any) =>
      href.includes("#")
      ? href === ch.href
      : href === `${ch.href.split("#")[0]}`
    );
    if (chapter) {
      this.chapterChangedSource.next(chapter.label);
      this._nameChapter = chapter.label;
    }
  }

  setPositionForColorPalette(contents: any): any {
    const selection = contents.document.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      if (range) {
        const rect = range.getBoundingClientRect();
        return (this._palettePosition = {
          top: `${rect.top - 130}px`,
          bottom: null,
          left: null,
          right: null
        });
      }
    }
  }

  applyHighlightColor(color: string): void {
    const cfi = this.selectedHighlightCfi || this.cfiRange;
    if (!cfi) return;

    const highlights = this.listhighlights;
    const existingHighlight = highlights.findIndex((highlight) => highlight.cfi === cfi);
    const data = {
      cfi,
      color,
      position: this.palettePosition,
    }

    if (existingHighlight !== -1) {
      this.updateHighlight(data, highlights, existingHighlight);
    } else {
      this.createHighlight(data);
    }

    this.clearBoxShadow();
    this.clearSelection();
  }

  private createHighlight(data: any): void {
    this.applyHighlight(data.cfi, data.color);
    this.saveOrUpdateHighlight(data);
  }

  private updateHighlight(data: any, highlights: any[], existingHighlight: number): void {
    // Actualiza solo el color del resaltado existente
    highlights[existingHighlight].color = data.color;
    this.saveOrUpdateHighlight(data);
    this.renditionJS.annotations.remove(data.cfi, "highlight");
    this.applyHighlight(data.cfi, data.color);
  }


  private applyHighlight(cfi: string, color: string): void {
    this.renditionJS.annotations.highlight(
      cfi,
      {},
      (e: any) => {
        const highlights = this.listhighlights;
        const clickedHighlight = highlights.find((hl) => hl.cfi === cfi);
        if (clickedHighlight) {
          this.showColorPaletteForHighlight(e, clickedHighlight);
        }
      },
      "",
      {
        fill: color,
        "fill-opacity": "0.3",
        cursor: "pointer",
        "pointer-events": "auto",
      }
    );
  }

  private saveOrUpdateHighlight(data: any): ListHighlight  {
    const highlightData = {
      userId: this.userId,
      ebookId: this.ebookId,
      cfi: data.cfi,
      color: data.color,
      text: this.textSelect,
      nameChapter: this.nameChapter ? this.nameChapter : this.textSelect,
      createdData: new Date,
      position: data.position
    };
    if (this.listhighlights.some(highlight => highlight.cfi === data.cfi)) {
      this.ebookApiService.updateHighlight(highlightData).subscribe();
    } else {
      this.ebookApiService.saveHighlight(highlightData).subscribe();
      this.listhighlights.push(highlightData);
    }

    return highlightData;
  }

  private getHighlight(): void {
    const highlights = this.listhighlights;
    if (highlights && highlights.length >= 1) {
      highlights.forEach((highlight) => {
        this.renditionJS.annotations.highlight(
          highlight?.cfi,
          {},
          (e) => {
            this.showColorPaletteForHighlight(e, highlight);
          },
          "",
          {
            fill: highlight?.color,
            cursor: "pointer",
            "pointer-events": "auto",
          }
        );
      });
    }
  }
  
  private showColorPaletteForHighlight(event: MouseEvent | TouchEvent, highlight: any): void {
    event.preventDefault();
    event.stopPropagation();
    this._palettePosition = {
      top: `${highlight.position.top}`,
      bottom: null,
      left: `${highlight.position.left}`,
      right: null
    };
    this.selectedHighlightCfi = highlight.cfi;
    this._highlightedColor = highlight.color; 
    this.isTextSelectedSource.next(true);
    this.isDeleteHighlightSubject.next(true);
  }

  removeSelectedHighlight(): void {
    if (this.selectedHighlightCfi || this.cfiRange) {
      this.removeHighlight(this.selectedHighlightCfi || this.cfiRange);
    }
  }

  removeHighlight(cfi: string): void {
    const highlightIndex = this.listhighlights.findIndex(highlight => highlight.cfi === cfi);
    if (highlightIndex !== -1) {
      this.renditionJS.annotations.remove(cfi, "highlight");
      this.listhighlights.splice(highlightIndex, 1);
      const payload = {
        userId: this.userId,
        ebookId: this.ebookId,
        cfi: cfi
      };
      this.ebookApiService.deleteHighlight(payload).subscribe();
    }
    this.clearSelection();
  }

  clearSelection(): void {
    this._cfiRange = null;
    this.selectedHighlightCfi = null;
    this._highlightedColor = '';
    this.isTextSelectedSource.next(false);
    this.isDeleteHighlightSubject.next(false);
  }

  claerEbook(): void {
    this._listhighlights = [];
    this.furthestPages = {};
  }

  disableCopyInIframe(): void {
    const contents = this.renditionJS.getContents();
    contents.forEach((content: any) => {
      const iframeDocument = content.document;
      iframeDocument.addEventListener('copy', (event: ClipboardEvent) => {
        event.preventDefault();
      });
    });
  }

  checkHighlightSelect(cfiRange: string): void {
    const highlights = this.listhighlights;
    const isHighlightExists = highlights.some((highlight) => highlight.cfi === cfiRange);
    this.isDeleteHighlightSubject.next(isHighlightExists);
  }

  applyCustomStyles(): void {
    const iframe = document.querySelector('iframe');
    if (iframe) {
      const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;
      if (iframeDocument) {
        const styleElement = this.renderer.createElement('style');
        const styleContent = `
          a:hover {
            color: inherit !important;
          }
        `;
        const styleText = this.renderer.createText(styleContent);
        this.renderer.appendChild(styleElement, styleText);
        const head = iframeDocument.head;
        this.renderer.appendChild(head, styleElement);
      }
    }
  }

  addEventListenerHooks(): void {
    this._rendition.hooks.content.register((contents) => {
      const iframeDocument = contents.document;
      iframeDocument.addEventListener('contextmenu', this.handleContextMenu.bind(this), false)
      iframeDocument.addEventListener('touchstart', this.handleTouchStart.bind(this), false);
      iframeDocument.addEventListener('touchmove', this.handleTouchMove.bind(this), false);
      iframeDocument.addEventListener('touchend', this.handleTouchEnd.bind(this), false);
    });
  }

  handleContextMenu(event: Event): void {
    event.preventDefault();
  }

  handleTouchStart(event: TouchEvent): void {
    this.touchStartX = event.changedTouches[0].screenX;
  }

  handleTouchMove(event: TouchEvent): void {
    this.touchEndX = event.changedTouches[0].screenX;
  }

  handleTouchEnd(): void {
    const swipeThreshold = 50;
    if (this.touchEndX - this.touchStartX > swipeThreshold) {
      this.goToPreviousPage();
    } else if (this.touchStartX - this.touchEndX > swipeThreshold) {
      this.goToNextPage();
    }
  }

  removeEventListener(): void {
    this._rendition.hooks.content.register((contents) => {
      const iframeDocument = contents.document;
      iframeDocument.removeEventListener('contextmenu', this.handleContextMenu.bind(this), false)
      iframeDocument.removeEventListener('touchstart', this.handleTouchStart.bind(this), false);
      iframeDocument.removeEventListener('touchmove', this.handleTouchMove.bind(this), false);
      iframeDocument.removeEventListener('touchend', this.handleTouchEnd.bind(this), false);
    });
  }

  private clearBoxShadow(): void {
    const contents = this.renditionJS.getContents();
    contents.forEach((content: any) => {
      content.window.getSelection().removeAllRanges();
    });
  }

  setTextSelected(): void {
    this.clearSelection();
    this.clearBoxShadow();
  }

  setDeletehighlight(value: boolean): void {
    this.isDeleteHighlightSubject.next(value);
  }

  setPercentage(): void {
    const consumption = Math.round(this.progressBar()); 
    this.ebookApiService.setPercentage(this.ebookId, consumption).subscribe();
  }

}
 