import { Component, OnInit, Input } from '@angular/core';
import { AdvanceSearchService } from '@app/layout/components/search-advanced/services/advance-search.service';
import { HttpLoadingService } from '@utils/http-loading.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-info-results',
  templateUrl: 'info-results.component.html',
  styles: [`
    h3 { font-size: 20px; font-weight: 300; }
    h4 { font-size: 14px; }
    .content-result { padding-left: 15px; }
  `]
})

export class InfoResultsComponent {

  @Input() searchText = '';
  @Input() totalItems = 0;
  @Input() isLoading: boolean = false;

  constructor(public advanceSearchService: AdvanceSearchService,
    private httpLoadingService: HttpLoadingService) { }

  get isLoading$(): Observable<boolean> {
    return this.httpLoadingService.isLoading$;
  }
}