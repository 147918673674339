import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, EventEmitter, Input, Output,
  OnChanges, SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements OnChanges {

  @Input() isActive: boolean = false;
  @Input() showSecondButton: boolean = true;
  @Input() showButtons: boolean = true;
  @Input() width: number = 1000;
  @Input() widthPercentage: string = '90%';
  @Input() wrapperClass: string = '';
  @Input() contentClass: string = '';
  @Input() marginTop: string = '25vh';
  @Input() firstButton: string = 'ACEPTAR';
  @Input() isbtnPaypal: boolean = false;
  @Input() secondButton: string = 'CERRAR';
  @Output()
  closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  emitFirstButton: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  emitSecondButton: EventEmitter<boolean> = new EventEmitter<boolean>();

  public fadeOut: boolean = false;
  public showModal: boolean = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.isActive && changes.isActive.currentValue) {
      this.showModal = true;
      this.fadeOut = false;
    } else {
      this.fadeOut = true;
      this.showModal = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  get widthPx(): string {
    return `${this.width}px`;
  }

  public toggleModal(): void {
    this.closeModal.emit(true);
  }

  public actionFirstButton(): void {
    this.emitFirstButton.emit(true);
  }

  public actionSecondButton(): void {
    this.emitSecondButton.emit(true);
  }
}
