<div class="modal-close">
  <span class="close-button" (click)="close()">
    <span>&times;</span>
  </span>
</div>
<div class="content">
  <div class="info-title">
    Código de acceso
  </div>
  <div class="info-content">
    Ingresa aquí tu código de acceso para acceder a este contenido de manera
    gratuita.
  </div>
  <div class="info-form">
    <form
      [formGroup]="form"
      (ngSubmit)="sendFormData()"
      novalidate>
      <div class="form-group">
        <div class="form-input">
          <input
          formControlName="code"
          [class.is-invalid]="isInValid(codeField) || isInvalidCode"
          type="text"
          class="form-control"
          id="gift-code"
          placeholder="Ingresa tu código de acceso"
          autocomplete="off"
          (keyup)="keyFunc($event)"
          maxlength="6"
          required
          />
          <div *ngIf="isInvalidCode" class="help-block">
            <span>Parece que ingresaste un código de acceso inválido. Verifícalo e intenta de nuevo</span>
          </div>
        </div>
      <div class="info-button">
        <button
          type="submit"
          class="btn btn-primary send-button"
          [disabled]="formDisabled"
          [style.background]="getGradientBackground()">
          ENVIAR
        </button>
      </div>
      <div class="info-button">
        <button
          type="button"
          (click)="close();"
          class="btn btn-cancel">
          CANCELAR
        </button>
      </div>
      </div>
    </form>
  </div>
</div>
