import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorage } from 'ngx-webstorage';

import { IUser } from '@interfaces/i-user.interface';
import { TypePropertyLocalStorage } from '@enums/type-property-localstorage.enum';
import { RoutesApp } from '@enums/type-routes-app.enum';

@Injectable({
  providedIn: 'root',
})
export class UserCanSubscribeGuard implements CanActivate {

  @LocalStorage(TypePropertyLocalStorage.USER) private user: IUser;

  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.user
      ? this.user.isSuscriptionPlanEnabled
        ? this.router.navigate([RoutesApp.HOME]) && false
        : true
      : this.router.navigate([RoutesApp.HOME]) && false;
  }
}
