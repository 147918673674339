import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as ENV } from '@environment/environment';
import { Observable } from 'rxjs';
import { IFollowUnfollowViewModel } from '@interfaces/i-follow-unfollow-view-model';
import { IFollowViewModel } from '@interfaces/i-follow-view-model';


@Injectable({
  providedIn: 'root',
})
export class FollowV3Service {

  private API = `${ENV.webApi}v2/follow`;

  constructor(private http: HttpClient) {}

    public followUnFollow(payload: IFollowUnfollowViewModel, puserId?: string): Observable<boolean> {
      payload.userId = puserId;
      return this.http.post<boolean>(`${this.API}/followUnFollow`, payload);
    }

    public checkFollows(IFvm: IFollowViewModel, puserId?: string): Observable<IFollowViewModel> {
      const url = `${this.API}/check`;
      return this.http.get<IFollowViewModel>(url, {params: {resourceIds: IFvm.resourceIds, puserId}});
    }

    public checkManyFollows(IFvm: IFollowViewModel, puserId?: string): Observable<IFollowViewModel> {
      const url = `${this.API}/checkMany`;
      return this.http.get<IFollowViewModel>(url, {params: {resourceIds: IFvm.resourceIds, puserId}});
    }
    
}
