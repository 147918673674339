import {Injectable} from '@angular/core';
import {IRestMethod, RestAction, RestParams, RestRequestMethod} from 'rest-core';
import {environment} from '../../../environments/environment';
import {RestClient} from '../rest-client';
import {EnlaceRestHandler} from '../enlace-rest-handler';
import {AuthenticationService} from '../authentication.service';
import {IRequestCardViewModel} from '../models/v2/i-request-card-view-model';
import {IRequestDefaultCardViewModel} from '../../core/models/interfaces/i-request-default-card-model';
import {IRequestRemoveCardViewModel} from '../../core/models/interfaces/i-request-remove-card-model';
import {IRequestCreateCardViewModel} from '../../core/models/interfaces/i-request-create-card-model';
import { IRequestCompletePayPalTransaction } from '../models/v2/i-request-complete-paypal-transaction';
import { ICreateTaxTransactionRequest } from '../models/v2/i-create-tax-transaction-request';
import { ICreateTaxTransactionResponse } from '../models/v2/i-create-tax-transaction-response';

import {IResponseCardViewModel} from '@core/models/interfaces/i-response-card-view-model';



export enum PurchaseType {
  Purchase = 'Purchase',
  Rent = 'Rent',
  GIFT_CODE = 'gift-code'
}

@Injectable({
  providedIn: 'root'
})
@RestParams({
  // IRestParams
  pathPrefix: environment.webApi + 'v2/wallet'
})
export class WalletService extends RestClient {

  acquiredContent = [];

  @RestAction({
    path: '/GetUserCCs',
    method: RestRequestMethod.Get
  })
  getUserCreditCards: IRestMethod<{ userId?: string }, IResponseCardViewModel[]>;

  @RestAction({
    path: '/PayWithCC',
    method: RestRequestMethod.Post
  })
  PayWithCC: IRestMethod<IRequestCardViewModel, IResponseCardViewModel>;

  @RestAction({
    path: '/UpdateDefaultCard',
    method: RestRequestMethod.Post
  })
  UpdateDefaultCard: IRestMethod<IRequestDefaultCardViewModel, boolean>;

  @RestAction({
    path: '/RemoveCC',
    method: RestRequestMethod.Post
  })
  RemoveCard: IRestMethod<IRequestRemoveCardViewModel, boolean>;

  @RestAction({
    path: '/SaveCC',
    method: RestRequestMethod.Post
  })
  AddCard: IRestMethod<IRequestCreateCardViewModel, any>;

  @RestAction({
    path: '/GetPaymentMethods',
    method: RestRequestMethod.Get
  })
  GetPaymentMethods: IRestMethod<{userId: string, provider: string }, any>;

  @RestAction({
    path: '/CompletePayPalTransaction',
    method: RestRequestMethod.Post
  })
  CompletePayPalTransaction: IRestMethod<IRequestCompletePayPalTransaction, any>;

  @RestAction({
    path: '/previewTaxesCalculation',
    method: RestRequestMethod.Post
  })
  previewTaxesCalculation: IRestMethod<ICreateTaxTransactionRequest, ICreateTaxTransactionResponse>;

  @RestAction({
    path: '/createTaxTransaction',
    method: RestRequestMethod.Post
  })
  createTaxTransaction: IRestMethod<ICreateTaxTransactionRequest, ICreateTaxTransactionResponse>;


  constructor(restHandler: EnlaceRestHandler, authService: AuthenticationService) {
    super(restHandler, authService);
  }
}
