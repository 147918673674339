import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment as ENV } from '@environment/environment';

import { IFeatureFlagStatus } from '@interfaces/i-feature-flag.interface';
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagV3Service {

  private API = `${ENV.webApi}v2/EnableFeatures`;
  private featureStatus: IFeatureFlagStatus[] = [];

  constructor(private http: HttpClient) {}

  get areThereFeatures(): boolean {
    return this.featureStatus.length > 0;
  }

  public getAllFeaturesStatus(): Observable<IFeatureFlagStatus[]> {
    return this.http.get<IFeatureFlagStatus[]>(this.API + '/GetAllFeaturesStatus/')
    .pipe(tap(flags => this.featureStatus = flags));
  }

  public getFeatureStatus(id: any): Observable<boolean> {
    const result = this.http
      .get<boolean>(this.API + '/GetFeatureStatus/' + id);
    return result;
  }

  public checkFeatureOn(featureName: string): boolean {
    let feature: IFeatureFlagStatus;
    feature = this.featureStatus.find((item: IFeatureFlagStatus) => {
      return item.feature === featureName;
    });
    return feature && feature.status === true;
  }

  public fillFeaturesFlags(result: IFeatureFlagStatus[]): void {
    this.featureStatus = result;
  }

}
