import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'forsort',
})
export class NgForSortPipe implements PipeTransform {
  transform(array: Array<any>, args: string): Array<any> {
    if (!!array) {
      args === 'acquisitionDate'
        ? array.sort((a: any, b: any) =>
            a[args] > b[args] ? -1 : a[args] < b[args] ? 1 : 0
          )
        : array.sort((a: any, b: any) =>
            a[args] < b[args] ? -1 : a[args] > b[args] ? 1 : 0
          );
    }
    return array;
  }
}
