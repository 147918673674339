<div class="watchlist"
     *ngIf="this.socialInfo && this.socialInfo.contentId"
     (click)="addRemoveToWhatchList()"
     [ngClass]="{'active': socialInfo.isMyContent, 'option': true }">

  <a [ngStyle]="{'cursor': enabledList? 'pointer' : 'wait' }"  class="button-link">
    <i class="iconlace iconlace-list"></i>
    <span>Mi Lista</span>
  </a>
</div>

