import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment as ENV } from '@environment/environment';
import { tap } from 'rxjs/operators';

import { IContentDigitalPurchasesViewModel } from '@interfaces/i-content-digital-purchases-view-model';
import { IContentSocialInfoViewModel } from '@interfaces/i-content-social-info-view-model';
import { ISearchResponseViewModel } from '@interfaces/i-search-response-view-model';
import { IPagingResponseModel } from '@interfaces/paging/ipaging-response-model';
import { ISearchRequestModel, PayloadAcquireByAccessCode, ResultAcquireByAccessCode } from '@interfaces/i-search-request-model';
import { IPagingRequestModel } from '@interfaces/ipaging-request-model';
import { IRatingViewModel } from '@interfaces/i-rating';

@Injectable({
  providedIn: 'root',
})
export class ContentV3Service {

  private APIV2 = `${ENV.webApi}v2/content`;
  private APIV3 = `${ENV.webApi}v3/content`;
  private contentSocialInfo: IContentSocialInfoViewModel[] = [];

  constructor(private http: HttpClient) {}

  public getContentSocialInfo(contentId: string, refresh = false): Observable<IContentSocialInfoViewModel> {
    const contentInfo = this.contentSocialInfo.find((value) => value?.contentId === contentId);
    return contentInfo && !refresh ? of(contentInfo) : this.getSocialInfo(contentId);
  }

  public followUnFollow(contentId: string): Observable<void> {
    const url = `${this.APIV2}/follow/unfollow/${contentId}`;
    return this.http.get<void>(url);
  }

  public getDigitalV3Purchases(
    payload: IPagingRequestModel
  ): Observable<IPagingResponseModel<IContentDigitalPurchasesViewModel>> {
    return this.http.post<
      IPagingResponseModel<IContentDigitalPurchasesViewModel>
    >(`${this.APIV3}/getDigitalPurchases`, payload);
  }

  public getAcquireContent(contentId: string): Observable<boolean> {
    const url = `${this.APIV2}/getacquirecontent/${contentId}`;
    return this.http.get<boolean>(url);
  }

  public contentAcquire(contentId?: string): Observable<boolean> {
    const url = `${this.APIV2}/acquire/${contentId}`;
    return this.http.get<boolean>(url);
  }

  public getRating(contentId: string): Observable<IRatingViewModel> {
    const url = `${this.APIV2}/contentrating/${contentId}`;
    return this.http.get<IRatingViewModel>(url);
  }

  public getSocialInfo(contentId: string): Observable<IContentSocialInfoViewModel> {
    const url = `${this.APIV2}/socialinfo/${contentId}`;
    return this.http
      .get<IContentSocialInfoViewModel>(url)
      .pipe(
        tap((response: IContentSocialInfoViewModel) =>
          this.contentSocialInfo.push(response)
        ));
  }

  public toggleMyLIst(contentId: string): Observable<void> {
    const url = `${this.APIV2}/toggle/mylist/${contentId}`;
    return this.http.get<void>(url);
  }

  public toggleLike(contentId: string, puserId?: string): Observable<void> {
    const url = `${this.APIV2}/toggle/like/${contentId}/${puserId}`;
    return this.http.get<void>(url);
  }

  public heared({ contentId: string, secondHeard: number }): Observable<void> {
    return this.http.post<void>(`${this.APIV2}/heared`, { contentId: string, secondHeard: number });
  }

  public rating(contentId: string, rating: number): Observable<string> {
    return this.http.post<string>(`${this.APIV2}/rating/${contentId}/${rating}`, {contentId, rating});
  }

  public advancedSearch(payload: ISearchRequestModel): Observable<ISearchResponseViewModel> {
    return this.http.post<ISearchResponseViewModel>(`${this.APIV2}/search`, payload);
  }

  public acquireByAccessCode(payload: PayloadAcquireByAccessCode): Observable<ResultAcquireByAccessCode> {
    return this.http.post<ResultAcquireByAccessCode>(`${this.APIV2}/acquireByAccessCode`, payload)
  }
}
