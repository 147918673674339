import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { RestHandlerHttpClient } from './http';
import {IRestHandlerResponse, IRestRequest} from 'rest-core';
import {Router} from '@angular/router';
import { TraceIdentifierService } from '@api/v2/trace-identifier.service';
import { LoadingOverlayService } from '@app/shared/services/loading-overlay.service';

/**
 * Created by sporta on 12/11/17.
 */

@Injectable()
export class EnlaceRestHandler extends RestHandlerHttpClient {

  queueRequest = [];

  constructor(
    http: HttpClient,
    private router: Router,
    private traceService: TraceIdentifierService,
    private loadingOverlayService: LoadingOverlayService
    ) {
    super(http);
  }

  cancelRequests() {
    this.queueRequest.forEach((value: IRestHandlerResponse) => {
      value.abort();
    });
  }

  handle(req: IRestRequest): IRestHandlerResponse {
    const request = this.prepareRequest(req);
    const resp: IRestHandlerResponse = {
      promise: null
    };

    this.queueRequest.push(resp);
    resp.promise = new Promise((resolve, reject) => {

      let subscription = this.request(request)
        .subscribe(
          (response: any) => {
            this.queueRequest.splice(this.queueRequest.indexOf(resp), 1);
            subscription = null;
            resolve(this.handleResponse(req, response));
          },
          (err: any) => {
            if(err.status === 500) {
              this.loadingOverlayService.hideLoadingOverlay();
              this.traceService.identifyTraceIdentifier(err);
            } else if(err.status === 404) {
              this.loadingOverlayService.hideLoadingOverlay();
            } else {
              this.queueRequest.splice(this.queueRequest.indexOf(resp), 1);
              subscription = null;
              if (err.error) {
                reject(err.error);
              }else {
                reject(this.handleResponse(req, err));
              }
            }

          }
        );

      resp.abort = () => {
        if (subscription) {
          this.queueRequest.splice(this.queueRequest.indexOf(resp), 1);
          subscription.unsubscribe();
          subscription = null;
        }
      };

    });

    return resp;
  }


}
