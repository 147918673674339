import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-more-info',
  templateUrl: './skeleton-more-info.component.html',
  styleUrls: ['./skeleton-more-info.component.scss']
})
export class SkeletonMoreInfoComponent {

  @Input() loading: boolean;
  @Input() contentType: string;

}
