import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-channel-loader',
  templateUrl: './channel-loader.component.html',
  styleUrls: ['./channel-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelLoaderComponent {

  public lottieConfig: Object;

  constructor() {
    this.lottieConfig = {
      path: 'assets/json/lottie-loader.json',
      autoplay: true,
      loop: true,
    };
  }
}
