import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-header-podcast',
  templateUrl: './header-podcast.component.html',
  styleUrls: ['./header-podcast.component.scss']
})
export class HeaderPodcastComponent {

  @Output() redirectToContent: EventEmitter<void> = new EventEmitter();

}
