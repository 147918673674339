import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef,
  Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { DLocalFormService } from '@utils/dlocal-forms.service';

import { LOCAL_CODES } from '@constants/country-codes.constants';
import { HandleDLocalErrors } from '@interfaces/i-dlocal-fields.interface';
import { Country } from '@interfaces/i-country';

@Component({
  selector: 'app-dlocal-form-inline',
  templateUrl: './dlocal-form-inline.component.html',
  styleUrls: ['./dlocal-form-inline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DlocalFormInlineComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() countries: Country[] = [];

  @ViewChild('formPanField', { static: true }) formPanField: ElementRef;
  @ViewChild('formExpirationField', { static: true }) formExpirationField: ElementRef;
  @ViewChild('formCvvField', { static: true }) formCvvField: ElementRef;

  public form: UntypedFormGroup;
  private formSubmitAttempt: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dLocalFormService: DLocalFormService
  ) { }

  ngOnInit(): void {
    this.buildForm();
  }

  ngAfterViewInit(): void {
    this.dLocalFormService.init(
      {
        formPanField: this.formPanField,
        formExpirationField: this.formExpirationField,
        formCvvField: this.formCvvField
      });
  }

  ngOnDestroy(): void {
    this.dLocalFormService.clear();
  }

  get localCountries(): Country[] {
    return this.countries.filter(country => LOCAL_CODES.includes(country.code));
  }

  get cardNameField(): AbstractControl {
    return this.form.get('cardName');
  }

  get countryField(): AbstractControl {
    return this.form.get('country');
  }

  get isDefaultCardField(): AbstractControl {
    return this.form.get('isDefaultCard');
  }

  get cardTokenField(): AbstractControl {
    return this.form.get('cardToken');
  }

  get isPanError$(): Observable<HandleDLocalErrors> {
    return this.dLocalFormService.isPanError$;
  }

  get isExpirationError$(): Observable<HandleDLocalErrors> {
    return this.dLocalFormService.isExpirationError$;
  }

  get isCvvError$(): Observable<HandleDLocalErrors> {
    return this.dLocalFormService.isCvvError$;
  }

  public saveCard(): void {
    this.formSubmitAttempt = true;
  }

  public isInValid(control: string): boolean {
    return (this.form.get(control).touched && !!this.form.get(control).errors)
    || (this.form.get(control).untouched && this.formSubmitAttempt);
  }

  public hasError(type: string, control: string): boolean {
    return this.form.get(control).errors[type];
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      cardName: ['', [Validators.required]],
      payerlastName: ['', [Validators.required]],
      address: ['', [Validators.required]],
      country: [null, [Validators.required]],
      state: ['', [Validators.required]],
      city: [null, [Validators.required]],
      isDefaultCard: [false],
      cardToken: [''],
      payerDocument: ['', [Validators.required]]
    });
  }

}
