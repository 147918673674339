import { Component, Input, OnInit } from "@angular/core";
import { PPV } from "@app/core/models/interfaces/home-catalog.interface";

@Component({
  selector: "app-tag-ppv",
  styles: [
    `
      .tag-ppv {
        position: absolute;
        right: -27px;
        top: 20px;
        width: 128px;
        background: linear-gradient(to right, #AF3DF5, #6C00C0);
        transform: rotate(45deg);
        top: 10px;
        text-align: center;
        box-shadow: 1px 1px 2px black;
        font-size: 12px;
        padding-left: 25px;
      }
    `,
  ],
  template: ` <div class="tag-ppv" *ngIf="showPPV">$ {{ price }}</div> `,
})
export class TagPpvComponent implements OnInit {
  @Input() ppv: PPV;
  @Input() acquired: boolean = false;

  price: number | string;
  showPPV: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (this.ppv) {
      this.showPPV = this.ppv !== null && !this.acquired;
      if (this.showPPV) {
        this.price = this.findPrice();
      }
    }
  }

  findPrice(): number | string {
    if (this.ppv !== null) {
      const price = (this.ppv.purchasePrice || this.ppv.rentalPrice) ?? 0;
      return price.toFixed(2);
    }
    return 0;
  }
}
