import {
  AfterViewChecked, ChangeDetectorRef,
  Component, Input, ViewEncapsulation,
  Output, EventEmitter, HostListener, OnInit
} from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';

import { IContentCatalogViewModel } from '@interfaces/i-content-catalog-view-model';
import { ICatalogViewModel } from '@interfaces/icatalog-view-model';
import { BreakpointLimit, BREAKPOINT_SEASON, LandingSeason } from '@api/models/entities/season.interface';
import { IUser } from '@interfaces/i-user.interface';

@Component({
  selector: 'app-seasons',
  templateUrl: './seasons.component.html',
  styleUrls: ['./seasons.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SeasonsComponent implements OnInit, AfterViewChecked {
  @LocalStorage('user')
  user: IUser;

  _programSeasons: ICatalogViewModel[];
  lastActive: any;

  sliderObject: any = null;
  limit: number = 1;
  moreSeasonsDropdown: boolean = false;
  SeasonLeftOutSelected: boolean = false;
  moreSeasonsName: string = 'Más'; //<i class="iconlace iconlace-chevron-down"></i>
  actionName: any = this.moreSeasonsName;
  innerWidth: any = 0;

  @Input() hostsList: any;
  @Input() seasonSelected: number;
  @Input() parentShowingPPV: number;
  @Input() parentShowingRental: number;
  @Input() landingSeason: string = LandingSeason.HOME;

  @Output() changeLastActive: EventEmitter<ICatalogViewModel> =
    new EventEmitter();
  @Output() changeContentSelected: EventEmitter<IContentCatalogViewModel> =
    new EventEmitter();
  @Output() paginate: EventEmitter<any> = new EventEmitter();

  contentSelected: IContentCatalogViewModel;

  @Input() isLandingProgram: boolean = false;
  @Input() numbersSeason: any[];

  @Input()
  set programSeasons(value: ICatalogViewModel[]) {
    this._programSeasons = value;

    this._programSeasons.forEach((v) => (v.isMySee = true));
    this._programSeasons.forEach((v) => (v.visible = false));
    if (value.length > 0) {
      if (this.seasonSelected) {
        this._programSeasons.map(item => {
          if (item.order === this.seasonSelected) {
            item.visible = true;
          }
          return item;
        });
        this.lastActive = this._programSeasons.find(item => item.order === this.seasonSelected);
      } else {
        this._programSeasons[0].visible = true;
        this.lastActive = this._programSeasons[0];
      }
      this.selectTab(this.lastActive);
    }
  }

  get programSeasons(): ICatalogViewModel[] {
    return this._programSeasons;
  }

  constructor(private cdRef: ChangeDetectorRef) {}

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  calculateProgress(content: any) {
    if (this.user && this.user.videoUserViews) {
      const videoUserView = this.user.videoUserViews.find((f) => {
        return f.videoId === content.contentId;
      });
      content.percent = 0;
      if (videoUserView) {
        content.percent = videoUserView.secondEnd / videoUserView.totalSeconds;
      }
    }
  }

  ngOnInit(): void {
    if (typeof window !== 'undefined') {
      this.seasonsLimit(window.innerWidth);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.seasonsLimit(event.target.innerWidth);
  }

  seasonsLimit(innerWidth: number): boolean {
    if (innerWidth >= BreakpointLimit.BREAK_MEDIUM_DESKTOP) {
      this.limit = BREAKPOINT_SEASON[this.landingSeason][BreakpointLimit.BREAK_MEDIUM_DESKTOP];
    } else if (innerWidth >= BreakpointLimit.BREAK_SMALL_LAPTOP) {
      this.limit = BREAKPOINT_SEASON[this.landingSeason][BreakpointLimit.BREAK_SMALL_LAPTOP];
    } else if (innerWidth >= BreakpointLimit.BREAK_TABLET) {
      this.limit = BREAKPOINT_SEASON[this.landingSeason][BreakpointLimit.BREAK_TABLET];
    } else if (innerWidth >= BreakpointLimit.BREAK_SMALL_TABLET) {
      this.limit = BREAKPOINT_SEASON[this.landingSeason][BreakpointLimit.BREAK_SMALL_TABLET];
    } else if (innerWidth >=  BreakpointLimit.BREAK_MOBILE) {
      this.limit = BREAKPOINT_SEASON[this.landingSeason][BreakpointLimit.BREAK_MOBILE];
    } else if (innerWidth >= BreakpointLimit.BREAK_MEDIUM_MOBILE && innerWidth < BreakpointLimit.BREAK_MOBILE) {
      this.limit = BREAKPOINT_SEASON[this.landingSeason][BreakpointLimit.BREAK_MEDIUM_MOBILE];
    } else {
      this.limit = 1;
    }
    this.defineActionName();
    return this.isLandingProgram
      ? this.numbersSeason.length > this.limit
      : this._programSeasons.length > this.limit;
  }

  get temporadasMain(): ICatalogViewModel[] {
    return this.seasonsLimit
      ? this.isLandingProgram
        ? this.numbersSeason.slice(0, this.limit)
        : this._programSeasons.slice(0, this.limit)
      : this.isLandingProgram
        ? this.numbersSeason
        : this._programSeasons;
  }

  get temporadasLeftOut(): ICatalogViewModel[] {
    this.SeasonLeftOutSelected = (this.lastActive.order > this.limit && !this.moreSeasonsDropdown);

    return this.seasonsLimit
      ? this.isLandingProgram
        ? this.numbersSeason.slice(this.limit, this.numbersSeason.length)
        : this._programSeasons.slice(this.limit, this._programSeasons.length)
      : [];
  }

  changeValue(value: string, type: string): void {
    if (type === 'dropdown') {
      this.actionName = value;
    } else if (type === 'link') {
      this.actionName = this.moreSeasonsName;
    }
  }

  toggleMoreSeasons() {
    this.moreSeasonsDropdown = !this.moreSeasonsDropdown;
    this.defineActionName();
  }

  defineActionName() {
    this.actionName = (
      !this.moreSeasonsDropdown &&
      this.temporadasLeftOut.some(tlo => tlo.order == this.lastActive.order)
        ? this.lastActive.order
        : this.moreSeasonsName);
  }

  selectTab(programSeason) {
    this.sliderObject = null;
    this.programSeasons.forEach((v) => {
      v.visible = false;
    });
    this.moreSeasonsDropdown = false; //Cerrar el dropdown de mas temporadas si esta abierta
    const findSeason = this.programSeasons.find(item => item.name === programSeason.name);
    !findSeason ? this.paginate.emit({programSeason, type: 'vertical'}) : this.changeTabDefault(findSeason);
  }

  changeTabDefault(programSeason: ICatalogViewModel): void {
    programSeason.visible = true;
    this.lastActive = this.programSeasons.find(item => item.name === programSeason.name);
    this.lastActive.visible = true;
    this.contentSelected = null;
    this.lastActive.contentCatalogViewModels.forEach((content) => {
      if (this.user && this.user.videoUserViews) {
        const videoUserView = this.user.videoUserViews.find((f) => {
          return f.videoId === content.contentId;
        });
        if (videoUserView && videoUserView.secondEnd > 0) {
          this.contentSelected = content;
        }
      }
    });
    if (this.contentSelected) {
      this.changeContentSelected.emit(this.contentSelected);
    } else {
      this.changeLastActive.emit(this.lastActive);
    }
  }

  onClickedOutside(e: Event) {
    this.moreSeasonsDropdown = !this.moreSeasonsDropdown;
  }

  pagingContentProgram(event): void {
    this.paginate.emit(event);
  }

  setupSlider(slider) {
    if (!this.sliderObject) {
      this.sliderObject = jQuery(slider).lightSlider({
        autoWidth: true,
        slideMove: 1, // slidemove will be 1 if loop is true
        slideMargin: 10,

        addClass: '',
        mode: 'slide',
        useCSS: true,
        cssEasing: 'ease', //'cubic-bezier(0.25, 0, 0.25, 1)',//
        easing: 'linear', //'for jquery animation',////
        adaptiveHeight: false,
        speed: 800, //ms'
        auto: false,
        loop: false,
        slideEndAnimation: true,
        pause: 2000,

        keyPress: false,
        controls: true,

        rtl: false,

        vertical: false,
        verticalHeight: 500,
        vThumbWidth: 100,

        pager: false,
        gallery: false,
        galleryMargin: 5,
        thumbMargin: 10,
        currentPagerPosition: 'middle',

        enableTouch: true,
        enableDrag: true,
        freeMove: true,
        swipeThreshold: 40,

        onSliderLoad: () => {},
        responsive: [
          {
            breakpoint: 800,
            settings: {
              item: 3,
              slideMove: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              item: 2,
              slideMove: 1,
            },
          },
        ],
      });
    }
  }
}
