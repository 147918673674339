import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseSoaApi } from '@app/core/models/interfaces/i-cancel-subscription';
import { environment as ENV } from '@environment/environment';
import { Observable, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ValidateExpressionService {
  private API = `${ENV.soaApiBaseUrl}badexpression`;

  constructor(private http: HttpClient) { }

  getValidateExpression(expression: string): Observable<ResponseSoaApi> {
    const url = `${this.API}/ThereIsBadExpressions/${expression}`;
    return this.http.get<ResponseSoaApi>(url);
  }
  
}
