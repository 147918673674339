import { Injectable } from '@angular/core';
import { AppInsightsService } from '@markpieszak/ng-application-insights';
import { IAppInsights, LogEvent } from '@interfaces/i-app-insights.interface';

@Injectable()
export class MyMonitoringService {

  constructor(private appInsightsService: AppInsightsService) {}

  public logPageView({name,url,properties,measurements,duration}: IAppInsights) {
    this.appInsightsService.trackPageView(name,url,properties,measurements,duration);
  }

  public logEvent({name, properties, measurements}: LogEvent) {
    this.appInsightsService.trackEvent(name, properties, measurements);
  }
}
