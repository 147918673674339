import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-paging',
  templateUrl: './loading-paging.component.html',
  styleUrls: ['./loading-paging.component.scss']
})
export class LoadingPagingComponent {

  @Input() loadingPaging: boolean

  constructor() { }

}