import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { MailingService } from '@services/mailing.service';

// Este validador solo será usado para formulario basado en plantilla
@Injectable({ providedIn: 'root' })
export class ValidatedEmailValidator implements AsyncValidator {

  constructor(private mailingService: MailingService) {}

  validate(
    control: AbstractControl
  ): Observable<ValidationErrors | null> {
    return this.mailingService.verifyEmail(control.value).pipe(
      map(isValid =>
        typeof isValid === 'boolean' && isValid
          ? null
          : { isInvalidEmail: true }
      ),
      catchError(() => of(null))
    );
  }

}
