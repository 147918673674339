import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagPlusComponent } from './tag-plus.component';

@NgModule({
  declarations: [TagPlusComponent],
  imports: [
    CommonModule
  ],
  exports: [TagPlusComponent]
})
export class TagPlusModule { }
