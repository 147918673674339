import { Injectable } from '@angular/core';
import { RestClient } from '../rest-client';
import { AuthenticationService } from '../authentication.service';
import { EnlaceRestHandler } from '../enlace-rest-handler';
import { environment } from '../../../environments/environment';
import { IRestMethod, RestAction, RestParams, RestRequestMethod, RestResponseBodyType } from 'rest-core';
import { ICatalogViewModel } from '@core/models/interfaces/icatalog-view-model';
import { IContentSocialInfoViewModel } from '@core/models/interfaces/i-content-social-info-view-model';
import { ISearchRequestModel } from '@core/models/interfaces/i-search-request-model';
import { IRatingViewModel } from '@core/models/interfaces/i-rating';
import { IHighlightTextViewModel } from '@core/models/interfaces/i-highlight-text';
import { HighlightedTextRequest } from '../models/highlighted-text-request';
import { HighlightTextRequest, RemoveHighlightTextRequest } from '../models/highlight-text-request';
import { IHighlightTextHistoryViewModel } from '@core/models/interfaces/i-highlight-text-history';
import { IContentDigitalPurchasesViewModel } from '@core/models/interfaces/i-content-digital-purchases-view-model';
import { IPagingResponseModel } from '@core/models/interfaces/paging/ipaging-response-model';
import { IPagingRequestModel } from '@core/models/interfaces/ipaging-request-model';

@Injectable({
  providedIn: 'root'
})
@RestParams({
  // IRestParams
  pathPrefix: environment.webApi + 'v2/content'
})
export class ContentV2Service extends RestClient {

  contentSocialInfo: IContentSocialInfoViewModel[] = [];

  catalogs: ICatalogViewModel[];

  constructor(restHandler: EnlaceRestHandler, authService: AuthenticationService) {
    super(restHandler, authService);
  }

  @RestAction({
    path: '/getDigitalPurchases',
    method: RestRequestMethod.Get
  })
  getDigitalPurchases: IRestMethod<{ contentType: string }, IContentDigitalPurchasesViewModel[]>;

  @RestAction({
    pathPrefix: environment.webApi + 'v3/content',
    path: '/getDigitalPurchases',
    method: RestRequestMethod.Post
  })
  getDigitalV3Purchases: IRestMethod<IPagingRequestModel, IPagingResponseModel<IContentDigitalPurchasesViewModel>>;

  @RestAction({
    path: '/toggle/like/{!contentId}',
    method: RestRequestMethod.Get
  })
  toggleLike: IRestMethod<{ contentId: string }, void>;

  @RestAction({
    path: '/toggle/mylist/{!contentId}',
    method: RestRequestMethod.Get
  })
  toggleMyLIst: IRestMethod<{ contentId: string }, void>;

  @RestAction({
    path: '/follow/unfollow/{!contentId}',
    method: RestRequestMethod.Get
  })
  followUnFollow: IRestMethod<{ contentId: string }, void>;

  @RestAction({
    path: '/socialinfo/{!contentId}',
    method: RestRequestMethod.Get
  })
  private _getSocialInfo: IRestMethod<{ contentId: string }, IContentSocialInfoViewModel>;

  @RestAction({
    path: '/heared',
    method: RestRequestMethod.Post
  })
  heared: IRestMethod<{ contentId: string, secondHeard: number }, void>;

  @RestAction({
    path: '/rating/{!contentId}/{!rating}',
    method: RestRequestMethod.Post,
    responseBodyType: RestResponseBodyType.Text
  })
  rating: IRestMethod<{ contentId: string, rating: number }, string>;

  /// Recover Rating by ContentId
  @RestAction({
    path: '/contentrating/{!contentId}',
    method: RestRequestMethod.Get
  })
  getRating: IRestMethod<{ contentId: string }, IRatingViewModel>;

  @RestAction({
    path: '/acquire/{!contentId}',
    method: RestRequestMethod.Get
  })
  contentAcquire: IRestMethod<{ contentId: string }, void>;

  @RestAction({
    path: '/getacquirecontent/{!contentId}',
    method: RestRequestMethod.Get
  })
  getAcquireContent: IRestMethod<{ contentId: string }, boolean>;

  @RestAction({
    path: '/gethighlightedtext',
    method: RestRequestMethod.Post
  })
  gethighlightedtext: IRestMethod<HighlightedTextRequest, IHighlightTextViewModel[]>;

  @RestAction({
    path: '/highlighttext',
    method: RestRequestMethod.Post
  })
  highlighttext: IRestMethod<HighlightTextRequest, IHighlightTextViewModel[]>;

  @RestAction({
    path: '/removehighlightedtext',
    method: RestRequestMethod.Post
  })
  removehighlightedtext: IRestMethod<RemoveHighlightTextRequest, IHighlightTextViewModel[]>;

  @RestAction({
    path: '/gethighlightedtextHistory',
    method: RestRequestMethod.Post
  })
  gethighlightedtextHistory: IRestMethod<HighlightedTextRequest, IHighlightTextHistoryViewModel[]>;

  changeMyListSocialInfo(isMyContent: boolean, contentId: string) {
    const content = this.contentSocialInfo.find((c) => {
      return c.contentId === contentId;
    });
    if (content) {
      content.isMyContent = isMyContent;
    }
  }

  getSocialInfo(contentId: string): Promise<IContentSocialInfoViewModel> {
    return new Promise((resolve, rejected) => {
      const contentInfo = this.contentSocialInfo.find((value) => {
        return value.contentId === contentId;
      });
      if (contentInfo) {
        resolve(contentInfo);
      } else {
        this._getSocialInfo({contentId: contentId}).then((value) => {
          this.contentSocialInfo.push(value);
          resolve(value);
        });
      }
    });
  }

  updateSocialInfoCacheIsFollow(contentId: string, enable: boolean) {
    this.contentSocialInfo.find(x => x.contentId == contentId).isFollow = enable;
  }

  updateSocialCacheIsLike(contentId: string, enable: boolean) {
    this.contentSocialInfo.find(x => x.contentId == contentId).isLike = enable;
  }

  @RestAction({
    pathPrefix: environment.webApi + 'v2/content',
    path: '/search',
    method: RestRequestMethod.Post
  })
  advancedSearch: IRestMethod<ISearchRequestModel, any>;

  @RestAction({
    path: '/played/{contentId}/{percentage}',
    method: RestRequestMethod.Get
  })
  setPercentage: IRestMethod<{ contentId: string, percentage: number }, void>;


  sendRequestSearch(searchRequest: ISearchRequestModel): Promise<any> {
    return new Promise((resolve, reject) => {
      this.advancedSearch(searchRequest).then((result) => {
        resolve(result);
      }).catch((e) => {
        console.warn('called .catch on %o arguments %o', this, e);
      });
    });
  }

}
