import { Injectable } from '@angular/core';
import {RestClient} from "./rest-client";
import {AuthenticationService} from "./authentication.service";
import {EnlaceRestHandler} from "./enlace-rest-handler";
import {RestParams, Rest, IRestAction, RestAction, IRestMethod, RestResponseBodyType} from "rest-core";
import {environment} from "../../environments/environment";
import {TranslatorLanguage} from "./models/translator-language";

@Injectable()
@RestParams({
  pathPrefix: environment.webApi + 'translator'
})
export class TranslatorService extends RestClient{

  constructor(restHandler: EnlaceRestHandler, authService: AuthenticationService) {
    super(restHandler,authService);
  }

  @RestAction({
    path: '/languages',
    isArray: true
  })
  getLanguagesAvailable: IRestMethod<any, TranslatorLanguage[]>;


}
