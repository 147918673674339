<div 
  *ngIf="!isHiddenbtn"  
  class="btn-text-container"  
  (click)="openModalPray(); $event.stopPropagation()">
  <button class="btn-pray-request">
    <i class="iconlace iconlace-pray icon-dimensions"></i>
  </button>
  <div class="pray-text-container">
    <p class="pray-text pray-text-show">Oración</p>
  </div>
</div>
