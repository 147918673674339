<div class="ppv-buttons-component" [class.from-modal]="fromModal">
  <div class="button-container" *ngIf="showAcquireButton()">
    <p class="price">${{purchasePrice}}</p>
    <div class="mobile purchase-btn" [ngClass]="color">
      <a (click)="acquireAction()" class="btn btn-primary {{type}}">COMPRAR</a>
    </div>
  </div>
  <div class="button-container" *ngIf="showRentButton()">
    <p class="price">${{rentalPrice}}</p>
    <span class="days"> por {{availableBy}} días</span>
    <div class="mobile rent-btn" [ngClass]="color">
      <a (click)="rentAction()" class="btn btn-primary {{type}}">ALQUILAR</a>
    </div>
  </div>
</div>
