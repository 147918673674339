<div class="container">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-xs-12">
      <app-donation-header
        [style]="style"
        [organizationName]="organizationName"
        [userCurrency]="userCurrency"
        [fromMobile]="fromMobile"
        [fromModal]="fromModal"
        (donationHeaderForm)="onDonationHeaderForm($event)"
        (onCancelDonation)="cancelDonation($event)">
      </app-donation-header>
    </div>
  </div>
  <div class="row" *ngIf="showForms">
    <div class="col-lg-12 col-md-12 col-xs-12">
      <app-subscription-body
        [countries]="countries"
        [enablePaypal]="enablePaypal"
        [style]="style"
        [showEmail]="showEmail"
        [isHidden]="true"
        [gateWay]="gateWay"
        (onGateway)="changeGateWay($event)"
        (onSubmitCard)="submitCard($event)">
      </app-subscription-body>
    </div>
  </div>
</div>
