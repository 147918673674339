import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpLoadingService {

  public httpLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {}

  get isLoading$(): Observable<boolean> {
    return this.httpLoading$.asObservable();
  }

  public show(): void {
    this.httpLoading$.next(true);
  }

  public hide(): void {
    this.httpLoading$.next(false);
  }

  public change(event: boolean): void {
    this.httpLoading$.next(event);
  }
}
