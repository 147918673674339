import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {AuthenticationService} from '../authentication.service';
import {EnlaceRestHandler} from '../enlace-rest-handler';
import {environment} from '../../../environments/environment';
import {IPersonSocialInfoViewModel} from '@interfaces/i-person-social-info-view-model';
import {IRestMethod, RestAction, RestParams, RestRequestMethod} from 'rest-core';
import {IContentCatalogViewModel} from '@interfaces/i-content-catalog-view-model';
import {CatalogContentWithType} from '@interfaces/i-catalog-content-with-type';
import {IPagingRequestModel} from '@interfaces/ipaging-request-model';
import {IPersonViewModel} from '@interfaces/i-person-view-model';
import {IPerson} from '@interfaces/i-person.interface';
import {INameID} from '@interfaces/INameID';

@Injectable({
  providedIn: 'root'
})
@RestParams({
  // IRestParams
  pathPrefix: environment.webApi + 'v2/person'
})
export class PersonService extends BaseService<IPerson> {
  hosts: IPerson[];
  public personId = '';

  constructor(restHandler: EnlaceRestHandler,
              authService: AuthenticationService) {
    super(restHandler, authService);
  }

  @RestAction({
    path: '/host/list',
    method: RestRequestMethod.Get
  })
  _listHosts: IRestMethod<void, IPerson[]>;


  listHosts(): Promise<IPerson[]> {
    return new Promise<IPerson[]>((resolve, reject) => {
      if (this.hosts) {
        resolve(this.hosts);
      } else {
        this._listHosts().then((persons) => {
          this.hosts = persons;
          resolve(this.hosts);
        })
      }
    });
  }

  @RestAction({
    path: '/getPeopleName',
    method: RestRequestMethod.Post
  })
  getPeopleName: IRestMethod<IPagingRequestModel, INameID[]>;

  @RestAction({
    path: '/view/{!personId}'
  })
  getView: IRestMethod<{ personId: string }, IPersonViewModel>;

  @RestAction({
    pathPrefix: environment.webApi + 'v2/person',
    path: '/view/{personId}',
  })
  getViewV3: IRestMethod<{ personId: string }, IPersonViewModel>;


  @RestAction({
    path: '/follow/unfollow/{!personId}',
    method: RestRequestMethod.Get
  })
  followUnFollow: IRestMethod<{ personId: string }, void>;

  @RestAction({
    path: '/socialinfo/{!personId}',
    method: RestRequestMethod.Get
  })
  getSocialInfo: IRestMethod<{ personId: string }, IPersonSocialInfoViewModel>;

  @RestAction({
    path: '/getPersonViewCatalog',
    method: RestRequestMethod.Post
  })
  getPersonViewCatalog: IRestMethod<any, CatalogContentWithType[]>;
  
  @RestAction({
    path: '/getPersonCatalogPaging',
    method: RestRequestMethod.Post
  })
  getPersonCatalogPaging: IRestMethod<any, IContentCatalogViewModel[]>;
}
