import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Ministry } from '../../../../api-client/models/ministry';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popup-filter',
  templateUrl: './popup-filter.component.html',
  styleUrls: ['./popup-filter.component.scss']
})
export class PopupFilterComponent implements OnInit {


  @Input()
  filter:any;

  @Output()
  addFilter: EventEmitter<any> = new EventEmitter<any>();

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.filter.items.forEach((value) => {
      value.visible = true;
    });
  }

  get isCategory(): boolean {
    return this.filter.title === 'Categorías';
  }

  get isTopic(): boolean {
    return this.filter.title === 'Temas';
  }

  get isAnotherFilter(): boolean {
    return !this.isCategory && !this.isTopic;
  }


  onLoaded(isFallback: boolean, item: any) {
    item.visible = true;
  }

  getLabel(item:any): string {
    let value =  item.name || item.fullName;
    value = value.toLowerCase();
    return value;
  }

  clickFilter(item: any) {
    this.addFilter.emit(item);
    this.activeModal.close();
  }

}


