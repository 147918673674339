import { ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { Router } from '@angular/router';
import { IVVideo } from '@interfaces/i-v-video';
import { RoutesApp } from '@enums/type-routes-app.enum';
import { UserStateService } from '@services/user-state.service';
import { CheckoutDonationModalComponent } from '@components/modals/checkout-donation-modal/checkout-donation-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IParametersRequestDonation } from '@app/core/models/interfaces/i-parameters.request.donation';
import { environment as ENV } from '@environment/environment';
import { FeatureFlagV3Service } from '@services/feature-flags-v3.service';
import { IUser } from '@app/core/models/interfaces/i-user.interface';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
@Component({
  selector: 'app-btn-donation',
  templateUrl: './btn-donation.component.html',
  styleUrls: ['./btn-donation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BtnDonationComponent {

  @Input() video: IVVideo;

  @LocalStorage('user') private _user: IUser;

  constructor(
    private router: Router,
    private userStateService: UserStateService,
    private modalService: NgbModal,
    private featureFlagService: FeatureFlagV3Service,
  ) { }

   // Para B2C toca usar el authenticationService
  get isUserLoggedIn(): boolean {
    return this.userStateService.isUserLoggedIn;
  }

  get isLandingDonationEnabled(): boolean {
    return this.featureFlagService.checkFeatureOn('WebEnableDonations');
  }

  modalDonate() {
    const parametersDonation = <IParametersRequestDonation>{
      organizationId: this.video.organizationId || this.video.program.organizationId,
      videoId: this.video.id,
      organizationName: this.video.organization || this.video.program.organization,
      origin: 'video'
    }

    if (this.isUserLoggedIn) {

      if (this._user && this._user.id) {
        const urlWithUserId = `${ENV.donationUrl}?uid=${this._user.id}`;
        window.open(urlWithUserId, "_blank");
      } else {
        window.open(ENV.donationUrl, "_blank");
      }
    } else {
      window.open(ENV.donationUrl, "_blank");
    }
  }

  get isVideoPPV(): boolean {
    return !!this.video && !!this.video.ppv && this.video.ppv.length > 0;
  }
  get isNotStandAlone(): boolean {
    return !!this.video && !!this.video.program;
  }
  get isProgramPPV(): boolean {
    return this.isNotStandAlone && !!this.video.program.ppv && this.video.program.ppv.length > 0;
  }
  get isOrganizationDonationEnabled(): boolean {
    return this.video.organizationDonationEnabled;
  }
  get isNotContentPPV(): boolean {
    return this.isOrganizationDonationEnabled && !this.isProgramPPV && !this.isVideoPPV;
  }
}
