import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {AuthenticationService} from '../authentication.service';
import {EnlaceRestHandler} from '../enlace-rest-handler';
import {environment} from '../../../environments/environment';
import {IRestMethod, RestAction, RestParams, RestRequestMethod } from 'rest-core';
import {IPodcast} from '@core/models/interfaces/i-podcast';
import {IPagingRequestModel} from '@core/models/interfaces/ipaging-request-model';
import {IPodcastContent, IPodcastViewModel} from '@core/models/interfaces/i-podcast-view-model';

@Injectable({
  providedIn: 'root'
})
@RestParams({
  // IRestParams
  pathPrefix: environment.webApi + 'v2/podcast'
})
export class PodcastService extends BaseService<IPodcast> {

  constructor(restHandler: EnlaceRestHandler,
              authService: AuthenticationService) {
    super(restHandler, authService);
  }
  //DONE: Migrados a PodcastV3Service.

  @RestAction({
    path: '/view',
    method: RestRequestMethod.Post
  })
  view: IRestMethod<IPagingRequestModel, IPodcastViewModel>;

  @RestAction({
    path: '/view/recommended',
    method: RestRequestMethod.Get
  })
  viewRecommended: IRestMethod<void, IPodcastViewModel[]>;

  @RestAction({
    path: '/view/podcastItem/{podcastItemId}',
    method: RestRequestMethod.Get
  })
  getPodcastParent: IRestMethod<{ podcastItemId: string }, IPodcastContent>;



}
