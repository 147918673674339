import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'containWord'
})
export class ContainWordPipe implements PipeTransform {

  transform(value: string, words: string[]): any {
    try {
      for (const w of words) {
        if(value.indexOf(w) !== -1) {
          return true;
        }
      }
      return false;
    }catch{
      return false;
    }
  }

}
