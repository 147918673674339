import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';
import { IUser } from '@app/core/models/interfaces/i-user.interface';
import { ModalManagementService } from '@app/shared/utils/modal-management.service';

@Component({
  selector: 'app-btn-pray-request',
  templateUrl: './btn-pray-request.component.html',
  styleUrls: ['./btn-pray-request.component.scss']
})
export class BtnPrayRequestComponent{

  private hiddenRoutes: string[] = ['auth', 'mySubscription', 'myPaymentMethods','mobile'];
  @LocalStorage("user") private user: IUser;

  constructor(
    private router: Router,
    private modalManagementService: ModalManagementService
  ) {}

  get currentRoute(): string {
    return this.router.url.split('?')[0];
  }

  get isHiddenbtn(): boolean {
    return this.currentRoute.split('/').some((el) => this.hiddenRoutes.includes(el));
  }

  openModalPray(): void {
    this.modalManagementService.openModalPray();
  }

}
