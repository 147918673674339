export const environment = {
  name: 'prod',
  production: true,
  tenant: '83a3c80c-dbfc-460d-842c-bc15254f4a39',
  clientID: 'cd0f1359-45fe-426b-9702-8b398a52e6bd',
  signUpSignInPolicy: 'B2C_1_SignInAndSignUp',
  b2cDomain: 'login-prod.enlace.plus',
  b2cScopes: ['https://enlaceb2c.onmicrosoft.com/public-site'],
  webApi: 'https://api.enlace.plus/api/',
  passwordReset: 'B2C_1__ResetPassword',
  authorityPR: 'https://login.microsoftonline.com/tfp/oab2ctest.onmicrosoft.com/B2C_1__ResetPassword',
  appInsights: {
    instrumentationKey: '4e9d9fde-783a-4f78-85e5-a89887e478c9'
  },
  SearchPaginationCount: 10,
  SearchByPublished: true,
  stripePk: 'pk_live_kHUWeNjB3D5GSDGuk5ugjZUu',
  dlocal: '',
  webBaseUrl: 'https://enlace.plus',
  iosBaseUrl: 'https://ios.enlace.plus/donate/approved',
  mobileBaseUrl: 'https://mobile.enlace.plus',
  convertCurrencyApi: 'https://data.fixer.io/api/',
  convertCurrencyAccessKey: '8ef907f44ade6d88db28952e73b5c295',
  blobImgUrl: 'https://enlaceimagesprod.blob.core.windows.net',
  bibleApiKey: 'dfabd59ff5f493934aefba937ff786e2',
  soaApiBaseUrl: 'https://soa-prod.enlace.plus/api/',
  donationUrl: 'https://donaciones.enlace.org/donacionesplus/',
  googleMapsApiKey: 'AIzaSyAqZ_pUuQPSe_igdtqY_dDEI_l3zwKH55w'
};
