<div class="content">
  <div class="info-icon">
    <i class="iconlace iconlace-circle circle"></i>
    <i class="iconlace iconlace-check check"></i>
  </div>
  <div class="info-title">
    Hemos identificado que ya eres un MISIONERO DIGITAL y te damos las gracias.
  </div>
  <div class="info-content">
    <p>Si quisieras dar una donación adicional para apoyar el desarrollo de Enlace+ haz click en Continuar.</p>
  </div>
  <div class="content-buttons">
    <button type="button" class="btn btn-secondary" (click)="actionSecondButton()">CANCELAR</button>
    <button type="button" class="btn btn-primary" (click)="actionFirstButton()">CONTINUAR</button>
  </div>
</div>
