<div class="form-group">
  <label for="date-issue" class="form-label">Fecha de Emisión</label>
  <app-dropdown-select
    [optionList]="filters"
    [currentValue]="currentValue"
    [title]="'DD/MM/AAAA'"
    [additionalClass]="'select-issue-date'"
    (emitOption)="changeFilter($event)">
  </app-dropdown-select>
</div>
