<div class="modal-alert">
  <div class="modal-header">
    <button type="button" class="close closemodal-login" (click)="activeModal.dismiss()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="modal-body">
    <div class="alert-img">
      <img class="img-responsive" src="assets/img/icons/warning.svg" />
    </div>
    <p class="title">{{title}}</p>
    <div class="text-subtitle">
      <span [innerHTML]="text"></span>
    </div>
    <div class="form-group center">
      <a (click)="activeModal.dismiss()" class="btn">Aceptar</a>
    </div>
  </div>
</div>
