import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isEmptyGuid'
})
export class IsEmptyGuidPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const result = value === '00000000-0000-0000-0000-000000000000';
    if (result) {
      return null;
    }

    return value;
  }

}
