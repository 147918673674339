import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseResult, ResponseModalAuth } from '@api/models/entities/auth.interface';


@Component({
  selector: 'app-auth-response-modal',
  templateUrl: './auth-response-modal.component.html',
  styleUrls: ['./auth-response-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthResponseModalComponent  {

  @Input() result: number;
  @Input() fromOutside: boolean;
  @Input() data: any = {};

  constructor(public activeModal: NgbActiveModal) {}

  get isEmailNotExist(): boolean {
    return this.result === BaseResult.USER_NOT_FOUND;
  }

  get isUserNeedsMigrate(): boolean {
    return this.result === BaseResult.USER_NEEDS_MIGRATE && this.fromOutside;
  }

  get isTokenExpired(): boolean {
    return this.result === BaseResult.TOKEN_EXPIRED;
  }

  get isInvalidEmail(): boolean {
    return this.result === BaseResult.INVALID_EMAIL;
  }

  get userWantToCreateAccount(): boolean {
    return (this.result === BaseResult.USER_ALREADY_EXISTS || this.result === BaseResult.USER_NEEDS_MIGRATE) && !this.fromOutside;
  }

  get isResultOk(): boolean {
    return this.result === BaseResult.OK;
  }

  get hasPasswordChangedSuccess(): boolean {
    return this.result === BaseResult.PASSWORD_CHANGED;
  }

  get hasUserMigratedSuccess(): boolean {
    return this.result === BaseResult.USER_HAS_BEEN_MIGRATED;
  }

  public goToCreateAccount(): void {
    this.activeModal.dismiss(ResponseModalAuth.NEW_ACCOUNT);
  }

  public closeModalWithOption(fromButtonHeader: boolean = false): void {
    let response = '';
    switch (this.result) {
      case BaseResult.USER_NOT_FOUND:
        response = ResponseModalAuth.ERROR_EMAIL;
        break;
      case BaseResult.USER_NEEDS_MIGRATE:
        response = ResponseModalAuth.START_MIGRATION;
        break;
      case BaseResult.USER_ALREADY_EXISTS:
        response = fromButtonHeader ? '' : ResponseModalAuth.SEND_EMAIL_RESTORE_PASSWORD;
        break;
      case BaseResult.OK:
        response = ResponseModalAuth.FORGOT_PASSWORD;
        break;
      default:
        break;
    }
    this.activeModal.dismiss(response);
  }

}
