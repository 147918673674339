<div class="modal-header">
  <span class="close-button" (click)="activeModal.close()">
    <span>&times;</span>
  </span>
  <div class="title">{{ filter.title }}</div>
</div>
<div class="modal-body csb">
  <div
    *ngFor="let item of filter.items"
    class="col-sm-6 col-md-6 item"
    [style.display]="item.visible ? 'flex' : 'none'"
    (click)="clickFilter(item)">
    <div class="item-img">
      <ng-container *ngIf="!filter.getAvatar(item) && isCategory">
        <span class="item-icon">
          <i class="iconlace iconlace-book"></i>
        </span>
      </ng-container>
      <ng-container *ngIf="!filter.getAvatar(item) && isTopic">
        <span class="item-icon">
          <i class="iconlace iconlace-topic"></i>
        </span>
      </ng-container>
      <ng-container *ngIf="filter.getAvatar(item)">
        <img
          src="{{ filter.getAvatar(item) }}"
          src-fallback="{{ filter.getAvatarDefault(item) }}"
          (loaded)="onLoaded($event, item)"
        />
      </ng-container>
    </div>
    <div class="item-label">
      {{ getLabel(item) }} <span class="count">({{ item.count }})</span>
    </div>
  </div>
</div>
