import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'searchElements',
})

export class SearchElementsPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {
    const value = this.toLowerCase(searchText);
    return value ? this.filter(items, value) : items;
  }
  
  filter(array: any[], searchText: string): any[] {
    return [...array].filter(item =>
      this.includesIgnoreCase(item.title, searchText) ||
      this.includesIgnoreCase(item.description, searchText)
    );
  }
  
  toLowerCase(text: string): string {
    return text.normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[“”]/g, '"')
    .toLowerCase()
    .trim();
  }
  
  includesIgnoreCase(source: string, searchString: string): boolean {
    return this.toLowerCase(source).includes(this.toLowerCase(searchString));
  }

}
