import { Injectable } from '@angular/core';
import {
  HttpRequest, HttpHandler,
  HttpEvent, HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TraceIdentifierService } from './trace-identifier.service';
import { LoadingOverlayService } from '@app/shared/services/loading-overlay.service';


@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptorService {

  private _ignoreUrls = [
    'api/Bible/search',
  ];

  constructor(
    private traceService: TraceIdentifierService,
    private loadingOverlayService: LoadingOverlayService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!!error && (error.status === 500) && !this.shouldIgnore(req.url)) {
          this.traceService.identifyTraceIdentifier(error);
        }
        if (!!error && (error.status === 401 || error.status === 403)) {
          this.loadingOverlayService.hideLoadingOverlay();
        }
        else if (error.status === 404) {
          this.loadingOverlayService.hideLoadingOverlay();
        }
        return throwError(error);
      })
    );
  }

  private shouldIgnore(url: string): boolean {
    return this._ignoreUrls.some(ignoredUrl => url.includes(ignoredUrl));
  }

}
