import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-tag-progress-bar',
  templateUrl: './tag-progress-bar.component.html',
  styleUrls: ['./tag-progress-bar.component.scss']
})
export class TagProgressBarComponent {

  @Input() progress: number = 0;
  @Input() classBottom: string;
  @Input() className: string = '';

  progressBarWidth: string = '0%';

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.progress) {
      this.updateProgressBarWidth();
    }
  }

  private updateProgressBarWidth(): void {
    this.progressBarWidth = `${this.progress}%`;
  }

}
