import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserCardRowsModule } from '@components/user-card-rows/user-card-rows.module';
import { DonationFormModule } from '@components/donation-form/donation-form.module';

import { CheckoutDonationModalComponent } from './checkout-donation-modal.component';
import { DonationPopupSuccessModule } from '../donation-popup-success/donation-popup-success.module';

@NgModule({
    declarations: [CheckoutDonationModalComponent],
    imports: [
        CommonModule,
        DonationFormModule,
        UserCardRowsModule,
        DonationPopupSuccessModule
    ],
    exports: [CheckoutDonationModalComponent]
})
export class CheckoutDonationModalModule { }
