import {Component, OnInit, Input, Output, EventEmitter, HostListener} from '@angular/core';
import {LocalStorage} from 'ngx-webstorage';
import {Router} from '@angular/router';

import {MyMonitoringService} from '../../../shared/services/lib/monitoring.service';
import {PPVContentService} from '@app/shared/utils/ppvcontent.service';
import {ProgramV3Service} from '@services/program-v3.service';
import {ContentV3Service} from '@services/content-v3.service';

import {IContentSocialInfoViewModel} from '@interfaces/i-content-social-info-view-model';
import {IContentCatalogViewModel} from '@interfaces/i-content-catalog-view-model';
import {ICatalogViewModel} from '@interfaces/icatalog-view-model';
import {Video} from '../../../api-client/models/video';
import {IRatingViewModel} from '@interfaces/i-rating';
import {IUser} from '@interfaces/i-user.interface';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-video-detail-inline',
  templateUrl: './video-detail-inline.component.html',
  styleUrls: ['./video-detail-inline.component.scss'],
})
export class VideoDetailInlineComponent implements OnInit {

  @LocalStorage('user') _user: IUser;

  @Input() color = '';
  @Input() showBreadCrumb = false;

  @Input()
  set contentCatalog(value: IContentCatalogViewModel) {
    this.programSeason = null;
    this.lastViewEpisodeId = null;
    this._contentCatalog = value;

    if (!!value) this.typeContent = value.type;

    this.socialInfo = <IContentSocialInfoViewModel>{isFollow: false};
    if (this._contentCatalog) {
      this.href = window.location.href + this._contentCatalog.type + '/';
      if (this._user && !!this._contentCatalog.contentId) {
        this.contentService.getContentSocialInfo(this._contentCatalog.contentId).subscribe((socialInfo: IContentSocialInfoViewModel) => {
          this.socialInfo = socialInfo;
          this.preloading = false;
        });
      }

      this.getSeasons();
      this.getHosts(this._contentCatalog);
      this.getGuests(this._contentCatalog);
    }
  }

  get contentCatalog(): IContentCatalogViewModel {
    return this._contentCatalog;
  }

  get showRanking(): boolean {
    return this.contentCatalog.type === 'podcast';
  }

  get hasBackgroundImageWide(): boolean {
    return !!this.contentCatalog && this.contentCatalog.backgroundImageWide >= 1;
  }

  @Input() contentSelected: IContentCatalogViewModel;
  @Input() enableScore: boolean;
  @Input() hiddeClose = false;
  @Input() isMyList: boolean;

  @Input()
  set isShow(value: boolean) {
    this._isShow = value;
    this.programSeason = null;
  }

  get isShow(): boolean {
    return this._isShow
  }

  @Input() noBoderBottom = false;
  @Input() showStars = false;
  @Input() toolTipFollow: string;
  @Input() showPlayButtom: boolean = true;

  @Output() clickWatchlist: EventEmitter<number> = new EventEmitter();
  @Output() closeDetail: EventEmitter<void> = new EventEmitter();

  _isShow: boolean = false;
  _contentCatalog: IContentCatalogViewModel;
  currentVideo: Video;
  preloading: boolean = true;
  socialInfo: IContentSocialInfoViewModel;
  href: string;
  display: string;
  organizationId: string;
  programSeason: ICatalogViewModel[];
  lastViewEpisodeId: string;
  isMobile: boolean = false;
  innerWidth: any;
  showMoreInfo: boolean = false;
  typeContent: string = '';
  hosts: any;
  guests: any;
  public tooltipText: string = 'Fecha de emisión original por televisión.';

  constructor(
    private contentService: ContentV3Service,
    private router: Router,
    public datepipe: DatePipe,
    private myMonitoringService: MyMonitoringService,
    private programService: ProgramV3Service,
    private ppvContentService: PPVContentService
  ) {}

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.isMobile = this.innerWidth < 319;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.isMobile = this.innerWidth < 319;
  }

  change(isShow: boolean, contentCatalog: IContentCatalogViewModel) {
    if (!isShow) {
      this.programSeason = null;
    } else if (this._contentCatalog && contentCatalog.contentId === this._contentCatalog.contentId) {
      this.getSeasons();
    } else if (this.contentCatalog && contentCatalog.contentId !== this._contentCatalog.contentId) {
      this.searchRating(contentCatalog.contentId);
    }
  }

  searchRating(id: string) {
    if (id) {
      this.contentService.getRating(id).subscribe((podcastViewModel: IRatingViewModel) => {
        if (podcastViewModel) {
          this.contentCatalog.rating = podcastViewModel.ratingAverage ? podcastViewModel.ratingAverage.toString() : '';
          this.contentCatalog.myRating = podcastViewModel.userRating != null ? podcastViewModel.userRating : 0;
          this.contentCatalog.totalVotes = podcastViewModel.ratingVotes != null ? podcastViewModel.ratingVotes : 0;
        }
      });
    }
  }

  getSeasons() {
    if (this._contentCatalog.type === 'program') {
      if (this._user) {
        this.programService.lastView(this._contentCatalog.contentId).subscribe((v) => {
          this.lastViewEpisodeId = v;
        });
      }
    }
  }

  mapVideoDetail(content, year) {
    let duration = '';
    let result = '';

    if (content.duration) {
      const hours = content.duration.split(':')[0];
      const mins = content.duration.split(':')[1];

      if (hours !== '00') {
        duration += hours;
        duration += ' h ';
      }

      if (mins !== '00') {
        duration += mins;
        duration += ' min';
      }
    }

    if (content.seasonNumber != null && content.episode != null) {
      if (duration !== '') {
        result = 'T.' + content.seasonNumber + ' E.' + content.episode + ' / ' + year + ' / ' + duration;
      } else {
        result = 'T.' + content.seasonNumber + ' E.' + content.episode + ' / ' + year;
      }
    } else {
      if (duration !== '') {
        result = year + ' - ' + duration;
      } else {
        result = year;
      }
    }

    return result;
  }

  getHosts(contentCatalog: IContentCatalogViewModel): void {
    if (contentCatalog.hostsList && contentCatalog.hostsList.length > 0) {
      this.hosts = this.contentCatalog.hostsList.filter(p => !!p.role && p.role === 'Host')
    }
  }

  getGuests(contentCatalog: IContentCatalogViewModel): void {
    if(contentCatalog.hostsList && contentCatalog.hostsList.length > 0) {
      this.guests = this.contentCatalog.hostsList.filter(p => !!p.role && p.role === 'Guest');
    }
  }

  getBackground() {
    if (this.contentCatalog) {
      return this.contentCatalog.urlBackgroundImage || this.contentCatalog.thumbnailUrl;
    }
    return '';
  }

  close() {
    this.programSeason = null;
    this.closeDetail.emit();
    this.isShow = false;
  }

  goToLanding(contentCatalog: IContentCatalogViewModel){
    this.router.navigate([contentCatalog.type, contentCatalog.contentId]);
  }

  play(contentCatalog: IContentCatalogViewModel) {
    if (this.contentSelected) {
      this.router.navigate([this.contentSelected.type, this.contentSelected.contentId]);
      return;
    }

    if ((!this.contentCatalog.ppv || this.contentCatalog.acquired) && this.lastViewEpisodeId && typeof this.lastViewEpisodeId === 'string') {
      this.router.navigate(['/video', this.lastViewEpisodeId]);
      return;
    }

    // TODO revisar esto porque no se estan manejando mas las temporadas en este componente
    if (this.programSeason) {
      if (this.programSeason && this.programSeason.length > 0) {
        // const season = this.programSeason[this.programSeason.length - 1];
        const season = this.programSeason[0];
        if (season.contentCatalogViewModels && season.contentCatalogViewModels.length > 0) {
          const episode = season.contentCatalogViewModels[0];
          this.router.navigate([episode.type, episode.contentId]);
        }
      }
    } else {
      // const season = this.programSeason[0];
      // const episode = season.contentCatalogViewModels[0];
      // this.router.navigate([episode.type,episode.contentId]);
      this.router.navigate([contentCatalog.type, contentCatalog.contentId]);
    }
  }

  /*
  show(video: VideoCatalog) {
    var images = video.video.videoImages.filter(v => v.image.imageTypeId == 6 || v.image.imageTypeId == 4).sort(function (a, b) {
      return b.image.imageTypeId - a.image.imageTypeId;
    });

    if (this.currentVideo && this.currentVideo.id !== video.videoId) {
      this.isShow = true;
      this.currentVideo = video.video;
      this.currentVideo.videoImages = images;
    } else {
      if (!this.currentVideo) {
        this.isShow = true;
        this.currentVideo = video.video;
        this.currentVideo.videoImages = images;
      } else {
        this.isShow = !this.isShow;
      }
    }


    if (this.isShow) {
      this.videoInfo = new VideoInfo();
      this.videoService.getVideoSocialInfo({id: this.currentVideo.id}).then((videoInfo: VideoInfo) => {
        this.videoInfo = videoInfo;
      }).catch((e) => {
        console.warn('called .catch on %o arguments %o', this, e);
        this.isShow = false;
        this.router.navigate(['/500']);
      });
    }
  }
  */

  trackShare(SocialAppId: string) {
    this.myMonitoringService.logEvent({name: 'Share',
    properties: {'VideoId': this.currentVideo.id, 'SocialEventTypeId': 2, 'SocialAppId': SocialAppId}});
  }

  clickWatchlistButton(videoId: number) {
    this.clickWatchlist.next(videoId);
  }

  navigate(contentCatalog: IContentCatalogViewModel) {
    if (!this.isPPV(contentCatalog)) {
      this.play(contentCatalog);
      return;
    } 
  }

  isPPV (contentCatalog: IContentCatalogViewModel) {
    if(this.ppvContentService.isPPV(contentCatalog) && !contentCatalog.acquired) {
      return true;
    }
    return false;
  }

  redirectPerson(id: string,published: boolean) {
    if(published) {
      this.router.navigate(['/people',id])
    }
  }

  public getAirDate(airDate: Date): string {
    if (airDate)
      return this.datepipe.transform(airDate, 'dd') + ' de ' + this.datepipe.transform(airDate, 'MMMM') + ' del ' + this.datepipe.transform(airDate, 'yyyy');
  }
}

