<div class="audiobook-listener-container">
  <div class="listener-container">
    <div class="row" *ngIf="audiobook">
      <div class="col-xs-12">
        <div class="container-breadcrumb">
          <app-breadcrumb-v2></app-breadcrumb-v2>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="row">

          <div class="col-xs-6">
            <div class="title-type"><i class="iconlace iconlace-audiobook"></i>
              <a >{{audiobook?.title}}</a>
            </div>
          </div>
          <div class="col-xs-6 actions-container">
            <div class="ebook-actions">
              <div class="ebook-action-item item1">
                <app-like [socialInfo]="socialInfo" [onlyIcon]="true"></app-like>
              </div>
              <div class="ebook-action-item item2">
                <app-follow [isReader]="true" 
                            [toolTip]="toolTipFollow" 
                            [color]="color" 
                            [socialInfo]="socialInfo" 
                            [preloading]="preloading" 
                            [contentFollowList]="contentFollowList" 
                            [type]="'audiobook'" 
                            [onlyIcon]="true"
                            [show]="_showComponent">
                  </app-follow>
              </div>
              <div class="ebook-action-item item3">
                <div class="ebook-action">
                  <app-social-share *ngIf="audiobook" share [title]="audiobook.title"
                    [description]="audiobook.description" [contentId]="audiobook.contentId" [onlyIcon]="true">
                  </app-social-share>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  <div class="iframe-container">
    <iframe width="100%" height="78%" frameBorder="0" [src]="urlSafe"></iframe>
  </div>
</div>