import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as ENV } from '@environment/environment';

import { IContentCatalogViewModel } from '@interfaces/i-content-catalog-view-model';
import { IProgramSummaryViewModel } from '@interfaces/i-program-summary-view-model';
import { IPagingResponseModel } from '@interfaces/paging/ipaging-response-model';
import { IPagingRequestModel } from '@interfaces/ipaging-request-model';
import { ICatalogViewModel } from '@interfaces/icatalog-view-model';
import { IProgram } from '@interfaces/i-program';
import { ResponseSoaApi } from '@app/core/models/interfaces/i-cancel-subscription';

@Injectable({
  providedIn: 'root',
})
export class ProgramV3Service {

  private API = `${ENV.webApi}v2/program`;
  private APISOA = `${ENV.soaApiBaseUrl}Program/get-program-season`;

  constructor(private http: HttpClient) {}

  public getSeason({
    programId,
    numberSeason,
  }): Observable<ICatalogViewModel[]> {
    const url = `${this.API}/${programId}/seasons/${numberSeason}`;
    return this.http.get<ICatalogViewModel[]>(url);
  }

  public getEpisodesBySeason({
    programId,
    numberSeason,
    paging,
  }): Observable<IContentCatalogViewModel[]> {
    const url = `${this.API}/${programId}/seasons/${numberSeason}/paging`;
    return this.http.get<IContentCatalogViewModel[]>(url, {
      params: { rows: paging.row, page: paging.page },
    });
  }

  public search(payload: IPagingRequestModel): Observable<IPagingResponseModel<IProgramSummaryViewModel>> {
    return this.http.post<IPagingResponseModel<IProgramSummaryViewModel>>(`${this.API}/search`, payload);
  }

  public getView({programId}): Observable<IProgram> {
    const url = `${this.API}/view/${programId}`;
    return this.http.get<IProgram>(url);
  }

  public lastView(programId: string): Observable<string> {
    const url = `${this.API}/lastview/${programId}`;
    return this.http.get<string>(url);
  }

  getProgramSeason(programId: string, season: number, page: number): Observable<ResponseSoaApi> {
    const url = `${this.APISOA}/${programId}/${season}/${page}`;
    return this.http.get<ResponseSoaApi>(url);
  }
}
