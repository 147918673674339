import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateExpiredSeparator'
})

export class DateExpiredSeparatorPipe implements PipeTransform {

  transform(date: string): string {
    if (date)
      return date.replace(/\/+/s, '').replace(/(\d{2})/s, '$1/').trim();
    return date;
  }
}
