import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
@Directive({
  selector: 'input[appOnlyNumber], appOnlyNumber'
})
export class OnlyNumberDirective {
  @Input() isOnlyNumber: boolean = true;

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    if(this.isOnlyNumber){
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    if ( initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
    }
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    if(this.isOnlyNumber){
    // const e = <KeyboardEvent> event;// Segun datos que encontre en internet el KeyboardEvent no seria compatible con Iphone.
    const e = event;
      if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
          // let it happen, don't do anything
          return;
        }
        if(e.shiftKey || !/^[0-9]+$/.test(e.key)){
          e.preventDefault();
        }
    }
  }
}
