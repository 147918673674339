import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICreateTaxTransactionResponse } from '@app/api-client/models/v2/i-create-tax-transaction-response';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-preview-taxe-modal',
  templateUrl: './preview-taxe-modal.component.html',
  styleUrls: ['./preview-taxe-modal.component.scss'],
})
export class PreviewTaxeModalComponent implements OnInit {

  @Input() titleContent: string;
  @Input() title: string;
  @Input() imgContent: string;
  @Input() previewTax: ICreateTaxTransactionResponse;
  @Input() gatewayPaypal: boolean;
  @Input() paymentContent: any;

  @Output() emitSavePay = new EventEmitter<any>();

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {}

  public closeModal(): void {
    this.activeModal.close('close');
  }

  pay() {
    this.emitSavePay.emit();
    this.closeModal();
  }
}
