<div *ngIf="IsPodcast" [flickity]="{cellAlign: 'center',
      pageDots: false,
      groupCells: true,
      freeScroll: false,
      wrapAround: true,
      draggable: false,
      imagesLoaded: true,
      autoPlay: true,
      initialIndex: 0}">
    <div class="carousel-cell" style="cursor: pointer" (click)="openMoreInfo(item.contentId, item.type)"
        *ngFor="let item of podcastsList" [ngStyle]="{'background-image': 'url(' + item?.urlBackgroundImage + ')'}">
    </div>
</div>
<div *ngIf="IsArticle" [flickity]="{cellAlign: 'center',
      pageDots: false,
      groupCells: true,
      freeScroll: false,
      wrapAround: true,
      draggable: false,
      imagesLoaded: true,
      autoPlay: true,
      initialIndex: 0}"
      style="display: inline-block; width: 100%; height: 100%;">
    <div class="carousel-cell-article" style="cursor: pointer; width: 100%; max-width: 979px; position: absolute; left: 0%;" [routerLink]="['/', item.type, item.contentId]" *ngFor="let item of catList">


        <div class='carousel-inner-div' style="height: 100%;">
            <div class="fondo"
            [ngStyle]="{'background-image': 'url(' + item?.urlBackgroundImageHorizontal + ')'}">
            </div>
            <div class="categoria">
                <span class="Articlecategory">{{item.articleTypeName}}</span>
            </div>

            <div class="informacion">
                <span>{{item.publishedDate | dateformat: 'dd MMM yyyy' | uppercase}}</span>
                <h2>{{item.title | cuttext: 30}}</h2>
                <p *ngIf="item.hostsList && item.hostsList.length > 0">
                    <ng-container *ngFor="let hosts of item.hostsList; let last = last">
                        {{hosts.fullName}} <ng-container *ngIf="!last">, </ng-container>
                    </ng-container>
                </p>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="isLibrary">
    <div [flickity]="options" class="hola">
        <div class="carousel-cell" [class.isLibrary]="isLibrary && showFilter" [flickityChild] style="cursor: pointer" [routerLink]="['/', item.type, item.contentId]"
                *ngFor="let item of featured" [ngStyle]="{'background-image': 'url(' + item.imageWebUrl + ')'}">
        </div>
    </div>
</ng-container>


<div *ngIf="newHeroArticle" [flickity]="{cellAlign: 'center',
pageDots: false,
groupCells: true,
freeScroll: false,
wrapAround: true,
draggable: false,
imagesLoaded: true,
autoPlay: true,
initialIndex: 0}"
style="display: inline-block; width: 100%; height: 100%;">
<div class="carousel-cell-article" style="cursor: pointer; width: 100%; max-width: 979px; position: absolute; left: 0%;" [routerLink]="['/article', item.id]" *ngFor="let item of catList">


  <div class='carousel-inner-div' style="height: 100%;">
      <div class="fondo"
      [ngStyle]="{'background-image': 'url(' + (item.content?.contentImages | getImageUrlByType: ['HeroCaratula','carátula para layout default']) + ')'}">
      </div>
      <div class="description">
        <div class="info-author">
          <div class="author">{{ item.content.contentPeople[0].person.fullName }}</div>
          <div class="date-publish">{{ item.content.publishedDate | date }}</div>
        </div>
        <div class="category">
          {{ item.articleType.name }}
        </div>
      </div>
  </div>
</div>
</div>

<!-- Carrusel of comunity -->
<div *ngIf="isComunity" [flickity]="{cellAlign: 'center',
    pageDots: false,
    groupCells: true,
    freeScroll: false,
    wrapAround: true,
    draggable: false,
    imagesLoaded: true,
    autoPlay: true,
    initialIndex: 0}"
    style="display: inline-block; width: 100%; height: 100%;"
>
    <div class="carousel-cell-comunity" *ngFor="let item of comunityList" (click)="openDetail(item)">
        <div class="fondo"
            [ngStyle]="{'background-image': 'url(' + (item?.imageFeaturedUrl | getImageUrlByType) + ')'}">
        </div>
        <div class="description-comunity">
            <div class="author">{{ item.companyName}}</div>
            <div class="location">
                <div><i class="iconlace iconlace-map-marker"></i></div>
                <div class="city">{{ item.state }} - {{ item.country}}</div>
            </div>
        </div>
        <div class="content-category">{{ item.category}}</div>
    </div>
</div>
