import { Pipe, PipeTransform } from '@angular/core';
import { DropdownSelectList } from '@core/models/interfaces/i-dropdown-select.interface';
@Pipe({
  name: 'mapperDropdownList',
})
export class MapperDropdownListPipe implements PipeTransform {
  transform(array: any[], args: string | string[]): DropdownSelectList[] {
    // Modificar según main keys
    return array.map((item) => {
      return typeof args === 'string'
      ? { name: item.name, value: item[args] }
      : { name: item[args[1]], value: item[args[0]]};
    });
  }
}
