import {Injectable} from '@angular/core';
import {ContentV2Service} from './content-v2.service';
import {HighlightTextRequest, RemoveHighlightTextRequest, VerseRequest} from '../models/highlight-text-request';
import {Router} from '@angular/router';
import * as copy from 'copy-to-clipboard';
import {ToastrService} from 'ngx-toastr';
import {FeaturedTooltipComponent} from 'app/pages/bible/featured-tooltip/featured-tooltip.component';

export enum BibleFeaturedColors {
  MarkColor1 = 'MarkColor1',
  MarkColor2 = 'MarkColor2',
  MarkColor3 = 'MarkColor3',
  MarkColor4 = 'MarkColor4',
  MarkColor5 = 'MarkColor5',
  None = ''
}

export class Verse {
  id: string;
  number: string;
  text: string;
  html: string;
  selected: boolean;
  color: BibleFeaturedColors;
  index: number;
  reference: string;
  title: string;
  subTitle: string;
}

@Injectable({
  providedIn: 'root'
})
export class BibleFeaturedService {
  isEditing: boolean = false;
  languageId: string;
  bibleId: string;
  bibleName: string;
  bibleCode: string;
  bookId: string;
  bookName: string;
  chapterId: string;
  private verses: Verse[] = [];

  constructor(
    private contentService: ContentV2Service,
    private toastr: ToastrService,
    private router: Router) {
  }

  addVerse(verse: Verse) {
    this.verses.push(verse);
  }

  removeVerse(verse: Verse) {
    const index = this.verses.indexOf(verse);
    if (index > -1) {
      this.verses.splice(index, 1);
    }
  }

  isSelected(featuredTooltipComponent: FeaturedTooltipComponent, verse: Verse) {
    const element = this.verses.find(item => {
      return verse.id === item.id;
    });
    if (this.verses.filter(x => x.selected).length == 0) {
      featuredTooltipComponent.close();
    }
    return element && element.selected;
  }

  getVerses(): Verse[] {
    return this.verses;
  }

  clear() {
    this.verses = [];
  }

  toggle(verse: Verse) {
    const element = this.verses.find((item: Verse, index: number) => {
      item.index = index;
      return verse.id === item.id;
    });
    if (element) {
      element.selected = !element.selected;
    }
  }

  isAdjacent(verse: Verse) {
    const some = this.verses.some(item => {
      return item.selected;
    });
    return !some || this.verses[verse.index - 1].selected || this.verses[verse.index + 1].selected;
  }

  highLightVerseById(id: string, color: string) {
    const element = this.verses.find(item => {
      return id === item.id;
    });
    if (element) {
      element.color = BibleFeaturedColors[color];
    }
  }

  openSelectedItems() {
    //window.open(window.location.href + '/!' + this.getLink());
    let ids = '';
    this.verses.forEach(item => {
      if (item.selected) {
        ids += ids === '' ? item.id : ',' + item.id;
      }
    });
    const url = window.location.origin + '/bible/search/' + this.languageId + '/' + this.bibleId + '/' + this.generateVersesUrl(ids);
    window.open(url);
    this.unSelectAll();
  }

  generateVersesUrl(verseIds: string) {
    let title = '';
    let firstRangeVerse = '';
    const versesRange = verseIds.split(',');

    if (versesRange.length === 1) {
      return `${this.chapterId}:` + versesRange[0];
    }

    for (let i = 0; i < versesRange.length; i++) {
      if (i === 0) {
        firstRangeVerse = `${this.chapterId}:` + versesRange[i];
      } else {
        if (parseInt(versesRange[i], 10) !== parseInt(versesRange[i - 1], 10) + 1) {
          title += `${firstRangeVerse}-${versesRange[i - 1]},${this.chapterId}:${versesRange[i]}`;
          firstRangeVerse = '';
        } else if (parseInt(versesRange[i], 10) === parseInt(versesRange[i - 1], 10) + 1 && i === versesRange.length - 1) {
          title += `${firstRangeVerse}-${versesRange[i]}`;
        }
      }
    }
    return title;
  }

  copyToClipboard() {
    let ids = '';
    this.verses.forEach(item => {
      if (item.selected) {
        ids += ids === '' ? item.id : ',' + item.id;
      }
    });
    const url = window.location.origin + '/bible/search/' + this.languageId + '/' + this.bibleId + '/' + this.generateVersesUrl(ids);
    copy(url);
    this.toastr.clear();
    const inserted = this.toastr.show('', '¡Link copiado!');
    this.unSelectAll();
  }

  getLink(): string {
    const elem = this.verses.find(verse => {
      return verse.selected;
    });
    return elem ? elem.id : '';
  }

  highLightVerses(markColor: BibleFeaturedColors, userId?: string) {
    const vRequest: VerseRequest[] = [];

    this.verses.forEach(verse => {
      if (verse.selected) {
        verse.color = markColor;
        verse.selected = false;
        vRequest.push({
          verseId: verse.id,
          verseText: verse.text
        });
      }
    });

    const request: HighlightTextRequest = {
      languageId: this.languageId,
      bibleId: this.bibleId,
      bibleName: this.bibleName,
      bibleCode: this.bibleCode,
      bookId: this.bookId,
      bookName: this.bookName,
      chapterId: this.chapterId,
      userId: userId,
      color: markColor,
      highlightList: vRequest
    }
    this.contentService.highlighttext(request).then(result => {
      console.log(result);
    });
  }

  removeHighLightVerses(markColor: BibleFeaturedColors, userId?: string) {
    const vRequest: String[] = [];

    this.verses.forEach(verse => {
      if (verse.selected) {
        verse.color = markColor;
        verse.selected = false;
        vRequest.push(verse.id);
      }
    });

    const request: RemoveHighlightTextRequest = {
      bibleId: this.bibleId,
      bookId: this.bookId,
      chapterId: this.chapterId,
      userId: userId,
      verseIdList: vRequest
    }
    this.contentService.removehighlightedtext(request).then(result => {
      console.log(result);
    });
  }

  unSelectAll() {
    this.isEditing = false;
    this.verses.forEach(verse => {
      verse.selected = false;
    });
  }
}
