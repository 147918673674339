import {Injectable} from '@angular/core';

import { COUNTRIES_CURRENCIES } from '@constants/country-time-zone.constants';
import { IUserCountry } from '@interfaces/i-country';

@Injectable({
  providedIn: 'root'
})

export class UserCurrenciesService {

  private countries: IUserCountry[] = COUNTRIES_CURRENCIES;

  constructor() {}

  public getUserCountryByName(name: string): IUserCountry {
    return this.countries.find(country => country.CountryName === name);
  }
}
