import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';


import { DonationHeaderComponent } from '@components/donation-header/donation-header.component';
import { NgxCurrencyModule } from 'ngx-currency';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxCurrencyModule,
  ],
  exports: [DonationHeaderComponent],
  declarations: [DonationHeaderComponent]
})
export class DonationHeaderModule { }
