export enum ContentType {
  video = 'video',
  program = 'program',
  ebook = 'ebook',
  audiobook = 'audiobook',
  article = 'article',
  podcast = 'podcast',
  person = 'person',
  organization = 'organization'
}
