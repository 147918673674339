import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ebook-progress-bar',
  templateUrl: './ebook-progress-bar.component.html',
  styleUrls: ['./ebook-progress-bar.component.scss']
})
export class EbookProgressBarComponent {
  
  percentBar: string = '0%';
  percentValue = 0;
  @Input() componentLocation = 'slider';
  @Input() set percent(percent: number) {
    this.percentBar = percent + '%';
    this.percentValue = percent;
  };

  @Input() hovered;

}
