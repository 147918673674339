export class HtmlElementZoom {
  static setZoomElement(self: any, val: string) {
    const element = <any>self.style;
    element.zoom = val;
  }

  static getZoomElement(self: any): string {
    const element = <any>self.style;
    return element.zoom;
  }
}
