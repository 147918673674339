<div class="header">
  <div class="header-button-container">
    <ng-container *ngIf="contentPPV && !contentPPV?.isParentPpv">
      <div *ngIf="contentPPV.rentalPrice" class="header-button">
        <ng-container *ngTemplateOutlet="buttonRent"></ng-container>
      </div>
      <div *ngIf="contentPPV.purchasePrice" class="header-button">
        <ng-container *ngTemplateOutlet="buttonPurcharse"></ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="!contentPPV && !onlyInfo">
      <div class="header-button">
        <ng-container *ngTemplateOutlet="buttonPlay"></ng-container>
      </div>
    </ng-container>
  </div>
</div>
<div *ngIf="contentDetail.accessCodeEnable">
  <app-gift-code
    [contentDetail]="contentDetail"
    [contentType]="contentType">
  </app-gift-code>
</div>


<!-- template ButtonRent -->
<ng-template #buttonRent>
  <app-button-enlace-plus [className]="'btn-video-rent'" (actionButton)="rent.emit()">
    <div textButton>
      ALQUILAR <span style="font-weight: 300">POR</span> ${{ contentPPV.rentalPrice }}
    </div>
  </app-button-enlace-plus>
</ng-template>

<!-- templete ButtonPurcharse -->
<ng-template #buttonPurcharse>
  <app-button-enlace-plus [className]="'btn-video-purchase'" (actionButton)="purcharse.emit()">
    <div textButton class="button-text">
      COMPRAR <span style="font-weight: 300">POR</span> ${{ contentPPV.purchasePrice }}
    </div>
  </app-button-enlace-plus>
</ng-template>

<!-- templete ButtonPlay -->
<ng-template #buttonPlay>
  <app-button-enlace-plus [className]="'btn-video-play'" (actionButton)="redirectToContent.emit()">
    <i iconButton class="iconlace iconlace-watch-lineal"></i>
    <div textButton>Reproducir</div>
  </app-button-enlace-plus>
</ng-template>
