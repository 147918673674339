import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {AuthenticationService} from '../authentication.service';
import {EnlaceRestHandler} from '../enlace-rest-handler';
import {environment} from '../../../environments/environment';
import {IRestMethod, RestAction, RestParams, RestResponseBodyType, RestRequestMethod} from 'rest-core';
import {IProgram} from '@core/models/interfaces/i-program';
import {IProgramSeason} from '@core/models/interfaces/i-program-season';
import {ICatalogViewModel} from '@core/models/interfaces/icatalog-view-model';
import {IPagingRequestModel} from '@core/models/interfaces/ipaging-request-model';

@Injectable({
  providedIn: 'root'
})
@RestParams({
  // IRestParams
  pathPrefix: environment.webApi + 'v2/program'
})
export class ProgramService extends BaseService<IProgram> {

  constructor(restHandler: EnlaceRestHandler,
              authService: AuthenticationService) {
    super(restHandler, authService);
  }

  @RestAction({
    path: '/seasons/{!programId}'
  })
  getSeasons: IRestMethod<{programId: string}, ICatalogViewModel[]>;

  @RestAction({
    path: '/lastview/{!programId}',
    responseBodyType: RestResponseBodyType.Text
  })
  lastView: IRestMethod<{programId: string}, string>;

  @RestAction({
    path: '/view/{!programId}',
    method: RestRequestMethod.Get
  })
  getView: IRestMethod<{programId: string}, IProgram>;

  @RestAction({
    path: '/search',
    method: RestRequestMethod.Post
  })
  search: IRestMethod<IPagingRequestModel, any>;
}
