import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import { FeatureFlagV3Service } from '@services/feature-flags-v3.service';
@Directive({
  selector: '[appFeatureIsOn]'
})
export class FeatureIsOnDirective implements OnInit {

  @Input() appFeatureIsOn: string;

  constructor(
    private _templateRef: TemplateRef<any>,
    private featureFlagService: FeatureFlagV3Service,
    private _viewContainer: ViewContainerRef) {
  }

  ngOnInit() {
    if (this.featureFlagService.checkFeatureOn(this.appFeatureIsOn)) {
      this._viewContainer.createEmbeddedView(this._templateRef);
    } else {
      this._viewContainer.clear();
    }
  }
}
