import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { DirectivesModule } from '@directives/directives.module';
import { PipesV2Module } from '@pipes/pipes-v2.module';

import { InternationalCardFormInlineComponent } from './international-card-form-inline.component';
@NgModule({
  declarations: [InternationalCardFormInlineComponent],
  imports: [CommonModule, ReactiveFormsModule, DirectivesModule, PipesV2Module],
  exports: [InternationalCardFormInlineComponent]
})
export class InternationalCardFormInlineModule { }
