import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {AuthenticationService} from '../authentication.service';
import {EnlaceRestHandler} from '../enlace-rest-handler';
import {environment} from '../../../environments/environment';
import {IRestMethod, RestAction, RestParams, RestRequestBodyType, RestRequestMethod} from 'rest-core';
import {IOrganization} from '@interfaces/i-organization';
import {IOrganizationViewModel} from '@interfaces/i-organization-view-model';
import {IOrganizationViewModelPaging} from '@interfaces/i-organization-view-model-pagin';
import {ISearchOrganizationRequestModel} from '@interfaces/i-search-organization-request-model';
import {IOrganizationSocialInfoViewModel} from '@interfaces/i-organization-social-info-view-model';
import {INameID} from '@interfaces/INameID';
import {IOfficesViewModel} from '@interfaces/i-offices-view-model';
import { IContentCatalogViewModel } from '@interfaces/i-content-catalog-view-model';

@Injectable({
  providedIn: 'root'
})
@RestParams({
  // IRestParams
  pathPrefix: environment.webApi + 'v2/organization'
})
export class OrganizationService extends BaseService<IOrganization> {

  constructor(restHandler: EnlaceRestHandler,
              authService: AuthenticationService) {
    super(restHandler, authService);
  }

  @RestAction({
    path: '/view/{!organizationId}'
  })
  getView: IRestMethod<{ organizationId: string }, IOrganizationViewModel>;

  @RestAction({
    path: '/view/paging',
    method: RestRequestMethod.Post
  })
  GetOrganizationPaging: IRestMethod<any, IContentCatalogViewModel[]>;

  @RestAction({
    path: '/getOrganizations',
    method: RestRequestMethod.Post
  })
  getOrganizations: IRestMethod<ISearchOrganizationRequestModel, IOrganizationViewModelPaging>;

  @RestAction({
    path: '/getAllOrganizationsName',
    method: RestRequestMethod.Post
  })
  getAllOrganizationsName: IRestMethod<ISearchOrganizationRequestModel, INameID[]>;

  @RestAction({
    path: '/getOffices',
    method: RestRequestMethod.Get
  })
  getOffices: IRestMethod<{}, IOfficesViewModel[]>;

  @RestAction({
    path: '/follow/unfollow/{!organizationId}',
    method: RestRequestMethod.Get
  })
  followUnFollow: IRestMethod<{ organizationId: string }, void>;

  @RestAction({
    path: '/socialinfo/{!organizationId}',
    method: RestRequestMethod.Get
  })
  getSocialInfo: IRestMethod<{ organizationId: string }, IOrganizationSocialInfoViewModel>;


}
