<div class="seasons-container" seasons *ngIf="programSeasons">
  <div class="seasons-tabs">
    <div class="nav-item title">Temporadas </div>
    <div class="nav-item" *ngFor="let mainSeason of temporadasMain">
      <a class="nav-link" [ngClass]="{'active': lastActive.name === mainSeason.name}" (click)="selectTab(mainSeason); changeValue(mainSeason.order, 'link')">
        {{mainSeason.order}}
      </a>
    </div>

    <div *ngIf="temporadasLeftOut?.length > 0" class="dropdown">
      <button (click)="toggleMoreSeasons()" class="dropbtn" [ngClass]="{'active': SeasonLeftOutSelected}" id="dropbtn">{{actionName}} <i class="iconlace iconlace-chevron-down"></i></button>
      <div *ngIf="moreSeasonsDropdown" class="dropdown-content" (clickOutside)="onClickedOutside($event)" [delayClickOutsideInit]="true">
        <a *ngFor="let leftOutSeason of temporadasLeftOut" class="nav-link" [ngClass]="{'active': lastActive.name === leftOutSeason.name}" (click)="selectTab(leftOutSeason); changeValue(leftOutSeason.order, 'dropdown')">
          {{leftOutSeason.order}}
        </a>
      </div>
    </div>

  </div>


  <div class="fluid-container">
    <app-slider [isProgram]="isLandingProgram" [showHeader]="false" [paginated]="lastActive.canPage || false" [fillInItems]="lastActive.contentCatalogViewModels" [catalog]="lastActive" [seasons]="true"
    [parentShowingPPV]="parentShowingPPV" [parentShowingRental]="parentShowingRental" (pagingContentProgram)="pagingContentProgram($event)"></app-slider>
  </div>
</div>
