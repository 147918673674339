import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'isExpiredContent'
})
export class IsExpiredContentPipe implements PipeTransform {

  transform(expireDate: string): boolean {
    return moment(expireDate).diff(moment(), 'days') <= 0;
  }

}
